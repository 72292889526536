import React, { useEffect, useRef } from 'react';
import classes from './TableSettingsButton.module.css';
import { Button } from '@consta/uikit/Button';
import LeftSideBar, { LeftSideBarHandleType } from '../../../../../../shared-front/LeftSideBar/LeftSideBar';
import {
  selectorCatalogActiveSection,
  selectorCatalogActiveSectionTableColumns,
  setTableColumns,
} from '../../../../../../store/catalogSlice';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/hooks';
import { IconSettings } from '@consta/uikit/IconSettings';
import TableSettings from '../../../../../../shared-front/TableSettings/TableSettings';
import { getShortColumns } from '../../../../../../shared-front/Table/utils/getShortColumns';
import { TableColumnType } from '../../../../../../shared-front/Table/Table';
import { getTableCatalogColumnsTemplate } from '../../../../../../utils/Catalog/getTableCatalogColumnsTemplate';

interface ITableSettingsButtonProps {
  isShow: boolean;
  refSidebarExternal: React.RefObject<LeftSideBarHandleType>;
}

const TableSettingsButton: React.FC<ITableSettingsButtonProps> = ({ isShow, refSidebarExternal }) => {
  const dispatch = useAppDispatch();
  const refSidebarLocal = useRef<LeftSideBarHandleType>(null);
  const refButton = useRef<HTMLButtonElement>(null);
  // const isHierarchyOpen = useAppSelector(selectorCatalogIsHierarchyOpen);
  const catalogActiveSection = useAppSelector(selectorCatalogActiveSection);
  const columnsStore = useAppSelector(selectorCatalogActiveSectionTableColumns);
  const defaultColumns = getTableCatalogColumnsTemplate(catalogActiveSection?.slug);

  const refSidebar = refSidebarExternal ? refSidebarExternal : refSidebarLocal;

  const openSidebarClick = () => {
    refSidebar.current?.openSidebar();
  };

  const closeSidebarClick = () => {
    refSidebar.current?.closeSidebar();
  };

  useEffect(() => {
    if (!columnsStore.length) {
      const columnsTemplate = getTableCatalogColumnsTemplate(catalogActiveSection?.slug);
      dispatch(setTableColumns(getShortColumns(columnsTemplate)));
    }
  }, [dispatch, catalogActiveSection?.slug, columnsStore.length]);

  const setColumns = (columns: (object & TableColumnType)[]) => {
    dispatch(setTableColumns(columns));
  };

  return (
    <div className={`${isShow ? classes.container : classes.hide}`}>
      <Button
        ref={refButton}
        label="Настройки"
        view="ghost"
        size={'s'}
        iconLeft={IconSettings}
        onClick={openSidebarClick}
        onlyIcon={true}
      />
      <LeftSideBar ref={refSidebar} refButton={refButton}>
        <TableSettings
          closeSidebarClick={closeSidebarClick}
          columns={columnsStore}
          setColumns={setColumns}
          hideFooter={true}
          defaultColumns={defaultColumns}
        />
      </LeftSideBar>
    </div>
  );
};

export default TableSettingsButton;
