import React, { useRef, useState } from 'react';

import classes from './CPRightSideBarInfoRow.module.css';
import { CPRightSideBarEnum } from '../../ConnectionsPage';
import { Text } from '@consta/uikit/Text';
import { Button } from '@consta/uikit/Button';
import { IconKebab } from '@consta/uikit/IconKebab';
import { ContextMenu } from '@consta/uikit/ContextMenu';
import ISPRightSideBarModalDelete from '../../../InfoSystemsPage/ISPRightSideBar/ISPRightSideBarModalDelete/ISPRightSideBarModalDelete';
import { IconEdit } from '@consta/uikit/IconEdit';
import { IconTrash } from '@consta/uikit/IconTrash';
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooks';
import {
  deleteConnectionsThunk,
  selectorConnections,
  selectorConnectionsTableActiveRowUuid,
} from '../../../../store/connectionsSlice';
import { IconDebug } from '../../../../Components/CustomIcons/IconDebug';
import CPRightSideBarModalCheckConnection from '../CPRightSideBarModalCheckConnection/CPRightSideBarModalCheckConnection';
import { useClickOutsideComponent } from '../../../../shared-front/hooks/useClickOutsideComponent';

type Item = {
  id: string;
  label: string;
  leftIcon: typeof IconEdit;
  accent?: 'alert';
};

const items: Item[] = [
  {
    id: 'edit',
    label: 'Редактировать',
    leftIcon: IconEdit,
  },
  {
    id: 'check',
    label: 'Проверить подключение',
    leftIcon: IconDebug,
  },
  {
    id: 'delete',
    label: 'Удалить',
    leftIcon: IconTrash,
    accent: 'alert',
  },
];

interface ICPRightSideBarInfoRowProps {
  rightSideBarMode: CPRightSideBarEnum | undefined;
  setRightSideBarMode?: (mode: CPRightSideBarEnum | undefined) => void;
  closeSideBar?: () => void;
}

const CPRightSideBarInfoRow: React.FC<ICPRightSideBarInfoRowProps> = ({
  rightSideBarMode,
  setRightSideBarMode,
  closeSideBar,
}) => {
  const dispatch = useAppDispatch();
  const refButton = useRef<HTMLButtonElement>(null);
  const refButtonContextMenu = useRef<HTMLDivElement>(null);
  useClickOutsideComponent(
    refButtonContextMenu,
    () => {
      setIsContextMenuOpen(false);
    },
    refButton
  );

  const [isModalDeleteOpen, setIsModalDeleteOpen] = React.useState(false);
  const [isModalCheckOpen, setIsModalCheckOpen] = React.useState(false);
  const [isContextMenuOpen, setIsContextMenuOpen] = useState<boolean>(false);

  const connections = useAppSelector(selectorConnections);
  const connectionsTableActiveRowUuid = useAppSelector(selectorConnectionsTableActiveRowUuid);
  const activeConnection = connections?.data.find((con) => con._uuid === connectionsTableActiveRowUuid);

  const onClick = () => {
    setIsContextMenuOpen(!isContextMenuOpen);
  };

  const onItemClick = ({ item }: { item: Item }) => {
    isContextMenuOpen && setIsContextMenuOpen(false);
    isModalCheckOpen && setIsModalCheckOpen(false);
    isModalDeleteOpen && setIsModalDeleteOpen(false);

    if (item.id === 'edit') {
      setRightSideBarMode && setRightSideBarMode(CPRightSideBarEnum.EditConnection);
    } else if (item.id === 'delete') {
      setIsModalDeleteOpen(true);
    } else if (item.id === 'check') {
      setIsModalCheckOpen(true);
    }
  };

  const onConfirmDelete = () => {
    if (activeConnection) {
      dispatch(deleteConnectionsThunk(activeConnection._uuid)).then(() => {
        closeSideBar && closeSideBar();
      });
    }
  };

  return (
    <div className={classes.container}>
      <Text className={classes.title}>
        {rightSideBarMode === CPRightSideBarEnum.DisplayConnection
          ? 'Просмотр подключения'
          : rightSideBarMode === CPRightSideBarEnum.CreateConnection
          ? 'Создание подключения'
          : rightSideBarMode === CPRightSideBarEnum.EditConnection
          ? 'Редактирование подключения'
          : ''}
      </Text>
      {rightSideBarMode === CPRightSideBarEnum.DisplayConnection && (
        <>
          <Button
            ref={refButton}
            label="режим"
            view="ghost"
            size={'xs'}
            iconLeft={IconKebab}
            onlyIcon={true}
            onClick={onClick}
          />
          <ContextMenu
            ref={refButtonContextMenu}
            isOpen={isContextMenuOpen}
            items={items}
            getItemStatus={(item) => item.accent}
            getItemLeftIcon={(item) => item.leftIcon}
            onItemClick={onItemClick}
            anchorRef={refButton}
            direction="downStartLeft"
            style={{ zIndex: 999, minWidth: '250px' }}
          />
        </>
      )}
      <ISPRightSideBarModalDelete
        isModalOpen={isModalDeleteOpen}
        setIsModalOpen={setIsModalDeleteOpen}
        modalDeleteTitle={activeConnection ? `Удалить «${activeConnection.name}»?` : ''}
        modalDeleteMessage={'При удалении подключения удалятся все отсканированные\nтехнические метаданные'}
        onConfirmDelete={onConfirmDelete}
      />
      {activeConnection && (
        <CPRightSideBarModalCheckConnection
          isModalOpen={isModalCheckOpen}
          setIsModalOpen={setIsModalCheckOpen}
          modalTitle={'Проверка подключения'}
          activeConnection={activeConnection}
        />
      )}
    </div>
  );
};

export default CPRightSideBarInfoRow;
