import { createTableColumn } from '../../shared-front/Table/utils/createTableColumn';
import { ColumnGenericType } from '../../types/types';
import { ReactNode } from 'react';
import { ObjectsTableRdbDataType } from './ObjectsTableRdbColumns';
import { ObjectsTableCdbDataType } from './ObjectsTableСdbColumns';

export type ObjectsTableDbDataType = {
  name?: string | null;
  hierarchy?: ReactNode | null;
  unified_object?: string | null;
  connector_type?: string | null;
  _alter_ts?: string | null;
  description_name?: string | null;
  system?: string | null;
  level_1?: string | null;
  level_2?: string | null;
  level_3?: string | null;
  object_kind?: string | null;
  description_value?: string | null;
  comment?: string | null;
  organization?: string | null;
  ts_alter?: string | null;
};

export const OBJECTS_TABLE_DB_COLUMNS: ColumnGenericType[] = [
  createTableColumn<ObjectsTableDbDataType>({
    accessorKey: 'name',
    title: 'Наименование',
    textLinesNumber: 2,
    isShow: true,
    size: 300,
  }),
  createTableColumn<ObjectsTableDbDataType>({
    accessorKey: 'unified_object',
    title: 'Тип',
    textLinesNumber: 2,
    isShow: true,
    size: 300,
  }),
  createTableColumn<ObjectsTableDbDataType>({
    accessorKey: 'hierarchy',
    title: 'Иерархия',
    textLinesNumber: 2,
    isShow: true,
    size: 300,
  }),
  createTableColumn<ObjectsTableDbDataType>({
    accessorKey: 'description_name',
    title: 'Краткое описание',
    textLinesNumber: 2,
    isShow: true,
    size: 300,
  }),
  createTableColumn<ObjectsTableDbDataType>({
    accessorKey: '_create_ts',
    title: 'Создано',
    textLinesNumber: 2,
    isShow: true,
    size: 300,
  }),
  createTableColumn<ObjectsTableDbDataType>({
    accessorKey: '_alter_ts',
    title: 'Изменено',
    textLinesNumber: 2,
    isShow: true,
    size: 300,
  }),

  createTableColumn<ObjectsTableDbDataType>({
    accessorKey: 'level_2',
    title: 'Схема данных',
    textLinesNumber: 2,
    isShow: false,
    size: 300,
  }),
  createTableColumn<ObjectsTableDbDataType>({
    accessorKey: 'object_kind',
    title: 'Тип объекта',
    textLinesNumber: 2,
    isShow: false,
    size: 300,
  }),
  createTableColumn<ObjectsTableDbDataType>({
    accessorKey: 'description_value',
    title: 'Расширенное описание',
    textLinesNumber: 2,
    isShow: false,
    size: 300,
  }),
  createTableColumn<ObjectsTableDbDataType>({
    accessorKey: 'comment',
    title: 'Описание из системы-источник',
    textLinesNumber: 2,
    isShow: false,
    size: 300,
  }),
  createTableColumn<ObjectsTableRdbDataType>({
    accessorKey: 'ts_create',
    title: 'Создан в системе-источнике',
    textLinesNumber: 2,
    isShow: false,
    size: 300,
  }),
  createTableColumn<ObjectsTableCdbDataType>({
    accessorKey: 'ts_alter',
    title: 'Изменено в системе-источнике',
    textLinesNumber: 2,
    isShow: false,
    size: 300,
  }),
];

// createTableColumn<ObjectsTableDbDataType>({
//   accessorKey: 'organization',
//   title: 'Организация',
//   textLinesNumber: 2,
//   isShow: false,
//   size: 300,
// }),
//   createTableColumn<ObjectsTableDbDataType>({
//     accessorKey: 'ts_alter',
//     title: 'Изменено в системе-источнике',
//     textLinesNumber: 2,
//     isShow: false,
//     size: 300,
//   }),
// createTableColumn<ObjectsTableDbDataType>({
//   accessorKey: 'connector_type',
//   title: 'Тип источника',
//   textLinesNumber: 2,
//   isShow: true,
//   size: 300,
// }),
// createTableColumn<ObjectsTableDbDataType>({
//   accessorKey: 'system',
//   title: 'Информационная система',
//   textLinesNumber: 2,
//   isShow: true,
//   size: 300,
// }),
// createTableColumn<ObjectsTableDbDataType>({
//   accessorKey: 'level_1',
//   title: 'База данных',
//   textLinesNumber: 2,
//   isShow: false,
//   size: 300,
// }),
//   createTableColumn<ObjectsTableDbDataType>({
//     accessorKey: 'level_3',
//     title: 'Объект данных',
//     textLinesNumber: 2,
//     isShow: false,
//     size: 300,
//   }),
