export const getUniqTypedQueries = ({ uniqTypes, queries }: { uniqTypes: string[]; queries: string[] }) => {
  const typedQueries: string[] = [];
  uniqTypes.forEach((curUniqType) => {
    const uuids: string[] = [];
    queries.forEach((curQuery) => {
      const curType = curQuery.split('[').pop()?.split(']')[0];
      if (curType === curUniqType) {
        const curUuid = curQuery.split('=')[1];
        if (curUuid) {
          uuids.push(curUuid);
        }
      }
    });
    if (uuids.length && uuids.length > 0 && uuids.length && uuids.length > 0) {
      const typedQuery = `filter[${curUniqType}]=${uuids.join('|')}`;
      typedQueries.push(typedQuery);
    }
  });
  return typedQueries;
};
