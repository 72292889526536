import { MultiFilterType } from '../Components/FilterWithScrollAndSearch/FilterWithScrollAndSearch';
import { DataFilterType } from '../Components/FilterData/FilterData';
import { FiltersGroupType } from '../Components/FiltersGroup/FiltersGroup';
import { GetCatalogItemsServerResponseType } from '../api/serverResponse';
import { CatalogSectionType, HierarchyItemType, SortingItemType } from '../types/types';
import { useAppDispatch, useAppSelector } from './hooks';
import { selectorMetaSearchValue } from '../store/metaSlice';
import {
  getCatalogItemsThunk,
  selectorCatalogActiveSection,
  selectorCatalogActiveSortingId,
  selectorCatalogDataFilters,
  selectorCatalogGroupFilters,
  selectorCatalogHierarchyItems,
  selectorCatalogItems,
  selectorCatalogMultiFilters,
} from '../store/catalogSlice';
import { SORTING_ITEMS } from '../Pages/CatalogPage/BookmarkContentSwitcher/CatalogContent/ControlRow/SortingSelector/SORTING_ITEMS';
import { getQueryMultiFilters } from '../utils/getQueryMultiFilters';
import { getQueryDataFilters } from '../utils/getQueryDataFilters';
import { getQueryGroupFilters } from '../utils/getQueryGroupFilters';
import { getQueryCatalogActiveSection } from '../utils/getQueryCatalogActiveSection';
import { getQueryCatalogActiveSorting } from '../utils/getQueryCatalogActiveSorting';
import { getQuerySearchValue } from '../utils/getQuerySearchValue';
import { getQueryPaginationFromFirstPage } from '../utils/getQueryPaginationFromFirstPage';
import { combineQuery } from '../utils/combineQuery';
import { getQueryHierarchyFilter } from '../utils/getQueryHierarchyFilter';

interface IUseGetTechMetaDocuments {
  multiFilters?: MultiFilterType[];
  dataFilters?: DataFilterType[];
  groupFilters?: FiltersGroupType[];
  catalogItems?: GetCatalogItemsServerResponseType;
  hierarchyItems?: HierarchyItemType[];
  catalogActiveSection?: CatalogSectionType;
  catalogActiveSortingItem?: SortingItemType;
  searchValue?: string;
  queryPagination?: string;
}

export const useGetTechMetaDocuments = () => {
  const dispatch = useAppDispatch();
  const multiFiltersStore = useAppSelector(selectorCatalogMultiFilters);
  const dataFiltersStore = useAppSelector(selectorCatalogDataFilters);
  const groupFiltersStore = useAppSelector(selectorCatalogGroupFilters);
  const catalogActiveSectionStore = useAppSelector(selectorCatalogActiveSection);
  const activeSortingIdStore = useAppSelector(selectorCatalogActiveSortingId);
  const searchValueStore = useAppSelector(selectorMetaSearchValue);
  const catalogItemsStore = useAppSelector(selectorCatalogItems);
  const hierarchyItemsStore = useAppSelector(selectorCatalogHierarchyItems);

  return ({
    multiFilters,
    dataFilters,
    groupFilters,
    catalogItems,
    hierarchyItems,
    catalogActiveSection,
    catalogActiveSortingItem,
    searchValue,
    queryPagination,
  }: IUseGetTechMetaDocuments) => {
    const queryMultiFilters = getQueryMultiFilters(multiFilters ? multiFilters : multiFiltersStore);
    const queryDataFilters = getQueryDataFilters(dataFilters ? dataFilters : dataFiltersStore);
    const queryGroupFilters = getQueryGroupFilters(groupFilters ? groupFilters : groupFiltersStore);
    const queryCatalogActiveSection = getQueryCatalogActiveSection(
      catalogActiveSection ? catalogActiveSection : catalogActiveSectionStore
    );
    const queryCatalogActiveSorting = getQueryCatalogActiveSorting(
      catalogActiveSortingItem
        ? catalogActiveSortingItem
        : SORTING_ITEMS.find((item) => item.id === activeSortingIdStore)
    );
    const queryCatalogSearchValue = getQuerySearchValue(searchValue ? searchValue : searchValueStore);
    const queryHierarchyFilter = getQueryHierarchyFilter(hierarchyItems ? hierarchyItems : hierarchyItemsStore);

    const requestQuery = combineQuery([
      queryMultiFilters,
      queryDataFilters,
      queryGroupFilters,
      queryCatalogActiveSorting,
      queryCatalogActiveSection,
      queryCatalogSearchValue,
      queryHierarchyFilter,
      queryPagination
        ? queryPagination
        : getQueryPaginationFromFirstPage(catalogItems ? catalogItems : catalogItemsStore),
    ]);
    dispatch(getCatalogItemsThunk(requestQuery));
  };
};
