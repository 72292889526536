import React from 'react';
import { Checkbox } from '@consta/uikit/Checkbox';

import classes from './FilterItem.module.css';
import { MultiFilterItemType } from '../../../FilterWithScrollAndSearch/FilterWithScrollAndSearch';

interface IFilterItemProps {
  item: MultiFilterItemType;
  onChangeItem?: (filterItem: MultiFilterItemType) => void;
  searchValue?: string | null;
}

const FilterItem: React.FC<IFilterItemProps> = ({ item, onChangeItem, searchValue }) => {
  const changeHandler = ({ checked }: { checked: boolean }) => {
    onChangeItem && onChangeItem({ ...item, isChecked: checked });
  };

  const FilterItem: React.FC = () => {
    return (
      <div className={classes.container}>
        <Checkbox checked={item.isChecked} label={item.value} onChange={changeHandler} />
      </div>
    );
  };

  if (searchValue) {
    if (item.value.toLowerCase().includes(searchValue.toLowerCase())) {
      return <FilterItem />;
    } else {
      return null;
    }
  }

  return <FilterItem />;
};

export default FilterItem;
