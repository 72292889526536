import { CreateNewConnectionsSliceType } from '../createNewConnectionSlice';

export const validateAndGoForwardStepTree = (state: CreateNewConnectionsSliceType) => {
  let validateError = false;
  if (!state.connectionName) {
    if (!validateError) {
      validateError = true;
    }
    state.isRequiredConnectionName = true;
  }
  if (!validateError) {
    state.activeStepIndex = 3;
    if (state.returnFromStepIndex) {
      state.returnFromStepIndex = undefined;
    }
  }
};
