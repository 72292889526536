import { DefaultLocale } from 'react-js-cron';

export const DEFAULT_LOCALE_RU: DefaultLocale = {
  everyText: 'каждый',
  emptyMonths: 'каждый месяц',
  emptyMonthDays: 'каждый день в месяце',
  emptyMonthDaysShort: 'день в месяце',
  emptyWeekDays: 'каждый день на неделе',
  emptyWeekDaysShort: 'день в неделю',
  emptyHours: 'каждый час',
  emptyMinutes: 'каждую минуту',
  emptyMinutesForHourPeriod: 'каждый',
  yearOption: 'год',
  monthOption: 'месяц',
  weekOption: 'неделя',
  dayOption: 'день',
  hourOption: 'час',
  minuteOption: 'минута',
  rebootOption: 'перезапустить',
  prefixPeriod: 'Каждый(ая)',
  prefixMonths: 'в',
  prefixMonthDays: 'в',
  prefixWeekDays: 'в',
  prefixWeekDaysForMonthAndYearPeriod: 'числа',
  prefixHours: 'в',
  prefixMinutes: ':',
  prefixMinutesForHourPeriod: 'в',
  suffixMinutesForHourPeriod: 'минута(ы)',
  errorInvalidCron: 'Неверный формат расписания',
  clearButtonText: 'Очистить',
  weekDays: [
    // Order is important, the index will be used as value
    'Воскресенье', // Sunday must always be first, it's "0"
    'Понедельник',
    'Вторник',
    'Среда',
    'Четверг',
    'Пятница',
    'Суббота',
  ],
  months: [
    // Order is important, the index will be used as value
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
  ],
  // Order is important, the index will be used as value
  altWeekDays: [
    'ВС', // Sunday must always be first, it's "0"
    'ПН',
    'ВТ',
    'СР',
    'ЧТ',
    'ПТ',
    'СБ',
  ],
  // Order is important, the index will be used as value
  altMonths: ['ЯНВ', 'ФЕВ', 'МАР', 'АПР', 'МАЙ', 'ИЮН', 'ИЮЛ', 'АВГ', 'СЕН', 'ОКТ', 'НОЯ', 'ДЕК'],
};
