import { CreateNewConnectionsSliceType } from '../createNewConnectionSlice';

export const validateAndGoForwardStepOne = (state: CreateNewConnectionsSliceType) => {
  let validateError = false;
  if (!state.activeInfoSystem) {
    validateError = true;
    state.isRequiredInfoSystem = true;
  }
  if (!state.activeSourceType) {
    if (!validateError) {
      validateError = true;
    }
    state.isRequiredSourceType = true;
  }
  if (!state.activeConnectorType) {
    if (!validateError) {
      validateError = true;
    }
    state.isRequiredConnectorType = true;
  }
  if (!validateError) {
    state.activeStepIndex = 1;
  }
};
