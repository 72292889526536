import { MultiFilterType } from '../Components/FilterWithScrollAndSearch/FilterWithScrollAndSearch';
import { FilterTypeEnum, MultiFilterTagType } from '../types/types';

export const getActiveMultiFilterTags = (multiFilters: MultiFilterType[]) => {
  const activeMultiFilters: MultiFilterTagType[] = [];
  multiFilters.forEach((multiFilter) => {
    multiFilter.items.forEach((item) => {
      if (item.isChecked) {
        activeMultiFilters.push({
          filterType: FilterTypeEnum.multiFilter,
          filterName: multiFilter.name,
          filterTile: multiFilter.title,
          itemUuid: item.uuid,
          itemValue: item.value,
        });
      }
    });
  });
  return activeMultiFilters;
};
