import React, { useEffect } from 'react';

import classes from './CardSystemContent.module.css';
import { BookmarkType } from '../../../../types/types';
import CardTabsRow, { CardTabType } from '../../../../Components/CardTabsRow/CardTabsRow';
import InfoSystemRow from './InfoSystemRow/InfoSystemRow';
import ContentSystemByTabs from './ContentSystemByTabs/ContentSystemByTabs';
import { useActiveTab } from '../../../../hooks/useActiveTab';
import { useLoadBookmarkData } from '../../../../hooks/useLoadBookmarkData';
import WrapperCatalogObjectNotFound from '../../../../Components/WrapperCatalogObjectNotFound/WrapperCatalogObjectNotFound';

interface ICardSystemContentProps {
  bookmark: BookmarkType;
}

export const TABS_SYSTEM: CardTabType[] = [
  { id: 0, label: 'Общая информация' },
  { id: 1, label: 'Объекты' },
];

const CardSystemContent: React.FC<ICardSystemContentProps> = ({ bookmark }) => {
  const loadBookmarkData = useLoadBookmarkData();
  const [activeTab, setActiveTab] = useActiveTab({ bookmark, tabs: TABS_SYSTEM });

  useEffect(() => {
    loadBookmarkData(bookmark);
  }, [bookmark, loadBookmarkData]);

  return (
    <div className={classes.container}>
      <WrapperCatalogObjectNotFound>
        <InfoSystemRow bookmark={bookmark} />
        <CardTabsRow tabs={TABS_SYSTEM} activeTab={activeTab} setActiveTab={setActiveTab} />
        <ContentSystemByTabs activeTab={activeTab} bookmark={bookmark} />
      </WrapperCatalogObjectNotFound>
    </div>
  );
};

export default CardSystemContent;
