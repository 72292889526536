import React from 'react';

import classes from './InformerIcoWithText.module.css';
import iconInfoCircle from '../../assets/CatalogPage/iconInfoCircle.svg';

interface IInformerIcoWithTextProps {
  text: string;
  className?: string | undefined;
}

const InformerIcoWithText: React.FC<IInformerIcoWithTextProps> = ({ text, className }) => {
  return (
    <div className={`${classes.container} ${className ? className : ''}`}>
      <img src={iconInfoCircle} className={classes.ico} alt={'infoIco'} />
      <div className={classes.text}>{text}</div>
    </div>
  );
};

export default InformerIcoWithText;
