import React from 'react';

import classes from './LaunchHistoryControlRow.module.css';
import { Button } from '@consta/uikit/Button';
import { IconRestart } from '@consta/uikit/IconRestart';
import { useAppSelector } from '../../../../../hooks/hooks';
import { selectorLaunchHistory } from '../../../../../store/launchHistorySlice';
import { useGetLaunchHistory } from '../../../../../hooks/useGetLaunchHistory';

interface ILaunchHistoryControlRowProps {}

const LaunchHistoryControlRow: React.FC<ILaunchHistoryControlRowProps> = () => {
  const getLaunchHistory = useGetLaunchHistory();
  const meta = useAppSelector(selectorLaunchHistory)?.meta;

  const onClickButtonRestart = () => {
    if (meta) {
      const queryPagination = `page[size]=${meta.per_page}&page[number]=${meta.current_page}`;
      getLaunchHistory({ queryPagination });
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.controlRow}>
        <Button
          label="Обновить"
          view="ghost"
          size={'s'}
          iconLeft={IconRestart}
          onlyIcon={true}
          onClick={onClickButtonRestart}
        />
      </div>
      <div className={classes.filterBarContainer}>{/*<FilterBar items={[]} onSave={() => {}} />*/}</div>
    </div>
  );
};

export default LaunchHistoryControlRow;
