import { HierarchyItemType } from '../../types/types';

const addToHierarchyItem = ({
  hierarchyItem,
  parentUuid,
  children,
}: {
  hierarchyItem: HierarchyItemType;
  parentUuid: string;
  children: HierarchyItemType[];
}): HierarchyItemType => {
  if (hierarchyItem.uuid === parentUuid) {
    return { ...hierarchyItem, children };
  } else if (hierarchyItem.children?.length && hierarchyItem.children?.length > 0) {
    return {
      ...hierarchyItem,
      children: hierarchyItem.children.map((child) =>
        addToHierarchyItem({ hierarchyItem: child, parentUuid, children })
      ),
    };
  }
  return hierarchyItem;
};

export const addChildrenToHierarchyItem = ({
  hierarchyItems,
  parentUuid,
  children,
}: {
  hierarchyItems: HierarchyItemType[];
  parentUuid: string;
  children: HierarchyItemType[];
}): HierarchyItemType[] => {
  return hierarchyItems.map((hierarchyItem) =>
    hierarchyItem.uuid === parentUuid
      ? { ...hierarchyItem, children }
      : hierarchyItem.children
      ? {
          ...hierarchyItem,
          children: hierarchyItem.children.map((child) =>
            addToHierarchyItem({ hierarchyItem: child, parentUuid, children })
          ),
        }
      : hierarchyItem
  );
};
