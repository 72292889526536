import React from 'react';

import classes from './AttributeTableType.module.css';
import { AttributeTypeType } from '../../types/types';
import KeyIcoAndValue from '../KeyIcoAndValue/KeyIcoAndValue';
import CustomHoverToolTip from '../CustomHoverToolTip/CustomHoverToolTip';

interface IAttributeTableTypeProps {
  type: AttributeTypeType;
  is_in_primary_key: boolean;
  is_in_foreign_key: boolean;
}

const AttributeTableType: React.FC<IAttributeTableTypeProps> = ({ type, is_in_foreign_key, is_in_primary_key }) => {
  return (
    <div className={classes.container}>
      <CustomHoverToolTip title={type?.name || '-'}>
        <div className={classes.title}>{type?.short_name || ''}</div>
      </CustomHoverToolTip>
      <KeyIcoAndValue is_in_primary_key={is_in_primary_key} is_in_foreign_key={is_in_foreign_key} isTextHide />
    </div>
  );
};

export default AttributeTableType;
