import React, { useEffect } from 'react';

import classes from './CPRightSideBarDisplayConnection.module.css';
import ConnectionStepSelector, { StepSelectorItemType } from '../ConnectionStepSelector/ConnectionStepSelector';
import DisplayConnectionContentSelector from './DisplayConnectionContentSelector/DisplayConnectionContentSelector';
import { Loader } from '@consta/uikit/Loader';
import { useDisplayConnection } from '../../../../hooks/useDisplayConnection';

interface ICPRightSideBarDisplayConnectionProps {
  displayStepSelectorValue: StepSelectorItemType | null;
  setDisplayStepSelectorValue: (value: StepSelectorItemType | null) => void;
  closeSideBar: () => void;
}

const CPRightSideBarDisplayConnection: React.FC<ICPRightSideBarDisplayConnectionProps> = ({
  closeSideBar,
  displayStepSelectorValue,
  setDisplayStepSelectorValue,
}) => {
  const { displayConnection, connectionDetail, getConnectionDetail, isLoadingConnectionDetail } =
    useDisplayConnection(closeSideBar);

  const setDisplayStepSelectorValueHandler = (value: StepSelectorItemType | null) => {
    setDisplayStepSelectorValue(value);
    if (value && value.id !== 'general' && !connectionDetail) {
      getConnectionDetail();
    }
  };

  useEffect(() => {
    if (displayConnection?._uuid !== connectionDetail?._uuid && displayStepSelectorValue?.id !== 'general') {
      if (!isLoadingConnectionDetail) {
        getConnectionDetail();
      }
    }
  }, [
    connectionDetail?._uuid,
    displayConnection?._uuid,
    displayStepSelectorValue?.id,
    getConnectionDetail,
    isLoadingConnectionDetail,
  ]);

  return displayConnection ? (
    <div className={classes.container}>
      <ConnectionStepSelector
        stepSelectorValue={displayStepSelectorValue}
        setStepSelectorValue={setDisplayStepSelectorValueHandler}
      />
      {isLoadingConnectionDetail ? (
        <Loader style={{ marginTop: '50%' }} />
      ) : (
        displayConnection && (
          <DisplayConnectionContentSelector
            stepSelectorValue={displayStepSelectorValue}
            connection={displayConnection}
            connectionDetail={connectionDetail}
          />
        )
      )}
    </div>
  ) : null;
};

export default CPRightSideBarDisplayConnection;
