import React from 'react';

import CustomInput from '../CustomInput/CustomInput';
import { BookmarkBaseType, BookmarkType, UpdateDescriptionParamsType } from '../../types/types';
import { selectorCatalogsIsUpdatingDescription, updateDescriptionThunk } from '../../store/catalogSlice';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';

interface IShortDescriptionCardInputProps {
  bookmark: BookmarkType;
}

const CardInputShortDescription: React.FC<IShortDescriptionCardInputProps> = ({ bookmark }) => {
  const dispatch = useAppDispatch();
  const isSaving = useAppSelector(selectorCatalogsIsUpdatingDescription);

  const bookmarkData = bookmark.data as BookmarkBaseType | undefined;

  const onClickUpdateDescription = (value: string) => {
    const params: UpdateDescriptionParamsType = {
      short_description: value === '' ? null : value,
      description: bookmarkData?.description ? bookmarkData.description : null,
    };
    dispatch(updateDescriptionThunk({ bookmark, params }));
  };

  return (
    <CustomInput
      value={bookmarkData?.short_description ? bookmarkData.short_description : ''}
      placeholder={'Краткое описание на русском...'}
      onSave={onClickUpdateDescription}
      isSaving={isSaving}
    />
  );
};

export default CardInputShortDescription;
