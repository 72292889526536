import React from 'react';

import classes from './RightSideBarTextField.module.css';
import { TextField } from '@consta/uikit/TextField';

interface IRightSideBarTextFieldProps {
  value?: string | null;
  onChange?: (value: string | null) => void;
  label?: string | undefined;
  placeholder?: string | undefined;
  required?: boolean | undefined;
  customCaption?: string | undefined;
  disabled?: boolean | undefined;
  isRequired?: boolean | undefined;
  isPassword?: boolean | undefined;
  inputRef?: React.Ref<HTMLTextAreaElement | HTMLInputElement>;
}

const RightSideBarTextField: React.FC<IRightSideBarTextFieldProps> = ({
  value,
  onChange,
  label,
  placeholder,
  required,
  customCaption,
  disabled,
  isRequired,
  isPassword,
  inputRef,
}) => {
  const onChangeHandler = ({ value }: { value: string | null }) => {
    onChange && onChange(value);
  };

  return (
    <div className={classes.container}>
      <TextField
        id={label}
        value={value}
        label={label}
        required={required}
        disabled={disabled}
        placeholder={placeholder}
        onChange={onChangeHandler}
        status={required && isRequired ? 'alert' : undefined}
        caption={customCaption ? customCaption : required && isRequired ? 'Обязательное к заполнению поле' : undefined}
        type={isPassword ? 'password' : undefined}
        style={{ width: '100%' }}
        inputRef={inputRef}
      />
    </div>
  );
};

export default RightSideBarTextField;
