import React, { ReactNode } from 'react';

import classes from './CardsRightColumnCard.module.css';

interface ICardsRightColumnCardProps {
  children: ReactNode;
}

const CardsRightColumnCard: React.FC<ICardsRightColumnCardProps> = ({ children }) => {
  return <div className={classes.container}>{children}</div>;
};

export default CardsRightColumnCard;
