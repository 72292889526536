import React from 'react';

import classes from './ConnectorTypeSelector.module.css';
import RightSideBarFormSelector from '../../../../../../../Components/RightSideBarFormSelector/RightSideBarFormSelector';
import { useAppDispatch, useAppSelector } from '../../../../../../../hooks/hooks';
import {
  clearStateForStepsFromTwoToFive,
  getConnectorTypesDictionaryThunk,
  selectorActiveConnectorType,
  selectorConnectorTypes,
  selectorIsLoadingConnectorTypes,
  selectorIsRequiredConnectorType,
  selectorReturnFromStepIndex,
  setActiveConnectorType,
} from '../../../../../../../store/createNewConnectionSlice';
import { ItemComboboxType } from '../../../../../../../types/types';
import CPRightSideBarModalCancel from '../../../../CPRightSideBarBottomButtons/CPRightSideBarModalCancel/CPRightSideBarModalCancel';

interface IConnectionTypeSelectorProps {}

const ConnectorTypeSelector: React.FC<IConnectionTypeSelectorProps> = () => {
  const dispatch = useAppDispatch();
  const connectorTypes = useAppSelector(selectorConnectorTypes);
  const isLoadingConnectorTypes = useAppSelector(selectorIsLoadingConnectorTypes);
  const isRequiredConnectorType = useAppSelector(selectorIsRequiredConnectorType);
  const activeConnectorType = useAppSelector(selectorActiveConnectorType);
  const returnFromStepIndex = useAppSelector(selectorReturnFromStepIndex);
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const connectorTypeItems = connectorTypes?.map((connector) => ({
    label: connector.name ? connector.name : '',
    id: connector._uuid,
    uuid: connector._uuid,
  }));

  const activeSourceTypeIndex = connectorTypeItems?.findIndex((item) => item.uuid === activeConnectorType?._uuid);
  const activeSourceTypeValue =
    activeSourceTypeIndex !== undefined && connectorTypeItems ? connectorTypeItems[activeSourceTypeIndex] : undefined;

  const onLoadItems = () => {
    dispatch(getConnectorTypesDictionaryThunk());
  };

  const onChangeItem = (value: ItemComboboxType | null) => {
    if (!returnFromStepIndex) {
      dispatch(setActiveConnectorType(value ? { ...value, _uuid: value?.uuid } : null));
    }
  };

  const onConfirmModal = () => {
    dispatch(clearStateForStepsFromTwoToFive());
    dispatch(setActiveConnectorType(null));
  };

  const onClick = () => {
    if (returnFromStepIndex) {
      setIsModalOpen(true);
    }
  };

  return (
    <div className={classes.container} onClick={onClick}>
      <RightSideBarFormSelector
        value={activeSourceTypeValue}
        items={connectorTypeItems}
        onChange={onChangeItem}
        required={true}
        isRequired={isRequiredConnectorType}
        placeholder={'Выбрать'}
        label={'Тип подключения'}
        isLoading={isLoadingConnectorTypes}
        onLoadItems={onLoadItems}
      />
      <CPRightSideBarModalCancel
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        modalTitle={'Изменение приведет к сбросу настроек'}
        modalMessage={'Изменение на данном шаге приведет к сбросу настроек\nподключения в последующих этапах'}
        onConfirm={onConfirmModal}
      />
    </div>
  );
};

export default ConnectorTypeSelector;
