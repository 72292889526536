import React, { useEffect } from 'react';
import { useSearchParams, createSearchParams } from 'react-router-dom';

import CatalogContent from './CatalogContent/CatalogContent';
import CardDbContent from './CardDbContent/CardDbContent';
import { useActiveBookmark } from '../../../hooks/useActiveBookmark';
import CardSystemContent from './CardSystemContent/CardSystemContent';
import CardSchemaContent from './CardSchemaContent/CardSchemaContent';
import CardTableViewTableFunctionContent from './CardTableViewTableFunctionContent/CardTableViewTableFunctionContent';
import CardAttributeContent from './CardAttributeContent/CardAttributeContent';
import CardProcedureTriggerFunctionContent from './CardProcedureTriggerFuntionContent/CardProcedureTriggerFuntionContent';
import Card1CObjectWithTableContent from './Card1CObjectWithTableContent/Card1CObjectWithTableContent';
import Card1CObjectContent from './Card1CObjectContent/Card1CObjectContent';
import Card1CAttributeContent from './Card1CAttributeContent/Card1CAttributeContent';
import { BookmarkType } from '../../../types/types';
import { useExistedOrCreateNewBookmark } from '../../../hooks/useExistedOrCreateNewBookmark';

const BookmarkContentSwitcher: React.FC = () => {
  const activeBookmark = useActiveBookmark();
  let [searchParams, setSearchParams] = useSearchParams();
  const doExistedOrCreateNewBookmark = useExistedOrCreateNewBookmark();

  useEffect(() => {
    const searchParamsHash = searchParams.get('hash');
    const searchParamsType = searchParams.get('type');
    const searchParamsSlug = searchParams.get('slug');
    if (searchParamsHash && searchParamsType && searchParamsSlug) {
      const bookmark = {
        label: 'Загрузка...',
        type: searchParamsType,
        slug: searchParamsSlug,
        hash: searchParamsHash,
      } as BookmarkType;
      doExistedOrCreateNewBookmark(bookmark);
    }
  }, [searchParams, doExistedOrCreateNewBookmark]);

  useEffect(() => {
    if (activeBookmark) {
      if (activeBookmark?.slug !== 'catalog') {
        const searchParams = createSearchParams({
          type: `${activeBookmark.type}`,
          hash: `${activeBookmark.hash}`,
          slug: `${activeBookmark.slug}`,
        });
        setSearchParams(searchParams);
      }
    }
  }, [activeBookmark, setSearchParams]);

  if (activeBookmark?.type === 'catalog') {
    return <CatalogContent />;
  } else if (activeBookmark?.slug === 'system') {
    return <CardSystemContent bookmark={activeBookmark} />;
  } else if (activeBookmark?.slug === 'db') {
    return <CardDbContent bookmark={activeBookmark} />;
  } else if (activeBookmark?.slug === 'schema') {
    return <CardSchemaContent bookmark={activeBookmark} />;
  } else if (
    activeBookmark?.slug === 'table' ||
    activeBookmark?.slug === 'view' ||
    activeBookmark?.slug === 'table_function' ||
    activeBookmark?.slug === 'dictionary'
  ) {
    return <CardTableViewTableFunctionContent bookmark={activeBookmark} />;
  } else if (activeBookmark?.slug === 'attr') {
    return <CardAttributeContent bookmark={activeBookmark} />;
  } else if (
    activeBookmark?.slug === 'function' ||
    activeBookmark?.slug === 'procedure' ||
    activeBookmark?.slug === 'trigger'
  ) {
    return <CardProcedureTriggerFunctionContent bookmark={activeBookmark} />;
  } else if (
    activeBookmark?.slug === 'document_journal' ||
    activeBookmark?.slug === 'exchange_plan' ||
    activeBookmark?.slug === 'information_register' ||
    activeBookmark?.slug === 'accumulation_register' ||
    activeBookmark?.slug === 'calculation_register' ||
    activeBookmark?.slug === 'accounting_register' ||
    activeBookmark?.slug === 'table_part' ||
    activeBookmark?.slug === 'odata1c_enum' ||
    activeBookmark?.slug === 'constant'
  ) {
    return <Card1CObjectContent bookmark={activeBookmark} />;
  } else if (
    activeBookmark?.slug === 'directory' ||
    activeBookmark?.slug === 'document' ||
    activeBookmark?.slug === 'chart_of_accounts' ||
    activeBookmark?.slug === 'chart_of_calculation_type' ||
    activeBookmark?.slug === 'chart_of_characteristic_types' ||
    activeBookmark?.slug === 'business_process' ||
    activeBookmark?.slug === 'task'
  ) {
    return <Card1CObjectWithTableContent bookmark={activeBookmark} />;
  } else if (activeBookmark?.slug === 'odata1c_attr') {
    return <Card1CAttributeContent bookmark={activeBookmark} />;
  }
  return null;
};

export default BookmarkContentSwitcher;
