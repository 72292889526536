import React, { useEffect } from 'react';

import classes from './CardSchemaContent.module.css';
import InfoSchemaRow from './InfoSchemaRow/InfoSchemaRow';
import { BookmarkType } from '../../../../types/types';
import CardTabsRow, { CardTabType } from '../../../../Components/CardTabsRow/CardTabsRow';
import ContentSchemaByTabs from './ContentSchemaByTabs/ContentSchemaByTabs';
import { useActiveTab } from '../../../../hooks/useActiveTab';
import { useLoadBookmarkData } from '../../../../hooks/useLoadBookmarkData';
import WrapperCatalogObjectNotFound from '../../../../Components/WrapperCatalogObjectNotFound/WrapperCatalogObjectNotFound';

interface ICardSchemaContentProps {
  bookmark: BookmarkType;
}

export const TABS_SCHEMA: CardTabType[] = [
  { id: 0, label: 'Общая информация' },
  { id: 1, label: 'Объекты' },
];

const CardSchemaContent: React.FC<ICardSchemaContentProps> = ({ bookmark }) => {
  const loadBookmarkData = useLoadBookmarkData();
  const [activeTab, setActiveTab] = useActiveTab({ bookmark, tabs: TABS_SCHEMA });

  useEffect(() => {
    loadBookmarkData(bookmark);
  }, [bookmark, loadBookmarkData]);

  return (
    <div className={classes.container}>
      <WrapperCatalogObjectNotFound>
        <InfoSchemaRow bookmark={bookmark} />
        <CardTabsRow tabs={TABS_SCHEMA} activeTab={activeTab} setActiveTab={setActiveTab} />
        <ContentSchemaByTabs activeTab={activeTab} bookmark={bookmark} />
      </WrapperCatalogObjectNotFound>
    </div>
  );
};

export default CardSchemaContent;
