import { OBJECTS_TABLE_RDB_COLUMNS } from '../../Templates/ObjectTableTemplates/ObjectsTableRdbColumns';
import { OBJECTS_TABLE_CDB_COLUMNS } from '../../Templates/ObjectTableTemplates/ObjectsTableСdbColumns';
import { OBJECTS_TABLE_ODATA1C_COLUMNS } from '../../Templates/ObjectTableTemplates/ObjectsTableOdata1cColumns';
import { OBJECTS_TABLE_DB_COLUMNS } from '../../Templates/ObjectTableTemplates/ObjectsTableDbColumns';
import { OBJECTS_TABLE_SCHEMA_COLUMNS } from '../../Templates/ObjectTableTemplates/ObjectsTableSchemaColumns';
import { ColumnGenericType } from '../../types/types';

export const getTableSystemObjectsColumnsTemplate = (slug: string | undefined): ColumnGenericType[] => {
  return slug
    ? slug === 'rdb'
      ? OBJECTS_TABLE_RDB_COLUMNS
      : slug === 'cdb'
      ? OBJECTS_TABLE_CDB_COLUMNS
      : slug === 'odata1c'
      ? OBJECTS_TABLE_ODATA1C_COLUMNS
      : slug === 'db'
      ? OBJECTS_TABLE_DB_COLUMNS
      : slug === 'schema'
      ? OBJECTS_TABLE_SCHEMA_COLUMNS
      : []
    : [];
};
