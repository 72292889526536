import React from 'react';

import classes from './ConnectionsContentControlRow.module.css';
import { IconAdd } from '@consta/uikit/IconAdd';
import { Button } from '@consta/uikit/Button';
import { IconSettings } from '@consta/uikit/IconSettings';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/hooks';
import {
  selectorSearchValueConnections,
  setConnectionsObjectsView,
  setSearchValueConnections,
  setTableConnectionsActiveRowUuid,
} from '../../../../../store/connectionsSlice';
import TextSearchArea from '../../../../../shared-front/TextSearchArea/TextSearchArea';
import ConnectionsObjectsView from '../../../../../Components/ConnectionsObjectsView/ConnectionsObjectsView';
import { useGetConnections } from '../../../../../hooks/useGetConnections';
import { LeftSideBarHandleType } from '../../../../../shared-front/LeftSideBar/LeftSideBar';
import { CPRightSideBarEnum } from '../../../ConnectionsPage';
import { clearCreateNewConnection } from '../../../../../store/createNewConnectionSlice';

interface IConnectionsContentControlRowProps {
  refSidebar: React.RefObject<LeftSideBarHandleType>;
  setRightSideBarMode: (mode: CPRightSideBarEnum | undefined) => void;
}

const ConnectionsContentControlRow: React.FC<IConnectionsContentControlRowProps> = ({
  refSidebar,
  setRightSideBarMode,
}) => {
  const dispatch = useAppDispatch();
  const getConnections = useGetConnections();
  const searchValue = useAppSelector(selectorSearchValueConnections);

  const setSearchValue = (value: string) => {
    dispatch(setSearchValueConnections(value));
  };

  const onClickButtonCreate = () => {
    dispatch(clearCreateNewConnection());
    dispatch(setTableConnectionsActiveRowUuid(undefined));
    setRightSideBarMode(CPRightSideBarEnum.CreateConnection);
    const isSidebarOpen = refSidebar.current?.getIsSidebarOpen();
    if (!isSidebarOpen) {
      refSidebar.current?.openSidebar();
    }
  };

  const onClickButtonTableTune = () => {
    setRightSideBarMode(CPRightSideBarEnum.TuneTable);
    const isSidebarOpen = refSidebar.current?.getIsSidebarOpen();
    if (!isSidebarOpen) {
      refSidebar.current?.openSidebar();
    }
  };

  const onChangeObjectsView = (val: string) => {
    dispatch(setConnectionsObjectsView(val));
  };

  const onSearch = (value: { searchValue?: string }) => {
    getConnections(value);
  };

  return (
    <div className={classes.container}>
      <div className={classes.controlRow}>
        <Button label="Создать" view="secondary" iconLeft={IconAdd} size={'s'} onClick={onClickButtonCreate} />
        <div className={classes.rightColumn}>
          <TextSearchArea
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            onSearch={onSearch}
            id={'ConnectionSearchAre'}
          />
          <div className={classes.vertDelimiter} />
          <ConnectionsObjectsView onMenuItemClick={onChangeObjectsView} />
          <div className={classes.vertDelimiter} />
          <Button
            label="Настройки"
            view="ghost"
            size={'s'}
            iconLeft={IconSettings}
            onlyIcon={true}
            onClick={onClickButtonTableTune}
          />
        </div>
      </div>
      <div className={classes.filterBarContainer}>{/*<FilterBar items={[]} onSave={() => {}} />*/}</div>
    </div>
  );
};

export default ConnectionsContentControlRow;
