import React from 'react';

import classes from './InfoDbRow.module.css';
import { Text } from '@consta/uikit/Text';
import { Badge } from '@consta/uikit/Badge';
import { BookmarkRdbCdbType, BookmarkType } from '../../../../../types/types';
import { declOfNum } from '../../../../../shared-front/utils/declOfNum';
import { getBadgeColor } from '../../../../../utils/Catalog/getBadgeColor';
import { selectorCatalogsIsLoadingBookmarkData } from '../../../../../store/catalogSlice';
import { useAppSelector } from '../../../../../hooks/hooks';
import CardInputShortDescription from '../../../../../Components/CardInputShortDescription/CardInputShortDescription';

interface IInfoRowProps {
  bookmark: BookmarkType;
}

const InfoDbRow: React.FC<IInfoRowProps> = ({ bookmark }) => {
  const isLoadingBookmarkData = useAppSelector(selectorCatalogsIsLoadingBookmarkData);
  const data = bookmark.data as BookmarkRdbCdbType | undefined;

  return (
    <div className={classes.container}>
      {!isLoadingBookmarkData && (
        <>
          <Text className={classes.title}>{data?.name ? data.name : ' '}</Text>
          <CardInputShortDescription bookmark={bookmark} />
          <div className={classes.row}>
            {bookmark.data?.unified_object && (
              <Badge
                view="stroked"
                label={data?.unified_object?.unified_name}
                style={{
                  color: getBadgeColor(data?.unified_object?.color_group),
                  boxShadow: `inset 0 0 0 1px ${getBadgeColor(data?.unified_object?.color_group)}`,
                }}
              />
            )}
            {Number(data?.schemas_count) >= 0 ? (
              <Text className={classes.description}>
                {`${data?.schemas_count} ${declOfNum(Number(data?.schemas_count), ['схема', 'схемы', 'схем'])}`}
              </Text>
            ) : null}
            {Number(data?.tables_count) >= 0 ? (
              <Text className={classes.description}>
                {`${data?.tables_count} ${declOfNum(Number(data?.tables_count), ['таблица', 'таблицы', 'таблиц'])}`}
              </Text>
            ) : null}
            {Number(data?.views_count) >= 0 ? (
              <Text className={classes.description}>
                {`${data?.views_count} ${declOfNum(Number(data?.views_count), [
                  'представление',
                  'представления',
                  'представлений',
                ])}`}
              </Text>
            ) : null}
            {Number(data?.other_objects_count) >= 0 ? (
              <Text className={classes.description}>
                {`${data?.other_objects_count} ${declOfNum(Number(data?.other_objects_count), [
                  'другой объект',
                  'других объекта',
                  'других объектов',
                ])}`}
              </Text>
            ) : null}
          </div>
        </>
      )}
    </div>
  );
};

export default InfoDbRow;
