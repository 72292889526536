import React from 'react';

import TableSettings from '../../../shared-front/TableSettings/TableSettings';
import LeftSideBar, { LeftSideBarHandleType } from '../../../shared-front/LeftSideBar/LeftSideBar';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import {
  selectorInfoSystems,
  selectorInfoSystemsTableColumns,
  setInfoSystemsTableColumns,
} from '../../../store/infoSystemsSlice';
import { TableColumnType } from '../../../shared-front/Table/Table';
import { IspRightSideBarEnum } from '../InfoSystemsPage';
import ISPRightSideBarInfoRow from './ISPRightSideBarInfoRow/ISPRightSideBarInfoRow';
import Delimiter from '../../../shared-front/Delimiter/Delimiter';
import ISPRightSideBarEditCreateForm from './ISPRightSideBarEditCreateForm/ISPRightSideBarEditCreateForm';
import { InfoSystemType } from '../../../types/types';
import ISPRightSideBarViewForm from './ISPRightSideBarViewForm/ISPRightSideBarViewForm';
import { INFO_SYSTEMS_TABLE_COLUMNS } from '../../../Templates/InfoSystemsTableColumns';

const newInfoSystem: InfoSystemType = {
  _uuid: '-1',
  name: null,
  en_name: null,
  description: null,
  business_system: null,
  business_solution: null,
  technical_system: null,
  organization: null,
  integration_dwh: null,
  scan_metadata: null,
  _create_ts: null,
  _create_user: null,
  _alter_ts: null,
  _alter_user: null,
};

interface IISPRightSideBarProps {
  refSidebar: React.RefObject<LeftSideBarHandleType>;
  rightSideBarMode: IspRightSideBarEnum | undefined;
  setRightSideBarMode: (mode: IspRightSideBarEnum | undefined) => void;
  activeInfoSystemUuid: string | undefined;
  setActiveInfoSystemUuid: (uuid: string | undefined) => void;
}

const ISPRightSideBar: React.FC<IISPRightSideBarProps> = ({
  refSidebar,
  rightSideBarMode,
  setRightSideBarMode,
  activeInfoSystemUuid,
  setActiveInfoSystemUuid,
}) => {
  const dispatch = useAppDispatch();
  const infoSystems = useAppSelector(selectorInfoSystems);
  const columnsStore = useAppSelector(selectorInfoSystemsTableColumns);
  const infoSystemForEdit = infoSystems?.data.find((infoSystem) => infoSystem._uuid === activeInfoSystemUuid);

  const defaultColumns = INFO_SYSTEMS_TABLE_COLUMNS;

  const setColumns = (columns: (object & TableColumnType)[]) => {
    dispatch(setInfoSystemsTableColumns(columns));
  };

  const closeSidebarClick = () => {
    setRightSideBarMode(undefined);
    if (activeInfoSystemUuid) {
      setActiveInfoSystemUuid(undefined);
    }
  };

  const closeSideBar = () => {
    closeSidebarClick();
    refSidebar.current?.closeSidebar();
  };

  return (
    <LeftSideBar ref={refSidebar} closeSidebar={closeSidebarClick} isSecondaryBackground={true}>
      {rightSideBarMode === IspRightSideBarEnum.TuneTable ? (
        <TableSettings
          columns={columnsStore}
          setColumns={setColumns}
          hideFooter={true}
          defaultColumns={defaultColumns}
        />
      ) : rightSideBarMode === IspRightSideBarEnum.DisplaySystem ? (
        <>
          <ISPRightSideBarInfoRow
            rightSideBarMode={rightSideBarMode}
            setRightSideBarMode={setRightSideBarMode}
            activeInfoSystemUuid={activeInfoSystemUuid}
            closeSideBar={closeSideBar}
          />
          <Delimiter />
          <ISPRightSideBarViewForm activeInfoSystemUuid={activeInfoSystemUuid} />
        </>
      ) : rightSideBarMode === IspRightSideBarEnum.CreateSystem ? (
        <>
          <ISPRightSideBarInfoRow rightSideBarMode={rightSideBarMode} />
          <Delimiter />
          <ISPRightSideBarEditCreateForm infoSystem={newInfoSystem} closeSideBar={closeSideBar} />
        </>
      ) : rightSideBarMode === IspRightSideBarEnum.EditSystem && infoSystemForEdit ? (
        <>
          <ISPRightSideBarInfoRow rightSideBarMode={rightSideBarMode} />
          <ISPRightSideBarEditCreateForm
            infoSystem={infoSystemForEdit}
            closeSideBar={closeSideBar}
            setRightSideBarMode={setRightSideBarMode}
          />
          <Delimiter />
        </>
      ) : (
        <div style={{ width: 500 }} />
      )}
    </LeftSideBar>
  );
};

export default ISPRightSideBar;
