import React from 'react';

import classes from './ProcedureTriggerFuntionScriptTab.module.css';

interface IRdbObjectsTabProps {}

const ProcedureTriggerFunctionScriptTab: React.FC<IRdbObjectsTabProps> = () => {
  return <div className={classes.container}>ProcedureTriggerFunctionScriptTab</div>;
};

export default ProcedureTriggerFunctionScriptTab;
