import React, { useEffect, useRef } from 'react';
import classes from './FiltersButton.module.css';
import { Button } from '@consta/uikit/Button';
import LeftSideBar, { LeftSideBarHandleType } from '../../../../../../shared-front/LeftSideBar/LeftSideBar';
import FiltersContainer from '../../../../../../Components/FiltersContainer/FiltersContainer';
import {
  getFiltersServerListThunk,
  selectorCatalogDataFilters,
  selectorCatalogFiltersServerList,
  selectorCatalogGroupFilters,
  selectorCatalogMultiFilters,
} from '../../../../../../store/catalogSlice';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/hooks';
import { IconFunnel } from '@consta/uikit/IconFunnel';
import { getActiveMultiFilterTags } from '../../../../../../utils/getActiveMultiFilters';
import { getActiveDataFilterTags } from '../../../../../../utils/getActiveDataFilters';
import { getActiveGroupFilterTags } from '../../../../../../utils/getActiveGroupFilters';

const FiltersButton: React.FC = () => {
  const dispatch = useAppDispatch();
  const refSidebar = useRef<LeftSideBarHandleType>(null);
  const refButton = useRef<HTMLButtonElement>(null);
  // const isHierarchyOpen = useAppSelector(selectorCatalogIsHierarchyOpen);
  const isFiltersUploaded = useAppSelector(selectorCatalogFiltersServerList).length;

  const multiFilters = useAppSelector(selectorCatalogMultiFilters);
  const dataFilters = useAppSelector(selectorCatalogDataFilters);
  const groupFilters = useAppSelector(selectorCatalogGroupFilters);

  const activeMultiFiltersTag = getActiveMultiFilterTags(multiFilters);
  const activeDataFilterTags = getActiveDataFilterTags(dataFilters);
  const activeGroupFilterTags = getActiveGroupFilterTags(groupFilters);

  const hasAppliedFilter = !![...activeMultiFiltersTag, ...activeDataFilterTags, ...activeGroupFilterTags].length;

  useEffect(() => {
    if (!isFiltersUploaded) {
      dispatch(getFiltersServerListThunk());
    }
  }, [dispatch, isFiltersUploaded]);

  const openSidebarClick = () => {
    refSidebar.current?.openSidebar();
  };

  const closeSidebarClick = () => {
    refSidebar.current?.closeSidebar();
  };

  return (
    <div className={classes.container}>
      <Button
        ref={refButton}
        label="Фильтры"
        view={hasAppliedFilter ? 'primary' : 'ghost'}
        size={'s'}
        iconLeft={IconFunnel}
        onClick={openSidebarClick}
        onlyIcon={true}
      />
      <LeftSideBar ref={refSidebar} refButton={refButton}>
        <FiltersContainer closeSidebarClick={closeSidebarClick} />
      </LeftSideBar>
    </div>
  );
};

export default FiltersButton;
