import { useAppDispatch, useAppSelector } from './hooks';
import { getQueryPaginationFromFirstPage } from '../utils/getQueryPaginationFromFirstPage';
import { combineQuery } from '../utils/combineQuery';

import { useCallback } from 'react';
import {
  getLaunchHistoryThunk,
  selectorIsLoadingLaunchHistory,
  selectorLaunchHistory,
  selectorSelectedConnectionUuId,
} from '../store/launchHistorySlice';
import { abortControllerLaunchHistory } from '../api/launchHistoryApi';

export const useGetLaunchHistory = () => {
  const dispatch = useAppDispatch();
  const metaStore = useAppSelector(selectorLaunchHistory)?.meta;
  const isLoadingLaunchHistory = useAppSelector(selectorIsLoadingLaunchHistory);
  const selectedConnectionUuId = useAppSelector(selectorSelectedConnectionUuId);

  return useCallback(
    ({ queryPagination }: { queryPagination?: string }) => {
      let requestQuery = combineQuery([
        queryPagination ? queryPagination : getQueryPaginationFromFirstPage(metaStore ? metaStore : undefined),
      ]);
      if (selectedConnectionUuId) {
        requestQuery = `/${selectedConnectionUuId}${requestQuery}`;
      }
      if (isLoadingLaunchHistory) {
        abortControllerLaunchHistory.abort();
        setTimeout(() => {
          dispatch(getLaunchHistoryThunk(requestQuery));
        });
      } else {
        dispatch(getLaunchHistoryThunk(requestQuery));
      }
    },
    [dispatch, metaStore, isLoadingLaunchHistory, selectedConnectionUuId]
  );
};
