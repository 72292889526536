import React from 'react';

import classes from './TableViewTableFunctionCommonInfoTab.module.css';
import CollapseBlock from '../../../../../../Components/CollapseBlock/CollapseBlock';
import CardsContentBlock from '../../../../../../Components/CardsContentBlock/CardsContentBlock';
import SystemCard from '../../../../../../Components/SystemCard/SystemCard';
import { BookmarkTableViewTableFunctionType, BookmarkType } from '../../../../../../types/types';
import { formatDateDDMMYYYY } from '../../../../../../utils/Formatters/formatDateDDMMYYYY';
import CardInputLongDescription from '../../../../../../Components/CardInputLongDescription/CardInputLongDescription';
import { useBookmarksView } from '../../../../../../hooks/useBookmarksView';
import EdmBlock from '../../../../../../Components/EdmBlock/EdmBlock';

interface IRdbCommonInfoTabProps {
  bookmark: BookmarkType;
}

const TableViewTableFunctionCommonInfoTab: React.FC<IRdbCommonInfoTabProps> = ({ bookmark }) => {
  const data = bookmark.data as BookmarkTableViewTableFunctionType | undefined;

  const {
    isOpenBookmarkCollapseBlockMain,
    isOpenBookmarkCollapseBlockAdditional,
    isOpenBookmarkCollapseTextField,
    isOpenBookmarkCollapseBlockEdm,
    textFieldValue,
    onIsOpenChangeCollapseBlockMain,
    onIsOpenChangeCollapseBlockAdditional,
    onIsOpenChangeCollapseTextField,
    onIsOpenChangeCollapseBlockEdm,
    onChangeTextFieldValue,
  } = useBookmarksView({ bookmarkId: bookmark.hash, bookmarkDescription: bookmark.data?.description });

  return (
    <div className={classes.container}>
      <div className={classes.leftColumn}>
        <CollapseBlock
          title={'Основное'}
          isOpen={isOpenBookmarkCollapseBlockMain}
          onIsOpenChange={onIsOpenChangeCollapseBlockMain}
        >
          <CardInputLongDescription
            bookmark={bookmark}
            textFieldValue={textFieldValue}
            isModeEdit={isOpenBookmarkCollapseTextField}
            onIsModeEditChange={onIsOpenChangeCollapseTextField}
            onChangeTextFieldValue={onChangeTextFieldValue}
          />
          <CardsContentBlock
            title={'Описание из системы-источник'}
            description={data?.comment ? data.comment : '-'}
            className={classes.description}
          />
        </CollapseBlock>
        <EdmBlock
          isOpen={isOpenBookmarkCollapseBlockEdm}
          onIsOpenChange={onIsOpenChangeCollapseBlockEdm}
          dataElements={data?.data_elements}
          edm={data?.edm}
        />
        <CollapseBlock
          title={'Дополнительно'}
          isOpen={isOpenBookmarkCollapseBlockAdditional}
          onIsOpenChange={onIsOpenChangeCollapseBlockAdditional}
        >
          <CardsContentBlock title={'Тип объекта'} description={data?.kind ? data.kind : '-'} />
          {bookmark.type === 'cdb' && (
            <CardsContentBlock title={'Движок'} description={data?.engine ? data.engine : '-'} />
          )}
          <CardsContentBlock
            title={'Создано в системе - источнике'}
            description={
              data?.created_in_source_system_at ? formatDateDDMMYYYY(new Date(data.created_in_source_system_at)) : '-'
            }
          />
          <CardsContentBlock
            title={'Изменено в системе - источнике'}
            description={
              data?.altered_in_source_system_at ? formatDateDDMMYYYY(new Date(data.altered_in_source_system_at)) : '-'
            }
          />
        </CollapseBlock>
        <div style={{ height: '60px' }} />
      </div>
      <div className={classes.rightColumn}>
        <SystemCard
          systemUuid={data?.system?._uuid}
          systemName={data?.system?.name}
          systemSourceIco={data?.source_icon}
          systemSourceType={data?.source_type}
          connection_name={data?.connection_name}
          last_scanned_at={data?.last_scanned_at}
          created_on_portal_ts={data?.created_on_portal_ts}
          updated_on_portal_ts={data?.updated_on_portal_ts}
        />
      </div>
    </div>
  );
};

export default TableViewTableFunctionCommonInfoTab;
