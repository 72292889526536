export const SafeArea = ({ submenu, mouseX, mouseY }: { submenu: HTMLDivElement; mouseX: number; mouseY: number }) => {
  const { height: submenuHeight, x: submenuX, y: submenuY } = submenu.getBoundingClientRect();

  const svgWidth = submenuX - mouseX + 4;
  const svgHeight = submenuHeight;

  return (
    <svg
      style={{
        position: 'fixed',
        width: svgWidth,
        height: submenuHeight,
        pointerEvents: 'none',
        zIndex: 2,
        top: submenuY,
        left: mouseX - 2,
      }}
      id="svg-safe-area"
    >
      <path
        pointerEvents="auto"
        fill="transparent"
        // prettier-ignore
        d={`M 0, ${mouseY-submenuY} 
            L ${svgWidth},${svgHeight}  
            L ${svgWidth},0 z`}
      ></path>
    </svg>
  );
};
