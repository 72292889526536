import { DataFilterType } from '../Components/FilterData/FilterData';
import { DataFilterTagType, FilterTypeEnum } from '../types/types';

export const getActiveDataFilterTags = (dataFilters: DataFilterType[]) => {
  const activeDataFilters: DataFilterTagType[] = [];
  dataFilters.forEach((dataFilter) => {
    if (dataFilter.dataRange[0] || dataFilter.dataRange[1]) {
      activeDataFilters.push({
        filterType: FilterTypeEnum.dataFilter,
        filterName: dataFilter.name,
        filterTile: dataFilter.title,
        filterDataRange: dataFilter.dataRange,
      });
    }
  });
  return activeDataFilters;
};
