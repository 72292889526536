import React from 'react';

import classes from './EditConnectionAssets.module.css';
import { useAppDispatch, useAppSelector } from '../../../../../../../hooks/hooks';
import {
  selectorEditConnection,
  selectorFullEditConnectionAssetsList,
  selectorIsRequiredEditConnectionSelectedAssets,
  setEditConnectionAssets,
} from '../../../../../../../store/editConnectionSlice';
import ConnectionAssetsCombobox from '../../../../../../../Components/ConnectionAssetsCombobox/ConnectionAssetsCombobox';
import { ItemComboboxType } from '../../../../../../../types/types';

interface IEditConnectionAssetsProps {}

const EditConnectionAssets: React.FC<IEditConnectionAssetsProps> = () => {
  const dispatch = useAppDispatch();
  const editConnection = useAppSelector(selectorEditConnection);
  const fullAssetsList = useAppSelector(selectorFullEditConnectionAssetsList);
  const isRequiredSelectedAssets = useAppSelector(selectorIsRequiredEditConnectionSelectedAssets);

  const isOdata = editConnection?.source_type?.card_view === 'odata';
  const isOracle = editConnection?.source_type?.card_view === 'oracle';
  const isDb = !isOdata && !isOracle;

  const onChangeAssets = (value: ItemComboboxType[] | null) => {
    dispatch(setEditConnectionAssets(value));
  };

  return isDb ? (
    <div className={classes.container}>
      <ConnectionAssetsCombobox
        assets={fullAssetsList}
        selectedAssets={editConnection?.assets}
        onChangeAssets={onChangeAssets}
        disabled={false}
        required={true}
        caption={isRequiredSelectedAssets ? 'Обязательное к заполнению поле' : undefined}
        status={isRequiredSelectedAssets ? 'alert' : undefined}
      />
    </div>
  ) : null;
};

export default EditConnectionAssets;
