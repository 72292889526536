import React from 'react';

import classes from './CPRightSideBarModalCancel.module.css';
import { Text } from '@consta/uikit/Text';
import { Button } from '@consta/uikit/Button';
import { Modal } from '@consta/uikit/Modal';
import Delimiter from '../../../../../shared-front/Delimiter/Delimiter';

interface ICPRightSideBarModalCancel {
  isModalOpen: boolean;
  setIsModalOpen: (val: boolean) => void;
  modalTitle: string;
  modalMessage: string;
  onConfirm: () => void;
  onCancel?: () => void;
}

const CPRightSideBarModalCancel: React.FC<ICPRightSideBarModalCancel> = ({
  isModalOpen,
  setIsModalOpen,
  modalTitle,
  modalMessage,
  onConfirm,
  onCancel,
}) => {
  const cancelHandler = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.stopPropagation();
    onCancel && onCancel();
    setIsModalOpen(false);
  };

  const onClickHandler = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.stopPropagation();
    onConfirm();
    setIsModalOpen(false);
  };

  return (
    <Modal
      isOpen={isModalOpen}
      hasOverlay
      onClickOutside={() => setIsModalOpen(false)}
      onEsc={() => setIsModalOpen(false)}
      style={{ zIndex: 1002 }}
      className={classes.container}
    >
      <Text className={classes.title}>{modalTitle}</Text>
      <Text className={classes.text}>{modalMessage}</Text>
      <Delimiter />
      <div className={classes.buttonGroup}>
        <Button size="m" view="primary" label="Подтвердить" onClick={onClickHandler} />
        <Button size="m" view="ghost" label="Отменить" onClick={cancelHandler} />
      </div>
    </Modal>
  );
};

export default CPRightSideBarModalCancel;
