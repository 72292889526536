import React from 'react';
import { IconDownload } from '@consta/uikit/IconDownload';
import { Button } from '@consta/uikit/Button';

interface IButtonDownloadProps {
  onClick?: () => void;
}

const ButtonDownload: React.FC<IButtonDownloadProps> = ({ onClick }) => {
  const onClickHandler = () => {
    onClick && onClick();
  };

  return (
    <Button
      label="Загрузить"
      view="clear"
      size={'xs'}
      iconRight={() => <IconDownload view={'link'} size={'xs'} />}
      onlyIcon
      disabled
      onClick={onClickHandler}
    />
  );
};

export default ButtonDownload;
