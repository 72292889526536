import React from 'react';

import classes from './LimitsForSchemaSettings.module.css';
import { Text } from '@consta/uikit/Text';
import { Radio } from '@consta/uikit/Radio';

import iconInfoCircle from '../../../assets/CatalogPage/iconInfoCircle.svg';
import { TextField } from '@consta/uikit/TextField';
import { ConnectionSettingsItemType } from '../../../types/types';
import CustomHoverToolTip from '../../CustomHoverToolTip/CustomHoverToolTip';

interface ILimitsForSchemaSettingsProps {
  connectionSettings: ConnectionSettingsItemType[] | undefined;
  setConnectionSettings?: (connectionSettings: ConnectionSettingsItemType[] | undefined) => void;
  isDisplayMode?: boolean;
}

const LimitsForSchemaSettings: React.FC<ILimitsForSchemaSettingsProps> = ({
  connectionSettings,
  setConnectionSettings,
  isDisplayMode,
}) => {
  const hasLimitsForSchema = connectionSettings?.find((setting) => setting.key === 'limits_for_schema');
  const limitsForSchemaValue = connectionSettings?.find((setting) => setting.key === 'limits_for_schema')?.value;
  const hasTextLimitsForSchema = typeof limitsForSchemaValue === 'string';
  const resetLimitsForSchema = () => {
    if (connectionSettings && setConnectionSettings) {
      setConnectionSettings(
        connectionSettings.map((setting) =>
          setting.key === 'limits_for_schema' ? { ...setting, value: null } : setting
        )
      );
    }
  };
  const setEmptyLimitsForSchema = () => {
    if (connectionSettings && setConnectionSettings) {
      setConnectionSettings(
        connectionSettings.map((setting) => (setting.key === 'limits_for_schema' ? { ...setting, value: '' } : setting))
      );
    }
  };
  const onChangeLimitsForSchema = ({ value }: { value: string | null }) => {
    if (connectionSettings && setConnectionSettings) {
      setConnectionSettings(
        connectionSettings.map((setting) =>
          setting.key === 'limits_for_schema' ? { ...setting, value: value ? value : '' } : setting
        )
      );
    }
  };

  return isDisplayMode || hasLimitsForSchema ? (
    <div className={classes.container}>
      <div className={classes.radioButtonGroup}>
        <Text className={classes.groupTitle}>{'Схемы'}</Text>
        <div className={classes.buttonsRow}>
          <Radio
            label={'Все'}
            checked={!hasTextLimitsForSchema}
            onChange={resetLimitsForSchema}
            disabled={isDisplayMode && hasTextLimitsForSchema}
          />
          <Radio
            label={'С ограничениями'}
            checked={hasTextLimitsForSchema}
            onChange={setEmptyLimitsForSchema}
            disabled={isDisplayMode && !hasTextLimitsForSchema}
          />
          {!isDisplayMode && (
            <div className={classes.toolTip}>
              <CustomHoverToolTip
                title={`Для перечисления схем используется\nзапятая в качестве разделителя.\nДоступно использование маски % и\nоператора NOT.`}
                zIndex={999}
              >
                <img src={iconInfoCircle} className={classes.ico} alt={'infoBtn'} />
              </CustomHoverToolTip>
            </div>
          )}
        </div>
        {hasTextLimitsForSchema ? (
          isDisplayMode ? (
            <Text className={classes.limitsText}>{limitsForSchemaValue as string}</Text>
          ) : (
            <TextField
              id={'limitsForSchema'}
              value={limitsForSchemaValue}
              placeholder={'Укажите ограничения'}
              onChange={onChangeLimitsForSchema}
            />
          )
        ) : null}
      </div>
    </div>
  ) : null;
};

export default LimitsForSchemaSettings;
