import React, { useEffect } from 'react';

import classes from './TableViewTableFunctionAttributesTab.module.css';
import { BookmarkTableViewTableFunctionType, BookmarkType } from '../../../../../../types/types';
import TableContainer from './TableContainer/TableContainer';
import Delimiter from '../../../../../../shared-front/Delimiter/Delimiter';
import Pagination from '../../../../../../shared-front/Pagination/Pagination';
import { useAppDispatch } from '../../../../../../hooks/hooks';
import { getBookmarkAttributesDataThunk } from '../../../../../../store/catalogSlice';
import { combineQuery } from '../../../../../../utils/combineQuery';
import { START_PAGINATION_QUERY } from '../../../../../../utils/constants';

interface IRdbObjectsTabProps {
  bookmark: BookmarkType;
}

const TableViewTableFunctionAttributesTab: React.FC<IRdbObjectsTabProps> = ({ bookmark }) => {
  const dispatch = useAppDispatch();

  const data = bookmark.data as BookmarkTableViewTableFunctionType | undefined;

  useEffect(() => {
    if (!data?.attributesData) {
      const requestQuery = combineQuery([START_PAGINATION_QUERY]);
      dispatch(getBookmarkAttributesDataThunk({ bookmark, requestQuery }));
    }
  }, [dispatch, bookmark, data?.attributesData]);

  const onChangePagination = (paginationQuery: string) => {
    const requestQuery = combineQuery([paginationQuery]);
    dispatch(getBookmarkAttributesDataThunk({ bookmark, requestQuery }));
  };

  return (
    <div className={classes.container}>
      <TableContainer bookmark={bookmark} />
      <Delimiter />
      <div className={classes.paginationContainer}>
        <Pagination
          currentPageSize={data?.attributesData?.meta.per_page}
          currentPage={data?.attributesData?.meta.current_page}
          total={data?.attributesData?.meta.total}
          onChangePagination={onChangePagination}
          lastPage={data?.attributesData?.meta.last_page}
        />
      </div>
    </div>
  );
};

export default TableViewTableFunctionAttributesTab;
