import React, { useEffect } from 'react';

import classes from './LaunchHistoryTable.module.css';
import { useGetLaunchHistoryTableData } from '../../../../../../hooks/useGetLaunchHistoryTableData';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/hooks';
import {
  selectorTableColumnsLaunchHistory,
  setTableColumnsLaunchHistory,
} from '../../../../../../store/launchHistorySlice';
import { useColumnsStore } from '../../../../../../hooks/useColumnsStore';
import { LAUNCH_HISTORY_TABLE_COLUMNS } from '../../../../../../Templates/launchHistoryTableColumns';
import { getShortColumns } from '../../../../../../shared-front/Table/utils/getShortColumns';
import { Table } from '../../../../../../shared-front/Table/Table';
import { getShowColumns } from '../../../../../../shared-front/Table/utils/getShowColumns';
import TableWrapper from '../../../../../../Components/TableWrapper/TableWrapper';
import { Text } from '@consta/uikit/Text';

interface ILaunchHistoryTableProps {}

const columnsTemplate = LAUNCH_HISTORY_TABLE_COLUMNS;

const LaunchHistoryTable: React.FC<ILaunchHistoryTableProps> = () => {
  const dispatch = useAppDispatch();
  const getLaunchHistoryTableData = useGetLaunchHistoryTableData();
  const columnsStore = useAppSelector(selectorTableColumnsLaunchHistory);
  const columns = useColumnsStore({ columnsStore, columnsTemplate });

  const tableData = getLaunchHistoryTableData();

  useEffect(() => {
    dispatch(setTableColumnsLaunchHistory(getShortColumns(columnsTemplate)));
  }, [dispatch]);

  return (
    <div className={classes.container}>
      {tableData.length > 0 ? (
        <TableWrapper columns={columns} height={'100%'}>
          <Table data={tableData} columns={getShowColumns(columns)} isColumnsResizable={true} isRowHover={true} />
        </TableWrapper>
      ) : (
        <Text className={classes.title}>{'Ничего не найдено'}</Text>
      )}
    </div>
  );
};

export default LaunchHistoryTable;
