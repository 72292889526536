import React from 'react';

import classes from './SectionDataElementItem.module.css';
import { EdmDataElementType } from '../../../../types/types';
import { IconOpenInNew } from '@consta/uikit/IconOpenInNew';
import { openURLinNewTab } from '../../../../utils/openURLinNewTab';

interface ISectionDataElementItemProps {
  item: EdmDataElementType;
}

const SectionDataElementItem: React.FC<ISectionDataElementItemProps> = ({ item }) => {
  const onClick = () => {
    if (item?.link) {
      openURLinNewTab(item.link);
    }
  };

  return (
    <div className={classes.container} onClick={onClick}>
      <IconOpenInNew size={'xs'} style={{ color: '#0078D2', marginRight: '8px' }} />
      {item.name}
    </div>
  );
};

export default SectionDataElementItem;
