import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import classes from './AppFooter.module.css';
import footerLogoDefault from './assets/footerLogo.svg';
import { scrollToTop } from './utils/utils';
import { Button } from '@consta/uikit/Button';
import { IconMail } from '@consta/uikit/IconMail';
import { IconOpenInNew } from '@consta/uikit/IconOpenInNew';
import { Text } from '@consta/uikit/Text';
import { Theme, presetGpnDark } from '@consta/uikit/Theme';

type BaseProps = {
  questionEmail?: string;
  portalName?: string;
  onLogoClickRoute?: string;
  onLogoClick?: () => void;
  withoutFooterRoutes?: string[];
  footerLogo?: React.ReactNode;
  showEso?: boolean;
  esoHref?: string;
};

type PropsType =
  | (BaseProps & { version?: string; versionNode?: never })
  | (BaseProps & { version?: never; versionNode?: React.ReactNode });

const AppFooter: React.FC<PropsType> = ({
  portalName,
  questionEmail,
  onLogoClickRoute,
  onLogoClick,
  withoutFooterRoutes,
  footerLogo,
  version,
  versionNode,
  showEso,
  esoHref = 'https://eso.gazprom-neft.local/direction/366',
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const onClickLogo = () => {
    onLogoClick && onLogoClick();
    if (onLogoClickRoute) {
      scrollToTop();
      navigate(onLogoClickRoute);
    }
  };

  if (withoutFooterRoutes) {
    for (const withoutFooterRoute of withoutFooterRoutes) {
      if (location.pathname.includes(withoutFooterRoute)) {
        return null;
      }
    }
  }

  const onClickSendEmail = () => {
    window.open(
      `mailto:${questionEmail ? questionEmail : 'data-platform@gazprom-neft.ru'}?subject=Вопрос по порталу ${
        portalName ? portalName : ''
      }`
    );
  };

  return (
    <Theme preset={presetGpnDark}>
      <div className={classes.container}>
        <div className={classes.leftColumn}>
          <div className={classes.leftPart}>
            <div className={classes.logoContainer} onClick={onClickLogo}>
              {footerLogo ? (
                footerLogo
              ) : (
                <img src={footerLogoDefault} className={classes.logo} alt="footer default logo" />
              )}
            </div>
            <Text className={classes.copyright} view={'ghost'}>{`© Дата-Платформа ${new Date().getFullYear()}`}</Text>
          </div>
          {version && <Text className={classes.versionContainer}>v{version}</Text>}
          {versionNode && <div className={classes.versionContainer}>{versionNode}</div>}
        </div>
        <div className={classes.rightColumn}>
          {showEso && (
            <div className={classes.questionsContainer}>
              <Text className={classes.questions} view={'ghost'}>{`Нашли ошибку?`}</Text>
              <Button
                size={'xs'}
                as={'a'}
                href={esoHref}
                target={'_blank'}
                view={'ghost'}
                form={'round'}
                label={'Подайте обращение в ЕСО'}
                className={classes.button}
                iconLeft={IconOpenInNew}
              />
            </div>
          )}
          <div className={classes.questionsContainer}>
            <Text className={classes.questions} view={'ghost'}>{`Остались вопросы?\nНапишите нам на почту:`}</Text>
            <Button
              size={'xs'}
              view={'ghost'}
              form={'round'}
              label={'data-platform@gazprom-neft.ru'}
              className={classes.button}
              iconLeft={IconMail}
              onClick={onClickSendEmail}
            />
          </div>
        </div>
      </div>
    </Theme>
  );
};

export default AppFooter;
