import React, { useEffect, useState } from 'react';
import classes from './FilterItemsList.module.css';
import FilterItem from './FilterItem/FilterItem';
import classNames from 'classnames';
import { MultiFilterItemType } from '../../FilterWithScrollAndSearch/FilterWithScrollAndSearch';

interface IFilterItemsList {
  items: MultiFilterItemType[];
  onChangeFilterItems?: (filterItems: MultiFilterItemType[]) => void;
  searchTitleValue?: string | null;
}

const FilterItemsList: React.FC<IFilterItemsList> = ({ items, onChangeFilterItems, searchTitleValue }) => {
  const [filterItems, setFilterItems] = useState<MultiFilterItemType[]>(items);

  useEffect(() => {
    setFilterItems(items);
  }, [items]);

  const onChangeItem = (filterItem: MultiFilterItemType) => {
    const changedItems = filterItems.map((item) => (item.uuid === filterItem.uuid ? filterItem : item));
    setFilterItems(changedItems);
    onChangeFilterItems && onChangeFilterItems(changedItems);
  };

  return (
    <div className={classNames(classes.container, classes.scroll)}>
      {filterItems.map((item, ind) => (
        <FilterItem key={ind} item={item} onChangeItem={onChangeItem} searchValue={searchTitleValue} />
      ))}
    </div>
  );
};

export default FilterItemsList;
