import React from 'react';

import classes from './EdmBlock.module.css';
import CardsContentBlock from '../CardsContentBlock/CardsContentBlock';
import CollapseBlock from '../CollapseBlock/CollapseBlock';
import { EdmDataElementType, EdmDataType } from '../../types/types';
import SectionDataElements from './SectionDataElements/SectionDataElements';
import { Button } from '@consta/uikit/Button';
import { IconOpenInNew } from '@consta/uikit/IconOpenInNew';
import { openURLinNewTab } from '../../utils/openURLinNewTab';

interface IEdmBlockProps {
  isOpen?: boolean;
  onIsOpenChange?: ((isOpen: boolean) => void) | undefined;
  edm?: EdmDataType;
  dataElements?: EdmDataElementType[] | undefined;
}

const EdmBlock: React.FC<IEdmBlockProps> = ({ isOpen, onIsOpenChange, dataElements, edm }) => {
  const onClick = () => {
    if (edm?.link) {
      openURLinNewTab(edm.link);
    }
  };

  return (
    <div className={classes.container}>
      {edm?.link && (
        <Button
          view={'secondary'}
          label={'Перейти'}
          size={'xs'}
          className={classes.button}
          iconLeft={IconOpenInNew}
          onClick={onClick}
        />
      )}
      <CollapseBlock title={'EDM'} isOpen={isOpen} onIsOpenChange={onIsOpenChange}>
        <CardsContentBlock title={'Бизнес описание'} description={edm?.business_description ?? '-'} />
        <CardsContentBlock title={'Расширенное описание'} description={edm?.description ?? '-'} />
        <SectionDataElements dataElements={dataElements} />
      </CollapseBlock>
    </div>
  );
};

export default EdmBlock;
