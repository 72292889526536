import React from 'react';
import classes from './AssetsByTypeList.module.css';
import { Text } from '@consta/uikit/Text';
import AssetByTypeCard from './AssetByTypeCard/AssetByTypeCard';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import {
  selectorMetaAssetsByType,
  selectorMetaAssetsViewType,
  selectorMetaIsLoading,
  setAssetsViewType,
} from '../../../store/metaSlice';
import { Loader } from '@consta/uikit/Loader';
import { Switch } from '@consta/uikit/Switch';
import DetailedAssetByTypeCard from './DetailedAssetByTypeCard/DetailedAssetByTypeCard';

const AssetsByTypeList: React.FC = () => {
  const dispatch = useAppDispatch();
  const metaIsLoading = useAppSelector(selectorMetaIsLoading);
  const assetsByType = useAppSelector(selectorMetaAssetsByType);
  const assetsViewType = useAppSelector(selectorMetaAssetsViewType);

  const onChangeAssetsViewType = ({ checked }: { checked: boolean }) => {
    dispatch(setAssetsViewType(checked ? 'detailed' : undefined));
  };

  const isDetailedView = assetsViewType === 'detailed';

  return (
    <div className={classes.container}>
      {metaIsLoading ? (
        <Loader style={{ marginTop: '10vh' }} />
      ) : (
        <>
          <div className={classes.topRow}>
            <Text className={classes.title}>{`Или выберите из разделов:`}</Text>
            <div className={classes.leftPart}>
              <Text className={classes.titleSwitch}>{`Подробно`}</Text>
              <Switch size="m" checked={isDetailedView} onChange={onChangeAssetsViewType} />
            </div>
          </div>
          {isDetailedView ? (
            <div className={classes.detailedlist}>
              {assetsByType.map((assetByType, ind) => (
                <DetailedAssetByTypeCard key={ind} assetByType={assetByType} />
              ))}
            </div>
          ) : (
            <div className={classes.list}>
              {assetsByType.map((assetByType, ind) => (
                <AssetByTypeCard key={ind} assetByType={assetByType} />
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default AssetsByTypeList;
