import React from 'react';

import classes from './ISPCopyToBuffer.module.css';
import { IconCopy } from '@consta/uikit/__internal__/src/icons/IconCopy/IconCopy';
import { Button } from '@consta/uikit/Button';
import { pushSnackBarItemsSuccess } from '../../store/infoSystemsSlice';
import { useAppDispatch } from '../../hooks/hooks';

interface IISPCopyToBufferProps {
  children: React.ReactNode;
  valueToCopy: string | undefined;
}

const ISPCopyToBuffer: React.FC<IISPCopyToBufferProps> = ({ children, valueToCopy }) => {
  const dispatch = useAppDispatch();

  const onClickCopyToBuffer = async () => {
    console.log(valueToCopy);
    await navigator.clipboard.writeText(valueToCopy ?? '');
    dispatch(pushSnackBarItemsSuccess('Скопировано в буфер'));
  };

  return (
    <div className={classes.container}>
      {children}
      <Button size="m" view="clear" iconLeft={IconCopy} onlyIcon onClick={onClickCopyToBuffer} />
    </div>
  );
};

export default ISPCopyToBuffer;
