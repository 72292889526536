import { FilterBarItemType } from '../shared-front/FilterBar/FilterBar';
import { FiltersGroupType } from '../Components/FiltersGroup/FiltersGroup';

export const getNewActiveGroupFiltersByFilterBarTags = (
  groupFilters: FiltersGroupType[],
  tagItems: FilterBarItemType[]
): FiltersGroupType[] => {
  const activeGroupFiltersTagUuids = tagItems
    .filter((tagItem) => tagItem.uuid.split('-*-')[0] === 'groupFilter')
    .map((item) => item.uuid.split('-*-')[1]);

  return groupFilters.map((filterGroup) => ({
    ...filterGroup,
    groupFilters: filterGroup.groupFilters.map((curFilter) =>
      activeGroupFiltersTagUuids.includes(curFilter.name)
        ? { ...curFilter, isChecked: true }
        : { ...curFilter, isChecked: false }
    ),
  }));
};
