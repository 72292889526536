import React, { useEffect, useState } from 'react';

import classes from './CustomInput.module.css';
import { TextField } from '@consta/uikit/TextField';
import { Text } from '@consta/uikit/Text';
import { Button } from '@consta/uikit/Button';
import iconEditBlue from '../../assets/CatalogPage/iconEditBlue.svg';
import CustomBlueTextButton from '../CustomBlueTextButton/CustomBlueTextButton';
import { Loader } from '@consta/uikit/Loader';

interface ICustomInputProps {
  value?: string;
  placeholder?: string;
  onSave: (value: string) => void;
  isSaving?: boolean;
}

const CustomInput: React.FC<ICustomInputProps> = ({ value, placeholder = '...', onSave, isSaving }) => {
  const [valueLocal, setValueLocal] = useState<string>(value ? value : '');
  const [isEditMode, setIsEditMode] = useState(false);
  const [isSavingLocal, setIsSavingLocal] = useState(false);

  useEffect(() => {
    setValueLocal(value ? value : '');
  }, [value]);

  const handleChange = ({ value }: { value: string | null }) => setValueLocal(value ? value : '');

  const handlerSave = () => {
    onSave(valueLocal);
    setIsSavingLocal(true);
  };

  const clickIsEditMode = () => {
    setIsEditMode(true);
  };

  const handlerCancel = () => {
    setValueLocal(value ? value : '');
    setIsEditMode(false);
  };

  useEffect(() => {
    if (isSavingLocal && !isSaving) {
      setIsSavingLocal(false);
      setIsEditMode(false);
    }
  }, [isSaving, isSavingLocal]);

  return (
    <div className={classes.container}>
      {isEditMode ? (
        <>
          <TextField
            onChange={handleChange}
            value={valueLocal}
            type="text"
            placeholder={placeholder}
            className={classes.textField}
          />
          {isSaving ? (
            <div className={classes.loaderContainer}>
              <div className={classes.loaderText}>Сохранение</div>
              <div>
                <Loader size={'xs'} className={classes.loader} />
              </div>
            </div>
          ) : (
            <>
              <CustomBlueTextButton title={'Сохранить'} onClick={handlerSave} className={classes.btnSave} />
              <CustomBlueTextButton title={'Отменить'} onClick={handlerCancel} />
            </>
          )}
        </>
      ) : (
        <>
          {valueLocal ? (
            <Text className={classes.value}>{valueLocal}</Text>
          ) : (
            <Text className={classes.placeholder}>{placeholder}</Text>
          )}
          <div className={classes.button}>
            <Button
              size={'s'}
              label="edit"
              view="clear"
              onlyIcon
              iconRight={() => <img src={iconEditBlue} alt="editIco" />}
              onClick={clickIsEditMode}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default CustomInput;
