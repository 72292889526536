import React from 'react';

import classes from './SourceTypeSelector.module.css';
import RightSideBarFormSelector from '../../../../../../../Components/RightSideBarFormSelector/RightSideBarFormSelector';
import { useAppDispatch, useAppSelector } from '../../../../../../../hooks/hooks';
import {
  clearStateForStepsFromTwoToFive,
  getSourceTypesDictionaryThunk,
  selectorActiveSourceType,
  selectorIsLoadingSourceTypes,
  selectorIsRequiredSourceType,
  selectorReturnFromStepIndex,
  selectorSourceTypes,
  setActiveSourceType,
} from '../../../../../../../store/createNewConnectionSlice';
import { ItemComboboxType } from '../../../../../../../types/types';
import CPRightSideBarModalCancel from '../../../../CPRightSideBarBottomButtons/CPRightSideBarModalCancel/CPRightSideBarModalCancel';

interface ISourceTypeSelectorProps {}

const SourceTypeSelector: React.FC<ISourceTypeSelectorProps> = () => {
  const dispatch = useAppDispatch();
  const sourceTypes = useAppSelector(selectorSourceTypes);
  const isLoadingSourceTypes = useAppSelector(selectorIsLoadingSourceTypes);
  const isRequiredSourceType = useAppSelector(selectorIsRequiredSourceType);
  const activeSourceType = useAppSelector(selectorActiveSourceType);
  const returnFromStepIndex = useAppSelector(selectorReturnFromStepIndex);

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const sourceTypeItems = sourceTypes?.map((source) => ({
    label: source.name ? source.name : '',
    id: source._uuid,
    uuid: source._uuid,
    card_view: source.card_view,
    slug: source.slug,
    default_settings: source.default_settings,
  }));

  const activeSourceTypeIndex = sourceTypeItems?.findIndex((item) => item.uuid === activeSourceType?._uuid);
  const activeSourceTypeValue =
    activeSourceTypeIndex !== undefined && sourceTypeItems ? sourceTypeItems[activeSourceTypeIndex] : undefined;

  const onLoadItems = () => {
    dispatch(getSourceTypesDictionaryThunk());
  };

  const onChangeItem = (value: ItemComboboxType | null) => {
    if (!returnFromStepIndex) {
      dispatch(setActiveSourceType(value ? { ...value, _uuid: value?.uuid } : null));
    }
  };

  const onClickSourceTypeSelector = () => {
    if (returnFromStepIndex) {
      setIsModalOpen(true);
    }
  };

  const onConfirmModal = () => {
    setIsModalOpen(false);
    dispatch(clearStateForStepsFromTwoToFive());
    dispatch(setActiveSourceType(null));
  };

  return (
    <div className={classes.container} onClick={onClickSourceTypeSelector}>
      <RightSideBarFormSelector
        value={activeSourceTypeValue}
        items={sourceTypeItems}
        onChange={onChangeItem}
        required={true}
        isRequired={isRequiredSourceType}
        placeholder={'Выбрать'}
        label={'Тип источника'}
        isLoading={isLoadingSourceTypes}
        onLoadItems={onLoadItems}
      />
      <CPRightSideBarModalCancel
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        modalTitle={'Изменение приведет к сбросу настроек'}
        modalMessage={'Изменение на данном шаге приведет к сбросу настроек\nподключения в последующих этапах'}
        onConfirm={onConfirmModal}
      />
    </div>
  );
};

export default SourceTypeSelector;
