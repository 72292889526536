import React from 'react';
import FilterCollapseContainer from '../../FilterCollapseContainer/FilterCollapseContainer';
import FilterData, { DataFilterType, DataRangeType } from '../../FilterData/FilterData';

interface IDataFiltersListProps {
  dataFilters: DataFilterType[];
  setDataFilters: (filters: DataFilterType[]) => void;
}

const getIsDataFilterHasSelectedItem = (filter: DataFilterType, dataFilters: DataFilterType[]): boolean => {
  const dataRange = dataFilters.find((dataFilter) => dataFilter.name === filter.name)?.dataRange;
  return !!(dataRange && (dataRange[0] || dataRange[1]));
};

const DataFiltersList: React.FC<IDataFiltersListProps> = ({ dataFilters, setDataFilters }) => {
  const clearDataRange = (filter: DataFilterType) => {
    setDataFilters(
      dataFilters.map((dataFilter) =>
        dataFilter.name === filter.name ? { ...dataFilter, dataRange: [undefined, undefined] } : dataFilter
      )
    );
  };

  const onChangeDataRange = (filter: DataFilterType, dataRange: DataRangeType) => {
    setDataFilters(
      dataFilters.map((dataFilter) => (dataFilter.name === filter.name ? { ...dataFilter, dataRange } : dataFilter))
    );
  };

  return (
    <>
      {dataFilters.map((filter, ind) => (
        <FilterCollapseContainer
          key={ind}
          title={filter.title}
          onClearFilter={() => clearDataRange(filter)}
          isFilterHasSelectedItem={getIsDataFilterHasSelectedItem(filter, dataFilters)}
        >
          <FilterData
            dataRange={filter.dataRange}
            onChangeDataRange={(dataRange: DataRangeType) => onChangeDataRange(filter, dataRange)}
          />
        </FilterCollapseContainer>
      ))}
    </>
  );
};

export default DataFiltersList;
