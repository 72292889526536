import { UserInfoType } from '../../types/types';
import { PageRoutesEnum } from '../../appMenuAndRouting/appRoutes';

export const getAppRedirectRouteByPermissions = (userInfo: UserInfoType | undefined) => {
  if (userInfo?.permissions.length) {
    const mainPageRoute = userInfo?.permissions.find(
      (permission) => permission.slug === PageRoutesEnum.MainPage.slice(1)
    );
    if (mainPageRoute) {
      return PageRoutesEnum.MainPage;
    }
  }
  return PageRoutesEnum.AccessDeniedPage;
};
