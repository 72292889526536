import { CreateNewConnectionsSliceType } from '../createNewConnectionSlice';
import { validateStepTwoDbFull } from './validateStepTwoDbFull';
import { validateStepTwoOracle } from './validateStepTwoOracle';
import { validateStepTwoOdata } from './validateStepTwoOdata';

export const validateAndGoForwardStepTwo = (state: CreateNewConnectionsSliceType) => {
  if (state.activeSourceType?.card_view) {
    if (state.activeSourceType.card_view === 'oracle') {
      if (!validateStepTwoOracle(state) && state.isNewConnectionChecked) {
        state.activeStepIndex = 2;
      }
    } else if (state.activeSourceType?.card_view === 'odata') {
      if (!validateStepTwoOdata(state) && state.isNewConnectionChecked) {
        state.activeStepIndex = 2;
      }
    } else {
      if (!validateStepTwoDbFull(state) && state.isNewConnectionChecked) {
        state.activeStepIndex = 2;
        if (state.returnFromStepIndex) {
          state.returnFromStepIndex = undefined;
        }
      }
    }
  }
};
