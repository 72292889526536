import { HierarchyItemType } from '../../types/types';

export const checkIsOneChildrenSelected = (hierarchyItem: HierarchyItemType): boolean => {
  const children = hierarchyItem.children;
  if (!children) {
    return false;
  }
  for (let child of children) {
    if (child.has_checkbox && child.isChecked) {
      return true;
    }
    if (checkIsOneChildrenSelected(child)) {
      return true;
    }
  }
  return false;
};
