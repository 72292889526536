import React from 'react';

import classes from './TableSettingsSearchRow.module.css';
import { IconClose } from '@consta/uikit/IconClose';
import { IconSearch } from '@consta/uikit/IconSearch';
import { TextField } from '@consta/uikit/TextField';

interface ITableSettingsSearchRowProps {
  searchTitleValue: string | null;
  setSearchTitleValue: (value: string | null) => void;
}

const TableSettingsSearchRow: React.FC<ITableSettingsSearchRowProps> = ({ searchTitleValue, setSearchTitleValue }) => {
  const handleChange = ({ value }: { value: string | null }) => setSearchTitleValue(value);

  const ResetSearchBtn = () => {
    const onClick = () => {
      handleChange({ value: '' });
    };
    return (
      <div>
        <IconClose style={{ width: '12px', cursor: 'pointer', marginTop: '6px', color: '#00395C' }} onClick={onClick} />
      </div>
    );
  };

  return (
    <div className={classes.container}>
      <TextField
        value={searchTitleValue}
        onChange={handleChange}
        style={{ flexGrow: '1' }}
        leftSide={IconSearch}
        rightSide={searchTitleValue ? ResetSearchBtn : undefined}
        placeholder="Введите название свойства..."
        size={'s'}
      />
    </div>
  );
};

export default TableSettingsSearchRow;
