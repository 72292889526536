import { CreateNewConnectionsSliceType } from '../createNewConnectionSlice';
import { maxPreviewValue, minPreviewValue } from '../../utils/constants';

export const validateAndGoForwardStepFour = (state: CreateNewConnectionsSliceType) => {
  const errorAsset = state.newConnectionSelectedAssets?.find((asset) => {
    const limitsForPreviewValue = asset.settings?.find((setting) => setting.key === 'limits_for_preview')?.value;
    if (limitsForPreviewValue) {
      return !(limitsForPreviewValue >= minPreviewValue && limitsForPreviewValue <= maxPreviewValue);
    } else {
      return false;
    }
  });

  if (errorAsset) {
    state.creatingNewConnectionMessage = `${errorAsset.name ?? ''}\nНеверный диапазон предпросмотра`;
  } else {
    state.activeStepIndex = 4;
    if (state.returnFromStepIndex) {
      state.returnFromStepIndex = undefined;
    }
  }
};
