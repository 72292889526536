import React from 'react';

import classes from './SectionDataElements.module.css';
import { Text } from '@consta/uikit/Text';
import { EdmDataElementType } from '../../../types/types';
import SectionDataElementItem from './SectionDataElementItem/SectionDataElementItem';

interface ISectionDataElementsProps {
  dataElements?: EdmDataElementType[] | undefined;
}

const SectionDataElements: React.FC<ISectionDataElementsProps> = ({ dataElements }) => {
  return (
    <div className={classes.container}>
      <Text className={classes.title}>{'Связь с объектами общекорпоративной модели данных (ЭД)'}</Text>
      {dataElements?.length && dataElements.length > 0 ? (
        <div className={classes.itemsList}>
          {dataElements.map((item, ind) => (
            <SectionDataElementItem key={ind} item={item} />
          ))}
        </div>
      ) : (
        <div className={classes.empty}>{'-'}</div>
      )}
    </div>
  );
};

export default SectionDataElements;
