import React, { useRef, useState } from 'react';

import { ContextMenu } from '@consta/uikit/ContextMenu';
import { Button } from '@consta/uikit/Button';
import { IconArrowDown } from '@consta/uikit/IconArrowDown';
import { IconArrowUp } from '@consta/uikit/IconArrowUp';
import classes from './ConnectionsObjectsView.module.css';

interface IConnectionsObjectsViewProps {
  onMenuItemClick: (value: string) => void;
}

export enum ObjectsViewEnum {
  allOpen = 'allOpen',
  allClose = 'allClose',
}

type ItemType = {
  id: ObjectsViewEnum;
  label: string;
};

const items: ItemType[] = [
  { id: ObjectsViewEnum.allOpen, label: 'Раскрыть все объекты' },
  { id: ObjectsViewEnum.allClose, label: 'Скрыть все объекты' },
];

const ConnectionsObjectsView: React.FC<IConnectionsObjectsViewProps> = ({ onMenuItemClick }) => {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onItemClick = ({ item }: { item: ItemType }) => {
    onMenuItemClick(item.id);
    setIsOpen(false);
  };

  return (
    <>
      <Button
        ref={ref}
        label="Вид"
        view="ghost"
        size={'s'}
        iconRight={isOpen ? IconArrowUp : IconArrowDown}
        onClick={() => setIsOpen(!isOpen)}
      />
      <ContextMenu
        isOpen={isOpen}
        items={items}
        getItemLabel={(item) => item.label}
        anchorRef={ref}
        onClickOutside={() => setIsOpen(false)}
        onItemClick={onItemClick}
        direction="downStartRight"
        className={classes.contextMenu}
      />
    </>
  );
};

export default ConnectionsObjectsView;
