import React from 'react';

import classes from './ContentDbByTabs.module.css';
import DbCommonInfoTab from './DbCommonInfoTab/DbCommonInfoTab';
import DbSchemaObjectsTab from '../../../../../Components/DbSchemaObjectsTab/DbSchemaObjectsTab';
import { useAppSelector } from '../../../../../hooks/hooks';
import { selectorCatalogsIsLoadingBookmarkData } from '../../../../../store/catalogSlice';
import { Loader } from '@consta/uikit/Loader';
import { BookmarkType } from '../../../../../types/types';
import { TABS_RDB } from '../CardDbContent';
import { CardTabType } from '../../../../../Components/CardTabsRow/CardTabsRow';

interface IContentRdbByTabsProps {
  activeTab?: CardTabType;
  bookmark: BookmarkType;
}

const ContentDbByTabs: React.FC<IContentRdbByTabsProps> = ({ activeTab, bookmark }) => {
  const isCommonInfoTab = activeTab?.label === TABS_RDB[0].label;
  const isLoadingBookmarkData = useAppSelector(selectorCatalogsIsLoadingBookmarkData);

  return (
    <div className={classes.container}>
      {isLoadingBookmarkData ? (
        <div style={{ display: 'flex', width: '100%' }}>
          <Loader />
        </div>
      ) : isCommonInfoTab ? (
        <DbCommonInfoTab bookmark={bookmark} />
      ) : (
        <DbSchemaObjectsTab bookmark={bookmark} />
      )}
    </div>
  );
};

export default ContentDbByTabs;
