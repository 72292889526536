import React, { useEffect, useState } from 'react';
import classes from './HierarchyLevel.module.css';
import { Collapse } from '@consta/uikit/Collapse';
import HierarchySearch from './HierarchySearch/HierarchySearch';
import { HierarchyItemType } from '../../../../../../types/types';
import './constaFix.css';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/hooks';
import {
  getHierarchyItemsThunk,
  selectorCatalogActiveSection,
  setIsOpenHierarchyItemStore,
} from '../../../../../../store/catalogSlice';
import { Loader } from '@consta/uikit/Loader';
import HierarchyLevelLabel from './HierarchyLevelLabel/HierarchyLevelLabel';

interface IHierarchyLevelProps {
  hierarchyItem: HierarchyItemType;
  level?: number;
}

const HierarchyLevel: React.FC<IHierarchyLevelProps> = ({ hierarchyItem, level = 1 }) => {
  const dispatch = useAppDispatch();
  const [searchValue, setSearchValue] = useState('');
  const catalogActiveSection = useAppSelector(selectorCatalogActiveSection);
  const [isLoadingChildren, setIsLoadingChildren] = useState(false);
  const [hierarchyItemChildren, setHierarchyItemChildren] = useState<HierarchyItemType[] | undefined>();

  const handleClick = () => {
    if (hierarchyItem.has_childs && !hierarchyItem.children && catalogActiveSection?.id) {
      const query = `filter_type=${hierarchyItem.type}&_hash_key=${hierarchyItem.uuid}`;
      setIsLoadingChildren(true);
      dispatch(
        getHierarchyItemsThunk({ hierarchySlug: catalogActiveSection.slug, query, parentUuid: hierarchyItem.uuid })
      ).finally(() => {
        setIsLoadingChildren(false);
      });
    }
    dispatch(
      setIsOpenHierarchyItemStore({
        uuid: hierarchyItem.uuid,
        isOpen: !hierarchyItem.isOpen,
      })
    );
  };

  useEffect(() => {
    if (!searchValue) {
      setHierarchyItemChildren(hierarchyItem.children);
    } else {
      setHierarchyItemChildren(
        hierarchyItem.children?.filter((child) => child.name.toLowerCase().includes(searchValue.toLowerCase()))
      );
    }
  }, [searchValue, hierarchyItem.children]);

  const showSearch = !!(!hierarchyItem.has_checkbox && hierarchyItem.children && hierarchyItem.children.length);

  return (
    <div className={classes.container}>
      {hierarchyItem.has_childs ? (
        <Collapse
          size={'m'}
          label={<HierarchyLevelLabel hierarchyItem={hierarchyItem} level={level} />}
          isOpen={hierarchyItem.isOpen}
          onClick={handleClick}
        >
          <div className={classes.content}>
            {showSearch && <HierarchySearch value={searchValue} onChangeValue={setSearchValue} />}
            {isLoadingChildren && (
              <div className={classes.loaderContainer}>
                <Loader size={'s'} />
              </div>
            )}
            {hierarchyItemChildren?.map((children, ind) => (
              <HierarchyLevel key={ind} level={level + 1} hierarchyItem={children} />
            ))}
          </div>
        </Collapse>
      ) : (
        <div style={{ marginLeft: '16px' }}>
          <HierarchyLevelLabel hierarchyItem={hierarchyItem} level={level} />
        </div>
      )}
    </div>
  );
};

export default HierarchyLevel;
