import { MultiFilterType } from '../Components/FilterWithScrollAndSearch/FilterWithScrollAndSearch';

export const getQueryMultiFilters = (multiFilters: MultiFilterType[]) => {
  const queries: string[] = [];
  multiFilters.forEach((multiFilter) => {
    const values: string[] = [];
    multiFilter.items.forEach((item) => {
      if (item.isChecked) {
        values.push(item.uuid);
      }
    });
    if (values.length > 0) {
      queries.push(`filter[${multiFilter.name}]=${values.join('|')}`);
    }
  });
  return queries.join('&');
};
