import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  ConnectionConnectorType,
  ConnectionSourceType,
  ConnectionSettingsItemType,
  InfoSystemType,
  ItemComboboxType,
  ConnectionType,
} from '../types/types';
import { RootState } from './store';
import { serverApi } from '../api/serverApi';
import { getRejectedValue } from './rejectedValueHelper';
import { validateAndGoForwardStepOne } from './utils/validateAndGoForwardStepOne';
import { validateAndGoForwardStepTwo } from './utils/validateAndGoForwardStepTwo';
import { CheckConnectionAssetType } from '../api/serverResponse';
import { validateStepTwoDbInputs } from './utils/validateStepTwoDbInputs';
import { validateStepTwoOracle } from './utils/validateStepTwoOracle';
import { validateStepTwoOdata } from './utils/validateStepTwoOdata';
import { validateAndGoForwardStepTree } from './utils/validateAndGoForwardStepThree';
import { validateAndGoForwardStepFour } from './utils/validateAndGoForwardStepFour';
import { getNewConnectionParams } from './utils/getNewConnectionParams';

export type CreateNewConnectionsSliceType = {
  activeStepIndex: number;
  returnFromStepIndex: number | undefined;

  ipAddress?: string | null;
  isRequiredIpAddress: boolean;

  connectionPort?: string | null;
  isRequiredConnectionPort: boolean;

  connectionUser?: string | null;
  isRequiredConnectionUser: boolean;

  connectionPassword?: string | null;
  isRequiredConnectionPassword: boolean;

  connectionInstance?: string | null;
  isRequiredConnectionInstance: boolean;

  connectionUrl?: string | null;
  isRequiredConnectionUrl: boolean;

  connectionDescription?: string | null;

  connectionName?: string | null;
  isRequiredConnectionName: boolean;

  connectionSearchValue?: string | undefined;

  connectionVersion?: string | undefined;

  infoSystems?: InfoSystemType[];
  isLoadingInfoSystem: boolean;
  activeInfoSystem?: InfoSystemType & { id: string | number };
  isRequiredInfoSystem: boolean;

  sourceTypes?: ConnectionSourceType[];
  isLoadingSourceTypes: boolean;
  activeSourceType?: ConnectionSourceType & { id: string | number };
  isRequiredSourceType: boolean;

  connectorTypes?: ConnectionConnectorType[];
  isLoadingConnectorTypes: boolean;
  activeConnectorType?: ConnectionConnectorType & { id: string | number };
  isRequiredConnectorType: boolean;

  isNewConnectionChecked: boolean;
  isNewConnectionOnChecking: boolean;
  isRequiredNewConnectionChecked: boolean;
  newConnectionAssets: CheckConnectionAssetType[] | undefined;
  newConnectionSelectedAssets: CheckConnectionAssetType[] | undefined;
  isRequiredSelectedAssets: boolean;

  connectionGeneralSettings: ConnectionSettingsItemType[] | undefined;

  connectionScanSchedule: string | undefined;
  connectionPreviewSchedule: string | undefined;

  scanAll: boolean;

  newCreatedConnection: ConnectionType | undefined;
  isCreatingNewConnection: boolean;
  creatingNewConnectionMessage: string | undefined;

  isErrorCreateNewConnections: string | undefined;
};

const initialState: CreateNewConnectionsSliceType = {
  activeStepIndex: 0,
  returnFromStepIndex: undefined,

  ipAddress: undefined,
  isRequiredIpAddress: false,

  connectionPort: undefined,
  isRequiredConnectionPort: false,

  connectionUser: undefined,
  isRequiredConnectionUser: false,

  connectionPassword: undefined,
  isRequiredConnectionPassword: false,

  connectionInstance: undefined,
  isRequiredConnectionInstance: false,

  connectionUrl: undefined,
  isRequiredConnectionUrl: false,

  connectionName: undefined,
  isRequiredConnectionName: false,

  connectionDescription: undefined,

  connectionSearchValue: undefined,

  connectionVersion: undefined,

  scanAll: false,

  infoSystems: undefined,
  isLoadingInfoSystem: false,
  activeInfoSystem: undefined,
  isRequiredInfoSystem: false,

  sourceTypes: undefined,
  isLoadingSourceTypes: false,
  activeSourceType: undefined,
  isRequiredSourceType: false,

  connectorTypes: undefined,
  isLoadingConnectorTypes: false,
  activeConnectorType: undefined,
  isRequiredConnectorType: false,

  isNewConnectionChecked: false,
  isNewConnectionOnChecking: false,
  isRequiredNewConnectionChecked: false,

  newConnectionAssets: undefined,
  newConnectionSelectedAssets: undefined,
  isRequiredSelectedAssets: false,

  connectionGeneralSettings: undefined,

  connectionScanSchedule: undefined,
  connectionPreviewSchedule: undefined,

  newCreatedConnection: undefined,
  isCreatingNewConnection: false,
  creatingNewConnectionMessage: undefined,

  isErrorCreateNewConnections: undefined,
};

export const getInfoSystemsDictionaryThunk = createAsyncThunk<
  InfoSystemType[],
  string | undefined,
  { rejectValue: string }
>('createNewConnections/getInfoSystemsDictionaryThunk', async (params, { rejectWithValue }) => {
  try {
    return await serverApi.getInfoSystemsDictionary(params);
  } catch (e) {
    return rejectWithValue(getRejectedValue('Подключения - Ошибка получения списка Информационных Систем', e));
  }
});

export const getSourceTypesDictionaryThunk = createAsyncThunk<
  ConnectionSourceType[],
  undefined,
  { rejectValue: string }
>('createNewConnections/getSourceTypesDictionaryThunk', async (_, { rejectWithValue }) => {
  try {
    return await serverApi.getSourceTypesDictionary();
  } catch (e) {
    return rejectWithValue(getRejectedValue('Подключения - Ошибка получения списка Типов Источников', e));
  }
});

export const getConnectorTypesDictionaryThunk = createAsyncThunk<
  ConnectionConnectorType[],
  undefined,
  { rejectValue: string }
>('createNewConnections/getConnectorTypesDictionaryThunk', async (_, { rejectWithValue }) => {
  try {
    return await serverApi.getConnectorTypesDictionary();
  } catch (e) {
    return rejectWithValue(getRejectedValue('Подключения - Ошибка получения списка Типов Подключений', e));
  }
});

export const createNewConnectionThunk = createAsyncThunk<ConnectionType, undefined, { rejectValue: string }>(
  'createNewConnections/createNewConnectionThunk',
  async (_, { rejectWithValue, getState }) => {
    try {
      const state = getState() as RootState;
      const params = getNewConnectionParams(state.createNewConnection);
      return await serverApi.createNewConnection(params);
    } catch (e) {
      return rejectWithValue(getRejectedValue('Ошибка создания Подключения', e));
    }
  }
);

export const createNewConnectionsSlice = createSlice({
  name: 'createNewConnectionsSlice',
  initialState,
  reducers: {
    clearCreateNewConnection: () => initialState,
    clearCreateNewConnectionsError: (state) => {
      state.isErrorCreateNewConnections = undefined;
    },
    goBackActiveStepIndex: (state) => {
      if (state.activeStepIndex > 0) {
        state.returnFromStepIndex = state.activeStepIndex;
        state.activeStepIndex = state.activeStepIndex - 1;
      }
    },
    setActiveInfoSystem: (state, action) => {
      state.activeInfoSystem = action.payload;
      if (state.isRequiredInfoSystem === true) {
        state.isRequiredInfoSystem = false;
      }
    },
    setActiveSourceType: (state, action) => {
      state.activeSourceType = action.payload;
      if (action.payload) {
        state.connectionGeneralSettings = action.payload.default_settings;
      }
      if (state.isRequiredSourceType === true) {
        state.isRequiredSourceType = false;
      }
    },
    setActiveConnectorType: (state, action) => {
      state.activeConnectorType = action.payload;
      if (state.isRequiredConnectorType === true) {
        state.isRequiredConnectorType = false;
      }
    },
    setActiveStepIndex: (state, action) => {
      state.activeStepIndex = action.payload;
    },
    setIpAddress: (state, action) => {
      state.ipAddress = action.payload;
      if (state.isRequiredIpAddress === true) {
        state.isRequiredIpAddress = false;
      }
    },
    setConnectionPort: (state, action) => {
      state.connectionPort = action.payload;
      if (state.isRequiredConnectionPort === true) {
        state.isRequiredConnectionPort = false;
      }
    },
    setConnectionUser: (state, action) => {
      state.connectionUser = action.payload;
      if (state.isRequiredConnectionUser === true) {
        state.isRequiredConnectionUser = false;
      }
    },
    setConnectionPassword: (state, action) => {
      state.connectionPassword = action.payload;
      if (state.isRequiredConnectionPassword === true) {
        state.isRequiredConnectionPassword = false;
      }
    },
    setConnectionInstance: (state, action) => {
      state.connectionInstance = action.payload;
      if (state.isRequiredConnectionInstance === true) {
        state.isRequiredConnectionInstance = false;
      }
    },
    setConnectionUrl: (state, action) => {
      state.connectionUrl = action.payload;
      if (state.isRequiredConnectionUrl === true) {
        state.isRequiredConnectionUrl = false;
      }
    },
    setConnectionName: (state, action) => {
      state.connectionName = action.payload;
      if (state.isRequiredConnectionName === true) {
        state.isRequiredConnectionName = false;
      }
    },
    setConnectionDescription: (state, action) => {
      state.connectionDescription = action.payload;
    },
    setConnectionSearchValue: (state, action) => {
      state.connectionSearchValue = action.payload;
    },
    setConnectionGeneralSettings: (state, action) => {
      state.connectionGeneralSettings = action.payload;
    },
    setSuccessConnectionCheck: (state, action) => {
      state.isNewConnectionOnChecking = false;
      state.isNewConnectionChecked = true;
      state.newConnectionAssets = action.payload.assets;
      state.connectionVersion = action.payload.version;
    },
    setFailureConnectionCheck: (state) => {
      state.isNewConnectionChecked = false;
      state.isNewConnectionOnChecking = false;
      state.newConnectionAssets = undefined;
    },
    setInProgressConnectionCheck: (state) => {
      state.isNewConnectionChecked = false;
      state.isNewConnectionOnChecking = true;
      state.newConnectionAssets = undefined;
      state.isErrorCreateNewConnections = undefined;
      state.isRequiredNewConnectionChecked = false;
    },
    setFinishConnectionCheck: (state) => {
      state.isNewConnectionOnChecking = false;
    },
    setNewConnectionSelectedAssets: (state, action) => {
      const newSelectedAssets: CheckConnectionAssetType[] = [];
      const items = action.payload as ItemComboboxType[] | null;

      if (items && state.newConnectionAssets) {
        items.forEach((item) => {
          const selectedAsset = state.newConnectionAssets?.find((asset) => asset.name === item.label);
          if (selectedAsset) {
            newSelectedAssets.push({ ...selectedAsset, settings: state.activeSourceType?.default_settings });
          }
        });
      }

      state.newConnectionSelectedAssets = !!newSelectedAssets.length ? newSelectedAssets : undefined;
      if (state.isRequiredSelectedAssets === true) {
        state.isRequiredSelectedAssets = false;
      }

      if (
        state.newConnectionSelectedAssets &&
        state.newConnectionAssets &&
        newSelectedAssets.length < state.newConnectionAssets.length &&
        state?.scanAll
      ) {
        state.scanAll = false;
      }
    },
    setNewConnectionAssetSettings: (state, action) => {
      const changedAsset = state.newConnectionSelectedAssets?.find(
        (asset) => asset.name === action.payload.connectionAsset.name
      );
      if (changedAsset) {
        changedAsset.settings = action.payload.assetSettings;
      }
    },

    setConnectionGeneralSettingsToAllAssets: (state) => {
      if (!!state.newConnectionSelectedAssets?.length) {
        state.newConnectionSelectedAssets.forEach((asset) => {
          asset.settings = state.connectionGeneralSettings;
        });
      }
    },

    setConnectionScanSchedule: (state, action) => {
      state.connectionScanSchedule = action.payload;
    },

    setConnectionPreviewSchedule: (state, action) => {
      state.connectionPreviewSchedule = action.payload;
    },

    setExistedConnection: (state, action) => {
      state.newCreatedConnection = action.payload;
    },

    sliceValidateStepTwoDbInputs: (state) => {
      validateStepTwoDbInputs(state);
    },

    sliceValidateStepTwoOracle: (state) => {
      validateStepTwoOracle(state);
    },

    sliceValidateStepTwoOdata: (state) => {
      validateStepTwoOdata(state);
    },

    validateAndGoForwardStep: (state) => {
      if (state.activeStepIndex === 0) {
        validateAndGoForwardStepOne(state);
      } else if (state.activeStepIndex === 1) {
        validateAndGoForwardStepTwo(state);
      } else if (state.activeStepIndex === 2) {
        validateAndGoForwardStepTree(state);
      } else if (state.activeStepIndex === 3) {
        validateAndGoForwardStepFour(state);
      }
    },
    clearCreatingNewConnectionMessage: (state) => {
      state.creatingNewConnectionMessage = undefined;
    },
    clearStateForStepsFromTwoToFive: (state) => {
      const tempInfoSystems = state.infoSystems;
      const tempActiveInfoSystem = state.activeInfoSystem;
      const tempSourceTypes = state.sourceTypes;
      const tempActiveSourceType = state.activeSourceType;
      const tempConnectorTypes = state.connectorTypes;
      const tempActiveConnectorType = state.activeConnectorType;
      Object.assign(state, initialState);
      state.activeStepIndex = 0;
      state.infoSystems = tempInfoSystems;
      state.activeInfoSystem = tempActiveInfoSystem;
      state.sourceTypes = tempSourceTypes;
      state.activeSourceType = tempActiveSourceType;
      state.connectorTypes = tempConnectorTypes;
      state.activeConnectorType = tempActiveConnectorType;
    },
    clearNewConnectionAssetsForStepTwo: (state) => {
      state.isNewConnectionChecked = false;
      state.isNewConnectionOnChecking = false;
      state.newConnectionAssets = undefined;
      state.newConnectionSelectedAssets = undefined;
      state.isRequiredSelectedAssets = false;
      state.returnFromStepIndex = undefined;
    },
    clearNewConnectionSelectedAssetsForStepTwo: (state) => {
      state.newConnectionSelectedAssets = undefined;
      state.isRequiredSelectedAssets = false;
      state.returnFromStepIndex = undefined;
    },
    newConnectionToggleScanAll: (state) => {
      state.scanAll = !state.scanAll;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInfoSystemsDictionaryThunk.fulfilled, (state, action) => {
        state.isLoadingInfoSystem = false;
        state.infoSystems = action.payload;
      })
      .addCase(getInfoSystemsDictionaryThunk.pending, (state) => {
        state.infoSystems = undefined;
        state.isLoadingInfoSystem = true;
        state.isErrorCreateNewConnections = undefined;
      })
      .addCase(getInfoSystemsDictionaryThunk.rejected, (state, action) => {
        state.isLoadingInfoSystem = false;
        state.isErrorCreateNewConnections = action.payload
          ? action.payload.toString()
          : 'Неизвестная ошибка - createNewConnectionsSlice';
      })

      .addCase(getSourceTypesDictionaryThunk.fulfilled, (state, action) => {
        state.isLoadingSourceTypes = false;
        state.sourceTypes = action.payload;
      })
      .addCase(getSourceTypesDictionaryThunk.pending, (state) => {
        state.sourceTypes = undefined;
        state.isLoadingSourceTypes = true;
        state.isErrorCreateNewConnections = undefined;
      })
      .addCase(getSourceTypesDictionaryThunk.rejected, (state, action) => {
        state.isLoadingSourceTypes = false;
        state.isErrorCreateNewConnections = action.payload
          ? action.payload.toString()
          : 'Неизвестная ошибка - createNewConnectionsSlice';
      })

      .addCase(getConnectorTypesDictionaryThunk.fulfilled, (state, action) => {
        state.isLoadingConnectorTypes = false;
        state.connectorTypes = action.payload;
      })
      .addCase(getConnectorTypesDictionaryThunk.pending, (state) => {
        state.connectorTypes = undefined;
        state.isLoadingConnectorTypes = true;
        state.isErrorCreateNewConnections = undefined;
      })
      .addCase(getConnectorTypesDictionaryThunk.rejected, (state, action) => {
        state.isLoadingConnectorTypes = false;
        state.isErrorCreateNewConnections = action.payload
          ? action.payload.toString()
          : 'Неизвестная ошибка - createNewConnectionsSlice';
      })

      .addCase(createNewConnectionThunk.fulfilled, (state, action) => {
        state.isCreatingNewConnection = false;
        state.newCreatedConnection = action.payload;
        state.creatingNewConnectionMessage = 'Подключение создано';
      })
      .addCase(createNewConnectionThunk.pending, (state) => {
        state.isCreatingNewConnection = true;
        state.newCreatedConnection = undefined;
        state.creatingNewConnectionMessage = undefined;
      })
      .addCase(createNewConnectionThunk.rejected, (state, action) => {
        state.isCreatingNewConnection = false;
        state.creatingNewConnectionMessage = action.payload ? action.payload : 'Ошибка создания Подключения';
      });
  },
});

export const {
  clearCreateNewConnection,
  clearCreateNewConnectionsError,
  goBackActiveStepIndex,
  setActiveInfoSystem,
  setActiveSourceType,
  setActiveConnectorType,
  setActiveStepIndex,
  setIpAddress,
  setConnectionPort,
  setConnectionUser,
  setConnectionPassword,
  setConnectionInstance,
  setConnectionUrl,
  setConnectionName,
  setConnectionSearchValue,
  setConnectionDescription,
  sliceValidateStepTwoDbInputs,
  sliceValidateStepTwoOracle,
  sliceValidateStepTwoOdata,
  validateAndGoForwardStep,
  setSuccessConnectionCheck,
  setFailureConnectionCheck,
  setInProgressConnectionCheck,
  setFinishConnectionCheck,
  setNewConnectionSelectedAssets,
  setConnectionGeneralSettings,
  setNewConnectionAssetSettings,
  setConnectionGeneralSettingsToAllAssets,
  setConnectionScanSchedule,
  setConnectionPreviewSchedule,
  clearCreatingNewConnectionMessage,
  clearStateForStepsFromTwoToFive,
  clearNewConnectionAssetsForStepTwo,
  clearNewConnectionSelectedAssetsForStepTwo,
  setExistedConnection,
  newConnectionToggleScanAll,
} = createNewConnectionsSlice.actions;

export const selectorActiveStepIndex = (state: RootState) => state.createNewConnection.activeStepIndex;
export const selectorReturnFromStepIndex = (state: RootState) => state.createNewConnection.returnFromStepIndex;

export const selectorInfoSystems = (state: RootState) => state.createNewConnection.infoSystems;
export const selectorIsLoadingInfoSystem = (state: RootState) => state.createNewConnection.isLoadingInfoSystem;
export const selectorIsRequiredInfoSystem = (state: RootState) => state.createNewConnection.isRequiredInfoSystem;
export const selectorActiveInfoSystem = (state: RootState) => state.createNewConnection.activeInfoSystem;

export const selectorSourceTypes = (state: RootState) => state.createNewConnection.sourceTypes;
export const selectorIsLoadingSourceTypes = (state: RootState) => state.createNewConnection.isLoadingSourceTypes;
export const selectorIsRequiredSourceType = (state: RootState) => state.createNewConnection.isRequiredSourceType;
export const selectorActiveSourceType = (state: RootState) => state.createNewConnection.activeSourceType;

export const selectorConnectorTypes = (state: RootState) => state.createNewConnection.connectorTypes;
export const selectorIsLoadingConnectorTypes = (state: RootState) => state.createNewConnection.isLoadingConnectorTypes;
export const selectorIsRequiredConnectorType = (state: RootState) => state.createNewConnection.isRequiredConnectorType;
export const selectorActiveConnectorType = (state: RootState) => state.createNewConnection.activeConnectorType;

export const selectorIpAddress = (state: RootState) => state.createNewConnection.ipAddress;
export const selectorIsRequiredIpAddress = (state: RootState) => state.createNewConnection.isRequiredIpAddress;

export const selectorConnectionPort = (state: RootState) => state.createNewConnection.connectionPort;
export const selectorIsRequiredConnectionPort = (state: RootState) =>
  state.createNewConnection.isRequiredConnectionPort;

export const selectorConnectionUser = (state: RootState) => state.createNewConnection.connectionUser;
export const selectorIsRequiredConnectionUser = (state: RootState) =>
  state.createNewConnection.isRequiredConnectionUser;

export const selectorConnectionInstance = (state: RootState) => state.createNewConnection.connectionInstance;
export const selectorIsRequiredConnectionInstance = (state: RootState) =>
  state.createNewConnection.isRequiredConnectionInstance;

export const selectorConnectionUrl = (state: RootState) => state.createNewConnection.connectionUrl;
export const selectorIsRequiredConnectionUrl = (state: RootState) => state.createNewConnection.isRequiredConnectionUrl;

export const selectorConnectionName = (state: RootState) => state.createNewConnection.connectionName;
export const selectorIsRequiredConnectionName = (state: RootState) =>
  state.createNewConnection.isRequiredConnectionName;

export const selectorConnectionDescription = (state: RootState) => state.createNewConnection.connectionDescription;
export const selectorConnectionSearchValue = (state: RootState) => state.createNewConnection.connectionSearchValue;

export const selectorConnectionPassword = (state: RootState) => state.createNewConnection.connectionPassword;
export const selectorIsRequiredConnectionPassword = (state: RootState) =>
  state.createNewConnection.isRequiredConnectionPassword;

export const selectorIsErrorCreateNewConnections = (state: RootState) =>
  state.createNewConnection.isErrorCreateNewConnections;

export const selectorIsNewConnectionChecked = (state: RootState) => state.createNewConnection.isNewConnectionChecked;

export const selectorIsNewConnectionOnChecking = (state: RootState) =>
  state.createNewConnection.isNewConnectionOnChecking;

export const selectorNewConnectionAssets = (state: RootState) => state.createNewConnection.newConnectionAssets;
export const selectorNewConnectionSelectedAssets = (state: RootState) =>
  state.createNewConnection.newConnectionSelectedAssets;

export const selectorIsRequiredSelectedAssets = (state: RootState) =>
  state.createNewConnection.isRequiredSelectedAssets;

export const selectorConnectionGeneralSettings = (state: RootState) =>
  state.createNewConnection.connectionGeneralSettings;

export const selectorIsRequiredNewConnectionChecked = (state: RootState) =>
  state.createNewConnection.isRequiredNewConnectionChecked;

export const selectorNewConnectionScanSchedule = (state: RootState) => state.createNewConnection.connectionScanSchedule;

export const selectorNewConnectionScanAll = (state: RootState) => state.createNewConnection.scanAll;

export const selectorNewConnectionPreviewSchedule = (state: RootState) =>
  state.createNewConnection.connectionPreviewSchedule;

export const selectorNewCreatedConnection = (state: RootState) => state.createNewConnection.newCreatedConnection;
export const selectorIsCreatingNewConnection = (state: RootState) => state.createNewConnection.isCreatingNewConnection;
export const selectorCreatingNewConnectionMessage = (state: RootState) =>
  state.createNewConnection.creatingNewConnectionMessage;

export default createNewConnectionsSlice.reducer;
