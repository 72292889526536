import React from 'react';

import classes from './ConnectionsContentContainer.module.css';
import ConnectionsTable from './ConnectionsTable/ConnectionsTable';
import { useAppSelector } from '../../../../../hooks/hooks';
import { selectorIsLoadingConnections } from '../../../../../store/connectionsSlice';
import { Loader } from '@consta/uikit/Loader';
import { LeftSideBarHandleType } from '../../../../../shared-front/LeftSideBar/LeftSideBar';
import { CPRightSideBarEnum } from '../../../ConnectionsPage';

interface IConnectionsContentContainerProps {
  refSidebar: React.RefObject<LeftSideBarHandleType>;
  setRightSideBarMode: (mode: CPRightSideBarEnum | undefined) => void;
}

const ConnectionsContentContainer: React.FC<IConnectionsContentContainerProps> = ({
  refSidebar,
  setRightSideBarMode,
}) => {
  const isLoadingConnections = useAppSelector(selectorIsLoadingConnections);

  return (
    <div className={classes.container}>
      {isLoadingConnections ? (
        <Loader />
      ) : (
        <ConnectionsTable refSidebar={refSidebar} setRightSideBarMode={setRightSideBarMode} />
      )}
    </div>
  );
};

export default ConnectionsContentContainer;
