import React from 'react';

import classes from './ConnectionAssetsCombobox.module.css';
import { Combobox } from '@consta/uikit/Combobox';
import { ItemComboboxConnectionAssetType, ItemComboboxType } from '../../types/types';
import { Badge } from '@consta/uikit/Badge';
import { CheckConnectionAssetType } from '../../api/serverResponse';
import { cn } from '@bem-react/classname';
const cnComboboxExampleRenderItem = cn('ComboboxExampleRenderItem');

const mapLabel = {
  pass: 'Доступно',
  partly: 'Ограничено',
  no_access: 'Не доступно',
} as const;

const mapStatus = {
  pass: 'success',
  partly: 'warning',
  no_access: 'error',
} as const;

const getLabel = (item: ItemComboboxConnectionAssetType | undefined) => {
  const slug = (item as ItemComboboxConnectionAssetType).slug;
  if (slug) {
    return mapLabel[slug];
  } else {
    return 'DB error';
  }
};

const getStatus = (item: ItemComboboxConnectionAssetType | undefined) => {
  const slug = (item as ItemComboboxConnectionAssetType).slug;
  if (slug) {
    return mapStatus[slug];
  } else {
    return 'error';
  }
};

const getComboboxValue = (
  items: ItemComboboxType[],
  selectedAssets: CheckConnectionAssetType[] | null | undefined
): ItemComboboxType[] | null => {
  const selectedItems: ItemComboboxType[] = [];
  selectedAssets?.forEach((asset) => {
    const selectedAsset = items.find((item) => item.label === asset.name);
    if (selectedAsset) {
      selectedItems.push(selectedAsset);
    }
  });
  return !!selectedItems.length ? selectedItems : null;
};

interface IConnectionAssetsComboboxProps {
  assets: CheckConnectionAssetType[] | null | undefined;
  selectedAssets: CheckConnectionAssetType[] | null | undefined;
  onChangeAssets: (value: ItemComboboxType[] | null) => void;
  disabled?: boolean | undefined;
  required?: boolean | undefined;
  caption?: string | undefined;
  status?: 'success' | 'warning' | 'alert' | undefined;
}

const ConnectionAssetsCombobox: React.FC<IConnectionAssetsComboboxProps> = ({
  assets = [],
  selectedAssets = [],
  onChangeAssets,
  disabled,
  required,
  caption,
  status,
}) => {
  const items = assets?.map((asset, ind) => ({
    id: ind,
    label: asset.name,
    slug: asset.status?.slug,
  })) as ItemComboboxConnectionAssetType[];

  const value = getComboboxValue(items, selectedAssets);

  const onChangeAssetsHandler = ({ value }: { value: ItemComboboxType[] | null }) => {
    onChangeAssets(value);
  };

  return (
    <div className={classes.container}>
      <Combobox
        label={'Базы данных'}
        id={'CreateConnectionAssetsCombobox'}
        placeholder="Выберите вариант"
        items={items}
        value={value}
        onChange={onChangeAssetsHandler}
        multiple
        selectAll
        style={{ zIndex: 999 }}
        disabled={disabled}
        required={required}
        caption={caption}
        status={status}
        renderItem={({ item, active, hovered, onClick, onMouseEnter }) => (
          <div
            className={cnComboboxExampleRenderItem('Item', { active, hovered })}
            aria-selected={active}
            aria-hidden="true"
            onMouseEnter={onMouseEnter}
            onClick={onClick}
            style={{ display: 'flex', marginLeft: '-2px' }}
          >
            <div className="ListItemGrid-Slot ListItemGrid-Slot_position_left" style={{ marginLeft: '-6px' }}>
              <label
                className="Checkbox Checkbox_size_l Checkbox_view_primary Checkbox_align_center"
                style={{ marginRight: '8px' }}
              >
                <input type="checkbox" className="Checkbox-Input MixFocus" checked={active} onChange={() => {}} />
              </label>
            </div>
            {item.label}
            {(item as ItemComboboxConnectionAssetType).slug && (
              <div className={classes.badgeContainer}>
                <Badge
                  className={cnComboboxExampleRenderItem('Badge')}
                  as="span"
                  size="s"
                  label={getLabel(item as ItemComboboxConnectionAssetType)}
                  status={getStatus(item as ItemComboboxConnectionAssetType)}
                />
              </div>
            )}
          </div>
        )}
      />
    </div>
  );
};

export default ConnectionAssetsCombobox;
