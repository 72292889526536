import React from 'react';

import classes from './ButtonDisplayExistedConnection.module.css';
import CustomBlueTextButton from '../../../../../../../../Components/CustomBlueTextButton/CustomBlueTextButton';
import { useAppDispatch } from '../../../../../../../../hooks/hooks';
import { clearConnectionsError, setConnectionsError } from '../../../../../../../../store/connectionsSlice';
import { serverApi } from '../../../../../../../../api/serverApi';
import { setExistedConnection } from '../../../../../../../../store/createNewConnectionSlice';
import { ProgressSpin } from '@consta/uikit/ProgressSpin';
import { useLoading } from '../../../../../../../../hooks/useLoading';
import { ConnectionDetailType } from '../../../../../../../../types/types';

interface IButtonDisplayExistedConnectionProps {
  connectionExistedUuid: string | undefined;
}

const ButtonDisplayExistedConnection: React.FC<IButtonDisplayExistedConnectionProps> = ({ connectionExistedUuid }) => {
  const dispatch = useAppDispatch();
  const [isLoadingConnectionDetail, getConnectionDetail] = useLoading<ConnectionDetailType>(
    serverApi.getConnectionDetail,
    connectionExistedUuid
  );

  const onClick = async () => {
    if (connectionExistedUuid) {
      dispatch(clearConnectionsError());
      const [connectionData, errorMessage] = await getConnectionDetail();
      if (errorMessage) {
        dispatch(setConnectionsError('Ошибка получения данных для подключения.\n' + errorMessage));
      } else if (connectionData) {
        dispatch(setExistedConnection(connectionData));
      }
    }
  };

  return connectionExistedUuid ? (
    <div className={classes.container}>
      {isLoadingConnectionDetail ? (
        <ProgressSpin style={{ marginLeft: '8px', height: '26px' }} />
      ) : (
        <CustomBlueTextButton title={'Открыть'} onClick={onClick} />
      )}
    </div>
  ) : null;
};

export default ButtonDisplayExistedConnection;
