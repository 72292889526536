import { TABLE_RDB_COLUMNS } from '../../Pages/CatalogPage/BookmarkContentSwitcher/CatalogContent/CatalogDataContainer/TableCatalog/tableRdbColumns';
import { TABLE_CDB_COLUMNS } from '../../Pages/CatalogPage/BookmarkContentSwitcher/CatalogContent/CatalogDataContainer/TableCatalog/tableСdbColumns';
import { TABLE_SYSTEM_COLUMNS } from '../../Pages/CatalogPage/BookmarkContentSwitcher/CatalogContent/CatalogDataContainer/TableCatalog/tableSystemColumns';
import { TABLE_ODATA1C_COLUMNS } from '../../Pages/CatalogPage/BookmarkContentSwitcher/CatalogContent/CatalogDataContainer/TableCatalog/tableOdata1cColumns';
import { TABLE_API_COLUMNS } from '../../Pages/CatalogPage/BookmarkContentSwitcher/CatalogContent/CatalogDataContainer/TableCatalog/tableApiColumns';

export const getTableCatalogColumnsTemplate = (slug: string | undefined) => {
  return slug
    ? slug === 'rdb'
      ? TABLE_RDB_COLUMNS
      : slug === 'cdb'
      ? TABLE_CDB_COLUMNS
      : slug === 'system'
      ? TABLE_SYSTEM_COLUMNS
      : slug === 'odata1c'
      ? TABLE_ODATA1C_COLUMNS
      : slug === 'api'
      ? TABLE_API_COLUMNS
      : []
    : [];
};
