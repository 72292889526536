import React from 'react';
import classes from './ObjectDeleted.module.css';
import objectDeleted from '../../assets/CatalogPage/objectDeleted.svg';
import { Text } from '@consta/uikit/Text';

const ObjectDeleted: React.FC = () => {
  return (
    <div className={classes.container}>
      <img className={classes.imgObjectDeleted} src={objectDeleted} alt="objectDeleted" />
      <Text className={classes.title}>{'Страница не найдена'}</Text>
      <Text className={classes.subTitle}>{'Объект был удален'}</Text>
    </div>
  );
};

export default ObjectDeleted;
