import { instanceAxios } from './instanceAxios';
import { UserInfoServerResponseType } from './serverResponse';
import { LogEventParamsType } from '../types/types';

export const appApi = {
  async getUserInfo() {
    const response = await instanceAxios.get<UserInfoServerResponseType>('/userinfo');
    return response.data;
  },

  async postLogEvent(params: LogEventParamsType) {
    const response = await instanceAxios.post('/event-log', params);
    return response.data;
  },
};
