import React from 'react';

import classes from './SourcesList.module.css';
import { InfoSystemSourceType } from '../../../../../types/types';
import { Text } from '@consta/uikit/Text';
import CustomHoverToolTip from '../../../../../shared-front/CustomHoverToolTip/CustomHoverToolTip';

interface ISourcesListProps {
  sourceTypes: InfoSystemSourceType[] | null | undefined;
}

const SourcesList: React.FC<ISourcesListProps> = ({ sourceTypes }) => {
  if (!!sourceTypes?.length) {
    return (
      <div className={classes.container}>
        {sourceTypes.map((source) => {
          if (source.icon) {
            return (
              <CustomHoverToolTip title={source.name ?? ''} direction={'downCenter'}>
                <img src={`data:image/svg+xml;base64,${source.icon}`} className={classes.sourceIco} alt="source icon" />
              </CustomHoverToolTip>
            );
          } else {
            return (
              <div className={classes.sourceIco}>
                <Text className={classes.source}>{source.name}</Text>
              </div>
            );
          }
        })}
      </div>
    );
  }
  return null;
};

export default SourcesList;
