import { metaApi } from './metaApi';
import { catalogApi } from './catalogApi';
import { appApi } from './appApi';
import { infoSystemsApi } from './InfoSystemsApi';
import { connectionsApi } from './connectionsApi';
import { createNewConnectionApi } from './createNewConnectionApi';
import { launchHistoryApi } from './launchHistoryApi';

export const serverApi = {
  ...appApi,
  ...metaApi,
  ...catalogApi,
  ...infoSystemsApi,
  ...connectionsApi,
  ...createNewConnectionApi,
  ...launchHistoryApi,
};
