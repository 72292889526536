import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { RootState } from './store';

import { AssetByTypeType, AssetsByTypeServerResponseType, HintSearchServerResponseType } from '../api/serverResponse';
import { serverApi } from '../api/serverApi';
import { getRejectedValue } from './rejectedValueHelper';

type MetaSliceType = {
  assetsByType: AssetByTypeType[];
  assetsViewType: 'detailed' | undefined;
  searchValue: string;
  searchHints: HintSearchServerResponseType[];
  isSearch: boolean;
  isLoading: boolean;
  isError: string | undefined;
};

const initialState: MetaSliceType = {
  assetsByType: [],
  assetsViewType: undefined,
  searchValue: '',
  searchHints: [],
  isSearch: false,
  isLoading: false,
  isError: undefined,
};

export const getMetaAssetsByTypeThunk = createAsyncThunk<
  AssetsByTypeServerResponseType,
  undefined,
  { rejectValue: string }
>('meta/getMetaAssetsThunk', async (_, { rejectWithValue }) => {
  try {
    return await serverApi.getMetaAssetsByType();
  } catch (e) {
    return rejectWithValue(getRejectedValue('Ошибка получения Категорий для Метаданных', e));
  }
});

export const getMetaHintsSearchThunk = createAsyncThunk<
  HintSearchServerResponseType[],
  string,
  { rejectValue: string }
>('meta/getMetaHintsSearchThunk', async (value, { rejectWithValue }) => {
  try {
    return await serverApi.getMetaHintsSearch(value);
  } catch (e) {
    return rejectWithValue(getRejectedValue('Ошибка поиска Категорий для Метаданных', e));
  }
});

export const metaSlice = createSlice({
  name: 'metaSlice',
  initialState,
  reducers: {
    setAssetsViewType: (state, action) => {
      state.assetsViewType = action.payload;
    },
    clearMetaError: (state) => {
      state.isError = undefined;
    },
    setSearchValue: (state, action) => {
      state.searchValue = action.payload;
    },
    resetSearchHints: (state) => {
      state.searchHints = [];
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getMetaAssetsByTypeThunk.fulfilled, (state, action) => {
        state.assetsByType = action.payload.data;
        state.isLoading = false;
      })
      .addCase(getMetaHintsSearchThunk.fulfilled, (state, action) => {
        state.searchHints = action.payload;
        state.isSearch = false;
      })
      .addCase(getMetaHintsSearchThunk.pending, (state) => {
        state.searchHints = [];
        state.isSearch = true;
      })
      .addCase(getMetaHintsSearchThunk.rejected, (state) => {
        state.isSearch = false;
      })
      .addMatcher(isAnyOf(getMetaAssetsByTypeThunk.pending), (state) => {
        state.isLoading = true;
      })
      .addMatcher(isAnyOf(getMetaAssetsByTypeThunk.rejected), (state, action) => {
        state.isLoading = false;
        state.isError = action.payload ? action.payload : 'Неизвестная ошибка - metaSlice';
      });
  },
});

export const { setSearchValue, resetSearchHints, clearMetaError, setAssetsViewType } = metaSlice.actions;

export const selectorMetaAssetsByType = (state: RootState) => state.meta.assetsByType;
export const selectorMetaSearchHints = (state: RootState) => state.meta.searchHints;
export const selectorMetaIsLoading = (state: RootState) => state.meta.isLoading;
export const selectorMetaIsSearch = (state: RootState) => state.meta.isSearch;
export const selectorMetaSliceError = (state: RootState) => state.meta.isError;
export const selectorMetaSearchValue = (state: RootState) => state.meta.searchValue;
export const selectorMetaAssetsViewType = (state: RootState) => state.meta.assetsViewType;

export default metaSlice.reducer;
