import { useEffect, useRef } from 'react';
import { useGetTechMetaDocuments } from './useGetTechMetaDocuments';
import { useAppSelector } from './hooks';
import { selectorCatalogActiveSection } from '../store/catalogSlice';

export const useCatalogItemsForDefaultStart = () => {
  const isUploadedDocumentsRef = useRef(false);
  const getTechMetaDocuments = useGetTechMetaDocuments();

  const catalogActiveSection = useAppSelector(selectorCatalogActiveSection);

  useEffect(() => {
    if (!isUploadedDocumentsRef.current && catalogActiveSection) {
      isUploadedDocumentsRef.current = true;
      getTechMetaDocuments({ catalogActiveSection });
    }
  }, [getTechMetaDocuments, catalogActiveSection]);
};
