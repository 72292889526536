import React from 'react';

import { TableColumnType } from '../../Table/Table';
import classes from './ColumnsTuneList.module.css';
import ColumnsTuneListItem from './ColumnsTuneListItem/ColumnsTuneListItem';

interface IColumnsTuneListProps {
  columns: (object & TableColumnType)[];
  setColumns: (columns: (object & TableColumnType)[]) => void;
  searchTitleValue: string | null;
}

const ColumnsTuneList: React.FC<IColumnsTuneListProps> = ({ columns, setColumns, searchTitleValue }) => {
  const onChangeShowColumn = (accessorKey: string) => {
    setColumns(
      columns.map((column) => (column.accessorKey === accessorKey ? { ...column, isShow: !column.isShow } : column))
    );
  };

  const moveColumn = (dragColumn: object & TableColumnType, hoverColumn: object & TableColumnType) => {
    const indexDragColumn = columns.findIndex((column) => column.accessorKey === dragColumn.accessorKey);
    const indexHoverColumn = columns.findIndex((column) => column.accessorKey === hoverColumn.accessorKey);
    if (indexDragColumn !== undefined && indexHoverColumn !== undefined) {
      const newColumns = [...columns];
      newColumns.splice(indexDragColumn, 1);
      newColumns.splice(indexHoverColumn, 0, dragColumn);
      setColumns(newColumns);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.selectedItemsList}>
        {columns.map((column) =>
          searchTitleValue ? (
            column.title.toLowerCase().includes(searchTitleValue.toLowerCase()) ? (
              <ColumnsTuneListItem
                key={column.accessorKey}
                column={{ ...column, id: column.accessorKey }}
                onChangeShowColumn={onChangeShowColumn}
                moveColumn={moveColumn}
              />
            ) : null
          ) : (
            <ColumnsTuneListItem
              key={column.accessorKey}
              column={{ ...column, id: column.accessorKey }}
              onChangeShowColumn={onChangeShowColumn}
              moveColumn={moveColumn}
            />
          )
        )}
      </div>
    </div>
  );
};

export default ColumnsTuneList;
