import React from 'react';

import classes from './ViewSelector.module.css';

import { ChoiceGroup } from '@consta/uikit/ChoiceGroup';
import { IconComponent } from '@consta/uikit/Icon';
import { IconCards } from '@consta/uikit/IconCards';
import { IconTable2 } from '@consta/uikit/IconTable2';
import {
  selectorCatalogActiveViewSelectorName,
  selectorCatalogIsHierarchyOpen,
  setActiveViewSelectorName,
} from '../../../../../../store/catalogSlice';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/hooks';

type ViewSelectorItemType = {
  name: string;
  icon?: IconComponent;
};

export const VIEW_SELECTOR_ITEMS: ViewSelectorItemType[] = [
  {
    name: 'Карточки',
    icon: IconCards,
  },
  {
    name: 'Таблица',
    icon: IconTable2,
  },
];

const ViewSelector: React.FC = () => {
  const dispatch = useAppDispatch();
  const activeViewSelectorName = useAppSelector(selectorCatalogActiveViewSelectorName);
  const isHierarchyOpen = useAppSelector(selectorCatalogIsHierarchyOpen);

  const activeViewSelectorItem = VIEW_SELECTOR_ITEMS.find((item) => item.name === activeViewSelectorName);

  const onChange = ({ value }: { value: ViewSelectorItemType }) => {
    dispatch(setActiveViewSelectorName(value.name));
  };

  return (
    <div className={classes.container}>
      <ChoiceGroup
        size={'s'}
        view={'ghost'}
        value={activeViewSelectorItem}
        onChange={onChange}
        items={VIEW_SELECTOR_ITEMS}
        getItemLabel={(item) => item.name}
        multiple={false}
        name="TypeOfViewSelector"
        onlyIcon={isHierarchyOpen}
      />
    </div>
  );
};

export default ViewSelector;
