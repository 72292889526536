import React, { useEffect } from 'react';

import classes from './CreateConnectionStepFive.module.css';
import SetCronJob from '../../../../../../shared-front/SetCronJob/SetCronJob';
import { useCronReducer } from '../../../../../../shared-front/SetCronJob/useCronReducer';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/hooks';
import {
  selectorConnectionGeneralSettings,
  selectorNewConnectionPreviewSchedule,
  selectorNewConnectionScanSchedule,
  setConnectionPreviewSchedule,
  setConnectionScanSchedule,
} from '../../../../../../store/createNewConnectionSlice';
import CollapseBlock from '../../../../../../Components/CollapseBlock/CollapseBlock';
import { defaultCronValue } from '../../../../../../utils/constants';

const CreateConnectionStepFive: React.FC = () => {
  const dispatch = useAppDispatch();
  const connectionGeneralSettings = useAppSelector(selectorConnectionGeneralSettings);
  const connectionScanSchedule = useAppSelector(selectorNewConnectionScanSchedule);
  const connectionPreviewSchedule = useAppSelector(selectorNewConnectionPreviewSchedule);
  const hasPreview = !!connectionGeneralSettings?.find((setting) => setting.key === 'limits_for_preview')?.value;

  const [cronValuesForScan, dispatchCronValuesForScan] = useCronReducer(connectionScanSchedule ?? defaultCronValue);
  const [cronValuesForPreview, dispatchCronValuesForPreview] = useCronReducer(
    connectionPreviewSchedule ?? defaultCronValue
  );

  useEffect(() => {
    dispatch(setConnectionScanSchedule(cronValuesForScan.cronValue));
  }, [dispatch, cronValuesForScan.cronValue]);

  useEffect(() => {
    dispatch(setConnectionPreviewSchedule(cronValuesForPreview.cronValue));
  }, [dispatch, cronValuesForPreview.cronValue]);

  return (
    <div className={classes.container}>
      <CollapseBlock
        title={'Расписание сканирования'}
        isOpen={true}
        isShortContainer={true}
        isShortChildrenContainer={true}
      >
        <SetCronJob cronValues={cronValuesForScan} dispatchCronValues={dispatchCronValuesForScan} />
      </CollapseBlock>
      {hasPreview && (
        <CollapseBlock
          title={'Расписание сканирования предпросмотра'}
          isOpen={true}
          isShortContainer={true}
          isShortChildrenContainer={true}
        >
          <SetCronJob cronValues={cronValuesForPreview} dispatchCronValues={dispatchCronValuesForPreview} />
        </CollapseBlock>
      )}
    </div>
  );
};

export default CreateConnectionStepFive;
