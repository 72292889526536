import React from 'react';

import classes from './HierarchyRow.module.css';
import iconTree from '../../assets/CatalogPage/Cards/iconTree.svg';
import iconArrowRight from '../../assets/CatalogPage/Cards/iconArrowRight.svg';
import { Text } from '@consta/uikit/Text';
import { BookmarkHierarchyLevelType, BookmarkType } from '../../types/types';
import { useExistedOrCreateNewBookmark } from '../../hooks/useExistedOrCreateNewBookmark';
import { createSearchParams, useSearchParams } from 'react-router-dom';

interface IHierarchyRowProps {
  level_1: BookmarkHierarchyLevelType;
  level_2?: BookmarkHierarchyLevelType;
  level_3?: BookmarkHierarchyLevelType;
  level_4?: BookmarkHierarchyLevelType;
}

const HierarchyRow: React.FC<IHierarchyRowProps> = ({ level_1, level_2, level_3, level_4 }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let [_, setSearchParams] = useSearchParams();
  const doExistedOrCreateNewBookmark = useExistedOrCreateNewBookmark();

  const onClick = (level: BookmarkHierarchyLevelType) => {
    const bookmark = {
      label: level.name,
      type: level.type,
      slug: level.slug,
      hash: level.uuid,
    } as BookmarkType;

    const searchParams = createSearchParams({
      type: `${level.type}`,
      hash: `${level.uuid}`,
      slug: `${level.slug}`,
    });
    setSearchParams(searchParams);

    doExistedOrCreateNewBookmark(bookmark);
  };

  return (
    <div className={classes.container}>
      <img src={iconTree} alt="hierarchy top" className={classes.ico} />
      <Text className={classes.linkToSystem} onClick={() => onClick(level_1)}>
        {level_1.name}
      </Text>
      {level_2 && (
        <>
          <img src={iconArrowRight} alt="hierarchy second level" className={classes.icoArrow} />
          <Text
            className={`${level_3 ? classes.linkToSystem : classes.edgeLevel}`}
            onClick={() => {
              level_3 && onClick(level_2);
            }}
          >
            {level_2.name}
          </Text>
        </>
      )}
      {level_3 && (
        <>
          <img src={iconArrowRight} alt="hierarchy third level" className={classes.icoArrow} />
          <Text
            className={`${level_4 ? classes.linkToSystem : classes.edgeLevel}`}
            onClick={() => {
              level_4 && onClick(level_3);
            }}
          >
            {level_3.name}
          </Text>
        </>
      )}
      {level_4 && (
        <>
          <img src={iconArrowRight} alt="hierarchy fourth level" className={classes.icoArrow} />
          <Text className={classes.edgeLevel}>{level_4.name}</Text>
        </>
      )}
    </div>
  );
};

export default HierarchyRow;
