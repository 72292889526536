import React, { useEffect } from 'react';

import classes from './CPRightSideBarCreateConnection.module.css';
import CreateProgressStepBar from './CreateProgressStepBar/CreateProgressStepBar';
import CreateProgressStepSelector from './CreateProgressStepSelector/CreateProgressStepSelector';
import CPRightSideBarInfoRow from '../CPRightSideBarInfoRow/CPRightSideBarInfoRow';
import Delimiter from '../../../../shared-front/Delimiter/Delimiter';
import { CPRightSideBarEnum } from '../../ConnectionsPage';
import CPRightSideBarBottomButtons from '../CPRightSideBarBottomButtons/CPRightSideBarBottomButtons';
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooks';
import { clearCreateNewConnection, selectorNewCreatedConnection } from '../../../../store/createNewConnectionSlice';
import { setTableConnectionsActiveRowUuid } from '../../../../store/connectionsSlice';

interface ICPRightSideBarCreateConnectionProps {
  rightSideBarMode: CPRightSideBarEnum | undefined;
  setRightSideBarMode: (mode: CPRightSideBarEnum | undefined) => void;
  closeSideBar?: () => void;
}

const CPRightSideBarCreateConnection: React.FC<ICPRightSideBarCreateConnectionProps> = ({
  rightSideBarMode,
  setRightSideBarMode,
  closeSideBar,
}) => {
  const dispatch = useAppDispatch();
  const newCreatedConnection = useAppSelector(selectorNewCreatedConnection);

  useEffect(() => {
    dispatch(clearCreateNewConnection());
  }, [dispatch]);

  useEffect(() => {
    if (newCreatedConnection && rightSideBarMode === CPRightSideBarEnum.CreateConnection) {
      setRightSideBarMode(CPRightSideBarEnum.DisplayConnection);
      dispatch(setTableConnectionsActiveRowUuid(undefined));
    }
  }, [dispatch, newCreatedConnection, setRightSideBarMode, rightSideBarMode]);

  return (
    <div className={classes.container}>
      <CPRightSideBarInfoRow
        rightSideBarMode={rightSideBarMode}
        setRightSideBarMode={setRightSideBarMode}
        closeSideBar={closeSideBar}
      />
      <Delimiter />
      <div className={classes.stepBarContainer}>
        <CreateProgressStepBar />
      </div>
      <div className={classes.contentContainer}>
        <CreateProgressStepSelector />
      </div>
      <CPRightSideBarBottomButtons closeSideBar={closeSideBar} />
    </div>
  );
};

export default CPRightSideBarCreateConnection;
