import { createTableColumn } from '../../shared-front/Table/utils/createTableColumn';
import { ColumnGenericType } from '../../types/types';
import { ReactNode } from 'react';

export type ObjectsTableSchemaDataType = {
  name?: string | null;
  hierarchy?: ReactNode | null;
  unified_object?: string | null;
  connector_type?: string | null;
  _alter_ts?: string | null;
  description_name?: string | null;
  level_1?: string | null;
  level_2?: string | null;
  object_attr?: string | null;
  attr_type?: string | null;
  object_kind?: string | null;
  description_value?: string | null;
  comment?: string | null;
  organization?: string | null;
  ts_alter?: string | null;
};

export const OBJECTS_TABLE_SCHEMA_COLUMNS: ColumnGenericType[] = [
  createTableColumn<ObjectsTableSchemaDataType>({
    accessorKey: 'name',
    title: 'Наименование',
    textLinesNumber: 2,
    isShow: true,
    size: 300,
  }),
  createTableColumn<ObjectsTableSchemaDataType>({
    accessorKey: 'unified_object',
    title: 'Тип',
    textLinesNumber: 2,
    isShow: true,
    size: 300,
  }),
  createTableColumn<ObjectsTableSchemaDataType>({
    accessorKey: 'hierarchy',
    title: 'Иерархия',
    textLinesNumber: 2,
    isShow: true,
    size: 300,
  }),
  createTableColumn<ObjectsTableSchemaDataType>({
    accessorKey: 'description_name',
    title: 'Краткое описание',
    textLinesNumber: 2,
    isShow: true,
    size: 300,
  }),
  createTableColumn<ObjectsTableSchemaDataType>({
    accessorKey: '_create_ts',
    title: 'Создано',
    textLinesNumber: 2,
    isShow: true,
    size: 300,
  }),
  createTableColumn<ObjectsTableSchemaDataType>({
    accessorKey: '_alter_ts',
    title: 'Изменено',
    textLinesNumber: 2,
    isShow: true,
    size: 300,
  }),

  createTableColumn<ObjectsTableSchemaDataType>({
    accessorKey: 'object_kind',
    title: 'Тип объекта',
    textLinesNumber: 2,
    isShow: false,
    size: 300,
  }),
  createTableColumn<ObjectsTableSchemaDataType>({
    accessorKey: 'description_value',
    title: 'Расширенное описание',
    textLinesNumber: 2,
    isShow: false,
    size: 300,
  }),
  createTableColumn<ObjectsTableSchemaDataType>({
    accessorKey: 'comment',
    title: 'Описание из системы-источник',
    textLinesNumber: 2,
    isShow: false,
    size: 300,
  }),
  createTableColumn<ObjectsTableSchemaDataType>({
    accessorKey: 'ts_create',
    title: 'Создан в системе-источнике',
    textLinesNumber: 2,
    isShow: false,
    size: 300,
  }),
  createTableColumn<ObjectsTableSchemaDataType>({
    accessorKey: 'ts_alter',
    title: 'Изменено в системе-источнике',
    textLinesNumber: 2,
    isShow: false,
    size: 300,
  }),
];

// createTableColumn<ObjectsTableSchemaDataType>({
//   accessorKey: 'level_1',
//   title: 'База данных',
//   textLinesNumber: 2,
//   isShow: false,
//   size: 300,
// }),
// createTableColumn<ObjectsTableSchemaDataType>({
//   accessorKey: 'level_2',
//   title: 'Объект данных',
//   textLinesNumber: 2,
//   isShow: false,
//   size: 300,
// }),
// createTableColumn<ObjectsTableSchemaDataType>({
//   accessorKey: 'object_attr',
//   title: 'Атрибут',
//   textLinesNumber: 2,
//   isShow: false,
//   size: 300,
// }),
// createTableColumn<ObjectsTableSchemaDataType>({
//   accessorKey: 'attr_type',
//   title: 'Тип данных атрибута',
//   textLinesNumber: 2,
//   isShow: false,
//   size: 300,
// }),
// createTableColumn<ObjectsTableSchemaDataType>({
//   accessorKey: 'organization',
//   title: 'Организация',
//   textLinesNumber: 2,
//   isShow: false,
//   size: 300,
// }),
// createTableColumn<ObjectsTableSchemaDataType>({
//   accessorKey: 'connector_type',
//   title: 'Тип источника',
//   textLinesNumber: 2,
//   isShow: true,
//   size: 300,
// }),
