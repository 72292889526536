import { TableColumnType } from '../Table';

export const getShortColumns = (longColumns: (object & TableColumnType)[]): TableColumnType[] => {
  return longColumns.map(
    (column) =>
      ({
        title: column.title,
        isShow: column.isShow,
        accessorKey: column.accessorKey,
      } as TableColumnType)
  );
};
