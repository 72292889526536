import React, { useRef } from 'react';

import classes from './ConnectionSettingsForm.module.css';
import RightSideBarTextField from '../../../../Components/RightSideBarTextField/RightSideBarTextField';
import CPRightSideBarModalCancel from '../CPRightSideBarBottomButtons/CPRightSideBarModalCancel/CPRightSideBarModalCancel';

interface IConnectionSettingsFormProps {
  valueIpAddress: string | null | undefined;
  onChangeIpAddress: (value: string | null) => void;
  isRequiredIpAddress: boolean;
  valueConnectionPort: string | null | undefined;
  onChangeConnectionPort: (value: string | null) => void;
  isRequiredConnectionPort: boolean;
  valueConnectionUser: string | null | undefined;
  onChangeConnectionUser: (value: string | null) => void;
  isRequiredConnectionUser: boolean;
  valueConnectionPassword: string | null | undefined;
  onChangeConnectionPassword: (value: string | null) => void;
  isRequiredConnectionPassword: boolean;
  valueConnectionInstance: string | null | undefined;
  onChangeConnectionInstance: (value: string | null) => void;
  isRequiredConnectionInstance: boolean;
  valueConnectionUrl: string | null | undefined;
  onChangeConnectionUrl: (value: string | null) => void;
  isRequiredConnectionUrl: boolean;
  isConnectionChecked: boolean;
  cardView?: 'db' | 'oracle' | 'odata' | null;
  onConfirmModal?: (modalMessage?: string) => void;
  isEditConnectionForm?: boolean;
}

const ConnectionSettingsForm: React.FC<IConnectionSettingsFormProps> = ({
  valueIpAddress,
  onChangeIpAddress,
  isRequiredIpAddress,
  valueConnectionPort,
  onChangeConnectionPort,
  isRequiredConnectionPort,
  valueConnectionUser,
  onChangeConnectionUser,
  isRequiredConnectionUser,
  valueConnectionPassword,
  onChangeConnectionPassword,
  isRequiredConnectionPassword,
  valueConnectionInstance,
  onChangeConnectionInstance,
  isRequiredConnectionInstance,
  valueConnectionUrl,
  onChangeConnectionUrl,
  isRequiredConnectionUrl,
  isConnectionChecked,
  cardView,
  onConfirmModal,
}) => {
  const [modalMessage, setModalMessage] = React.useState<string | undefined>(undefined);

  const setModalMessageHandler = (val: boolean) => {
    if (val) {
      setModalMessage((prev) => prev);
    } else {
      setModalMessage(undefined);
    }
  };

  const inputRefConnectionUrl = useRef<HTMLTextAreaElement | HTMLInputElement>(null);
  const inputRefIpAddress = useRef<HTMLTextAreaElement | HTMLInputElement>(null);
  const inputRefConnectionPort = useRef<HTMLTextAreaElement | HTMLInputElement>(null);
  const inputRefConnectionUser = useRef<HTMLTextAreaElement | HTMLInputElement>(null);
  const inputRefConnectionPassword = useRef<HTMLTextAreaElement | HTMLInputElement>(null);
  const inputRefConnectionInstance = useRef<HTMLTextAreaElement | HTMLInputElement>(null);

  const isOdata = cardView === 'odata';
  const isOracle = cardView === 'oracle';
  const isDb = !isOdata && !isOracle;

  const onClickConnectionUrl = () => {
    if (isConnectionChecked) {
      setModalMessage('ConnectionUrl');
    }
  };

  const onClickIpAddress = () => {
    if (isConnectionChecked) {
      setModalMessage('IpAddress');
    }
  };

  const onClickConnectionPort = () => {
    if (isConnectionChecked) {
      setModalMessage('ConnectionPort');
    }
  };

  const onClickConnectionUser = () => {
    if (isConnectionChecked) {
      setModalMessage('ConnectionUser');
    }
  };

  const onClickConnectionPassword = () => {
    if (isConnectionChecked) {
      setModalMessage('ConnectionPassword');
    }
  };

  const onClickConnectionInstance = () => {
    if (isConnectionChecked) {
      setModalMessage('ConnectionInstance');
    }
  };

  const onConfirmModalHandler = () => {
    setModalMessage(undefined);
    if (modalMessage === 'ConnectionUrl') {
      onChangeConnectionUrl(null);
      onConfirmModal && onConfirmModal('ConnectionUrl');
      inputRefConnectionUrl.current?.focus();
    } else if (modalMessage === 'IpAddress') {
      onChangeIpAddress(null);
      onConfirmModal && onConfirmModal('IpAddress');
      inputRefIpAddress.current?.focus();
    } else if (modalMessage === 'ConnectionPort') {
      onChangeConnectionPort(null);
      onConfirmModal && onConfirmModal('ConnectionPort');
      inputRefConnectionPort.current?.focus();
    } else if (modalMessage === 'ConnectionUser') {
      onChangeConnectionUser(null);
      onConfirmModal && onConfirmModal('ConnectionUser');
      inputRefConnectionUser.current?.focus();
    } else if (modalMessage === 'ConnectionPassword') {
      onChangeConnectionPassword(null);
      onConfirmModal && onConfirmModal('ConnectionPassword');
      inputRefConnectionPassword.current?.focus();
    } else if (modalMessage === 'ConnectionInstance') {
      onChangeConnectionInstance(null);
      onConfirmModal && onConfirmModal('ConnectionInstance');
      inputRefConnectionInstance.current?.focus();
    }
    onConfirmModal && onConfirmModal();
  };

  return (
    <div className={classes.container}>
      {isOdata ? (
        <div onClick={onClickConnectionUrl}>
          <RightSideBarTextField
            inputRef={inputRefConnectionUrl}
            required={true}
            label={'URL'}
            value={valueConnectionUrl}
            onChange={onChangeConnectionUrl}
            placeholder={'URL'}
            isRequired={isRequiredConnectionUrl}
          />
        </div>
      ) : (
        <div onClick={onClickIpAddress}>
          <RightSideBarTextField
            inputRef={inputRefIpAddress}
            required={true}
            label={'IP адрес/хост'}
            value={valueIpAddress}
            onChange={onChangeIpAddress}
            placeholder={'000.000.000.000'}
            isRequired={isRequiredIpAddress}
          />
        </div>
      )}
      {!isOdata && (
        <div onClick={onClickConnectionPort}>
          <RightSideBarTextField
            inputRef={inputRefConnectionPort}
            required={true}
            label={'Порт'}
            value={valueConnectionPort}
            onChange={onChangeConnectionPort}
            placeholder={'Порт'}
            isRequired={isRequiredConnectionPort}
          />
        </div>
      )}
      <div onClick={onClickConnectionUser}>
        <RightSideBarTextField
          inputRef={inputRefConnectionUser}
          required={true}
          label={'Пользователь'}
          value={valueConnectionUser}
          onChange={onChangeConnectionUser}
          placeholder={'Пользователь'}
          isRequired={isRequiredConnectionUser}
        />
      </div>
      <div onClick={onClickConnectionPassword}>
        <RightSideBarTextField
          inputRef={inputRefConnectionPassword}
          required={true}
          label={'Пароль'}
          value={valueConnectionPassword}
          onChange={onChangeConnectionPassword}
          placeholder={'Укажите пароль'}
          isRequired={isRequiredConnectionPassword}
          isPassword={true}
        />
      </div>
      {isOracle && (
        <div onClick={onClickConnectionInstance}>
          <RightSideBarTextField
            inputRef={inputRefConnectionInstance}
            required={true}
            label={'Экземпляр'}
            value={valueConnectionInstance}
            onChange={onChangeConnectionInstance}
            placeholder={'Ввод'}
            isRequired={isRequiredConnectionInstance}
          />
        </div>
      )}
      <CPRightSideBarModalCancel
        isModalOpen={!!modalMessage}
        setIsModalOpen={setModalMessageHandler}
        modalTitle={'Редактирование приведет к сбросу параметров'}
        modalMessage={
          isDb
            ? 'Редактирование данного поля приведет к сбросу\nвыбранных баз данных, а так же потребуется повторная проверка подключения'
            : 'После редактирования данного поля потребуется повторная проверка подключения'
        }
        onConfirm={onConfirmModalHandler}
      />
    </div>
  );
};

export default ConnectionSettingsForm;
