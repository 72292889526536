import React from 'react';

import classes from './StringWithSelectedTags.module.css';
import { Text } from '@consta/uikit/Text';
import { removeHTMLTags } from '../../utils/Formatters/removeHTMLTags';
import CustomHoverToolTip from '../CustomHoverToolTip/CustomHoverToolTip';
import { setTableSearchStyle } from '../../utils/Formatters/setTableSearchStyle';

interface IStringWithSelectedTagsProps {
  name: string | null | undefined;
  minLengthForTooltip?: number;
}

const StringWithSelectedTags: React.FC<IStringWithSelectedTagsProps> = ({ name, minLengthForTooltip }) => {
  const isLongString = !!(minLengthForTooltip && name?.length! > minLengthForTooltip);

  return (
    <div className={classes.container}>
      {isLongString ? (
        <CustomHoverToolTip title={removeHTMLTags(name!)}>
          <Text className={classes.titleText}>{setTableSearchStyle(name)}</Text>
        </CustomHoverToolTip>
      ) : (
        <Text className={classes.titleText}>{setTableSearchStyle(name)}</Text>
      )}
    </div>
  );
};

export default StringWithSelectedTags;
