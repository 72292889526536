import { useAppDispatch, useAppSelector } from './hooks';
import { getQuerySearchValue } from '../utils/getQuerySearchValue';
import { getQueryPaginationFromFirstPage } from '../utils/getQueryPaginationFromFirstPage';
import { combineQuery } from '../utils/combineQuery';

import { getConnectionsThunk, selectorConnections, selectorSearchValueConnections } from '../store/connectionsSlice';
import { abortControllerConnections } from '../api/connectionsApi';
import { useCallback, useRef } from 'react';

export const useGetConnections = () => {
  const dispatch = useAppDispatch();
  const metaStore = useAppSelector(selectorConnections)?.meta;
  const searchValueStore = useAppSelector(selectorSearchValueConnections);
  const requestRef = useRef(false);

  return useCallback(
    ({ searchValue, queryPagination }: { searchValue?: string; queryPagination?: string }) => {
      const querySearchValue = getQuerySearchValue(
        searchValue ? searchValue : searchValue === '' ? '' : searchValueStore
      );

      const requestQuery = combineQuery([
        querySearchValue,
        queryPagination ? queryPagination : getQueryPaginationFromFirstPage(metaStore ? metaStore : undefined),
      ]);
      if (requestRef.current) {
        abortControllerConnections.abort();
        setTimeout(() => {
          requestRef.current = true;
          dispatch(getConnectionsThunk(requestQuery)).then(() => {
            requestRef.current = false;
          });
        });
      } else {
        requestRef.current = true;
        dispatch(getConnectionsThunk(requestQuery)).then(() => {
          requestRef.current = false;
        });
      }
    },
    [dispatch, metaStore, searchValueStore]
  );
};
