import React, { useEffect, useState } from 'react';
import classes from './BookmarkSelector.module.css';
import { BookmarkTabs } from '@consta/uikit/BookmarkTabsCanary';

import './BookmarksTab.fix.css';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { deleteBookmark, selectorCatalogBookmarks, setActiveBookmarkKey } from '../../../store/catalogSlice';
import { BookmarkTabsItemDefault } from '@consta/uikit/__internal__/src/components/BookmarkTabsCanary/types';
import CatalogIco from './CatalogIco/CatalogIco';
import { useActiveBookmark } from '../../../hooks/useActiveBookmark';
import { createSearchParams, useSearchParams } from 'react-router-dom';

const BookmarkSelector: React.FC = () => {
  const dispatch = useAppDispatch();
  const activeBookmark = useActiveBookmark();
  const bookmarks = useAppSelector(selectorCatalogBookmarks);
  const [selectorBookmarks, setSelectorBookmarks] = useState<BookmarkTabsItemDefault[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    setSelectorBookmarks(
      bookmarks.map((bookmark) => ({
        key: bookmark.key,
        label: bookmark.label,
        fixed: bookmark.type === 'catalog',
        leftIcon: bookmark.type === 'catalog' ? CatalogIco : undefined,
      }))
    );
  }, [bookmarks]);

  const handleChange = (bookmark: BookmarkTabsItemDefault) => {
    if (bookmark.label === 'Каталог') {
      if (searchParams?.size > 0) {
        const emptySearchParams = createSearchParams({});
        setSearchParams(emptySearchParams);
      }
      dispatch(setActiveBookmarkKey(bookmark.key));
    } else {
      const goToBookmark = bookmarks.find((bm) => bm.key === bookmark.key);
      if (goToBookmark) {
        const searchParams = createSearchParams({
          type: `${goToBookmark.type}`,
          hash: `${goToBookmark.hash}`,
          slug: `${goToBookmark.slug}`,
        });
        setSearchParams(searchParams);
        dispatch(setActiveBookmarkKey(bookmark.key));
      }
    }
  };

  const handleRemove = (bookmark: BookmarkTabsItemDefault, { e }: { e: React.MouseEvent }) => {
    e.stopPropagation();
    const searchParams = createSearchParams({});
    setSearchParams(searchParams);

    if (bookmark.key === activeBookmark?.key) {
      dispatch(setActiveBookmarkKey(0));
    }
    if (bookmark.key !== 0) {
      dispatch(deleteBookmark(bookmark.key));
    }
  };

  return (
    <div className={classes.container}>
      {!!selectorBookmarks.length && (
        <BookmarkTabs
          form="round"
          size={'s'}
          items={selectorBookmarks}
          value={activeBookmark}
          onChange={handleChange}
          onRemove={handleRemove}
        />
      )}
    </div>
  );
};

export default BookmarkSelector;
