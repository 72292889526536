import React from 'react';

import classes from './ISPRightSideBarViewForm.module.css';
import ISPRightSideBarMainInfo from '../ISPRightSideBarMainInfo/ISPRightSideBarMainInfo';
import ISPRightSideBarSystemInfo from '../ISPRightSideBarSystemInfo/ISPRightSideBarSystemInfo';
import { useAppSelector } from '../../../../hooks/hooks';
import { selectorInfoSystems } from '../../../../store/infoSystemsSlice';

interface IISPRightSideBarViewFormProps {
  activeInfoSystemUuid: string | undefined;
}

const ISPRightSideBarViewForm: React.FC<IISPRightSideBarViewFormProps> = ({ activeInfoSystemUuid }) => {
  const infoSystems = useAppSelector(selectorInfoSystems);

  const infoSystem = infoSystems?.data.find((infoSystem) => infoSystem._uuid === activeInfoSystemUuid);

  if (!infoSystem) {
    return null;
  }

  return (
    <div className={classes.container}>
      <ISPRightSideBarMainInfo infoSystem={infoSystem} />
      <ISPRightSideBarSystemInfo infoSystem={infoSystem} />
    </div>
  );
};

export default ISPRightSideBarViewForm;
