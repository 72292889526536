import { GetCatalogItemsServerResponseType, MetaResponseType } from '../api/serverResponse';

export const getQueryPaginationFromFirstPage = (
  catalogItems: GetCatalogItemsServerResponseType | MetaResponseType | undefined
) => {
  if (catalogItems?.per_page) {
    return `page[size]=${catalogItems.per_page}&page[number]=1`;
  }
  return `page[size]=25&page[number]=1`;
};
