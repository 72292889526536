export const getUniqTypesFromQueriesArray = (queries: string[]) => {
  const uniqTypes: string[] = [];
  if (queries.length && queries.length > 0) {
    queries.forEach((query) => {
      const curType = query.split('[').pop()?.split(']')[0];
      if (curType && !uniqTypes.includes(curType)) {
        uniqTypes.push(curType);
      }
    });
  }
  return uniqTypes;
};
