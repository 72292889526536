import React, { useState } from 'react';

import LeftSideBar, { LeftSideBarHandleType } from '../../../shared-front/LeftSideBar/LeftSideBar';
import TableSettings from '../../../shared-front/TableSettings/TableSettings';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import {
  selectorConnectionsTableActiveRowUuid,
  selectorTableColumnsConnections,
  setTableColumnsConnections,
  setTableConnectionsActiveRowUuid,
} from '../../../store/connectionsSlice';
import { CONNECTIONS_TABLE_COLUMNS } from '../../../Templates/connectionsTableColumns';
import { getShortColumns } from '../../../shared-front/Table/utils/getShortColumns';
import { TableColumnType } from '../../../shared-front/Table/Table';
import { CPRightSideBarEnum } from '../ConnectionsPage';
import CPRightSideBarInfoRow from './CPRightSideBarInfoRow/CPRightSideBarInfoRow';
import Delimiter from '../../../shared-front/Delimiter/Delimiter';
import CPRightSideBarDisplayConnection from './CPRightSideBarDisplayConnection/CPRightSideBarDisplayConnection';
import CPRightSideBarCreateConnection from './CPRightSideBarCreateConnection/CPRightSideBarCreateConnection';
import CPRightSideBarEditConnection from './CPRightSideBarEditConnection/CPRightSideBarEditConnection';
import { stepSelectorItems, StepSelectorItemType } from './ConnectionStepSelector/ConnectionStepSelector';

const columnsTemplate = CONNECTIONS_TABLE_COLUMNS;

interface ICPRightSideBarProps {
  refSidebar: React.RefObject<LeftSideBarHandleType>;
  rightSideBarMode: CPRightSideBarEnum | undefined;
  setRightSideBarMode: (mode: CPRightSideBarEnum | undefined) => void;
}

const CPRightSideBar: React.FC<ICPRightSideBarProps> = ({ refSidebar, rightSideBarMode, setRightSideBarMode }) => {
  const dispatch = useAppDispatch();
  const columnsStore = useAppSelector(selectorTableColumnsConnections);
  const connectionsTableActiveRowUuid = useAppSelector(selectorConnectionsTableActiveRowUuid);

  const [displayStepSelectorValue, setDisplayStepSelectorValue] = useState<StepSelectorItemType | null>(
    stepSelectorItems[0]
  );

  const closeSidebarClick = () => {
    setRightSideBarMode(undefined);
    setDisplayStepSelectorValue(stepSelectorItems[0]);
    if (connectionsTableActiveRowUuid) {
      dispatch(setTableConnectionsActiveRowUuid(undefined));
    }
  };

  const closeSideBar = () => {
    closeSidebarClick();
    refSidebar.current?.closeSidebar();
  };

  const setColumns = (columns: (object & TableColumnType)[]) => {
    dispatch(setTableColumnsConnections(getShortColumns(columns)));
  };

  return (
    <LeftSideBar ref={refSidebar} closeSidebar={closeSidebarClick} isSecondaryBackground={true}>
      {rightSideBarMode === CPRightSideBarEnum.TuneTable ? (
        <TableSettings
          columns={columnsStore}
          setColumns={setColumns}
          hideFooter={true}
          defaultColumns={columnsTemplate}
        />
      ) : rightSideBarMode === CPRightSideBarEnum.CreateConnection ? (
        <CPRightSideBarCreateConnection
          rightSideBarMode={rightSideBarMode}
          setRightSideBarMode={setRightSideBarMode}
          closeSideBar={closeSideBar}
        />
      ) : rightSideBarMode === CPRightSideBarEnum.DisplayConnection ? (
        <>
          <CPRightSideBarInfoRow
            rightSideBarMode={rightSideBarMode}
            setRightSideBarMode={setRightSideBarMode}
            closeSideBar={closeSideBar}
          />
          <Delimiter />
          <CPRightSideBarDisplayConnection
            displayStepSelectorValue={displayStepSelectorValue}
            setDisplayStepSelectorValue={setDisplayStepSelectorValue}
            closeSideBar={closeSideBar}
          />
        </>
      ) : rightSideBarMode === CPRightSideBarEnum.EditConnection ? (
        <>
          <CPRightSideBarInfoRow
            rightSideBarMode={rightSideBarMode}
            setRightSideBarMode={setRightSideBarMode}
            closeSideBar={closeSideBar}
          />
          <Delimiter />
          <CPRightSideBarEditConnection
            stepSelectorValue={displayStepSelectorValue}
            setStepSelectorValue={setDisplayStepSelectorValue}
            setRightSideBarMode={setRightSideBarMode}
            closeSideBar={closeSideBar}
          />
        </>
      ) : (
        <div style={{ width: 640 }} />
      )}
    </LeftSideBar>
  );
};

export default CPRightSideBar;
