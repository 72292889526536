import React from 'react';
import classes from './CatalogIco.module.css';
import { Text } from '@consta/uikit/Text';

const CatalogIco: React.FC = () => {
  return (
    <div className={classes.container}>
      <Text className={classes.title}>{'Каталог'}</Text>
    </div>
  );
};

export default CatalogIco;
