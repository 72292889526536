export const getPaginationQuery = ({
  pageSize,
  pageNumber,
}: {
  pageSize: string | number | null | undefined;
  pageNumber: string | number | null | undefined;
}) => {
  if (pageSize && pageNumber) {
    return `page[size]=${pageSize}&page[number]=${pageNumber}`;
  }
  return `page[size]=25&page[number]=1`;
};
