import React from 'react';

import classes from './InfoSystemSettingsSelector.module.css';
import RightSideBarFormSelector from '../RightSideBarFormSelector/RightSideBarFormSelector';
import {
  ConnectionSettingsItemType,
  InfoSystemConnectionSettingsType,
  InfoSystemType,
  ItemComboboxType,
} from '../../types/types';

interface IInfoSystemSelectorProps {
  infoSystems: InfoSystemType[] | undefined;
  connectionSettings: ConnectionSettingsItemType[] | undefined;
  setConnectionSettings?: (connectionSettings: ConnectionSettingsItemType[] | undefined) => void;
}

const InfoSystemSettingsSelector: React.FC<IInfoSystemSelectorProps> = ({
  connectionSettings,
  setConnectionSettings,
  infoSystems,
}) => {
  const infoSystemParam = connectionSettings?.find((setting) => setting.key === 'info_system');

  const infoSystemsItems: ItemComboboxType[] | undefined = infoSystems?.map((system) => ({
    label: system.name ? system.name : '',
    id: system._uuid,
    uuid: system._uuid,
  }));

  const activeInfoSystemItemIndex = infoSystemsItems?.findIndex(
    (item) => item.uuid === (infoSystemParam?.value as InfoSystemConnectionSettingsType)?._uuid
  );

  const activeInfoSystemValue =
    activeInfoSystemItemIndex !== undefined && infoSystemsItems
      ? infoSystemsItems[activeInfoSystemItemIndex]
      : undefined;

  const onChangeInfoSystems = (value: ItemComboboxType | null) => {
    if (setConnectionSettings && connectionSettings) {
      setConnectionSettings(
        connectionSettings.map((setting) =>
          setting.key === 'info_system'
            ? { ...setting, value: value?.uuid ? { _uuid: value.uuid, name: value.label } : null }
            : setting
        )
      );
    }
  };

  if (!infoSystemParam) {
    return null;
  }

  return (
    <div className={classes.container}>
      <RightSideBarFormSelector
        value={activeInfoSystemValue}
        items={infoSystemsItems}
        onChange={onChangeInfoSystems}
        placeholder={'Выбрать'}
        label={'Интегрируемая система'}
        className={classes.selector}
      />
    </div>
  );
};

export default InfoSystemSettingsSelector;
