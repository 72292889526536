import React, { ReactNode, useEffect, useRef, useState } from 'react';

import classes from './CustomHoverToolTip.module.css';
import { Tooltip } from '@consta/uikit/TooltipCanary';
import { Direction } from '@consta/uikit/__internal__/src/components/Popover/Popover';

interface ICustomHoverToolTipProps {
  title: string;
  children: ReactNode;
  direction?: Direction;
}

const CustomHoverToolTip: React.FC<ICustomHoverToolTipProps> = ({ title, direction, children }) => {
  const anchorRef = useRef<HTMLDivElement>(null);
  const timer = useRef<NodeJS.Timeout | null>(null);
  const [isToolTipVisible, setIsToolTipVisible] = useState(false);

  const onMouseEnter = () => {
    if (!isToolTipVisible) {
      timer.current = setTimeout(() => {
        setIsToolTipVisible(true);
      }, 500);
    }
  };

  const onMouseLeave = () => {
    if (isToolTipVisible) {
      setIsToolTipVisible(false);
    }
    clearTimeout(timer.current!);
  };

  useEffect(() => {
    return () => {
      clearTimeout(timer.current!);
    };
  }, []);

  return (
    <div className={classes.container} ref={anchorRef} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {children}
      {isToolTipVisible && (
        <Tooltip
          isOpen={true}
          className={classes.toolTip}
          anchorRef={anchorRef}
          direction={direction ? direction : 'downLeft'}
          placeholder={''}
          size="l"
        >
          {title}
        </Tooltip>
      )}
    </div>
  );
};

export default CustomHoverToolTip;
