import { CPViewSelectorItemType } from '../Pages/ConnectionsPage/CPViewSelectorRow/CPViewSelector/CPViewSelector';

export const PERSIST_STORAGE_KEY = 'session';

export const START_PAGINATION_QUERY = 'page[size]=25&page[number]=1';

export const CP_VIEW_SELECTOR_ITEMS: CPViewSelectorItemType[] = [
  {
    name: 'Подключения',
  },
  {
    name: 'История запусков',
  },
];

export const CYRILLIC_PATTERN = /[А-яЁё]/;

export const minPreviewValue = 1;
export const maxPreviewValue = 10000;

export const defaultCronValue = '';
