import { HierarchyItemType } from '../../types/types';

const noSelectAllChildren = ({ hierarchyItem }: { hierarchyItem: HierarchyItemType }): HierarchyItemType => {
  return {
    ...hierarchyItem,
    isChecked: false,
    children: hierarchyItem.children
      ? hierarchyItem.children.map((child) => noSelectAllChildren({ hierarchyItem: child }))
      : undefined,
  };
};

export const setNoSelectForAllHierarchyItems = ({
  hierarchyItems,
}: {
  hierarchyItems: HierarchyItemType[];
}): HierarchyItemType[] => {
  return hierarchyItems.map((hierarchyItem) => ({
    ...hierarchyItem,
    isChecked: false,
    children: hierarchyItem.children
      ? hierarchyItem.children.map((child) => noSelectAllChildren({ hierarchyItem: child }))
      : undefined,
  }));
};
