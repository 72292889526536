import React from 'react';

import classes from './ISPRightSideBarMainInfo.module.css';
import { Text } from '@consta/uikit/Text';
import { InfoSystemType } from '../../../../types/types';
import BlueLinkOpenInNewTab from '../../../../Components/BlueLinkOpenInNewTab/BlueLinkOpenInNewTab';
import TextWithMark from '../../../../Components/TextWithMark/TextWithMark';
import iconInfoCircle from '../../../../assets/CatalogPage/iconInfoCircle.svg';
import CustomHoverToolTip from '../../../../Components/CustomHoverToolTip/CustomHoverToolTip';

interface IISPRightSideBarMainInfoProps {
  infoSystem: InfoSystemType;
}

const ISPRightSideBarMainInfo: React.FC<IISPRightSideBarMainInfoProps> = ({ infoSystem }) => {
  return (
    <div className={classes.container}>
      <div className={classes.section}>
        <Text className={classes.sectionTitle}>{'Код'}</Text>
        <Text className={classes.sectionValue}>{infoSystem.code ? infoSystem.code : '-'}</Text>
      </div>
      <div className={classes.section}>
        <Text className={classes.sectionTitle}>{'Наименование'}</Text>
        <Text className={classes.sectionValue}>{infoSystem.name ? infoSystem.name : '-'}</Text>
      </div>
      <div className={classes.section}>
        <Text className={classes.sectionTitle}>{'Полное наименование'}</Text>
        <Text className={classes.sectionValue}>{infoSystem.full_name ? infoSystem.full_name : '-'}</Text>
      </div>
      <div className={classes.section}>
        <div className={classes.row}>
          <Text className={classes.sectionTitle}>{'Техническое наименование'}</Text>
          <CustomHoverToolTip
            title={'Наименование интегрируемой системы в EDM'}
            zIndex={999}
            direction={'downRight'}
            className={classes.informer}
            classNameTooltip={classes.tooltip}
          >
            <img src={iconInfoCircle} className={classes.ico} alt={'infoBtn'} />
          </CustomHoverToolTip>
        </div>
        <Text className={classes.sectionValue}>{infoSystem.tech_name_edm ? infoSystem.tech_name_edm : '-'}</Text>
      </div>
      <div className={classes.section}>
        <div className={classes.row}>
          <Text className={classes.sectionTitle}>{'Краткое техническое наименование'}</Text>
          <CustomHoverToolTip
            title={'Префикс системы используемый для разработки'}
            zIndex={999}
            direction={'downRight'}
            className={classes.informer}
            classNameTooltip={classes.tooltip}
          >
            <img src={iconInfoCircle} className={classes.ico} alt={'infoBtn'} />
          </CustomHoverToolTip>
        </div>
        <Text className={classes.sectionValue}>{infoSystem.slug ? infoSystem.slug : '-'}</Text>
      </div>
      <div className={classes.section}>
        <Text className={classes.sectionTitle}>{'Описание'}</Text>
        <Text className={classes.sectionValue}>{infoSystem.description ? infoSystem.description : '-'}</Text>
      </div>
      <div className={classes.section}>
        <Text className={classes.sectionTitle}>{'Бизнес-система'}</Text>
        <Text className={classes.sectionValue}>
          {infoSystem.business_system?.name ? infoSystem.business_system?.name : '-'}
        </Text>
      </div>
      <div className={classes.section}>
        <Text className={classes.sectionTitle}>{'Бизнес-решение'}</Text>
        <Text className={classes.sectionValue}>
          {infoSystem.business_solution?.name ? infoSystem.business_solution?.name : '-'}
        </Text>
      </div>
      <div className={classes.section}>
        <Text className={classes.sectionTitle}>{'Техническая система'}</Text>
        <Text className={classes.sectionValue}>
          {infoSystem.technical_system?.name ? infoSystem.technical_system?.name : '-'}
        </Text>
      </div>
      <div className={classes.section}>
        <Text className={classes.sectionTitle}>{'Организация'}</Text>
        <Text className={classes.sectionValue}>
          {infoSystem.organization?.name ? infoSystem.organization?.name : '-'}
        </Text>
      </div>
      <div className={classes.section}>
        <Text className={classes.sectionTitle}>{'Блок'}</Text>
        <Text className={classes.sectionValue}>{infoSystem.block?.name ? infoSystem.block?.name : '-'}</Text>
      </div>
      <div className={classes.section}>
        <Text className={classes.sectionTitle}>{'EDM'}</Text>
        <Text className={classes.sectionValue}>
          {infoSystem.link_edm ? (
            <BlueLinkOpenInNewTab linkUrl={infoSystem.link_edm} linkTitle={'Смотреть в EDM'} />
          ) : (
            '-'
          )}
        </Text>
      </div>
      <div className={classes.section}>
        <Text className={classes.sectionTitle}>{'Интегрирация с хранилищем Дата-платформы'}</Text>
        <Text className={classes.sectionValue}>
          <TextWithMark
            isComplete={infoSystem.integration_dwh}
            textDone={'Интегрировано с хранилищем'}
            textNotDone={'Не интегрировано с хранилищем'}
          />
        </Text>
      </div>
      <div className={classes.section}>
        <Text className={classes.sectionTitle}>{'Сканирование метаданных'}</Text>
        <Text className={classes.sectionValue}>
          <TextWithMark isComplete={infoSystem.scan_metadata} textDone={'Сканировано'} textNotDone={'Не сканировано'} />
        </Text>
      </div>
    </div>
  );
};

export default ISPRightSideBarMainInfo;
