import React, { useEffect } from 'react';

import classes from './ModalError.module.css';
import { Button } from '@consta/uikit/Button';
import { Modal } from '@consta/uikit/Modal';
import { Text } from '@consta/uikit/Text';
import { PERSIST_STORAGE_KEY } from '../../utils/constants';
import { useAppDispatch } from '../../hooks/hooks';
import { clearCatalogError } from '../../store/catalogSlice';
import { clearMetaError } from '../../store/metaSlice';
import storage from 'redux-persist/lib/storage';
import { clearAppError } from '../../store/appSlice';
import { clearInfoSystemsError } from '../../store/infoSystemsSlice';
import { clearConnectionsError } from '../../store/connectionsSlice';
import { clearCreateNewConnectionsError } from '../../store/createNewConnectionSlice';

type PropsType = {
  errorMessage?: string | undefined;
};

const ModalError: React.FC<PropsType> = ({ errorMessage }) => {
  const dispatch = useAppDispatch();
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  useEffect(() => {
    if (errorMessage) {
      setIsModalOpen(true);
    }
  }, [errorMessage]);

  const onClick = async () => {
    dispatch(clearAppError());
    dispatch(clearMetaError());
    dispatch(clearCatalogError());
    dispatch(clearInfoSystemsError());
    dispatch(clearConnectionsError());
    dispatch(clearCreateNewConnectionsError());
    await storage.removeItem(`persist:${PERSIST_STORAGE_KEY}`);
    setIsModalOpen(false);
  };

  return (
    <Modal
      isOpen={isModalOpen}
      hasOverlay
      onClickOutside={() => setIsModalOpen(false)}
      onEsc={() => setIsModalOpen(false)}
      className={classes.container}
    >
      <Text size="xl" view="link" className={classes.title}>
        Сообщение
      </Text>
      <Text size="xl" view="alert" className={classes.errorMessage}>
        {errorMessage}
      </Text>
      <div className={classes.btn}>
        <Button size="m" view="primary" label="Закрыть" onClick={onClick} />
      </div>
    </Modal>
  );
};

export default ModalError;
