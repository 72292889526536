import React, { Children } from 'react';
import { useAppSelector } from '../../hooks/hooks';
import { selectorCatalogIsObjectNotFound } from '../../store/catalogSlice';
import ObjectDeleted from '../ObjectDeleted/ObjectDeleted';

interface IWrapperCatalogObjectNotFoundProps {
  children: React.ReactElement | React.ReactElement[];
}

const WrapperCatalogObjectNotFound: React.FC<IWrapperCatalogObjectNotFoundProps> = ({
  children,
}: IWrapperCatalogObjectNotFoundProps) => {
  const isObjectNotFound = useAppSelector(selectorCatalogIsObjectNotFound);

  if (isObjectNotFound) {
    return <ObjectDeleted />;
  }

  return <>{Children.map(children, (child) => child)}</>;
};

export default WrapperCatalogObjectNotFound;
