import React, { useState } from 'react';

import classes from './MainPageHero.module.css';
import { Text } from '@consta/uikit/Text';
import SearchRow from './SearchRow/SearchRow';
import {
  getMetaHintsSearchThunk,
  resetSearchHints,
  selectorMetaAssetsByType,
  selectorMetaSearchValue,
  setSearchValue,
} from '../../../store/metaSlice';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';

import { PageRoutesEnum } from '../../../appMenuAndRouting/appRoutes';
import { useNavigate } from 'react-router-dom';
import {
  selectorCatalogBookmarks,
  selectorCatalogSections,
  setActiveBookmarkKey,
  setActiveSection,
} from '../../../store/catalogSlice';
import useDebouncedFunction from '../../../shared-front/hooks/useDebounceFunction';
import { removeHTMLTags } from '../../../utils/Formatters/removeHTMLTags';

interface IMainPageHeroProps {}

const MainPageHero: React.FC<IMainPageHeroProps> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [showSearchHints, setShowSearchHints] = useState(false);

  const bookmarks = useAppSelector(selectorCatalogBookmarks);
  const searchValue = useAppSelector(selectorMetaSearchValue);
  const assetsByType = useAppSelector(selectorMetaAssetsByType);
  const catalogSections = useAppSelector(selectorCatalogSections);

  const sendRequest = useDebouncedFunction(
    (val) => {
      if (val) {
        setShowSearchHints(true);
        dispatch(getMetaHintsSearchThunk(val));
      }
    },
    500,
    true
  );

  const changeHandler = (value: string) => {
    setShowSearchHints(false);
    dispatch(resetSearchHints());
    dispatch(setSearchValue(value));
    sendRequest(value);
  };

  const selectHandler = (value?: string) => {
    if (value) {
      dispatch(setSearchValue(removeHTMLTags(value)));
    }

    if (assetsByType?.[0]) {
      const existedCatalogSection = bookmarks.find((bookmark) => bookmark.slug === 'catalog');
      if (existedCatalogSection) {
        dispatch(setActiveSection(catalogSections[0]));
        dispatch(setActiveBookmarkKey(0));
        navigate(PageRoutesEnum.CatalogPage);
      } else {
        dispatch(setActiveSection(catalogSections[1]));
        navigate(PageRoutesEnum.CatalogPage);
      }
    }
  };

  return (
    <div className={classes.container}>
      <Text className={classes.title}>{'Технические\nметаданные'}</Text>
      <Text className={classes.subtitle}>
        {'Технические характеристики данных, систем их хранения\nи процессов перемещения данных между системами'}
      </Text>
      <SearchRow
        value={searchValue}
        onChange={changeHandler}
        onSelect={selectHandler}
        showSearchHints={showSearchHints}
        setShowSearchHints={setShowSearchHints}
        placeHolder={'Поиск'}
      />
    </div>
  );
};

export default MainPageHero;
