import React from 'react';

import classes from './EditConnectionContentSelector.module.css';
import { StepSelectorItemType } from '../../ConnectionStepSelector/ConnectionStepSelector';
import EditConnectionGeneral from './EditConnectionGeneral/EditConnectionGeneral';
import EditConnectionSettings from './EditConnectionSettings/EditConnectionSettings';
import EditConnectionAdditional from './EditConnectionAdditional/EditConnectionAdditional';
import EditConnectionSchedule from './EditConnectionSchedule/EditConnectionSchedule';

interface IEditConnectionContentSelectorProps {
  stepSelectorValue: StepSelectorItemType | null;
}

const EditConnectionContentSelector: React.FC<IEditConnectionContentSelectorProps> = ({ stepSelectorValue }) => {
  return (
    <div className={classes.container}>
      {stepSelectorValue ? (
        stepSelectorValue.id === 'general' ? (
          <EditConnectionGeneral />
        ) : stepSelectorValue.id === 'settings' ? (
          <EditConnectionSettings />
        ) : stepSelectorValue.id === 'additional' ? (
          <EditConnectionAdditional />
        ) : stepSelectorValue.id === 'schedule' ? (
          <EditConnectionSchedule />
        ) : null
      ) : null}
    </div>
  );
};

export default EditConnectionContentSelector;
