import React from 'react';

import classes from './TableSettingsFooter.module.css';
import { Button } from '@consta/uikit/Button';

interface ITableSettingsFooterProps {
  hideFooter: boolean | undefined;
  applyHandler: () => void;
  cancelHandler: () => void;
}

const TableSettingsFooter: React.FC<ITableSettingsFooterProps> = ({ hideFooter, applyHandler, cancelHandler }) => {
  if (hideFooter) {
    return null;
  } else {
    return (
      <div className={classes.container}>
        <div className={classes.delimiterButton} />
        <div className={classes.bottomButtons}>
          <Button size={'s'} view={'ghost'} label={'Отменить'} style={{ width: '193px' }} onClick={cancelHandler} />
          <Button size={'s'} label={'Применить'} style={{ width: '193px' }} onClick={applyHandler} />
        </div>
      </div>
    );
  }
};

export default TableSettingsFooter;
