import React, { useEffect } from 'react';

import classes from './EditConnectionSchedule.module.css';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/hooks';
import {
  selectorEditConnection,
  setEditConnectionPreviewSchedule,
  setEditConnectionScanSchedule,
} from '../../../../../../store/editConnectionSlice';
import CollapseBlock from '../../../../../../Components/CollapseBlock/CollapseBlock';
import SetCronJob from '../../../../../../shared-front/SetCronJob/SetCronJob';
import { useCronReducer } from '../../../../../../shared-front/SetCronJob/useCronReducer';
import { defaultCronValue } from '../../../../../../utils/constants';

interface IEditConnectionScheduleProps {}

const EditConnectionSchedule: React.FC<IEditConnectionScheduleProps> = () => {
  const dispatch = useAppDispatch();
  const editConnection = useAppSelector(selectorEditConnection);

  const scanSchedule = editConnection?.scanSchedule ? editConnection.scanSchedule : defaultCronValue;
  const previewLimitsValue =
    (editConnection?.settings?.find((setting) => setting.key === 'limits_for_preview')?.value as
      | string
      | null
      | undefined) ?? null;

  const previewSchedule =
    previewLimitsValue && editConnection?.previewSchedule ? editConnection.previewSchedule : defaultCronValue;

  const [cronValuesForScan, dispatchCronValuesForScan] = useCronReducer(scanSchedule);
  const [cronValuesForPreview, dispatchCronValuesForPreview] = useCronReducer(previewSchedule);

  useEffect(() => {
    dispatch(setEditConnectionScanSchedule(cronValuesForScan.cronValue));
  }, [dispatch, cronValuesForScan.cronValue]);

  useEffect(() => {
    previewLimitsValue && dispatch(setEditConnectionPreviewSchedule(cronValuesForPreview.cronValue));
  }, [dispatch, cronValuesForPreview.cronValue, previewLimitsValue]);

  return (
    <div className={classes.container}>
      {
        <CollapseBlock
          title={'Расписание сканирования'}
          isOpen={true}
          isShortContainer={true}
          isShortChildrenContainer={true}
        >
          <SetCronJob cronValues={cronValuesForScan} dispatchCronValues={dispatchCronValuesForScan} />
        </CollapseBlock>
      }
      {previewLimitsValue && (
        <CollapseBlock
          title={'Расписание сканирования предпросмотра'}
          isOpen={true}
          isShortContainer={true}
          isShortChildrenContainer={true}
        >
          <SetCronJob cronValues={cronValuesForPreview} dispatchCronValues={dispatchCronValuesForPreview} />
        </CollapseBlock>
      )}
    </div>
  );
};

export default EditConnectionSchedule;
