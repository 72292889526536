import React from 'react';

import classes from './ConnectionAssetsItem.module.css';
import CollapseBlock from '../../CollapseBlock/CollapseBlock';

import { ConnectionSettingsItemType, InfoSystemType } from '../../../types/types';
import { CheckConnectionAssetType } from '../../../api/serverResponse';
import ConnectionItemSettings from '../../ConnectionItemSettings/ConnectionItemSettings';

interface ICreateConnectionAssetsItemProps {
  connectionAsset: CheckConnectionAssetType;
  onChangeConnectionAsset?: ({
    connectionAsset,
    assetSettings,
  }: {
    connectionAsset: CheckConnectionAssetType;
    assetSettings: ConnectionSettingsItemType[] | undefined;
  }) => void;
  isModeDisplay?: boolean;
  infoSystems: InfoSystemType[] | undefined;
  isModeEdit?: boolean;
}

const ConnectionAssetsItem: React.FC<ICreateConnectionAssetsItemProps> = ({
  connectionAsset,
  onChangeConnectionAsset,
  isModeDisplay,
  infoSystems,
  isModeEdit,
}) => {
  const setAssetSettingsHandler = (assetSettings: ConnectionSettingsItemType[] | undefined) => {
    onChangeConnectionAsset && onChangeConnectionAsset({ connectionAsset, assetSettings });
  };

  return (
    <div className={classes.assetContainer}>
      <CollapseBlock
        title={connectionAsset.name ?? ''}
        isOpen={false}
        isShortContainer={true}
        isShortChildrenContainer={true}
      >
        <ConnectionItemSettings
          connectionSettings={connectionAsset.settings}
          setConnectionSettings={setAssetSettingsHandler}
          isModeDisplay={isModeDisplay}
          infoSystems={infoSystems}
          isModeEdit={isModeEdit}
        />
      </CollapseBlock>
    </div>
  );
};

export default ConnectionAssetsItem;
