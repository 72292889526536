import React from 'react';

import classes from './SystemObjectsSettings.module.css';
import { Text } from '@consta/uikit/Text';
import { Radio } from '@consta/uikit/Radio';
import { ConnectionSettingsItemType } from '../../../types/types';

interface ISystemObjectsSettingsProps {
  connectionSettings: ConnectionSettingsItemType[] | undefined;
  setConnectionSettings?: (connectionSettings: ConnectionSettingsItemType[] | undefined) => void;
  isDisplayMode?: boolean;
}

const SystemObjectsSettings: React.FC<ISystemObjectsSettingsProps> = ({
  connectionSettings,
  setConnectionSettings,
  isDisplayMode,
}) => {
  const hasSystemObjects = connectionSettings?.find((setting) => setting.key === 'system_object');
  const includeSystemObjects = !!connectionSettings?.find((setting) => setting.key === 'system_object')?.value;
  const onChangeSystemObjects = () => {
    if (connectionSettings && setConnectionSettings) {
      setConnectionSettings(
        connectionSettings.map((setting) =>
          setting.key === 'system_object' ? { ...setting, value: !setting.value } : setting
        )
      );
    }
  };

  return isDisplayMode || hasSystemObjects ? (
    <div className={classes.container}>
      <div className={classes.radioButtonGroup}>
        <Text className={classes.groupTitle}>{'Системные объекты'}</Text>
        <div className={classes.buttonsRow}>
          <Radio
            label={'Включает'}
            checked={includeSystemObjects}
            onChange={onChangeSystemObjects}
            disabled={isDisplayMode && !includeSystemObjects}
          />
          <Radio
            label={'Исключает'}
            checked={!includeSystemObjects}
            onChange={onChangeSystemObjects}
            disabled={isDisplayMode && includeSystemObjects}
          />
        </div>
        {!isDisplayMode && (
          <Text className={classes.groupDescription}>
            {
              'Включение системных объектов приведет к увеличению длительности сканирования,\nувеличению количества результатов поиска, а также дублированию сканирования'
            }
          </Text>
        )}
      </div>
    </div>
  ) : null;
};

export default SystemObjectsSettings;
