import React from 'react';
import FilterCollapseContainer from '../../FilterCollapseContainer/FilterCollapseContainer';
import FilterWithScrollAndSearch, { MultiFilterType } from '../../FilterWithScrollAndSearch/FilterWithScrollAndSearch';

interface IMultiFiltersProps {
  multiFilters: MultiFilterType[];
  setMultiFilters: (multiFilters: MultiFilterType[]) => void;
}

const getIsFilterHasSelectedItem = (filter: MultiFilterType, multiFilters: MultiFilterType[]): boolean => {
  return !!multiFilters.find((multiFilter) => multiFilter.name === filter.name)?.items.find((item) => item.isChecked)
    ?.isChecked;
};

const MultiFiltersList: React.FC<IMultiFiltersProps> = ({ multiFilters, setMultiFilters }) => {
  const onChangeFilter = (filter: MultiFilterType) => {
    setMultiFilters(
      multiFilters.map((multiFilter) =>
        multiFilter.name === filter.name
          ? { ...multiFilter, items: filter.items, searchTitleValue: filter.searchTitleValue }
          : multiFilter
      )
    );
  };

  const onClearFilter = (filter: MultiFilterType) => {
    setMultiFilters(
      multiFilters.map((multiFilter) =>
        multiFilter.name === filter.name
          ? {
              ...multiFilter,
              items: filter.items.map((item) => ({ ...item, isChecked: false })),
              searchTitleValue: filter.searchTitleValue,
            }
          : multiFilter
      )
    );
  };

  return (
    <>
      {multiFilters.map((filter, ind) => (
        <FilterCollapseContainer
          key={ind}
          title={filter.title}
          onClearFilter={() => onClearFilter(filter)}
          isFilterHasSelectedItem={getIsFilterHasSelectedItem(filter, multiFilters)}
        >
          <FilterWithScrollAndSearch
            title={filter.title}
            name={filter.name}
            items={filter.items}
            searchTitleValue={filter.searchTitleValue ? filter.searchTitleValue : null}
            onChangeFilter={onChangeFilter}
          />
        </FilterCollapseContainer>
      ))}
    </>
  );
};

export default MultiFiltersList;
