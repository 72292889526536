import React from 'react';
import { createIcon } from '@consta/uikit/__internal__/src/icons/createIcon/createIcon';

export type CreateIconRenderType = {
  l?: 'use' | 'default';
  m?: 'use' | 'default';
  s?: 'use' | 'default';
  xs?: 'use' | 'default';
};

export type CreateIconArguments = {
  l: React.FC<React.SVGProps<SVGSVGElement>>;
  m: React.FC<React.SVGProps<SVGSVGElement>>;
  s: React.FC<React.SVGProps<SVGSVGElement>>;
  xs: React.FC<React.SVGProps<SVGSVGElement>>;
  name: string;
  renderType?: CreateIconRenderType;
  color?: 'mono' | 'multiple';
};

const IconDebugElement: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.171 10.758C12.6949 9.96738 13 9.01916 13 7.99967C13 5.5801 11.2814 3.56194 8.99828 3.09934V1.07031C12.3914 1.5548 15 4.4726 15 7.99967C15 9.57241 14.4813 11.024 13.6057 12.1927L12.171 10.758Z"
      fill="#002033"
    />
    <path
      d="M6.99828 1.0708C3.60682 1.55678 1 4.47379 1 7.99967C1 9.57123 1.51789 11.0218 2.39232 12.1901L3.82719 10.7552C3.30441 9.96517 3 9.01797 3 7.99967C3 5.58132 4.7169 3.56397 6.99828 3.10004V1.0708Z"
      fill="#002033"
    />
    <path
      d="M8 12.9997C6.98013 12.9997 6.03158 12.6943 5.24078 12.1701L3.80609 13.6048C4.97497 14.4808 6.42689 14.9997 8 14.9997C9.57193 14.9997 11.0228 14.4815 12.1913 13.6067L10.7564 12.1719C9.9662 12.695 9.01868 12.9997 8 12.9997Z"
      fill="#002033"
    />
    <path d="M6.5 10.4995L10.5 7.99951L6.5 5.49951V10.4995Z" fill="#002033" />
  </svg>
);

export const IconDebug = createIcon({
  m: IconDebugElement,
  s: IconDebugElement,
  xs: IconDebugElement,
  name: 'iconDebug',
});
