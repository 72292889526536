import React from 'react';

import classes from './Content1CObjectWithTableByTabs.module.css';
import { useAppSelector } from '../../../../../hooks/hooks';
import { selectorCatalogsIsLoadingBookmarkData } from '../../../../../store/catalogSlice';
import { Loader } from '@consta/uikit/Loader';
import { BookmarkType } from '../../../../../types/types';
import { TABS_1C_OBJECT_WITH_TABLE_PART } from '../Card1CObjectWithTableContent';
import { CardTabType } from '../../../../../Components/CardTabsRow/CardTabsRow';
import SectionInDevelopment from '../../../../../Components/SectionInDevelopment/SectionInDevelopment';
import Odata1CObjectWithTableCommonInfoTab from './Odata1CObjectWithTableCommonInfoTab/Odata1CObjectWithTableCommonInfoTab';
import Unify1CObjectAttributesTab from '../../../../../Components/Unify1CObjectAttributesTab/Unify1CObjectAttributesTab';
import UnifyCardDataPreview from '../../../../../Components/UnifyCardDataPreview/UnifyCardDataPreview';

interface IContentRdbByTabsProps {
  activeTab?: CardTabType;
  bookmark: BookmarkType;
}

const Content1CObjectWithTableByTabs: React.FC<IContentRdbByTabsProps> = ({ activeTab, bookmark }) => {
  const isCommonInfoTab = activeTab?.label === TABS_1C_OBJECT_WITH_TABLE_PART[0].label;
  const isAttributesTab = activeTab?.label === TABS_1C_OBJECT_WITH_TABLE_PART[1].label;
  const isDataPreviewTab = activeTab?.label === TABS_1C_OBJECT_WITH_TABLE_PART[2].label;
  const isLoadingBookmarkData = useAppSelector(selectorCatalogsIsLoadingBookmarkData);
  const isPreviewTab = activeTab?.label === 'Предпросмотр';

  return (
    <div className={classes.container}>
      {isLoadingBookmarkData ? (
        <div style={{ display: 'flex', width: '100%' }}>
          <Loader />
        </div>
      ) : isCommonInfoTab ? (
        <Odata1CObjectWithTableCommonInfoTab bookmark={bookmark} />
      ) : isAttributesTab ? (
        <Unify1CObjectAttributesTab bookmark={bookmark} />
      ) : isDataPreviewTab ? (
        <UnifyCardDataPreview bookmark={bookmark} isPreviewTab={isPreviewTab} />
      ) : (
        <SectionInDevelopment />
      )}
    </div>
  );
};

export default Content1CObjectWithTableByTabs;
