import { SortingItemType } from '../../../../../../types/types';
import { IconSortDown } from '@consta/uikit/IconSortDown';
import { IconSortUp } from '@consta/uikit/IconSortUp';
import React from 'react';

export const SORTING_ITEMS: SortingItemType[] = [
  {
    id: 0,
    label: 'По релевантности',
    ico: <IconSortDown view={'link'} size={'s'} style={{ marginRight: '10px' }} />,
    icoItem: <IconSortDown size={'s'} style={{ marginRight: '10px' }} />,
    sortName: '',
    sortDirection: '',
  },
  {
    id: 1,
    label: 'Сначала новые',
    ico: <IconSortDown view={'link'} size={'s'} style={{ marginRight: '10px' }} />,
    icoItem: <IconSortDown size={'s'} style={{ marginRight: '10px' }} />,
    sortName: '_create_ts',
    sortDirection: 'desc',
  },
  {
    id: 2,
    label: 'Сначала старые',
    ico: <IconSortUp view={'link'} size={'s'} style={{ marginRight: '10px' }} />,
    icoItem: <IconSortUp size={'s'} style={{ marginRight: '10px' }} />,
    sortName: '_create_ts',
    sortDirection: 'asc',
  },
  {
    id: 4,
    label: 'По алфавиту A - Z, А - Я',
    ico: <IconSortDown view={'link'} size={'s'} style={{ marginRight: '10px' }} />,
    icoItem: <IconSortDown size={'s'} style={{ marginRight: '10px' }} />,
    sortName: 'name',
    sortDirection: 'asc',
  },
  {
    id: 5,
    label: 'По алфавиту Я - А, Z - A',
    ico: <IconSortUp view={'link'} size={'s'} style={{ marginRight: '10px' }} />,
    icoItem: <IconSortUp size={'s'} style={{ marginRight: '10px' }} />,
    sortName: 'name',
    sortDirection: 'desc',
  },
];
