import React, { ReactNode } from 'react';

import classes from './CardsContentBlock.module.css';
import { Text } from '@consta/uikit/Text';
import CustomHoverToolTip from '../CustomHoverToolTip/CustomHoverToolTip';
import iconInfoCircle from '../../assets/CatalogPage/iconInfoCircle.svg';

interface ICardsContentBlockProps {
  title: string;
  description?: string;
  children?: ReactNode;
  className?: string;
  informer?: string;
}

const CardsContentBlock: React.FC<ICardsContentBlockProps> = ({
  title,
  className,
  description,
  children,
  informer,
}) => {
  return (
    <div className={`${classes.container} ${className ? className : ''}`}>
      <div className={classes.row}>
        <Text className={classes.title}>{title}</Text>
        {informer && (
          <CustomHoverToolTip
            title={`${informer}`}
            zIndex={999}
            direction={'downRight'}
            className={classes.informer}
            classNameTooltip={classes.tooltip}
          >
            <img src={iconInfoCircle} className={classes.ico} alt={'infoBtn'} />
          </CustomHoverToolTip>
        )}
      </div>

      {description && <Text className={classes.description}>{description}</Text>}
      {children && children}
    </div>
  );
};

export default CardsContentBlock;
