import React from 'react';
import classes from './FiltersGroup.module.css';
import { Checkbox } from '@consta/uikit/Checkbox';

export type FilterForGroup = {
  name: string;
  title: string;
  isChecked: boolean;
};

export type FiltersGroupType = {
  groupName: string;
  groupTitle: string;
  groupFilters: FilterForGroup[];
};

interface IFiltersGroup {
  filtersGroup: FiltersGroupType;
  onChangeFilterForGroup: (filter: FilterForGroup) => void;
}

const FiltersGroup: React.FC<IFiltersGroup> = ({ filtersGroup, onChangeFilterForGroup }) => {
  return (
    <div className={classes.container}>
      {filtersGroup.groupFilters.map((filter, ind) => (
        <div key={ind} className={classes.filterRow}>
          <Checkbox
            checked={filter.isChecked}
            label={filter.title}
            onChange={({ checked }) => onChangeFilterForGroup({ ...filter, isChecked: checked })}
          />
        </div>
      ))}
    </div>
  );
};

export default FiltersGroup;
