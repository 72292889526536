import { BookmarkType } from '../types/types';
import { useAppDispatch } from './hooks';
import { useEffect, useRef } from 'react';
import { getBookmarkDataThunk } from '../store/catalogSlice';

export const useLoadBookmarkData = () => {
  const dispatch = useAppDispatch();

  const loadDataRef = useRef(false);

  useEffect(() => {
    loadDataRef.current = false;
  }, []);

  return (bookmark: BookmarkType) => {
    if (!loadDataRef.current) {
      loadDataRef.current = true;
      dispatch(getBookmarkDataThunk(bookmark));
    }
  };
};
