import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';
import { ObjectsViewEnum } from '../Components/ConnectionsObjectsView/ConnectionsObjectsView';
import { CP_VIEW_SELECTOR_ITEMS } from '../utils/constants';
import { ColumnGenericType, StoreSnackBarItemType } from '../types/types';
import {
  ConnectionRunPreviewResponseType,
  ConnectionRunScannerResponseType,
  ConnectionsServerResponseType,
  DeleteConnectionServerResponseType,
} from '../api/serverResponse';
import { serverApi } from '../api/serverApi';
import { getRejectedValue } from './rejectedValueHelper';

type ConnectionsSliceType = {
  activeViewSelector: string;
  searchValueConnections: string;
  connections: ConnectionsServerResponseType | undefined;
  tableColumns: ColumnGenericType[];
  tableActiveRowUuid: string | undefined;
  tableHoveredRowUuid: string | undefined;
  snackBarItems: StoreSnackBarItemType[];
  connectionsUuidWithOpenedAssets: string[];
  runningScannersUuids: string[];
  runningPreviewsUuids: string[];
  isLoadingConnections: boolean;
  isErrorConnections: string | undefined;
};

const initialState: ConnectionsSliceType = {
  activeViewSelector: CP_VIEW_SELECTOR_ITEMS[0].name,
  searchValueConnections: '',
  connections: undefined,
  tableColumns: [],
  tableActiveRowUuid: undefined,
  tableHoveredRowUuid: undefined,
  snackBarItems: [],
  connectionsUuidWithOpenedAssets: [],
  runningScannersUuids: [],
  runningPreviewsUuids: [],
  isLoadingConnections: false,
  isErrorConnections: undefined,
};

export const getConnectionsThunk = createAsyncThunk<ConnectionsServerResponseType, string, { rejectValue: string }>(
  'app/getConnectionsThunk',
  async (requestQuery, { rejectWithValue }) => {
    try {
      return await serverApi.getConnections(requestQuery);
    } catch (e) {
      return rejectWithValue(getRejectedValue('Ошибка получения данных о Подключениях', e));
    }
  }
);

export const postConnectionRunScannerThunk = createAsyncThunk<
  { connectionsRunScannerResponse: ConnectionRunScannerResponseType; connectionUuid: string },
  string,
  { rejectValue: string }
>('app/postConnectionsRunScannerThunk', async (connectionUuid, { rejectWithValue }) => {
  try {
    const connectionsRunScannerResponse = await serverApi.postConnectionRunScanner(connectionUuid);
    return { connectionsRunScannerResponse, connectionUuid };
  } catch (e) {
    return rejectWithValue(getRejectedValue('Ошибка запуска сканера', e));
  }
});

export const postConnectionAssetRunScannerThunk = createAsyncThunk<
  { connectionsRunScannerResponse: ConnectionRunScannerResponseType; assetUuid: string },
  { connectionUuid: string; assetUuid: string },
  { rejectValue: string }
>('app/postConnectionAssetRunScannerThunk', async (params, { rejectWithValue }) => {
  try {
    const connectionsRunScannerResponse = await serverApi.postConnectionAssetRunScanner(params);
    return { connectionsRunScannerResponse, assetUuid: params.assetUuid };
  } catch (e) {
    return rejectWithValue(getRejectedValue('Ошибка запуска сканера', e));
  }
});

export const postConnectionRunPreviewThunk = createAsyncThunk<
  { connectionsRunPreviewResponse: ConnectionRunPreviewResponseType; connectionUuid: string },
  string,
  { rejectValue: string }
>('app/postConnectionRunPreviewThunk', async (connectionUuid, { rejectWithValue }) => {
  try {
    const connectionsRunPreviewResponse = await serverApi.postConnectionRunPreview(connectionUuid);
    return { connectionsRunPreviewResponse, connectionUuid };
  } catch (e) {
    return rejectWithValue(getRejectedValue('Ошибка запуска предпросмотра', e));
  }
});

export const postConnectionAssetRunPreviewThunk = createAsyncThunk<
  { connectionRunAssetPreviewResponse: ConnectionRunPreviewResponseType; assetUuid: string },
  { connectionUuid: string; assetUuid: string },
  { rejectValue: string }
>('app/postConnectionAssetRunPreviewThunk', async (params, { rejectWithValue }) => {
  try {
    const connectionRunAssetPreviewResponse = await serverApi.postConnectionAssetRunPreview(params);
    return { connectionRunAssetPreviewResponse, assetUuid: params.assetUuid };
  } catch (e) {
    return rejectWithValue(getRejectedValue('Ошибка запуска предпросмотра', e));
  }
});

export const deleteConnectionsThunk = createAsyncThunk<
  DeleteConnectionServerResponseType,
  string,
  { rejectValue: string }
>('app/deleteConnectionsThunk', async (connectionUuid, { rejectWithValue }) => {
  try {
    return await serverApi.deleteConnection(connectionUuid);
  } catch (e) {
    return rejectWithValue(getRejectedValue('Ошибка удаления подключения', e));
  }
});

export const connectionsSlice = createSlice({
  name: 'connectionsSlice',
  initialState,
  reducers: {
    clearConnections: (state) => {
      state.activeViewSelector = CP_VIEW_SELECTOR_ITEMS[0].name;
      state.searchValueConnections = '';
      state.connections = undefined;
      // state.tableColumns = [],
      state.tableActiveRowUuid = undefined;
      state.tableHoveredRowUuid = undefined;
      state.snackBarItems = [];
      state.connectionsUuidWithOpenedAssets = [];
      state.runningScannersUuids = [];
      state.runningPreviewsUuids = [];
      state.isLoadingConnections = false;
      state.isErrorConnections = undefined;
    },
    setConnectionsError: (state, action) => {
      state.isErrorConnections = action.payload;
    },
    clearConnectionsError: (state) => {
      state.isErrorConnections = undefined;
    },
    setActiveViewSelector: (state, action) => {
      state.activeViewSelector = action.payload;
    },
    setSearchValueConnections: (state, action) => {
      state.searchValueConnections = action.payload;
    },
    setConnectionsObjectsView: (state, action) => {
      if (!!state?.connections?.data.length) {
        state.connectionsUuidWithOpenedAssets = [];
        if (action.payload === ObjectsViewEnum.allOpen) {
          state.connections.data.forEach((connection) => {
            if (connection.assets?.length && connection.assets?.length > 1) {
              state.connectionsUuidWithOpenedAssets.push(connection._uuid);
            }
          });
        }
      }
    },
    clearSnackBarItemsConnections: (state) => {
      state.snackBarItems = [];
    },
    deleteSnackBarItemConnections: (state, action) => {
      state.snackBarItems = state.snackBarItems.filter((item) => item.key !== action.payload.key);
    },
    pushSnackBarItemConnections: (state, action) => {
      state.snackBarItems.push({
        key: action.payload.key ? action.payload.key : action.payload.message + action.payload.status,
        message: action.payload.message,
        status: action.payload.status,
        autoClose: action.payload.autoClose ? 5 : undefined,
      });
    },
    setTableColumnsConnections: (state, action) => {
      state.tableColumns = action.payload;
    },
    setTableConnectionsActiveRowUuid: (state, action) => {
      state.tableActiveRowUuid = action.payload;
    },
    setTableConnectionsHoveredRowUuid: (state, action) => {
      state.tableHoveredRowUuid = action.payload;
    },
    clearTableConnectionsActiveRowUuid: (state) => {
      state.tableActiveRowUuid = undefined;
    },
    toggleDropDownConnectionsUuidWithOpenedAssets: (state, action) => {
      if (state.connectionsUuidWithOpenedAssets?.includes(action.payload)) {
        state.connectionsUuidWithOpenedAssets = state.connectionsUuidWithOpenedAssets.filter(
          (uuid) => uuid !== action.payload
        );
      } else {
        state.connectionsUuidWithOpenedAssets = state.connectionsUuidWithOpenedAssets
          ? [...state.connectionsUuidWithOpenedAssets, action.payload]
          : [action.payload];
      }
    },
    clearRunningScannersUuids: (state) => {
      state.runningScannersUuids = [];
    },
    updateConnectionsAfterEditConnection: (state, action) => {
      if (state.connections?.data) {
        state.connections.data = state.connections?.data?.map((connection) =>
          connection._uuid === action.payload._uuid ? action.payload : connection
        );
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getConnectionsThunk.fulfilled, (state, action) => {
        state.isLoadingConnections = false;
        state.connectionsUuidWithOpenedAssets = [];
        state.connections = action.payload;
      })
      .addCase(getConnectionsThunk.pending, (state) => {
        state.isLoadingConnections = true;
        state.connections = undefined;
      })
      .addCase(getConnectionsThunk.rejected, (state, action) => {
        state.isLoadingConnections = false;
        state.isErrorConnections = action.payload ? action.payload.toString() : 'Неизвестная ошибка - connectionsSlice';
      })

      .addCase(postConnectionRunScannerThunk.fulfilled, (state, action) => {
        state.runningScannersUuids = state.runningScannersUuids.filter((uuid) => uuid !== action.meta.arg);
        state.snackBarItems.push({
          key: action.meta.requestId,
          message: `Сканирование запущено.\nСмотрите статус в истории запусков`,
          status: 'success',
          autoClose: 5,
        });
      })
      .addCase(postConnectionRunScannerThunk.pending, (state, action) => {
        state.runningScannersUuids.push(action.meta.arg);
      })
      .addCase(postConnectionRunScannerThunk.rejected, (state, action) => {
        state.runningScannersUuids = [];
        state.snackBarItems.push({
          key: action.meta.requestId,
          message: 'Ошибка доступа AirFlow',
          status: 'alert',
        });
      })

      .addCase(postConnectionAssetRunScannerThunk.fulfilled, (state, action) => {
        state.runningScannersUuids = state.runningScannersUuids.filter((uuid) => uuid !== action.meta.arg.assetUuid);
        state.snackBarItems.push({
          key: action.meta.requestId,
          message: `Сканирование запущено.\nСмотрите статус в истории запусков`,
          status: 'success',
          autoClose: 5,
        });
      })
      .addCase(postConnectionAssetRunScannerThunk.pending, (state, action) => {
        state.runningScannersUuids.push(action.meta.arg.assetUuid);
      })
      .addCase(postConnectionAssetRunScannerThunk.rejected, (state, action) => {
        state.runningScannersUuids = [];
        state.snackBarItems.push({
          key: action.meta.requestId,
          message: 'Ошибка доступа AirFlow',
          status: 'alert',
        });
      })

      .addCase(postConnectionRunPreviewThunk.fulfilled, (state, action) => {
        state.runningPreviewsUuids = state.runningPreviewsUuids.filter((uuid) => uuid !== action.meta.arg);
        state.snackBarItems.push({
          key: action.meta.requestId,
          message: `Сканирование запущено.\nСмотрите статус в истории запусков`,
          status: 'success',
          autoClose: 5,
        });
      })
      .addCase(postConnectionRunPreviewThunk.pending, (state, action) => {
        state.runningPreviewsUuids.push(action.meta.arg);
      })
      .addCase(postConnectionRunPreviewThunk.rejected, (state, action) => {
        state.runningPreviewsUuids = [];
        state.snackBarItems.push({
          key: action.meta.requestId,
          message: 'Ошибка доступа AirFlow',
          status: 'alert',
        });
      })

      .addCase(postConnectionAssetRunPreviewThunk.fulfilled, (state, action) => {
        state.runningPreviewsUuids = state.runningPreviewsUuids.filter((uuid) => uuid !== action.meta.arg.assetUuid);
        state.snackBarItems.push({
          key: action.meta.requestId,
          message: `Сканирование запущено.\nСмотрите статус в истории запусков`,
          status: 'success',
          autoClose: 5,
        });
      })
      .addCase(postConnectionAssetRunPreviewThunk.pending, (state, action) => {
        state.runningPreviewsUuids.push(action.meta.arg.assetUuid);
      })
      .addCase(postConnectionAssetRunPreviewThunk.rejected, (state, action) => {
        state.runningPreviewsUuids = [];
        state.snackBarItems.push({
          key: action.meta.requestId,
          message: 'Ошибка доступа AirFlow',
          status: 'alert',
        });
      })

      .addCase(deleteConnectionsThunk.fulfilled, (state, action) => {
        if (!!state.connections?.data.length) {
          state.connections = {
            ...state.connections,
            data: state.connections?.data.filter((connection) => connection._uuid !== action.payload.connectionUuid),
          };
        }

        state.snackBarItems.push({
          key: action.meta.requestId,
          message: `Подключение удалено.`,
          status: 'success',
          autoClose: 5,
        });
      })
      .addCase(deleteConnectionsThunk.rejected, (state, action) => {
        state.runningPreviewsUuids = [];
        state.snackBarItems.push({
          key: action.meta.requestId,
          message: 'Ошибка удаления подключения',
          status: 'alert',
        });
      });
  },
});

export const {
  clearConnections,
  setConnectionsError,
  clearConnectionsError,
  setActiveViewSelector,
  setSearchValueConnections,
  setConnectionsObjectsView,
  clearSnackBarItemsConnections,
  deleteSnackBarItemConnections,
  pushSnackBarItemConnections,
  setTableColumnsConnections,
  setTableConnectionsActiveRowUuid,
  setTableConnectionsHoveredRowUuid,
  clearTableConnectionsActiveRowUuid,
  toggleDropDownConnectionsUuidWithOpenedAssets,
  clearRunningScannersUuids,
  updateConnectionsAfterEditConnection,
} = connectionsSlice.actions;

export const selectorConnectionsActiveViewSelector = (state: RootState) => state.connections.activeViewSelector;
export const selectorSearchValueConnections = (state: RootState) => state.connections.searchValueConnections;
export const selectorSnackBarItemsConnections = (state: RootState) => state.connections.snackBarItems;
export const selectorIsErrorConnections = (state: RootState) => state.connections.isErrorConnections;
export const selectorIsLoadingConnections = (state: RootState) => state.connections.isLoadingConnections;
export const selectorConnections = (state: RootState) => state.connections.connections;
export const selectorTableColumnsConnections = (state: RootState) => state.connections.tableColumns;
export const selectorConnectionsTableActiveRowUuid = (state: RootState) => state.connections.tableActiveRowUuid;
export const selectorConnectionsTableHoveredRowUuid = (state: RootState) => state.connections.tableHoveredRowUuid;
export const selectorConnectionsUuidWithOpenedAssets = (state: RootState) =>
  state.connections.connectionsUuidWithOpenedAssets;
export const selectorRunningScannersUuids = (state: RootState) => state.connections.runningScannersUuids;
export const selectorRunningPreviewsUuids = (state: RootState) => state.connections.runningPreviewsUuids;

export default connectionsSlice.reducer;
