import React from 'react';

import classes from './ISPRightSideBarSystemInfo.module.css';
import { Text } from '@consta/uikit/Text';
import Delimiter from '../../../../shared-front/Delimiter/Delimiter';
import { formatDateDDMMYYYYHHMM } from '../../../../utils/Formatters/formatDateDDMMYYYYHHMM';
import { InfoSystemType } from '../../../../types/types';
import UserInfo from '../../../../Components/UserInfo/UserInfo';

interface IISPRightSideBarSystemInfoProps {
  infoSystem: InfoSystemType;
}

const ISPRightSideBarSystemInfo: React.FC<IISPRightSideBarSystemInfoProps> = ({ infoSystem }) => {
  return (
    <div className={classes.container}>
      <Text className={classes.title}>{'Системное'}</Text>
      <Delimiter />
      <div className={classes.sectionsGroup}>
        <div className={classes.section}>
          <Text className={classes.sectionTitle}>{`Создано \u00A0\u00A0\u00A0 ${
            infoSystem._create_ts ? formatDateDDMMYYYYHHMM(new Date(infoSystem._create_ts)) : ''
          }`}</Text>
          {infoSystem._create_user ? <UserInfo user={infoSystem._create_user} /> : '-'}
        </div>
        <div className={classes.section}>
          <Text className={classes.sectionTitle}>{`Изменено \u00A0\u00A0\u00A0 ${
            infoSystem._alter_ts ? formatDateDDMMYYYYHHMM(new Date(infoSystem._alter_ts)) : ''
          }`}</Text>
          {infoSystem._alter_user ? <UserInfo user={infoSystem._alter_user} /> : '-'}
        </div>
      </div>
    </div>
  );
};

export default ISPRightSideBarSystemInfo;
