import React from 'react';

import classes from './InfoTableViewTableFunctionRow.module.css';
import { Text } from '@consta/uikit/Text';
import { Badge } from '@consta/uikit/Badge';
import { BookmarkTableViewTableFunctionType, BookmarkType } from '../../../../../types/types';
import { declOfNum } from '../../../../../shared-front/utils/declOfNum';
import { getBadgeColor } from '../../../../../utils/Catalog/getBadgeColor';
import { selectorCatalogsIsLoadingBookmarkData } from '../../../../../store/catalogSlice';
import { useAppSelector } from '../../../../../hooks/hooks';
import HierarchyRow from '../../../../../Components/HierarchyRow/HierarchyRow';
import CardInputShortDescription from '../../../../../Components/CardInputShortDescription/CardInputShortDescription';

interface IInfoRowProps {
  bookmark: BookmarkType;
}

const InfoTableViewTableFunctionContentRow: React.FC<IInfoRowProps> = ({ bookmark }) => {
  const isLoadingBookmarkData = useAppSelector(selectorCatalogsIsLoadingBookmarkData);
  const data = bookmark?.data as BookmarkTableViewTableFunctionType | undefined;

  return (
    <div className={classes.container}>
      {!isLoadingBookmarkData && (
        <>
          <div className={classes.titleContainer}>
            <Text className={classes.title}>{data?.name ? data.name : ' '}</Text>
          </div>
          <CardInputShortDescription bookmark={bookmark} />
          {data?.hierarchical_structure && (
            <HierarchyRow
              level_1={data.hierarchical_structure.level_1}
              level_2={data.hierarchical_structure.level_2}
              level_3={data.hierarchical_structure.level_3}
            />
          )}
          <div className={classes.row}>
            {bookmark.data?.unified_object && (
              <Badge
                view="stroked"
                label={data?.unified_object?.unified_name}
                style={{
                  color: getBadgeColor(data?.unified_object?.color_group),
                  boxShadow: `inset 0 0 0 1px ${getBadgeColor(data?.unified_object?.color_group)}`,
                }}
              />
            )}
            {Number(data?.attr_count) >= 0 ? (
              <Text className={classes.description}>
                {`${data?.attr_count} ${declOfNum(Number(data?.attr_count), ['атрибут', 'атрибута', 'атрибутов'])}`}
              </Text>
            ) : null}
          </div>
        </>
      )}
    </div>
  );
};

export default InfoTableViewTableFunctionContentRow;
