import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { UserInfoType } from '../types/types';
import { RootState } from './store';
import { serverApi } from '../api/serverApi';
import { getRejectedValue } from './rejectedValueHelper';
import { UserInfoServerResponseType } from '../api/serverResponse';

type AppSliceType = {
  userInfo: UserInfoType | undefined;
  isLoadingUserInfo: boolean;
  isErrorUserInfo: string | undefined;
};

const initialState: AppSliceType = {
  userInfo: undefined,
  isLoadingUserInfo: false,
  isErrorUserInfo: undefined,
};

export const getUserInfoThunk = createAsyncThunk<UserInfoServerResponseType, undefined, { rejectValue: string }>(
  'app/getUserInfoThunk',
  async (_, { rejectWithValue }) => {
    try {
      return await serverApi.getUserInfo();
    } catch (e) {
      return rejectWithValue(getRejectedValue('Ошибка получения данных пользователя', e));
    }
  }
);

export const appSlice = createSlice({
  name: 'appSlice',
  initialState,
  reducers: {
    clearAppError: (state) => {
      state.isErrorUserInfo = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserInfoThunk.fulfilled, (state, action) => {
        state.isLoadingUserInfo = false;
        state.userInfo = action.payload.data;
      })
      .addCase(getUserInfoThunk.pending, (state) => {
        state.userInfo = undefined;
        state.isErrorUserInfo = undefined;
        state.isLoadingUserInfo = true;
      })
      .addCase(getUserInfoThunk.rejected, (state, action) => {
        state.userInfo = undefined;
        state.isLoadingUserInfo = false;
        state.isErrorUserInfo = action.payload ? action.payload : 'Неизвестная ошибка - appSlice';
      });
  },
});

export const { clearAppError } = appSlice.actions;

export const selectorUserInfo = (state: RootState) => state.app.userInfo;
export const selectorIsLoadingUserInfo = (state: RootState) => state.app.isLoadingUserInfo;
export const selectorIsErrorUserInfo = (state: RootState) => state.app.isErrorUserInfo;

export default appSlice.reducer;
