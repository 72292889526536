import { Dispatch, FC, useState } from 'react';
import Cron, { CronError } from 'react-js-cron';

import 'react-js-cron/dist/styles.css';

import { DEFAULT_LOCALE_RU } from './locale';
import s from './SetCronJob.module.css';
import { TextField } from '@consta/uikit/TextField';

export interface ICronJobProps {
  cronValues: {
    inputValue: string;
    cronValue: string;
  };
  dispatchCronValues: Dispatch<{
    type: 'set_cron_value' | 'set_input_value' | 'set_values';
    value: string;
  }>;
}

const SetCronJob: FC<ICronJobProps> = ({ cronValues, dispatchCronValues }) => {
  const [error, onError] = useState<CronError>();

  return (
    <div>
      <TextField
        label={'CRON'}
        className={s.input}
        value={cronValues.inputValue}
        onChange={(event) => {
          event.e.stopPropagation();
          dispatchCronValues({
            type: 'set_input_value',
            value: event.value ?? '',
          });
        }}
        onBlur={(event) => {
          event.stopPropagation();
          dispatchCronValues({
            type: 'set_cron_value',
            value: cronValues.inputValue,
          });
        }}
        onKeyPress={(event) => {
          event.stopPropagation();
          dispatchCronValues({
            type: 'set_cron_value',
            value: cronValues.inputValue,
          });
        }}
      />

      <div className={s.divider}>или</div>

      <Cron
        humanizeValue
        humanizeLabels
        locale={DEFAULT_LOCALE_RU}
        value={cronValues.cronValue}
        setValue={(newValue: string) => {
          dispatchCronValues &&
            dispatchCronValues({
              type: 'set_values',
              value: newValue,
            });
        }}
        onError={onError}
      />

      <p className={s.error}>{error && error.description && `${error.description}`}</p>
    </div>
  );
};

export default SetCronJob;
