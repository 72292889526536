import { HierarchyItemType } from '../../types/types';

export const selectInHierarchyItem = ({
  hierarchyItem,
  itemUuid,
  isChecked,
  isOpen,
}: {
  hierarchyItem: HierarchyItemType;
  itemUuid: string;
  isChecked: boolean;
  isOpen: boolean;
}): HierarchyItemType => {
  if (hierarchyItem.uuid === itemUuid) {
    return { ...hierarchyItem, isChecked, isOpen };
  } else if (hierarchyItem.children?.length && hierarchyItem.children?.length > 0) {
    return {
      ...hierarchyItem,
      children: hierarchyItem.children.map((child) =>
        selectInHierarchyItem({ hierarchyItem: child, itemUuid, isChecked, isOpen })
      ),
    };
  }
  return hierarchyItem;
};
