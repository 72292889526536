import { useState } from 'react';
import {
  clearConnectionsError,
  pushSnackBarItemConnections,
  updateConnectionsAfterEditConnection,
} from '../store/connectionsSlice';
import { serverApi } from '../api/serverApi';
import { CPRightSideBarEnum } from '../Pages/ConnectionsPage/ConnectionsPage';
import { useAppDispatch, useAppSelector } from './hooks';
import { clearEditConnection, selectorEditConnection } from '../store/editConnectionSlice';

export const useSaveEditConnection = (setRightSideBarMode: (mode: CPRightSideBarEnum | undefined) => void) => {
  const dispatch = useAppDispatch();
  const editConnection = useAppSelector(selectorEditConnection);
  const [isSavingEditedConnection, setIsSavingEditedConnection] = useState(false);

  const saveEditedConnection = () => {
    if (editConnection) {
      setIsSavingEditedConnection(true);
      dispatch(clearConnectionsError());
      serverApi
        .saveEditedConnection(editConnection)
        .then((result) => {
          dispatch(
            pushSnackBarItemConnections({
              key: editConnection?._uuid,
              message: 'Данные подключения сохранены',
              status: 'success',
              autoClose: true,
            })
          );
          dispatch(updateConnectionsAfterEditConnection(result));
          setRightSideBarMode(CPRightSideBarEnum.DisplayConnection);
          dispatch(clearEditConnection());
        })
        .catch((e) => {
          dispatch(
            pushSnackBarItemConnections({
              key: editConnection?._uuid,
              message: 'Ошибка сохранения данных подключения.\n' + e.message,
              status: 'alert',
              autoClose: true,
            })
          );
        })
        .finally(() => {
          setIsSavingEditedConnection(false);
        });
    }
  };

  return { isSavingEditedConnection, saveEditedConnection };
};
