import React from 'react';

import classes from './EditConnectionBottomButtons.module.css';
import Delimiter from '../../../../../shared-front/Delimiter/Delimiter';
import { Button } from '@consta/uikit/Button';

interface IEditConnectionBottomButtonsProps {
  onSave: () => void;
  onCancel: () => void;
}

const EditConnectionBottomButtons: React.FC<IEditConnectionBottomButtonsProps> = ({ onSave, onCancel }) => {
  return (
    <div className={classes.container}>
      <Delimiter />
      <div className={classes.bottomButtons}>
        <Button size={'s'} view={'ghost'} label={'Отменить'} style={{ width: '140px' }} onClick={onCancel} />
        <Button size={'s'} label={'Сохранить'} style={{ width: '140px' }} onClick={onSave} />
      </div>
    </div>
  );
};

export default EditConnectionBottomButtons;
