import { CardTabType } from '../Components/CardTabsRow/CardTabsRow';
import { BookmarkType } from '../types/types';
import { useAppDispatch } from './hooks';
import { setBookmarkActiveTab } from '../store/catalogSlice';
import { useEffect } from 'react';

export const useActiveTab = ({
  bookmark,
  tabs,
}: {
  bookmark: BookmarkType;
  tabs: CardTabType[];
}): [CardTabType | undefined, (value: CardTabType) => void] => {
  const dispatch = useAppDispatch();
  const activeTab = bookmark.activeTab;
  const setActiveTab = (value: CardTabType) => {
    dispatch(setBookmarkActiveTab({ bookmarkHash: bookmark.hash, activeTab: value }));
  };

  useEffect(() => {
    if (!activeTab) {
      dispatch(setBookmarkActiveTab({ bookmarkHash: bookmark.hash, activeTab: tabs[0] }));
    }
  }, [dispatch, activeTab, bookmark.hash, tabs]);

  return [activeTab, setActiveTab];
};
