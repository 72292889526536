import React from 'react';
import classes from './HierarchySearch.module.css';
import { IconSearch } from '@consta/uikit/IconSearch';
import { TextField } from '@consta/uikit/TextField';
import { IconClose } from '@consta/uikit/IconClose';

interface IHierarchySearchProps {
  value: string;
  onChangeValue: (val: string) => void;
}

const HierarchySearch: React.FC<IHierarchySearchProps> = ({ value, onChangeValue }) => {
  const handleChange = ({ value }: { value: string | null }) => {
    onChangeValue(value!);
  };

  return (
    <div className={classes.container}>
      <TextField
        value={value}
        onChange={handleChange}
        style={{ width: '100%' }}
        leftSide={IconSearch}
        placeholder="Поиск"
        size={'s'}
        rightSide={
          value
            ? () => (
                <IconClose
                  size={'xs'}
                  onClick={() => {
                    handleChange({ value: '' });
                  }}
                  style={{ cursor: 'pointer', color: 'rgba(0,32,51,.6)' }}
                />
              )
            : undefined
        }
      />
    </div>
  );
};

export default HierarchySearch;
