import React, { useRef } from 'react';
import classes from './SearchHints.module.css';
import { Loader } from '@consta/uikit/Loader';
import { useAppSelector } from '../../../../../hooks/hooks';
import { selectorMetaIsSearch, selectorMetaSearchHints } from '../../../../../store/metaSlice';
import { useClickOutsideComponent } from '../../../../../shared-front/hooks/useClickOutsideComponent';
import { setHintSearchStyle } from '../../../../../utils/Formatters/setHintSearchStyle';

type PropsType = {
  value: string | null;
  hideHints: () => void;
  onSelectHint: (val: string) => void;
  refResetSearch: React.RefObject<HTMLElement>;
};

const SearchHints: React.FC<PropsType> = ({ value, hideHints, onSelectHint, refResetSearch }) => {
  const isSearch = useAppSelector(selectorMetaIsSearch);
  const searchHints = useAppSelector(selectorMetaSearchHints);

  const ref = useRef<HTMLDivElement>(null);
  useClickOutsideComponent(ref, hideHints, refResetSearch);

  const onClickHandler = (hintValue: string) => {
    if (hintValue) {
      onSelectHint(hintValue);
    }
    hideHints();
  };

  if (!value) {
    return null;
  }

  if (isSearch) {
    return (
      <div className={classes.container}>
        <Loader size="s" />
      </div>
    );
  }

  return (
    <div ref={ref} className={classes.container}>
      {searchHints.length === 0 ? (
        <div className={classes.nothingFindTitle}>{`Ничего не найдено`}</div>
      ) : (
        <div className={classes.searchHintsList}>
          {searchHints.map((searchHint, ind) => {
            return (
              <div key={ind} className={classes.searchHint} onClick={() => onClickHandler(searchHint.tf_hint_search)}>
                {setHintSearchStyle(searchHint.tf_hint_search)}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default SearchHints;
