import React from 'react';
import FiltersGroup, { FilterForGroup, FiltersGroupType } from '../../FiltersGroup/FiltersGroup';
import FilterCollapseContainer from '../../FilterCollapseContainer/FilterCollapseContainer';

interface IGroupFiltersListProps {
  groupFilters: FiltersGroupType[];
  setGroupFilters: (filters: FiltersGroupType[]) => void;
}

const getIsFilterHasSelectedItem = (filter: FiltersGroupType, groupFilters: FiltersGroupType[]): boolean => {
  return !!groupFilters
    .find((groupFilter) => groupFilter.groupName === filter.groupName)
    ?.groupFilters.find((item) => item.isChecked)?.isChecked;
};

const GroupFiltersList: React.FC<IGroupFiltersListProps> = ({ groupFilters, setGroupFilters }) => {
  const onChangeFilterForGroup = (filter: FilterForGroup) => {
    setGroupFilters(
      groupFilters.map((filterGroup) => ({
        ...filterGroup,
        groupFilters: filterGroup.groupFilters.map((curFilter) =>
          curFilter.name === filter.name ? filter : curFilter
        ),
      }))
    );
  };

  const onClearFilter = (groupFilter: FiltersGroupType) => {
    setGroupFilters(
      groupFilters.map((filterGroup) =>
        filterGroup.groupName === groupFilter.groupName
          ? {
              ...groupFilter,
              groupFilters: groupFilter.groupFilters.map((filter) => ({ ...filter, isChecked: false })),
            }
          : filterGroup
      )
    );
  };

  return (
    <>
      {groupFilters.map((groupFilter, ind) => (
        <FilterCollapseContainer
          key={ind}
          title={groupFilter.groupTitle}
          onClearFilter={() => onClearFilter(groupFilter)}
          isFilterHasSelectedItem={getIsFilterHasSelectedItem(groupFilter, groupFilters)}
        >
          {groupFilters.map((filtersGroup, ind) => (
            <FiltersGroup key={ind} filtersGroup={filtersGroup} onChangeFilterForGroup={onChangeFilterForGroup} />
          ))}
        </FilterCollapseContainer>
      ))}
    </>
  );
};

export default GroupFiltersList;
