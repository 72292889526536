import { UserInfoType } from '../../types/types';
import { ReactNode } from 'react';
import { PageRoutesEnum } from '../../appMenuAndRouting/appRoutes';

export const getAppRoutesByPermissions = (
  userInfo: UserInfoType | undefined,
  appRoutes: { path: string; element: ReactNode }[]
) => {
  if (userInfo?.permissions.length) {
    const permitRoutes = appRoutes.filter((appRoute) =>
      userInfo.permissions.find((permission) => permission.slug === appRoute.path.slice(1))
    );
    const infoPageRoute = appRoutes.find((appRoute) => appRoute.path === PageRoutesEnum.InfoPage);
    return infoPageRoute ? [...permitRoutes, infoPageRoute] : permitRoutes;
  }
  return appRoutes.filter(
    (appRoute) => appRoute.path === PageRoutesEnum.AccessDeniedPage || appRoute.path === PageRoutesEnum.InfoPage
  );
};
