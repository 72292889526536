import React from 'react';

import classes from './ActionDateTimeWithUser.module.css';
import { Text } from '@consta/uikit/Text';
import { formatDateDDMMYYYYHHMM } from '../../utils/Formatters/formatDateDDMMYYYYHHMM';
import UserInfo from '../UserInfo/UserInfo';
import { UserBaseType } from '../../types/types';

interface IActionDateTimeWithUserProps {
  actionTitle: string;
  actionDateTime?: string | null;
  actionUser?: UserBaseType | null;
}

const ActionDateTimeWithUser: React.FC<IActionDateTimeWithUserProps> = ({
  actionTitle,
  actionDateTime,
  actionUser,
}) => {
  return (
    <div className={classes.container}>
      <div className={classes.row}>
        <Text className={classes.title}>{actionTitle}</Text>
        <Text className={classes.titleDate}>
          {actionDateTime ? formatDateDDMMYYYYHHMM(new Date(actionDateTime)) : '-'}
        </Text>
      </div>
      {actionUser && <UserInfo user={actionUser} />}
    </div>
  );
};

export default ActionDateTimeWithUser;
