import { MultiFilterType } from '../Components/FilterWithScrollAndSearch/FilterWithScrollAndSearch';

export const clearAllMultiFilters = (
  multiFilters: MultiFilterType[],
  setMultiFilters: (filters: MultiFilterType[]) => void
) => {
  setMultiFilters(
    multiFilters.map((filter) => ({
      ...filter,
      searchTitleValue: null,
      items: filter.items.map((item) => ({ ...item, isChecked: false })),
    }))
  );
};
