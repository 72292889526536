import React from 'react';

import classes from './KeyIcoAndValue.module.css';
import { Text } from '@consta/uikit/Text';

import iconForeignKey from '../../assets/CatalogPage/Cards/iconForeignKey.svg';
import iconPrimaryKey from '../../assets/CatalogPage/Cards/iconPrimaryKey.svg';
import CustomHoverToolTip from '../CustomHoverToolTip/CustomHoverToolTip';

interface IKeyIcoAndValueProps {
  is_in_primary_key?: boolean | null;
  is_in_foreign_key?: boolean | null;
  isTextHide?: boolean;
}

const KeyIcoAndValue: React.FC<IKeyIcoAndValueProps> = ({ is_in_primary_key, is_in_foreign_key, isTextHide }) => {
  return (
    <div className={classes.container}>
      {is_in_primary_key && (
        <div className={classes.row}>
          <CustomHoverToolTip title={'Первичный'}>
            <img src={iconPrimaryKey} className={classes.ico} alt={'key'} />
          </CustomHoverToolTip>
          {!isTextHide && <Text className={classes.description}>{'Первичный'}</Text>}
        </div>
      )}
      {is_in_foreign_key && (
        <div className={classes.row}>
          <CustomHoverToolTip title={'Внешний'}>
            <img src={iconForeignKey} className={classes.ico} alt={'key'} />
          </CustomHoverToolTip>
          {!isTextHide && <Text className={classes.description}>{'Внешний'}</Text>}
        </div>
      )}
      {!isTextHide && !is_in_foreign_key && !is_in_primary_key && '-'}
    </div>
  );
};

export default KeyIcoAndValue;
