import React, { useRef } from 'react';

import classes from './FilterBar.module.css';
import FilterBarItem from './FilterBarItem/FilterBarItem';
import FilterBarModalForm from './FilterBarModalForm/FilterBarModalForm';
import { Button } from '@consta/uikit/Button';
import { IconArrowDown } from '@consta/uikit/IconArrowDown';
import { IconClose } from '@consta/uikit/IconClose';
import { Modal } from '@consta/uikit/Modal';
import { Tag } from '@consta/uikit/Tag';

export type FilterBarItemType = {
  uuid: string;
  title: string;
};

interface IFilterBarProps {
  items: FilterBarItemType[];
  onSave: (items: FilterBarItemType[]) => void;
  customClass?: string;
}

const FilterBar: React.FC<IFilterBarProps> = ({ items, onSave, customClass }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const refContainer = useRef<HTMLDivElement>(null);
  const refChildren = useRef<HTMLDivElement>(null);

  const isFlexEnd =
    refContainer.current?.offsetWidth &&
    refChildren.current?.offsetWidth &&
    refContainer.current.offsetWidth > 0 &&
    refChildren.current.offsetWidth >= refContainer.current.offsetWidth;

  const onCancelItem = (item: FilterBarItemType) => {
    onSave(items.filter((curItem) => curItem.uuid !== item.uuid));
  };

  const onClearAll = () => {
    onSave([]);
  };

  const onCloseModal = () => {
    setIsModalOpen(false);
  };

  const onShowAll = () => {
    setIsModalOpen(true);
  };

  return (
    <div ref={refContainer} className={`${classes.container} ${customClass ? customClass : ''}`}>
      {items.length > 0 && (
        <div ref={refChildren} className={`${classes.childrenContainer} ${isFlexEnd ? classes.flexEnd : ''}`}>
          {items.map((item) => (
            <FilterBarItem key={item.uuid} item={item} onCancel={() => onCancelItem(item)} />
          ))}
          <Button size={'xs'} label="Смотреть все" view="clear" iconRight={IconArrowDown} onClick={onShowAll} />
          <Button size={'xs'} label="Сбросить все" view="clear" iconRight={IconClose} onClick={onClearAll} />
        </div>
      )}
      {items.length === 0 && (
        <div ref={refChildren} className={classes.childrenContainer}>
          <Tag mode="button" label="Фильтры не выбраны" size={'xs'} onClick={() => {}} />
        </div>
      )}
      <Modal isOpen={isModalOpen} hasOverlay onClickOutside={onCloseModal} onEsc={onCloseModal}>
        <FilterBarModalForm items={items} onSave={onSave} onClose={onCloseModal} />
      </Modal>
    </div>
  );
};

export default FilterBar;
