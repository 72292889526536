import React from 'react';
import classes from './PaginationRow.module.css';
import Pagination from '../../../../../shared-front/Pagination/Pagination';
import { useAppSelector } from '../../../../../hooks/hooks';
import { selectorCatalogItems } from '../../../../../store/catalogSlice';
import { useGetTechMetaDocuments } from '../../../../../hooks/useGetTechMetaDocuments';
import ButtonDownload from '../../../../../Components/ButtonDownload/ButtonDownload';

const PaginationRow: React.FC = () => {
  const catalogItems = useAppSelector(selectorCatalogItems);
  const getTechMetaDocuments = useGetTechMetaDocuments();

  const onChangePagination = (queryPagination: string) => {
    getTechMetaDocuments({ queryPagination });
  };

  return (
    <div className={classes.container}>
      <Pagination
        currentPageSize={catalogItems?.per_page}
        currentPage={catalogItems?.current_page}
        total={catalogItems?.total}
        className={classes.pagination}
        onChangePagination={onChangePagination}
        lastPage={catalogItems?.last_page}
        customButton={<ButtonDownload />}
      />
    </div>
  );
};

export default PaginationRow;
