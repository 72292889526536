import React, { ReactNode } from 'react';

import classes from './TableCellWithDropDown.module.css';
import { IconArrowDown } from '@consta/uikit/IconArrowDown';
import { IconArrowUp } from '@consta/uikit/IconArrowUp';

interface IOdata1CObjectAttributesNameProps {
  cellValue: ReactNode;
  isOpenDropDown: boolean;
  onClickToggleDropDown: () => void;
  customClassForCellValue?: string;
}

const TableCellWithDropDown: React.FC<IOdata1CObjectAttributesNameProps> = ({
  cellValue,
  isOpenDropDown,
  onClickToggleDropDown,
  customClassForCellValue,
}) => {
  const onClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    onClickToggleDropDown();
  };

  return (
    <div className={classes.container}>
      <div className={classes.dropDownIco} onClick={onClick}>
        {isOpenDropDown ? <IconArrowUp size={'xs'} /> : <IconArrowDown size={'xs'} />}
      </div>
      <div className={customClassForCellValue ? customClassForCellValue : ''}>{cellValue}</div>
    </div>
  );
};

export default TableCellWithDropDown;
