import React, { ReactNode, useCallback, useState } from 'react';

import classes from './TableContainer1CObject.module.css';
import { Table } from '../../../shared-front/Table/Table';

import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import {
  selectorCatalogsIsLoadingAttributesData,
  setBookmarkInnerRows,
  setBookmarkOpenedRows,
  setCatalogError,
} from '../../../store/catalogSlice';
import { Loader } from '@consta/uikit/Loader';
import { Bookmark1CObject, BookmarkType } from '../../../types/types';
import {
  TABLE_ODATA1C_OBJECT_ATTRIBUTES_COLUMNS,
  TableOdata1CObjectAttributesType,
} from './TableOdata1CObjectAttributesColumnsType';
import TableCellWithDropDown from '../../../shared-front/Table/TableCellWithDropDown/TableCellWithDropDown';
import { useExistedOrCreateNewBookmark } from '../../../hooks/useExistedOrCreateNewBookmark';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { serverApi } from '../../../api/serverApi';

interface ITableContainerProps {
  bookmark: BookmarkType;
}

const TableContainer1CObject: React.FC<ITableContainerProps> = ({ bookmark }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let [_, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const doExistedOrCreateNewBookmark = useExistedOrCreateNewBookmark();
  const isLoading = useAppSelector(selectorCatalogsIsLoadingAttributesData);
  const [isLoadingInnerRowsUuid, setIsLoadingInnerRowsUuid] = useState<string | undefined>(undefined);

  const data = bookmark.data as Bookmark1CObject | undefined;
  const storeInnerRows = data?.innerRows;
  const storeOpenedRows = data?.openedRows;

  const toggleDropDown = useCallback(
    (id: string) => {
      if (storeOpenedRows?.includes(id)) {
        const newOpenedRows = storeOpenedRows.filter((rowId) => rowId !== id);
        dispatch(setBookmarkOpenedRows({ bookmarkHash: bookmark.hash, openedRows: newOpenedRows }));
      } else {
        const newOpenedRows = storeOpenedRows ? [...storeOpenedRows, id] : [id];
        dispatch(setBookmarkOpenedRows({ bookmarkHash: bookmark.hash, openedRows: newOpenedRows }));
      }
    },
    [dispatch, bookmark.hash, storeOpenedRows]
  );

  const onClickToggleDropDown = useCallback(
    (uuid: string) => {
      toggleDropDown(uuid);
      if (!storeInnerRows?.[uuid]) {
        setIsLoadingInnerRowsUuid(uuid);
        serverApi
          .getOdata1cTablePartAttributesData(uuid)
          .then((result) => {
            const newInnerRows = { ...storeInnerRows };
            newInnerRows[uuid] = result.data;
            dispatch(setBookmarkInnerRows({ bookmarkHash: bookmark.hash, innerRows: newInnerRows }));
          })
          .catch((e) => {
            dispatch(setCatalogError('Ошибка получения вложенных атрибутов.\n' + e.message));
          })
          .finally(() => {
            setIsLoadingInnerRowsUuid(undefined);
          });
      }
    },
    [dispatch, bookmark.hash, storeInnerRows, toggleDropDown]
  );

  const getTableData = useCallback(() => {
    const tableRows: (TableOdata1CObjectAttributesType & {
      rowId?: string;
      isOpenDropDownRow?: boolean;
      dropDownRow?: ReactNode;
    })[] = [];
    data?.attributesData?.data.forEach((attribute) => {
      tableRows.push({
        rowId: attribute._uuid,
        name:
          attribute.type === 'table_part' ? (
            <TableCellWithDropDown
              cellValue={attribute.name.label}
              isOpenDropDown={!!storeOpenedRows?.includes(attribute._uuid)}
              onClickToggleDropDown={() => onClickToggleDropDown(attribute._uuid)}
            />
          ) : (
            attribute.name.label
          ),
        unified_data_type: attribute.unified_data_type || '-',
        short_description: attribute.short_description || '-',
        description: attribute.description || '-',
        type: attribute.type || '-',
        not_null: attribute.not_null || '-',
        uuid: attribute._uuid,
      });
      if (attribute._uuid === isLoadingInnerRowsUuid) {
        tableRows.push({
          rowId: attribute._uuid,
          name: (
            <div className={classes.loaderContainer}>
              <Loader size={'xs'} className={classes.loader} />
            </div>
          ),
          short_description: '',
          description: '',
          type: '',
          not_null: '',
          uuid: 'uuid_loading',
        });
      }
      if (storeInnerRows?.[attribute._uuid] && storeOpenedRows?.includes(attribute._uuid)) {
        storeInnerRows?.[attribute._uuid].forEach((attribute) => {
          tableRows.push({
            rowId: `${attribute._uuid}_innerRow`,
            name: <div style={{ marginLeft: 48 }}>{attribute.name.label || '-'}</div>,
            short_description: attribute.short_description || '-',
            description: attribute.description || '-',
            type: attribute.type || '-',
            not_null: attribute.not_null || '-',
            uuid: attribute._uuid,
          });
        });
      }
    });
    return tableRows;
  }, [data?.attributesData?.data, isLoadingInnerRowsUuid, storeInnerRows, storeOpenedRows, onClickToggleDropDown]);

  const onRowClick = (originalRow: { [key: string]: unknown }) => {
    const item = data?.attributesData?.data.find((item) => item._uuid === originalRow.uuid);
    if (item?.name.label) {
      const bookmark = {
        label: item.name.label,
        type: 'odata1c',
        slug: item.name.slug,
        hash: item.name.hash,
      } as BookmarkType;

      const searchParams = createSearchParams({
        type: `${bookmark.type}`,
        hash: `${bookmark.hash}`,
        slug: `${bookmark.slug}`,
      });
      setSearchParams(searchParams);
      doExistedOrCreateNewBookmark(bookmark);
    } else {
      if ((originalRow.rowId as string).includes('innerRow')) {
        const bookmark = {
          label: 'Загрузка...',
          type: 'odata1c',
          slug: 'odata1c_attr',
          hash: originalRow.uuid,
        } as BookmarkType;

        const searchParams = createSearchParams({
          type: `${bookmark.type}`,
          hash: `${bookmark.hash}`,
          slug: `${bookmark.slug}`,
        });
        setSearchParams(searchParams);
        doExistedOrCreateNewBookmark(bookmark);
      }
    }
  };

  const tableData = getTableData();

  return (
    <div className={classes.container}>
      <div className={classes.tableContainer}>
        {isLoading ? (
          <Loader />
        ) : (
          <div className={classes.tableWrapper}>
            <Table
              data={tableData}
              columns={TABLE_ODATA1C_OBJECT_ATTRIBUTES_COLUMNS}
              isColumnsResizable={true}
              isRowHover={true}
              onRowClick={onRowClick}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default TableContainer1CObject;
