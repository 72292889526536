import React from 'react';

import classes from './CPViewSelectorRow.module.css';
import CPViewSelector from './CPViewSelector/CPViewSelector';
import Delimiter from '../../../shared-front/Delimiter/Delimiter';

interface ICPViewSelectorRowProps {}

const CPViewSelectorRow: React.FC<ICPViewSelectorRowProps> = () => {
  return (
    <div className={classes.container}>
      <CPViewSelector />
      <Delimiter customClass={classes.delimiter} />
    </div>
  );
};

export default CPViewSelectorRow;
