import React from 'react';

import classes from './CreateConnectionStepOne.module.css';
import InfoSystemSelector from './InfoSystemSelector/InfoSystemSelector';
import SourceTypeSelector from './SourceTypeSelector/SourceTypeSelector';
import ConnectorTypeSelector from './ConnectorTypeSelector/ConnectorTypeSelector';

interface ICreateConnectionStepOneProps {}

const CreateConnectionStepOne: React.FC<ICreateConnectionStepOneProps> = () => {
  return (
    <div className={classes.container}>
      <InfoSystemSelector />
      <SourceTypeSelector />
      <ConnectorTypeSelector />
    </div>
  );
};

export default CreateConnectionStepOne;
