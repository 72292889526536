import { HierarchyItemType } from '../../types/types';

export const searchParentInHierarchyByChildUuid = (
  hierarchyItems: HierarchyItemType[],
  childUuid: string
): HierarchyItemType | undefined => {
  let result: HierarchyItemType | undefined;

  for (let parent of hierarchyItems) {
    if (parent.children) {
      const isParentFound = parent.children.filter((childItem) => childItem.uuid === childUuid)?.[0];
      if (isParentFound) {
        result = parent;
      }
      if (!result && parent.children) {
        result = searchParentInHierarchyByChildUuid(parent.children, childUuid);
      }
    }
  }
  return result;
};
