import { HierarchyItemType } from '../../types/types';

export const checkIsAllGrandChildrenSelected = (hierarchyItem: HierarchyItemType): boolean => {
  const children = hierarchyItem.children;
  if (!children) {
    return false;
  }
  let result = false;
  for (let child of children) {
    if (!child.has_checkbox && child.children) {
      for (let grandChild of child.children) {
        result = true;
        if (!grandChild.isChecked) {
          return false;
        }
      }
    }
  }
  return result;
};
