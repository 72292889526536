import React, { useEffect, useRef, useState } from 'react';
import classes from './HierarchyFilter.module.css';
import { Button } from '@consta/uikit/Button';

import { CSSTransition } from 'react-transition-group';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/hooks';
import {
  closeAllHierarchyItemsStore,
  closeIsOpenHierarchy,
  getHierarchyItemsThunk,
  selectorCatalogActiveSection,
  selectorCatalogHierarchyItems,
  selectorCatalogIsHierarchyOpen,
  selectorCatalogsIsLoadingHierarchy,
  setNoSelectAllHierarchyItemsStore,
} from '../../../../../store/catalogSlice';
import HierarchyLevel from './HierarchyLevel/HierarchyLevel';
import HierarchySearch from './HierarchyLevel/HierarchySearch/HierarchySearch';
import { Text } from '@consta/uikit/Text';
import { Loader } from '@consta/uikit/Loader';
import { useGetTechMetaDocuments } from '../../../../../hooks/useGetTechMetaDocuments';
import { HierarchyItemType } from '../../../../../types/types';
import { IconRestart } from '@consta/uikit/IconRestart';

const HierarchyFilter: React.FC = () => {
  const dispatch = useAppDispatch();
  const getTechMetaDocuments = useGetTechMetaDocuments();
  const isHierarchyOpen = useAppSelector(selectorCatalogIsHierarchyOpen);
  const hierarchyItems = useAppSelector(selectorCatalogHierarchyItems);
  const catalogActiveSection = useAppSelector(selectorCatalogActiveSection);
  const isLoadingHierarchy = useAppSelector(selectorCatalogsIsLoadingHierarchy);

  const refHFilter = useRef<HTMLDivElement>(null);
  const refChildren = useRef<HTMLDivElement>(null);
  const tempHierarchyItems = useRef<HierarchyItemType[] | undefined>(undefined);

  useEffect(() => {
    if (hierarchyItems.length === 0 && catalogActiveSection?.slug && isHierarchyOpen) {
      dispatch(getHierarchyItemsThunk({ hierarchySlug: catalogActiveSection.slug }));
    }
  }, [dispatch, catalogActiveSection?.slug, hierarchyItems.length, isHierarchyOpen]);

  const closeHFilter = () => {
    dispatch(closeIsOpenHierarchy(tempHierarchyItems.current));
    if (tempHierarchyItems.current) {
      tempHierarchyItems.current = undefined;
    }
  };

  const onApplyClick = () => {
    tempHierarchyItems.current = hierarchyItems;
    getTechMetaDocuments({ hierarchyItems });
  };

  const clearAllHandler = () => {
    dispatch(setNoSelectAllHierarchyItemsStore());
    dispatch(closeAllHierarchyItemsStore());
    if (catalogActiveSection?.slug) {
      dispatch(getHierarchyItemsThunk({ hierarchySlug: catalogActiveSection.slug }));
    }
  };

  const [localHierarchyItems, setLocalHierarchyItems] = useState(hierarchyItems);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    if (isHierarchyOpen && !tempHierarchyItems.current) {
      tempHierarchyItems.current = hierarchyItems;
    }
  }, [isHierarchyOpen, hierarchyItems]);

  useEffect(() => {
    if (!searchValue) {
      setLocalHierarchyItems(hierarchyItems);
    } else {
      setLocalHierarchyItems(
        hierarchyItems?.filter((child) => child.name.toLowerCase().includes(searchValue.toLowerCase()))
      );
    }
  }, [searchValue, hierarchyItems]);

  return (
    <div ref={refHFilter} className={classes.container}>
      <CSSTransition in={isHierarchyOpen} nodeRef={refChildren} timeout={300} classNames={{ ...classes }} unmountOnExit>
        <div ref={refChildren} className={classes.children}>
          <div className={classes.content}>
            <div className={classes.topRow}>
              <Text className={classes.title}>{catalogActiveSection?.label ? catalogActiveSection?.label : ''}</Text>
              <div className={classes.clearAllBtn}>
                <Button size={'s'} view="primary" iconLeft={IconRestart} onlyIcon onClick={clearAllHandler} />
              </div>
            </div>
            <div className={classes.delimiter} />
            {isLoadingHierarchy && hierarchyItems.length === 0 ? (
              <div style={{ marginTop: '20px' }}>
                <Loader size={'s'} />
              </div>
            ) : (
              <div className={classes.hierarchyWrapper}>
                <HierarchySearch value={searchValue} onChangeValue={setSearchValue} />
                {localHierarchyItems.map((hierarchyItem, ind) => (
                  <HierarchyLevel key={ind} hierarchyItem={hierarchyItem} />
                ))}
              </div>
            )}
          </div>
          <div className={classes.delimiter} />
          <div className={classes.bottomButtons}>
            <Button size={'s'} view={'ghost'} label={'Отменить'} style={{ width: '193px' }} onClick={closeHFilter} />
            <Button size={'s'} label={'Применить'} style={{ width: '193px' }} onClick={onApplyClick} />
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

export default HierarchyFilter;
