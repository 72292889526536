import React from 'react';

import classes from './Odata1CAttributeCommonInfoTab.module.css';
import CollapseBlock from '../../../../../../Components/CollapseBlock/CollapseBlock';
import CardsContentBlock from '../../../../../../Components/CardsContentBlock/CardsContentBlock';
import SystemCard from '../../../../../../Components/SystemCard/SystemCard';
import { Bookmark1CAttributeType, BookmarkType } from '../../../../../../types/types';
import CardInputLongDescription from '../../../../../../Components/CardInputLongDescription/CardInputLongDescription';
import { useBookmarksView } from '../../../../../../hooks/useBookmarksView';
import EdmBlock from '../../../../../../Components/EdmBlock/EdmBlock';

interface IRdbCommonInfoTabProps {
  bookmark: BookmarkType;
}

const Odata1CAttributeCommonInfoTab: React.FC<IRdbCommonInfoTabProps> = ({ bookmark }) => {
  const data = bookmark.data as Bookmark1CAttributeType | undefined;
  const {
    isOpenBookmarkCollapseTextField,
    isOpenBookmarkCollapseBlockEdm,
    textFieldValue,
    onIsOpenChangeCollapseTextField,
    onIsOpenChangeCollapseBlockEdm,
    onChangeTextFieldValue,
  } = useBookmarksView({ bookmarkId: bookmark.hash, bookmarkDescription: bookmark.data?.description });

  return (
    <div className={classes.container}>
      <div className={classes.leftColumn}>
        <CollapseBlock title={'Основное'} isOpen={true}>
          <CardInputLongDescription
            bookmark={bookmark}
            textFieldValue={textFieldValue}
            isModeEdit={isOpenBookmarkCollapseTextField}
            onIsModeEditChange={onIsOpenChangeCollapseTextField}
            onChangeTextFieldValue={onChangeTextFieldValue}
          />
        </CollapseBlock>
        <EdmBlock
          isOpen={isOpenBookmarkCollapseBlockEdm}
          onIsOpenChange={onIsOpenChangeCollapseBlockEdm}
          dataElements={data?.data_elements}
          edm={data?.edm}
        />
        <CollapseBlock title={'Дополнительно'} isOpen={true}>
          <CardsContentBlock
            title={'Наименование объекта odata'}
            description={data?.odata_name ? data.odata_name : '-'}
          />
          <CardsContentBlock
            title={'Унифицированный тип данных'}
            description={data?.unified_data_type ? data.unified_data_type : '-'}
          />
          <CardsContentBlock title={'Тип данных'} description={data?.type ? data.type : '-'} />
          <CardsContentBlock title={'Допускает пустое значение'} description={data?.not_null ? data.not_null : '-'} />
        </CollapseBlock>
        <div style={{ height: '60px' }} />
      </div>
      <div className={classes.rightColumn}>
        <SystemCard
          systemUuid={data?.system._uuid}
          systemName={data?.system.name}
          systemSourceIco={data?.source_icon}
          systemSourceType={data?.source_type}
          connection_name={data?.connection_name}
          last_scanned_at={data?.last_scanned_at}
          created_on_portal_ts={data?.created_on_portal_ts}
          updated_on_portal_ts={data?.updated_on_portal_ts}
        />
      </div>
    </div>
  );
};

export default Odata1CAttributeCommonInfoTab;
