import React, { useEffect } from 'react';

import classes from './CardProcedureTriggerFuntionContent.module.css';
import { BookmarkType } from '../../../../types/types';
import CardTabsRow, { CardTabType } from '../../../../Components/CardTabsRow/CardTabsRow';
import ContentProcedureTriggerFunctionByTabs from './ContentProcedureTriggerFuntionByTabs/ContentProcedureTriggerFuntionByTabs';
import InfoProcedureTriggerFunctionRow from './InfoProcedureTriggerFuntionRow/InfoProcedureTriggerFuntionRow';
import { useActiveTab } from '../../../../hooks/useActiveTab';
import { useLoadBookmarkData } from '../../../../hooks/useLoadBookmarkData';
import WrapperCatalogObjectNotFound from '../../../../Components/WrapperCatalogObjectNotFound/WrapperCatalogObjectNotFound';

interface ICardSchemaContentProps {
  bookmark: BookmarkType;
}

export const TABS_PROCEDURE_TRIGGER_FUNCTION: CardTabType[] = [
  { id: 0, label: 'Общая информация' },
  { id: 1, label: 'Скрипт' },
];

const CardProcedureTriggerFunctionContent: React.FC<ICardSchemaContentProps> = ({ bookmark }) => {
  const loadBookmarkData = useLoadBookmarkData();
  const [activeTab, setActiveTab] = useActiveTab({ bookmark, tabs: TABS_PROCEDURE_TRIGGER_FUNCTION });

  useEffect(() => {
    loadBookmarkData(bookmark);
  }, [bookmark, loadBookmarkData]);

  return (
    <div className={classes.container}>
      <WrapperCatalogObjectNotFound>
        <InfoProcedureTriggerFunctionRow bookmark={bookmark} />
        <CardTabsRow tabs={TABS_PROCEDURE_TRIGGER_FUNCTION} activeTab={activeTab} setActiveTab={setActiveTab} />
        <ContentProcedureTriggerFunctionByTabs activeTab={activeTab} bookmark={bookmark} />
      </WrapperCatalogObjectNotFound>
    </div>
  );
};

export default CardProcedureTriggerFunctionContent;
