import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MenuItemType } from '../../../types/types';
import classes from './SubMenuItem.module.css';
import { Text } from '@consta/uikit/Text';
import classNames from 'classnames';
import { Popover } from '@consta/uikit/Popover';
import { Card } from '@consta/uikit/Card';
import { IconArrowRight } from '@consta/uikit/IconArrowRight';
import { SafeArea } from './SafeArea';
import useMousePosition from './useMousePosition';

type PropsType = {
  item: MenuItemType;
};

const SubMenuItem: React.FC<PropsType> = ({ item }) => {
  const navigate = useNavigate();
  const itemHasChildren = item.children?.length;
  const itemContainer = useRef<HTMLDivElement>(null);
  const popover = useRef<HTMLDivElement>(null);
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const itemContainerClassName = itemHasChildren
    ? classNames(classes.container_withChildren, isPopoverVisible && classes.itemHighlighted)
    : classes.container;
  const itemTextClassName = item.children ? classes.title_withChildren : classes.title;
  const { x: mouseX, y: mouseY } = useMousePosition();

  const onClick = (targetItem: MenuItemType) => {
    if (targetItem.href) {
      navigate(targetItem.href);
    } else if (targetItem.extUrl) {
      window.open(targetItem.extUrl, '_blank', 'noreferrer');
    }
  };

  const handleMouseEnter = () => {
    itemHasChildren && setIsPopoverVisible(true);
  };

  const handleMouseLeave = () => {
    itemHasChildren && setIsPopoverVisible(false);
  };

  if (item.isDelimiter) {
    return (
      <div className={classes.delimiterContainer}>
        <Text className={classes.titleDelimiter}>{item.label}</Text>
        <div className={classes.delimiter} />
      </div>
    );
  }

  return (
    <div
      ref={itemContainer}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={itemContainerClassName}
      onClick={() => onClick(item)}
    >
      <Text className={classNames(itemTextClassName, { [classes.disabled]: item.disabled })}>{item.label}</Text>
      {isPopoverVisible && itemContainer.current && popover.current && (
        <SafeArea submenu={popover.current} mouseX={mouseX} mouseY={mouseY} />
      )}
      {item.children && (
        <>
          <IconArrowRight className={classes.arrowIcon} />
          {isPopoverVisible && (
            <Popover
              ref={popover}
              anchorRef={itemContainer}
              style={{ zIndex: 2000 }}
              direction="rightStartUp"
              placeholder={''}
            >
              <Card border={true} className={classes.popover}>
                {item.children.map((child, index) => (
                  <div key={index} className={classes.container} onClick={() => onClick(child)}>
                    <Text className={classNames(classes.title, { [classes.disabled]: item.disabled })}>
                      {child.label}
                    </Text>
                  </div>
                ))}
              </Card>
            </Popover>
          )}
        </>
      )}
    </div>
  );
};

export default SubMenuItem;
