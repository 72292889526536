import { CreateNewConnectionsSliceType } from '../createNewConnectionSlice';

export const validateStepTwoDbInputs = (state: CreateNewConnectionsSliceType) => {
  let validateError = false;

  if (!state.ipAddress) {
    validateError = true;
    state.isRequiredIpAddress = true;
  }
  if (!state.connectionPort) {
    if (!validateError) {
      validateError = true;
    }
    state.isRequiredConnectionPort = true;
  }
  if (!state.connectionUser) {
    if (!validateError) {
      validateError = true;
    }
    state.isRequiredConnectionUser = true;
  }
  if (!state.connectionPassword) {
    if (!validateError) {
      validateError = true;
    }
    state.isRequiredConnectionPassword = true;
  }
  if (state.isRequiredSelectedAssets) {
    state.isRequiredSelectedAssets = false;
  }

  return validateError;
};
