import { instanceAxios } from './instanceAxios';
import {
  ConnectionRunPreviewResponseType,
  ConnectionRunScannerResponseType,
  ConnectionsServerResponseType,
  DeleteConnectionServerResponseType,
} from './serverResponse';
import { ConnectionDetailType } from '../types/types';

export let abortControllerConnections = new AbortController();

export const connectionsApi = {
  async getConnections(requestQuery: string) {
    return instanceAxios
      .get<ConnectionsServerResponseType>(`/connections${requestQuery ? requestQuery : ''}`)
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        if (e.name === 'CanceledError') {
          abortControllerConnections = new AbortController();
          return {
            data: [],
            meta: { from: 0, to: 0, total: 0, per_page: 0, current_page: 0, last_page: 0 },
          } as ConnectionsServerResponseType;
        } else {
          throw new Error(e.message);
        }
      });
  },

  async getConnectionDetail(connectionUuid: string | undefined) {
    if (!connectionUuid) {
      return undefined;
    }
    const response = await instanceAxios.get<{ data: ConnectionDetailType }>(`/connections/${connectionUuid}`);
    return response.data.data;
  },

  async postConnectionRunScanner(connectionUuid: string) {
    const response = await instanceAxios.post<ConnectionRunScannerResponseType>(
      `/connections/${connectionUuid}/run-scanner`
    );
    return response.data;
  },

  async postConnectionAssetRunScanner({ connectionUuid, assetUuid }: { connectionUuid: string; assetUuid: string }) {
    const response = await instanceAxios.post<ConnectionRunScannerResponseType>(
      `/connections/${connectionUuid}/run-scanner/${assetUuid}`
    );
    return response.data;
  },

  async postConnectionRunPreview(connectionUuid: string) {
    const response = await instanceAxios.post<ConnectionRunPreviewResponseType>(
      `/connections/${connectionUuid}/run-preview`
    );
    return response.data;
  },

  async postConnectionAssetRunPreview({ connectionUuid, assetUuid }: { connectionUuid: string; assetUuid: string }) {
    const response = await instanceAxios.post<ConnectionRunPreviewResponseType>(
      `/connections/${connectionUuid}/run-preview/${assetUuid}`
    );
    return response.data;
  },

  async saveEditedConnection(editConnection: ConnectionDetailType) {
    const response = await instanceAxios.put<{ data: ConnectionDetailType }>(
      `/connections/${editConnection._uuid}`,
      editConnection
    );
    return response.data.data;
  },

  async deleteConnection(connectionUuid: string) {
    await instanceAxios.delete<DeleteConnectionServerResponseType>(`/connections/${connectionUuid}`);
    return { connectionUuid };
  },
};
