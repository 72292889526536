import React from 'react';

import classes from './DisplayConnectionSettings.module.css';
import { ConnectionDetailType } from '../../../../../../types/types';
import { Text } from '@consta/uikit/Text';

interface IDisplayConnectionSettingsProps {
  connectionDetail: ConnectionDetailType | undefined;
}

const DisplayConnectionSettings: React.FC<IDisplayConnectionSettingsProps> = ({ connectionDetail }) => {
  const isOdata = connectionDetail?.source_type?.card_view === 'odata';
  const isOracle = connectionDetail?.source_type?.card_view === 'oracle';
  const isDb = !isOdata && !isOracle;

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={classes.section}>
          {isOdata ? (
            <div className={classes.sectionItem}>
              <Text className={classes.sectionItemTitle}>{`URL`}</Text>
              <Text className={classes.sectionItemValue}>{connectionDetail?.url ?? '-'}</Text>
            </div>
          ) : (
            <div className={classes.sectionItem}>
              <Text className={classes.sectionItemTitle}>{`IP адрес/хост`}</Text>
              <Text className={classes.sectionItemValue}>{connectionDetail?.host ?? '-'}</Text>
            </div>
          )}
          {!isOdata && (
            <div className={classes.sectionItem}>
              <Text className={classes.sectionItemTitle}>{`Порт`}</Text>
              <Text className={classes.sectionItemValue}>{connectionDetail?.port ?? '-'}</Text>
            </div>
          )}
          <div className={classes.sectionItem}>
            <Text className={classes.sectionItemTitle}>{`Пользователь`}</Text>
            <Text className={classes.sectionItemValue}>{connectionDetail?.user ?? '-'}</Text>
          </div>
          <div className={classes.sectionItem}>
            <Text className={classes.sectionItemTitle}>{`Пароль`}</Text>
            <Text className={classes.sectionItemValue}>{'********'}</Text>
          </div>
          {isOracle && (
            <div className={classes.sectionItem}>
              <Text className={classes.sectionItemTitle}>{`Экземпляр`}</Text>
              <Text className={classes.sectionItemValue}>{connectionDetail?.instance ?? '-'}</Text>
            </div>
          )}
          {isDb && (
            <div className={classes.sectionItem}>
              <Text className={classes.sectionItemTitle}>{`База данных`}</Text>
              {connectionDetail?.assets?.map((asset, ind) => {
                return (
                  <Text key={ind} className={classes.sectionItemValue}>
                    &bull; {asset.name ?? '-'}
                  </Text>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DisplayConnectionSettings;
