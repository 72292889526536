import { HierarchyItemType } from '../../types/types';
import { selectInHierarchyItem } from './selectInHierarchyItem';

export const setIsOpenHierarchyItem = ({
  hierarchyItems,
  itemUuid,
  isOpen,
}: {
  hierarchyItems: HierarchyItemType[];
  itemUuid: string;
  isOpen: boolean;
}): HierarchyItemType[] => {
  return hierarchyItems.map((hierarchyItem) => {
    return hierarchyItem.uuid === itemUuid
      ? { ...hierarchyItem, isOpen }
      : hierarchyItem.children
      ? {
          ...hierarchyItem,
          children: hierarchyItem.children.map((child) =>
            selectInHierarchyItem({ hierarchyItem: child, itemUuid, isOpen, isChecked: child.isChecked })
          ),
        }
      : hierarchyItem;
  });
};
