import { instanceAxios } from './instanceAxios';
import { AssetsByTypeServerResponseType, HintSearchServerResponseType } from './serverResponse';

export const metaApi = {
  async getMetaAssetsByType() {
    const response = await instanceAxios.get<AssetsByTypeServerResponseType>('/asset-types');
    return response.data;
  },

  async getMetaHintsSearch(value: string) {
    const response = await instanceAxios.get<HintSearchServerResponseType[]>(`/search?name=${value}`);
    return response.data;
  },
};
