import React, { useRef } from 'react';

import classes from './ObjectTabControlRow.module.css';
import SearchRow from '../../Pages/MainPage/MainPageHero/SearchRow/SearchRow';
import { Button } from '@consta/uikit/Button';
import { IconSettings } from '@consta/uikit/IconSettings';
import LeftSideBar, { LeftSideBarHandleType } from '../../shared-front/LeftSideBar/LeftSideBar';
import TableSettings from '../../shared-front/TableSettings/TableSettings';
import { IconEdit } from '@consta/uikit/IconEdit';
import { BookmarkType, CatalogSectionType } from '../../types/types';
import { useAppDispatch } from '../../hooks/hooks';
import { getObjectsThunk, setBookmarkTableColumns, setObjectsSearchValue } from '../../store/catalogSlice';
import { getObjectsQueryParams } from '../../utils/Objects/getObjectsQueryParams';
import { TableColumnType } from '../../shared-front/Table/Table';

interface IObjectTabControlRowProps {
  bookmark: BookmarkType;
  className?: string;
  objectType?: CatalogSectionType;
}

const ObjectTabControlRow: React.FC<IObjectTabControlRowProps> = ({ bookmark, className, objectType }) => {
  const dispatch = useAppDispatch();
  const refButton = useRef<HTMLButtonElement>(null);
  const refSidebar = useRef<LeftSideBarHandleType>(null);

  const activeObjectTypeTab = bookmark.objects?.activeObjectTypeTab;
  const searchValue = bookmark.objects?.searchValue ? bookmark.objects.searchValue : '';
  const objectsData = bookmark.objects?.objectsData;
  const columns = bookmark.tableColumns ? bookmark.tableColumns : [];

  const onChange = (searchValue: string) => {
    if (bookmark.hash) {
      dispatch(setObjectsSearchValue({ bookmarkHash: bookmark.hash, searchValue }));
    }
  };

  const onFind = () => {
    if (objectType) {
      const queryParams = getObjectsQueryParams({
        bookmark,
        objectTypeTab: objectType,
        objectsData,
        searchValue,
        resetPagination: true,
      });
      dispatch(getObjectsThunk({ bookmark, queryParams, objectType }));
    } else if (activeObjectTypeTab) {
      const queryParams = getObjectsQueryParams({
        bookmark,
        objectTypeTab: activeObjectTypeTab,
        objectsData,
        searchValue,
        resetPagination: true,
      });
      dispatch(getObjectsThunk({ bookmark, queryParams, objectType: activeObjectTypeTab }));
    }
  };

  const openSidebarClick = () => {
    refSidebar.current?.openSidebar();
  };

  const closeSidebarClick = () => {
    refSidebar.current?.closeSidebar();
  };

  const setColumns = (columns: (object & TableColumnType)[]) => {
    dispatch(setBookmarkTableColumns({ bookmarkHash: bookmark.hash, tableColumns: columns }));
  };

  return (
    <div className={`${classes.container} ${className ? className : ''}`}>
      <SearchRow
        value={searchValue}
        onChange={onChange}
        onSelect={onFind}
        showSearchHints={false}
        setShowSearchHints={() => {}}
        placeHolder={'Поиск по объектам'}
        isCatalogSearchView={true}
        withoutSearchHints={true}
      />
      <Button
        label={'Редактировать'}
        ref={refButton}
        view="ghost"
        size={'s'}
        iconLeft={IconEdit}
        onClick={() => {}}
        className={classes.buttonEdit}
        disabled={true}
        onlyIcon={true}
      />
      <Button
        ref={refButton}
        label="Настройки"
        view="ghost"
        size={'s'}
        iconLeft={IconSettings}
        onClick={openSidebarClick}
      />
      <LeftSideBar ref={refSidebar} refButton={refButton}>
        <TableSettings
          closeSidebarClick={closeSidebarClick}
          columns={columns}
          setColumns={setColumns}
          hideFooter={true}
        />
      </LeftSideBar>
    </div>
  );
};

export default ObjectTabControlRow;
