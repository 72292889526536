import React from 'react';

import classes from './LaunchHistoryStatusBadge.module.css';
import { Text } from '@consta/uikit/Text';
import { ProgressLine } from '@consta/uikit/ProgressLine';
import { Badge } from '@consta/uikit/Badge';

interface ILaunchHistoryStatusBadgeProps {
  status?: 'Ошибка' | 'Успешно' | 'Сканирование';
}

const LaunchHistoryStatusBadge: React.FC<ILaunchHistoryStatusBadgeProps> = ({ status }) => {
  switch (status) {
    case 'Сканирование':
      return (
        <div className={classes.containerScanning}>
          <Text className={classes.titleScanning}>{'сканирование'}</Text>
          <ProgressLine size="s" />
        </div>
      );
    case 'Ошибка':
      return (
        <div className={classes.container}>
          <Badge minified status="error" label="Ошибка" size="s" />
          <Text className={classes.title}>{'Ошибка'}</Text>
        </div>
      );
    case 'Успешно':
      return (
        <div className={classes.container}>
          <Badge minified status="success" label="Успешно" size="s" />
          <Text className={classes.title}>{'Успешно'}</Text>
        </div>
      );
    default:
      return null;
  }
};

export default LaunchHistoryStatusBadge;
