import React from 'react';
import { Children } from 'react';

import classes from './LayoutOrdinaryPage.module.css';

type PropsType = {
  children: React.ReactElement | React.ReactElement[];
  className?: string;
};

const LayoutOrdinaryPage: React.FC<PropsType> = ({ className, children }) => {
  return (
    <div className={`${classes.container} ${className ? className : ''}`}>
      <div className={classes.contentContainer}>{Children.map(children, (child) => child)}</div>
    </div>
  );
};

export default LayoutOrdinaryPage;
