import React from 'react';

import classes from './Content1CAttributeByTabs.module.css';
import { useAppSelector } from '../../../../../hooks/hooks';
import { selectorCatalogsIsLoadingBookmarkData } from '../../../../../store/catalogSlice';
import { Loader } from '@consta/uikit/Loader';
import { BookmarkType } from '../../../../../types/types';
import { TABS_1C_ATTRIBUTE } from '../Card1CAttributeContent';
import { CardTabType } from '../../../../../Components/CardTabsRow/CardTabsRow';
import Odata1CAttributeCommonInfoTab from './Odata1CAttributeCommonInfoTab/Odata1CAttributeCommonInfoTab';

interface IContentRdbByTabsProps {
  activeTab?: CardTabType;
  bookmark: BookmarkType;
}

const Content1CAttributeByTabs: React.FC<IContentRdbByTabsProps> = ({ activeTab, bookmark }) => {
  const isCommonInfoTab = activeTab?.label === TABS_1C_ATTRIBUTE[0].label;
  const isLoadingBookmarkData = useAppSelector(selectorCatalogsIsLoadingBookmarkData);

  return (
    <div className={classes.container}>
      {isLoadingBookmarkData ? (
        <Loader style={{ marginTop: '32px' }} />
      ) : (
        isCommonInfoTab && <Odata1CAttributeCommonInfoTab bookmark={bookmark} />
      )}
    </div>
  );
};

export default Content1CAttributeByTabs;
