import React, { useState } from 'react';
import classes from './FilterData.module.css';
import { ChoiceGroup } from '@consta/uikit/ChoiceGroup';
import { DatePicker } from '@consta/uikit/DatePicker';

import './constaFix.css';

export type DataRangeType = [Date | undefined, Date | undefined];
export type DataRangeStrType = [string | undefined, string | undefined];

const items = ['Одна дата', 'Диапазон'];

export type DataFilterType = {
  name: string;
  title: string;
  dataRange: DataRangeType;
};

export type DataFilterStrType = {
  name: string;
  title: string;
  dataRange: DataRangeStrType;
};

interface IFilterDataProps {
  dataRange: DataRangeType;
  onChangeDataRange: (dataRange: DataRangeType) => void;
}

const FilterData: React.FC<IFilterDataProps> = ({ dataRange, onChangeDataRange }) => {
  const [value, setValue] = useState<string | null>(dataRange[1] ? items[1] : items[0]);

  const onChangeValue = ({ value }: { value: string }) => {
    setValue(() => {
      onChangeDataRange([dataRange[0], undefined]);
      return value;
    });
  };

  return (
    <div className={classes.container}>
      <ChoiceGroup
        view={'ghost'}
        size={'s'}
        value={value}
        onChange={onChangeValue}
        items={items}
        getItemLabel={(item) => item}
        multiple={false}
        name="ChoiceDataType"
        className={classes.choiceGroup}
      />
      <div className={classes.row}>
        {value === items[0] ? (
          <DatePicker
            size="s"
            type="date"
            value={dataRange?.[0]}
            onChange={({ value }) => onChangeDataRange([value!, undefined])}
            style={{ width: '100%' }}
          />
        ) : (
          <DatePicker
            size="s"
            type="date-range"
            value={dataRange}
            onChange={({ value }) => onChangeDataRange(value ? [value[0], value[1]] : [undefined, undefined])}
            style={{ width: '100%' }}
          />
        )}
      </div>
    </div>
  );
};

export default FilterData;
