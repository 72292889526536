import React from 'react';

import classes from './EditSettingsForm.module.css';
import RightSideBarTextField from '../../../../../../../Components/RightSideBarTextField/RightSideBarTextField';
import { useAppDispatch, useAppSelector } from '../../../../../../../hooks/hooks';
import {
  selectorEditConnection,
  selectorIsRequiredEditConnectionPassword,
  selectorIsRequiredEditConnectionUser,
  setEditConnectionPassword,
  setEditConnectionUser,
} from '../../../../../../../store/editConnectionSlice';
import { Text } from '@consta/uikit/Text';

interface IEditSettingsFormProps {}

const EditSettingsForm: React.FC<IEditSettingsFormProps> = () => {
  const dispatch = useAppDispatch();
  const editConnection = useAppSelector(selectorEditConnection);
  const isRequiredEditConnectionUser = useAppSelector(selectorIsRequiredEditConnectionUser);
  const isRequiredEditConnectionPassword = useAppSelector(selectorIsRequiredEditConnectionPassword);

  const isOdata = editConnection?.source_type?.card_view === 'odata';
  const isOracle = editConnection?.source_type?.card_view === 'oracle';

  const onChangeConnectionUser = (val: string | null) => {
    dispatch(setEditConnectionUser(val));
  };

  const onChangeConnectionPassword = (val: string | null) => {
    dispatch(setEditConnectionPassword(val));
  };

  return (
    <div className={classes.container}>
      {isOdata ? (
        <div className={classes.sectionItem}>
          <Text className={classes.sectionItemTitle}>{'URL'}</Text>
          <Text className={classes.sectionItemValue}>{editConnection?.url}</Text>
        </div>
      ) : (
        <div className={classes.sectionItem}>
          <Text className={classes.sectionItemTitle}>{'IP адрес/хост'}</Text>
          <Text className={classes.sectionItemValue}>{editConnection?.host}</Text>
        </div>
      )}
      {!isOdata && (
        <div className={classes.sectionItem}>
          <Text className={classes.sectionItemTitle}>{'Порт'}</Text>
          <Text className={classes.sectionItemValue}>{editConnection?.port}</Text>
        </div>
      )}
      {isOracle && (
        <div className={classes.sectionItem}>
          <Text className={classes.sectionItemTitle}>{'Экземпляр'}</Text>
          <Text className={classes.sectionItemValue}>{editConnection?.instance}</Text>
        </div>
      )}
      <RightSideBarTextField
        label={'Сменить пользователя'}
        value={editConnection?.user}
        onChange={onChangeConnectionUser}
        placeholder={'Пользователь'}
        isRequired={isRequiredEditConnectionUser}
      />
      <RightSideBarTextField
        label={'Сменить пароль'}
        value={editConnection?.password}
        onChange={onChangeConnectionPassword}
        placeholder={'Укажите пароль'}
        isRequired={isRequiredEditConnectionPassword}
        isPassword={true}
      />
    </div>
  );
};

export default EditSettingsForm;
