import React, { useEffect } from 'react';

import classes from './CreateProgressStepSelector.module.css';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/hooks';
import {
  clearCreatingNewConnectionMessage,
  selectorActiveStepIndex,
  selectorCreatingNewConnectionMessage,
} from '../../../../../store/createNewConnectionSlice';
import CreateConnectionStepOne from './CreateConnectionStepOne/CreateConnectionStepOne';
import CreateConnectionStepTwo from './CreateConnectionStepTwo/CreateConnectionStepTwo';
import CreateConnectionStepThree from './CreateConnectionStepThree/CreateConnectionStepThree';
import CreateConnectionStepFour from './CreateConnectionStepFour/CreateConnectionStepFour';
import CreateConnectionStepFive from './CreateConnectionStepFive/CreateConnectionStepFive';
import { pushSnackBarItemConnections } from '../../../../../store/connectionsSlice';

const CreateProgressStepSelector: React.FC = () => {
  const dispatch = useAppDispatch();
  const activeStepIndex = useAppSelector(selectorActiveStepIndex);
  const creatingNewConnectionMessage = useAppSelector(selectorCreatingNewConnectionMessage);

  useEffect(() => {
    if (creatingNewConnectionMessage) {
      const isErrorMessage =
        creatingNewConnectionMessage.toLowerCase().includes('ошибка') ||
        creatingNewConnectionMessage.toLowerCase().includes('неверный');
      dispatch(
        pushSnackBarItemConnections({
          key: creatingNewConnectionMessage,
          message: creatingNewConnectionMessage,
          status: isErrorMessage ? 'alert' : 'success',
          autoClose: true,
        })
      );
    }
    dispatch(clearCreatingNewConnectionMessage());
  }, [creatingNewConnectionMessage, dispatch]);

  return (
    <div className={classes.container}>
      {activeStepIndex === 0 ? (
        <CreateConnectionStepOne />
      ) : activeStepIndex === 1 ? (
        <CreateConnectionStepTwo />
      ) : activeStepIndex === 2 ? (
        <CreateConnectionStepThree />
      ) : activeStepIndex === 3 ? (
        <CreateConnectionStepFour />
      ) : activeStepIndex === 4 ? (
        <CreateConnectionStepFive />
      ) : null}
    </div>
  );

  // return (
  //   <div className={classes.container}>
  //     {activeStepIndex === 0 ? (
  //       <CreateConnectionStepOne />
  //     ) : activeStepIndex === 1 ? (
  //       <CreateConnectionStepTwo />
  //     ) : activeStepIndex === 2 ? (
  //       <CreateConnectionStepThree />
  //     ) : activeStepIndex === 3 ? (
  //       <CreateConnectionStepFour />
  //     ) : activeStepIndex === 4 ? (
  //       <CreateConnectionStepFive />
  //     ) : null}
  //   </div>
  // );
};

export default CreateProgressStepSelector;
