import React from 'react';

import classes from './HierarchyCell.module.css';
import { CatalogItemType } from '../../types/types';
import iconTree from '../../assets/CatalogPage/iconTree.svg';
import { Text } from '@consta/uikit/Text';
import iconMore from '../../assets/CatalogPage/iconMore.svg';

interface IHierarchyCellProps {
  item: CatalogItemType;
}

const HierarchyCell: React.FC<IHierarchyCellProps> = ({ item }) => {
  return (
    <div className={classes.hierarchy}>
      {item.level_1 && (
        <div className={classes.hierarchyRow}>
          <img src={iconTree} alt="hierarchy top" className={classes.ico} />
          <Text className={classes.hierarchyText}>{item.level_1 || ''}</Text>
        </div>
      )}
      {item.level_2 && (
        <div className={classes.hierarchyRow}>
          <img src={iconMore} alt="hierarchy first" className={classes.icoFirst} />
          <Text className={classes.hierarchyText}>{item.level_2}</Text>
        </div>
      )}
      {item.level_3 && (
        <div className={classes.hierarchyRow}>
          <img src={iconMore} alt="hierarchy second" className={classes.icoSecond} />
          <Text className={classes.hierarchyText}>{item.level_3}</Text>
        </div>
      )}
    </div>
  );
};

export default HierarchyCell;
