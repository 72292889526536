import React from 'react';

import classes from './ContentTableViewTableFunctionByTabs.module.css';
import TableViewTableFunctionCommonInfoTab from './TableViewTableFunctionCommonInfoTab/TableViewTableFunctionCommonInfoTab';
import TableViewTableFunctionAttributesTab from './TableViewTableFunctionAttributesTab/TableViewTableFunctionAttributesTab';
import { useAppSelector } from '../../../../../hooks/hooks';
import { selectorCatalogsIsLoadingBookmarkData } from '../../../../../store/catalogSlice';
import { Loader } from '@consta/uikit/Loader';
import { BookmarkType } from '../../../../../types/types';
import { TABS_TABLE_VIEW_TABLE_FUNCTION } from '../CardTableViewTableFunctionContent';
import { CardTabType } from '../../../../../Components/CardTabsRow/CardTabsRow';
import SectionInDevelopment from '../../../../../Components/SectionInDevelopment/SectionInDevelopment';
import UnifyCardDataPreview from '../../../../../Components/UnifyCardDataPreview/UnifyCardDataPreview';
import UnifyScriptPreview from '../../../../../Components/UnifyScriptPreview/UnifyScriptPreview';

interface IContentRdbByTabsProps {
  activeTab?: CardTabType;
  bookmark: BookmarkType;
}

const ContentTableViewTableFunctionByTabs: React.FC<IContentRdbByTabsProps> = ({ activeTab, bookmark }) => {
  const isCommonInfoTab = activeTab?.label === TABS_TABLE_VIEW_TABLE_FUNCTION[0].label;
  const isAttributesTab = activeTab?.label === TABS_TABLE_VIEW_TABLE_FUNCTION[1].label;
  const isDataPreviewTab = activeTab?.label === TABS_TABLE_VIEW_TABLE_FUNCTION[2].label;
  const isScriptTab = activeTab?.label === TABS_TABLE_VIEW_TABLE_FUNCTION[3].label;
  const isLoadingBookmarkData = useAppSelector(selectorCatalogsIsLoadingBookmarkData);
  const isPreviewTab = activeTab?.label === 'Предпросмотр';

  return (
    <div className={classes.container}>
      {isLoadingBookmarkData ? (
        <div style={{ display: 'flex', width: '100%' }}>
          <Loader />
        </div>
      ) : isCommonInfoTab ? (
        <TableViewTableFunctionCommonInfoTab bookmark={bookmark} />
      ) : isAttributesTab ? (
        <TableViewTableFunctionAttributesTab bookmark={bookmark} />
      ) : isDataPreviewTab ? (
        <UnifyCardDataPreview bookmark={bookmark} isPreviewTab={isPreviewTab} />
      ) : isScriptTab ? (
        <UnifyScriptPreview bookmark={bookmark} />
      ) : (
        <SectionInDevelopment />
      )}
    </div>
  );
};

export default ContentTableViewTableFunctionByTabs;
