import React from 'react';

import classes from './CPContentSelector.module.css';
import { useAppSelector } from '../../../hooks/hooks';
import { selectorConnectionsActiveViewSelector } from '../../../store/connectionsSlice';
import ConnectionsContent from './ConnectionsContent/ConnectionsContent';
import LaunchHistoryContent from './LaunchHistoryContent/LaunchHistoryContent';
import { CP_VIEW_SELECTOR_ITEMS } from '../../../utils/constants';
import { LeftSideBarHandleType } from '../../../shared-front/LeftSideBar/LeftSideBar';
import { CPRightSideBarEnum } from '../ConnectionsPage';

interface ICPContentSelectorProps {
  refSidebar: React.RefObject<LeftSideBarHandleType>;
  setRightSideBarMode: (mode: CPRightSideBarEnum | undefined) => void;
}

const CPContentSelector: React.FC<ICPContentSelectorProps> = ({ refSidebar, setRightSideBarMode }) => {
  const activeViewSelector = useAppSelector(selectorConnectionsActiveViewSelector);
  const isConnectionsContent = activeViewSelector === CP_VIEW_SELECTOR_ITEMS[0].name;

  return (
    <div className={classes.container}>
      {isConnectionsContent ? (
        <ConnectionsContent refSidebar={refSidebar} setRightSideBarMode={setRightSideBarMode} />
      ) : (
        <LaunchHistoryContent />
      )}
    </div>
  );
};

export default CPContentSelector;
