import { FiltersGroupType } from '../Components/FiltersGroup/FiltersGroup';
import { FilterTypeEnum, GroupFilterTagType } from '../types/types';

export const getActiveGroupFilterTags = (groupFilters: FiltersGroupType[]) => {
  const activeGroupFilters: GroupFilterTagType[] = [];
  groupFilters.forEach((groupFilter) => {
    groupFilter.groupFilters.forEach((filter) => {
      if (filter.isChecked) {
        activeGroupFilters.push({
          filterType: FilterTypeEnum.groupFilter,
          filterName: filter.name,
          filterTile: filter.title,
          filterIsChecked: true,
        });
      }
    });
  });
  return activeGroupFilters;
};
