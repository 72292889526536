import React from 'react';

import classes from './ConnectionsTableObjects.module.css';
import { ConnectionType } from '../../../../../../../types/types';
import TableCellWithDropDown from '../../../../../../../shared-front/Table/TableCellWithDropDown/TableCellWithDropDown';
import { useAppDispatch, useAppSelector } from '../../../../../../../hooks/hooks';
import {
  selectorConnectionsUuidWithOpenedAssets,
  setActiveViewSelector,
  toggleDropDownConnectionsUuidWithOpenedAssets,
} from '../../../../../../../store/connectionsSlice';
import { Button } from '@consta/uikit/Button';
import icoTimer from '../../../../../../../assets/ConnectionsPage/icoTimer.svg';
import CustomHoverToolTip from '../../../../../../../Components/CustomHoverToolTip/CustomHoverToolTip';
import { setSelectorSelectedConnectionUuId } from '../../../../../../../store/launchHistorySlice';
import { CP_VIEW_SELECTOR_ITEMS } from '../../../../../../../utils/constants';

interface IConnectionsTableObjectsProps {
  connection: ConnectionType;
  hoveredConnectionUuid?: string | undefined;
}

const ConnectionsTableObjects: React.FC<IConnectionsTableObjectsProps> = ({ connection, hoveredConnectionUuid }) => {
  const dispatch = useAppDispatch();
  const connectionsUuidWithOpenedAssets = useAppSelector(selectorConnectionsUuidWithOpenedAssets);

  const onClickToggleDropDown = () => {
    dispatch(toggleDropDownConnectionsUuidWithOpenedAssets(connection._uuid));
  };

  const onClickGoToLaunchHistory = (event: React.MouseEvent<Element, MouseEvent>) => {
    event.stopPropagation();
    dispatch(setSelectorSelectedConnectionUuId(connection._uuid));
    dispatch(setActiveViewSelector(CP_VIEW_SELECTOR_ITEMS[1].name));
  };

  return (
    <div className={classes.container}>
      {!connection.assets?.length ? (
        '-'
      ) : connection.assets.length === 1 ? (
        connection.assets[0].name ? (
          connection.assets[0].name
        ) : (
          'no name'
        )
      ) : (
        <TableCellWithDropDown
          cellValue={connection.assets.length}
          isOpenDropDown={connectionsUuidWithOpenedAssets.includes(connection._uuid)}
          onClickToggleDropDown={onClickToggleDropDown}
        />
      )}
      {hoveredConnectionUuid === connection._uuid && (
        <CustomHoverToolTip title={`История запусков`} direction={'downCenter'}>
          <Button
            iconRight={() => <img src={icoTimer} alt="icoTimer" />}
            onClick={onClickGoToLaunchHistory}
            onlyIcon
            view={'ghost'}
            size={'xs'}
            style={{ marginRight: '18px' }}
          />
        </CustomHoverToolTip>
      )}
    </div>
  );
};

export default ConnectionsTableObjects;
