import React, { useEffect, useState } from 'react';
import classes from './FiltersContainer.module.css';
import { Text } from '@consta/uikit/Text';
import { Button } from '@consta/uikit/Button';
import { DataFilterType } from '../FilterData/FilterData';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import {
  getCatalogFilterDictionaryThunk,
  selectorCatalogDataFilters,
  selectorCatalogGroupFilters,
  selectorCatalogIsFiltersDictionaryAsked,
  selectorCatalogMultiFilters,
  setDataFiltersStore,
  setGroupFiltersStore,
  setIsFiltersDictionaryAsked,
  setMultiFiltersStore,
} from '../../store/catalogSlice';
import MultiFiltersList from './MultiFiltersList/MultiFiltersList';
import DataFiltersList from './DataFiltersList/DataFiltersList';
import { MultiFilterType } from '../FilterWithScrollAndSearch/FilterWithScrollAndSearch';
import { FiltersGroupType } from '../FiltersGroup/FiltersGroup';
import GroupFiltersList from './GroupFiltersList/GroupFiltersList';
import { getActiveMultiFiltersAmount } from '../../utils/getActiveMultiFiltersAmount';
import { getActiveDataFiltersAmount } from '../../utils/getActiveDataFiltersAmount';
import { getActiveGroupFiltersAmount } from '../../utils/getActiveGroupFiltersAmount';
import { clearAllMultiFilters } from '../../utils/clearAllMultiFilters';
import { clearAllDataFilters } from '../../utils/clearAllDataFilters';
import { clearAllGroupFilters } from '../../utils/clearAllGroupFilters';
import { dataFiltersDateToDataFiltersStr } from '../../utils/dataFiltersDateToDataFiltersStr';
import { useGetTechMetaDocuments } from '../../hooks/useGetTechMetaDocuments';

interface IFiltersContainerProps {
  closeSidebarClick: () => void;
}

const FiltersContainer: React.FC<IFiltersContainerProps> = ({ closeSidebarClick }) => {
  const dispatch = useAppDispatch();

  const multiFiltersStore = useAppSelector(selectorCatalogMultiFilters);
  const isFiltersDictionaryAsked = useAppSelector(selectorCatalogIsFiltersDictionaryAsked);
  const getTechMetaDocuments = useGetTechMetaDocuments();

  const [multiFilters, setMultiFilters] = useState<MultiFilterType[]>([]);

  useEffect(() => {
    if (!isFiltersDictionaryAsked && multiFiltersStore.length > 0) {
      dispatch(setIsFiltersDictionaryAsked(true));
      multiFiltersStore.filter((filter) => dispatch(getCatalogFilterDictionaryThunk(filter.name)));
    }
    setMultiFilters(multiFiltersStore);
  }, [dispatch, multiFiltersStore, isFiltersDictionaryAsked]);

  const dataFiltersStore = useAppSelector(selectorCatalogDataFilters);
  const [dataFilters, setDataFilters] = useState<DataFilterType[]>([]);
  useEffect(() => {
    setDataFilters(dataFiltersStore);
  }, [dataFiltersStore]);

  const groupFiltersStore = useAppSelector(selectorCatalogGroupFilters);
  const [groupFilters, setGroupFilters] = useState<FiltersGroupType[]>([]);
  useEffect(() => {
    setGroupFilters(groupFiltersStore);
  }, [groupFiltersStore]);

  const onApplyClick = () => {
    dispatch(setMultiFiltersStore(multiFilters));
    dispatch(setDataFiltersStore(dataFiltersDateToDataFiltersStr(dataFilters)));
    dispatch(setGroupFiltersStore(groupFilters));
    getTechMetaDocuments({ multiFilters, dataFilters, groupFilters });
    closeSidebarClick();
  };

  const onClearAll = () => {
    clearAllMultiFilters(multiFilters, setMultiFilters);
    clearAllDataFilters(dataFilters, setDataFilters);
    clearAllGroupFilters(groupFilters, setGroupFilters);
  };

  const allActiveFiltersAmount =
    getActiveDataFiltersAmount(dataFilters) +
    getActiveMultiFiltersAmount(multiFilters) +
    getActiveGroupFiltersAmount(groupFilters);

  return (
    <div className={classes.container}>
      <div className={classes.topRow}>
        <div className={classes.titleContainer}>
          <Text className={classes.title}>{`Фильтры`}</Text>
          {allActiveFiltersAmount > 0 && (
            <div className={classes.filterAmountRow}>
              <Text className={classes.amount}>{`(${allActiveFiltersAmount})`}</Text>
              <Button
                view={'ghost'}
                label={'Сбросить фильтры'}
                size={'s'}
                style={{ width: '193px' }}
                onClick={onClearAll}
              />
            </div>
          )}
        </div>
      </div>
      <div className={classes.delimiter} />
      <div className={classes.list}>
        <MultiFiltersList multiFilters={multiFilters} setMultiFilters={setMultiFilters} />
        <DataFiltersList dataFilters={dataFilters} setDataFilters={setDataFilters} />
        <GroupFiltersList groupFilters={groupFilters} setGroupFilters={setGroupFilters} />
      </div>
      <div className={classes.delimiter} />
      <div className={classes.bottomButtons}>
        <Button size={'s'} view={'ghost'} label={'Отменить'} style={{ width: '193px' }} onClick={closeSidebarClick} />
        <Button size={'s'} label={'Применить'} style={{ width: '193px' }} onClick={onApplyClick} />
      </div>
    </div>
  );
};

export default FiltersContainer;
