import React from 'react';

import classes from './ISPRightSideBarModalDelete.module.css';
import { Text } from '@consta/uikit/Text';
import { Button } from '@consta/uikit/Button';
import { Modal } from '@consta/uikit/Modal';
import { IconTrash } from '@consta/uikit/IconTrash';

interface IISPRightSideBarModalDeleteProps {
  isModalOpen: boolean;
  setIsModalOpen: (val: boolean) => void;
  modalDeleteMessage: string;
  modalDeleteTitle: string;
  onConfirmDelete: () => void;
  onCancelDelete?: () => void;
}

const ISPRightSideBarModalDelete: React.FC<IISPRightSideBarModalDeleteProps> = ({
  isModalOpen,
  setIsModalOpen,
  modalDeleteMessage,
  modalDeleteTitle,
  onConfirmDelete,
  onCancelDelete,
}) => {
  const cancelDeleteHandler = () => {
    onCancelDelete && onCancelDelete();
    setIsModalOpen(false);
  };

  return (
    <Modal
      isOpen={isModalOpen}
      hasOverlay
      onClickOutside={() => setIsModalOpen(false)}
      onEsc={() => setIsModalOpen(false)}
      style={{ zIndex: 999 }}
      className={classes.container}
    >
      <Text className={classes.title}>{modalDeleteTitle}</Text>
      <Text className={classes.text}>{modalDeleteMessage}</Text>
      <div className={classes.delimiter} />
      <div className={classes.buttonGroup}>
        <Button size="m" view="ghost" label="Отменить" onClick={cancelDeleteHandler} />
        <Button
          size="m"
          view="primary"
          label="Удалить"
          onClick={onConfirmDelete}
          iconLeft={IconTrash}
          style={{ backgroundColor: '#eb3333' }}
        />
      </div>
    </Modal>
  );
};

export default ISPRightSideBarModalDelete;
