import { FilterBarItemType } from '../shared-front/FilterBar/FilterBar';
import { DataFilterType } from '../Components/FilterData/FilterData';

export const getNewActiveDataFiltersByFilterBarTags = (
  dataFilters: DataFilterType[],
  tagItems: FilterBarItemType[]
): DataFilterType[] => {
  const activeDataFiltersTagUuids = tagItems
    .filter((tagItem) => tagItem.uuid.split('-*-')[0] === 'dataFilter')
    .map((item) => item.uuid.split('-*-')[1]);

  return dataFilters.map((dataFilter) => {
    return {
      ...dataFilter,
      dataRange: activeDataFiltersTagUuids.includes(dataFilter.name) ? dataFilter.dataRange : [undefined, undefined],
    };
  });
};
