import React, { useEffect, useRef } from 'react';

import classes from './CreateConnectionStepThree.module.css';
import RightSideBarTextField from '../../../../../../Components/RightSideBarTextField/RightSideBarTextField';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/hooks';
import {
  selectorActiveSourceType,
  selectorConnectionDescription,
  selectorConnectionName,
  selectorConnectionUrl,
  selectorIpAddress,
  selectorIsRequiredConnectionName,
  setConnectionDescription,
  setConnectionName,
} from '../../../../../../store/createNewConnectionSlice';
import { TextField } from '@consta/uikit/TextField';

interface ICreateConnectionStepThreeProps {}

const CreateConnectionStepThree: React.FC<ICreateConnectionStepThreeProps> = () => {
  const dispatch = useAppDispatch();
  const valueIpAddress = useAppSelector(selectorIpAddress);
  const valueConnectionName = useAppSelector(selectorConnectionName);
  const valueConnectionUrl = useAppSelector(selectorConnectionUrl);
  const activeSourceType = useAppSelector(selectorActiveSourceType);
  const valueConnectionDescription = useAppSelector(selectorConnectionDescription);
  const isRequiredConnectionName = useAppSelector(selectorIsRequiredConnectionName);

  const onChangeConnectionName = (value: string | null) => {
    dispatch(setConnectionName(value));
  };

  const onChangeConnectionDescription = ({ value }: { value: string | null }) => {
    dispatch(setConnectionDescription(value));
  };

  const refIsSetDefaultConnectionName = useRef(false);

  useEffect(() => {
    if (!valueConnectionName && !refIsSetDefaultConnectionName.current) {
      refIsSetDefaultConnectionName.current = true;
      dispatch(
        setConnectionName(`dc_src_${activeSourceType?.slug}_${valueIpAddress ? valueIpAddress : valueConnectionUrl}`)
      );
    }
  }, [dispatch, activeSourceType?.slug, valueConnectionUrl, valueIpAddress, valueConnectionName]);

  return (
    <div className={classes.container}>
      <RightSideBarTextField
        required={true}
        label={'Наименование подключения'}
        value={valueConnectionName}
        onChange={onChangeConnectionName}
        placeholder={'Наименование подключения'}
        isRequired={isRequiredConnectionName}
      />
      <TextField
        label={'Описание'}
        onChange={onChangeConnectionDescription}
        value={valueConnectionDescription}
        type="textarea"
        rows={7}
        placeholder="Описание"
        style={{ marginBottom: '24px' }}
      />
    </div>
  );
};

export default CreateConnectionStepThree;
