import React, { ReactNode } from 'react';
import classes from './TableWrapper.module.css';
import { Text } from '@consta/uikit/Text';
import { getIsColumnToShow } from '../../utils/getIsColumnToShow';
import { TableColumnType } from '../../shared-front/Table/Table';

interface ITableWrapperProps {
  columns: TableColumnType[];
  children: ReactNode;
  isHierarchyOpen?: boolean;
  height?: string;
}

const TableWrapper: React.FC<ITableWrapperProps> = ({ columns, children, isHierarchyOpen, height }) => {
  const isColumnToShow = getIsColumnToShow(columns);

  return (
    <div className={classes.container}>
      <div className={classes.tableContainer} style={{ height: height ? height : undefined }}>
        {isColumnToShow ? (
          <div className={`${classes.tableWrapper} ${isHierarchyOpen ? classes.tableWrapperOpenHierarchy : ''}`}>
            {children}
          </div>
        ) : (
          <div className={`${classes.nothingToShow} ${isHierarchyOpen ? classes.tableWrapperOpenHierarchy : ''}`}>
            <Text className={classes.nothingToShowTitle}>{`Все столбцы таблицы скрыты в\nнастройках`}</Text>
          </div>
        )}
      </div>
    </div>
  );
};

export default TableWrapper;
