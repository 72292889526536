import React from 'react';

import classes from './ConnectionsContentPaginationRow.module.css';
import Pagination from '../../../../../shared-front/Pagination/Pagination';
import { useAppSelector } from '../../../../../hooks/hooks';
import { selectorConnections } from '../../../../../store/connectionsSlice';
import { useGetConnections } from '../../../../../hooks/useGetConnections';

interface IConnectionsContentPaginationRowProps {}

const ConnectionsContentPaginationRow: React.FC<IConnectionsContentPaginationRowProps> = () => {
  const getConnections = useGetConnections();
  const meta = useAppSelector(selectorConnections)?.meta;

  const onChangePagination = (queryPagination: string) => {
    getConnections({ queryPagination });
  };

  return (
    <div className={classes.container}>
      <Pagination
        currentPageSize={meta?.per_page}
        currentPage={meta?.current_page}
        total={meta?.total}
        className={classes.pagination}
        onChangePagination={onChangePagination}
        lastPage={meta?.last_page}
      />
    </div>
  );
};

export default ConnectionsContentPaginationRow;
