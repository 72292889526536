import { ConnectionDetailType } from '../../types/types';

export type GeneralValidationErrorType = 'requiredName';

export const validateGeneralForEditConnection = (editConnection: ConnectionDetailType) => {
  let validationErrors: GeneralValidationErrorType[] = [];
  if (!editConnection.name) {
    validationErrors.push('requiredName');
  }
  if (!!validationErrors.length) {
    return validationErrors;
  } else {
    return undefined;
  }
};
