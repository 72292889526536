import React from 'react';

import classes from './LaunchHistoryContent.module.css';
import LaunchHistoryControlRow from './LaunchHistoryControlRow/LaunchHistoryControlRow';
import Delimiter from '../../../../shared-front/Delimiter/Delimiter';
import LaunchHistoryContentContainer from './LaunchHistoryContentContainer/LaunchHistoryContentContainer';
import LaunchHistoryPaginationRow from './LaunchHistoryPaginationRow/LaunchHistoryPaginationRow';

interface ILaunchHistoryContentProps {}

const LaunchHistoryContent: React.FC<ILaunchHistoryContentProps> = () => {
  return (
    <div className={classes.container}>
      <div className={classes.growContainer}>
        <LaunchHistoryControlRow />
        <Delimiter />
        <LaunchHistoryContentContainer />
        <Delimiter />
        <LaunchHistoryPaginationRow />
      </div>
    </div>
  );
};

export default LaunchHistoryContent;
