import React, { useState } from 'react';

import classes from './EditCheckConnectionRow.module.css';
import { Button } from '@consta/uikit/Button';
import { ProgressSpin } from '@consta/uikit/ProgressSpin';
import CustomBlueTextButton from '../../../../../../../Components/CustomBlueTextButton/CustomBlueTextButton';
import { Badge } from '@consta/uikit/Badge';
import { useAppDispatch, useAppSelector } from '../../../../../../../hooks/hooks';
import {
  selectorEditConnection,
  selectorIsEditConnectionChecked,
  setIsEditConnectionChecked,
} from '../../../../../../../store/editConnectionSlice';
import { CheckNewConnectionParamsType } from '../../../../../../../api/createNewConnectionApi';
import { serverApi } from '../../../../../../../api/serverApi';
import { pushSnackBarItemConnections } from '../../../../../../../store/connectionsSlice';
import { getRejectedValue } from '../../../../../../../store/rejectedValueHelper';
import { Text } from '@consta/uikit/Text';

interface ICheckEditConnectionRowProps {}

export let abortControllerCheckEditedConnection = new AbortController();

const EditCheckConnectionRow: React.FC<ICheckEditConnectionRowProps> = () => {
  const dispatch = useAppDispatch();
  const editConnection = useAppSelector(selectorEditConnection);
  const isEditConnectionChecked = useAppSelector(selectorIsEditConnectionChecked);

  const [isConnectionOnChecking, setIsNewConnectionOnChecking] = useState(false);

  const checkEditConnection = async (params: CheckNewConnectionParamsType) => {
    if (!isConnectionOnChecking) {
      setIsNewConnectionOnChecking(true);
      if (isEditConnectionChecked) {
        dispatch(setIsEditConnectionChecked(false));
      }
      try {
        const checkConnectionResult = await serverApi.checkNewConnection(params);
        if (!checkConnectionResult.data) {
          setIsNewConnectionOnChecking(false);
          dispatch(
            pushSnackBarItemConnections({
              key: params.system + params.source_type + params.connector_type,
              message: 'Ошибка проверки подключения',
              status: 'alert',
              autoClose: true,
            })
          );
        } else {
          setIsNewConnectionOnChecking(false);
          dispatch(setIsEditConnectionChecked(true));
        }
      } catch (e: any) {
        if (e.name === 'CanceledError') {
          abortControllerCheckEditedConnection = new AbortController();
        } else if (e.response?.data?.message) {
          dispatch(
            pushSnackBarItemConnections({
              key: params.system + params.source_type + params.connector_type,
              message: `Ошибка проверки подключения\n-${e.response.data.message}`,
              status: 'alert',
              autoClose: true,
            })
          );
        } else {
          dispatch(
            pushSnackBarItemConnections({
              key: params.system + params.source_type + params.connector_type,
              message: getRejectedValue('Ошибка проверки подключения\n- ', e),
              status: 'alert',
              autoClose: true,
            })
          );
        }
        setIsNewConnectionOnChecking(false);
      }
    }
  };

  const onClickCheckConnection = async () => {
    if (editConnection) {
      dispatch(setIsEditConnectionChecked(false));
      setIsNewConnectionOnChecking(true);
      await checkEditConnection({
        system: editConnection.system?._uuid ?? '',
        source_type: editConnection.source_type?._uuid ?? '',
        connector_type: editConnection.connector_type?._uuid ?? '',
        host: editConnection.host ?? undefined,
        port: Number(editConnection.port) ?? undefined,
        user: editConnection.user ?? undefined,
        password: editConnection.password ?? undefined,
        instance: editConnection.instance ?? undefined,
        uuid: editConnection._uuid ?? undefined,
      });
    }
  };

  const onClickCancelCheckConnection = () => {
    abortControllerCheckEditedConnection.abort();
    setIsNewConnectionOnChecking(false);
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <Button
          size={'s'}
          label={'Проверить подключение'}
          onClick={onClickCheckConnection}
          disabled={isConnectionOnChecking || !(editConnection?.user && editConnection?.password)}
        />
        {isConnectionOnChecking ? (
          <div className={classes.buttonsGroup}>
            <ProgressSpin style={{ marginLeft: '8px', height: '26px' }} />
            <CustomBlueTextButton title={'Отмена'} onClick={onClickCancelCheckConnection} />
          </div>
        ) : (
          isEditConnectionChecked && (
            <div className={classes.badgeSection}>
              <Badge status={'success'} label={'Подключение актуально'} />
            </div>
          )
        )}
      </div>
      <Text className={classes.informer}>{'Для нового пользователя и пароля'}</Text>
    </div>
  );
};

export default EditCheckConnectionRow;
