import React from 'react';
import classes from './CardTabsRow.module.css';

import { Tabs } from '@consta/uikit/Tabs';
import { useAppSelector } from '../../hooks/hooks';
import { selectorCatalogsIsLoadingBookmarkData } from '../../store/catalogSlice';

export type CardTabType = {
  id: number;
  label: string;
  info?: string;
};

interface ITabsRdbRowProps {
  tabs: CardTabType[];
  activeTab?: CardTabType;
  setActiveTab: (value: CardTabType) => void;
}

const CardTabsRow: React.FC<ITabsRdbRowProps> = ({ activeTab, setActiveTab, tabs }) => {
  const isLoadingBookmarkData = useAppSelector(selectorCatalogsIsLoadingBookmarkData);

  const changeHandler = ({ value }: { value: CardTabType }) => {
    setActiveTab(value);
  };

  const renderItem = ({
    label,
    checked,
    onChange,
  }: {
    label: string;
    checked: boolean;
    onChange: (event: React.MouseEvent<HTMLDivElement>) => void;
  }) => (
    <div className={`${classes.tab} ${checked ? classes.tabActive : ''}`} onClick={onChange}>
      {label}
    </div>
  );

  return (
    <div className={classes.container}>
      <div className={classes.constantHeight}>
        {!isLoadingBookmarkData && !!tabs.length && (
          <div className={classes.wrapper}>
            <Tabs view={'clear'} value={activeTab} items={tabs} onChange={changeHandler} renderItem={renderItem} />
          </div>
        )}
      </div>
      <div className={classes.delimiter} />
    </div>
  );
};

export default CardTabsRow;
