import { HierarchyItemType } from '../../types/types';
import { selectInHierarchyItem } from './selectInHierarchyItem';

export const setIsSelectHierarchyItem = ({
  hierarchyItems,
  itemUuid,
  isChecked,
}: {
  hierarchyItems: HierarchyItemType[];
  itemUuid: string;
  isChecked: boolean;
}): HierarchyItemType[] => {
  return hierarchyItems.map((hierarchyItem) =>
    hierarchyItem.uuid === itemUuid
      ? { ...hierarchyItem, isChecked }
      : hierarchyItem.children
      ? {
          ...hierarchyItem,
          children: hierarchyItem.children.map((child) =>
            selectInHierarchyItem({ hierarchyItem: child, itemUuid, isChecked, isOpen: child.isOpen })
          ),
        }
      : hierarchyItem
  );
};
