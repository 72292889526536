import React, { useEffect } from 'react';

import classes from './UnifyScriptPreview.module.css';
import {
  BookmarkProcedureTriggerFunctionType,
  BookmarkTableViewTableFunctionType,
  BookmarkType,
} from '../../types/types';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { getBookmarkScriptDataThunk, selectorCatalogsIsLoadingScriptData } from '../../store/catalogSlice';
import { Loader } from '@consta/uikit/Loader';

import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import CustomBlueTextButton from '../CustomBlueTextButton/CustomBlueTextButton';
import { IconCopy } from '@consta/uikit/__internal__/src/icons/IconCopy/IconCopy';
import { IconDownload } from '@consta/uikit/IconDownload';
import { Text } from '@consta/uikit/Text';

interface IUnifyScriptPreviewProps {
  bookmark: BookmarkType;
}

const customStyle = {
  color: '#002033',
  lineHeight: '24px',
  fontSize: '14px',
  borderRadius: '5px',
  backgroundColor: '#FFF',
  padding: '20px',
  fontStyle: 'normal',
  fontWeight: '400',
};

const UnifyScriptPreview: React.FC<IUnifyScriptPreviewProps> = ({ bookmark }) => {
  const dispatch = useAppDispatch();
  const isLoadingScriptData = useAppSelector(selectorCatalogsIsLoadingScriptData);
  const data = bookmark.data as BookmarkTableViewTableFunctionType | BookmarkProcedureTriggerFunctionType | undefined;

  useEffect(() => {
    if (!data?.scriptData) {
      dispatch(getBookmarkScriptDataThunk(bookmark));
    }
  }, [dispatch, bookmark, data?.scriptData]);

  const copyCodeToClipboard = () => {
    if (data?.scriptData?.data.code) {
      navigator.clipboard.writeText(data.scriptData.data.code).then();
    }
  };

  const downloadCode = () => {
    if (data?.scriptData?.data.code) {
      const element = document.createElement('a');
      const file = new Blob([data?.scriptData?.data.code], { type: 'text/plain' });
      element.href = URL.createObjectURL(file);
      element.download = `${bookmark.label}_script.txt`;
      element.click();
    }
  };

  return (
    <div className={classes.container}>
      {isLoadingScriptData ? (
        <Loader />
      ) : data?.scriptData?.data.code ? (
        <>
          <div className={classes.buttonsRow}>
            <CustomBlueTextButton
              title={'Скопировать в буфер обмена'}
              IconLeft={<IconCopy size={'xs'} style={{ color: '#0078D2', marginTop: '10px' }} />}
              onClick={copyCodeToClipboard}
            />
            <CustomBlueTextButton
              title={'Скачать TXT'}
              IconLeft={<IconDownload size={'xs'} style={{ color: '#0078D2', marginTop: '10px' }} />}
              onClick={downloadCode}
            />
          </div>
          <SyntaxHighlighter language="sql" customStyle={customStyle}>
            {data.scriptData.data.code}
          </SyntaxHighlighter>
        </>
      ) : (
        <Text className={classes.nothingFound}>{`Ничего не найдено`}</Text>
      )}
    </div>
  );
};

export default UnifyScriptPreview;
