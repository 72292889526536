import React from 'react';

import classes from './UserInfo.module.css';
import { Avatar } from '@consta/uikit/Avatar';
import { Text } from '@consta/uikit/Text';
import { UserBaseType } from '../../types/types';

interface IUserInfoProps {
  user: UserBaseType;
}

const UserInfo: React.FC<IUserInfoProps> = ({ user }) => {
  return (
    <div className={classes.container}>
      <div className={classes.ico}>
        <Avatar
          className={classes.avatar}
          url={user.icon ? `data:image/png;base64,${user.icon}` : undefined}
          name={user.full_name}
          size={'s'}
        />
      </div>

      <div className={classes.textContainer}>
        <Text className={classes.name}>{user.full_name}</Text>
        {user.position && <Text className={classes.position}>{user.position}</Text>}
      </div>
    </div>
  );
};

export default UserInfo;
