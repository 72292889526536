import React, { useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import Delimiter from '../Delimiter/Delimiter';
import { GeneralTableColumnType } from '../Table/Table';
import ColumnsTuneList from './ColumnsTuneList/ColumnsTuneList';
import classes from './TableSettings.module.css';
import TableSettingsControl from './TableSettingsControl/TableSettingsControl';
import TableSettingsFooter from './TableSettingsFooter/TableSettingsFooter';
import TableSettingsSearchRow from './TableSettingsSearchRow/TableSettingsSearchRow';
import { Text } from '@consta/uikit/Text';

interface ITableColumnsTuneProps {
  columns: GeneralTableColumnType[];
  setColumns: (columns: GeneralTableColumnType[]) => void;
  closeSidebarClick?: () => void;
  onCancel?: () => void;
  onApply?: (columns: GeneralTableColumnType[]) => void;
  hideFooter?: boolean;
  hideHeader?: boolean;
  defaultColumns?: GeneralTableColumnType[];
}

const TableSettings: React.FC<ITableColumnsTuneProps> = ({
  columns,
  setColumns,
  closeSidebarClick,
  onCancel,
  onApply,
  hideFooter,
  hideHeader,
  defaultColumns,
}) => {
  const [searchTitleValue, setSearchTitleValue] = useState<string | null>(null);

  const cancelHandler = () => {
    onCancel && onCancel();
    closeSidebarClick && closeSidebarClick();
  };

  const applyHandler = () => {
    onApply && onApply(columns);
    closeSidebarClick && closeSidebarClick();
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className={classes.container}>
        {!hideHeader && (
          <>
            <div className={classes.row}>
              <Text className={classes.title}>{`Настройка таблицы`}</Text>
            </div>
            <Delimiter />
          </>
        )}
        <div className={`${classes.content} ${hideFooter ? classes.contentWithoutFooter : ''}`}>
          <TableSettingsSearchRow searchTitleValue={searchTitleValue} setSearchTitleValue={setSearchTitleValue} />
          <TableSettingsControl columns={columns} setColumns={setColumns} defaultColumns={defaultColumns} />
          <ColumnsTuneList columns={columns} setColumns={setColumns} searchTitleValue={searchTitleValue} />
        </div>
        <TableSettingsFooter hideFooter={hideFooter} applyHandler={applyHandler} cancelHandler={cancelHandler} />
      </div>
    </DndProvider>
  );
};

export default TableSettings;
