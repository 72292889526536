import React, { useEffect } from 'react';
import AssetsByTypeList from './AssetsByTypeList/AssetsByTypeList';
import { getMetaAssetsByTypeThunk, selectorMetaSliceError } from '../../store/metaSlice';
import ModalError from '../../Components/ModalError/ModalError';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import LayoutOrdinaryPage from '../../Components/LayoutOrdinaryPage/LayoutOrdinaryPage';
import MainPageHero from './MainPageHero/MainPageHero';
import classes from './MainPage.module.css';

const MainPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const metaSliceError = useAppSelector(selectorMetaSliceError);

  useEffect(() => {
    dispatch(getMetaAssetsByTypeThunk());
  }, [dispatch]);

  useEffect(() => {
    document.title = `ТехМет Главная`;
  }, []);

  return (
    <LayoutOrdinaryPage className={classes.secondaryBackground}>
      <MainPageHero />
      <AssetsByTypeList />
      <ModalError errorMessage={metaSliceError} />
    </LayoutOrdinaryPage>
  );
};

export default MainPage;
