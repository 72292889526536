import { CreateNewConnectionsSliceType } from '../createNewConnectionSlice';
import { validateStepTwoDbInputs } from './validateStepTwoDbInputs';

export const validateStepTwoDbFull = (state: CreateNewConnectionsSliceType) => {
  let validateError = validateStepTwoDbInputs(state);
  if (!state.newConnectionSelectedAssets?.length) {
    if (!validateError) {
      validateError = true;
    }
    state.isRequiredSelectedAssets = true;
  }
  if (!state.isNewConnectionChecked) {
    if (!validateError) {
      validateError = true;
    }
    state.isRequiredNewConnectionChecked = true;
  }
  return validateError;
};
