import React, { useEffect } from 'react';
import classes from './CatalogPage.module.css';
import BookmarkSelector from './BookmarkSelector/BookmarkSelector';
import { useCatalogItemsForDefaultStart } from '../../hooks/useCatalogItemsForDefaultStart';
import { useCatalogActiveSectionForDefaultStart } from '../../hooks/useCatalogActiveSectionForDefaultStart';
import ModalError from '../../Components/ModalError/ModalError';
import { selectorCatalogSliceError } from '../../store/catalogSlice';
import { useAppSelector } from '../../hooks/hooks';
import BookmarkContentSwitcher from './BookmarkContentSwitcher/BookmarkContentSwitcher';

const CatalogPage: React.FC = () => {
  useCatalogItemsForDefaultStart();
  useCatalogActiveSectionForDefaultStart();

  useEffect(() => {
    document.title = `ТехМет Каталог`;
  }, []);

  const catalogSliceError = useAppSelector(selectorCatalogSliceError);

  return (
    <div className={classes.container}>
      <BookmarkSelector />
      <BookmarkContentSwitcher />
      <ModalError errorMessage={catalogSliceError} />
    </div>
  );
};

export default CatalogPage;
