import React from 'react';
import { useAppSelector } from '../../../../../../hooks/hooks';
import {
  selectorCatalogActiveSection,
  selectorCatalogActiveSectionTableColumns,
  selectorCatalogIsHierarchyOpen,
  selectorCatalogItems,
} from '../../../../../../store/catalogSlice';
import { useColumnsStore } from '../../../../../../hooks/useColumnsStore';
import HierarchyCell from '../../../../../../Components/HierarchyCell/HierarchyCell';
import { formatDateDDMMYYYY } from '../../../../../../utils/Formatters/formatDateDDMMYYYY';
import TableWrapper from '../../../../../../Components/TableWrapper/TableWrapper';
import { Table } from '../../../../../../shared-front/Table/Table';
import { getShowColumns } from '../../../../../../shared-front/Table/utils/getShowColumns';
import { removeHTMLTags } from '../../../../../../utils/Formatters/removeHTMLTags';
import { BookmarkType } from '../../../../../../types/types';
import { useExistedOrCreateNewBookmark } from '../../../../../../hooks/useExistedOrCreateNewBookmark';
import { getTableCatalogColumnsTemplate } from '../../../../../../utils/Catalog/getTableCatalogColumnsTemplate';
import { setTableSearchStyle } from '../../../../../../utils/Formatters/setTableSearchStyle';
import BlueLinkOpenInNewTab from '../../../../../../Components/BlueLinkOpenInNewTab/BlueLinkOpenInNewTab';
import TextWithMark from '../../../../../../Components/TextWithMark/TextWithMark';

interface ITableCatalog {}

const TableCatalog: React.FC<ITableCatalog> = () => {
  const catalogItems = useAppSelector(selectorCatalogItems);
  const columnsStore = useAppSelector(selectorCatalogActiveSectionTableColumns);
  const isHierarchyOpen = useAppSelector(selectorCatalogIsHierarchyOpen);
  const catalogActiveSection = useAppSelector(selectorCatalogActiveSection);

  const doExistedOrCreateNewBookmark = useExistedOrCreateNewBookmark();

  const columnsTemplate = getTableCatalogColumnsTemplate(catalogActiveSection?.slug);
  const columns = useColumnsStore({ columnsStore, columnsTemplate });

  const tableData = catalogItems
    ? catalogItems.data.map((item) => ({
        ...item,
        system: item.system?.name ?? '',
        business_solution: item.business_solution?.name ?? '',
        business_system: item.business_system?.name ?? '',
        technical_system: item.technical_system?.name ?? '',
        organization: item.organization?.name ? item.organization.name : '',
        hierarchy: <HierarchyCell item={item} />,
        _alter_ts: item._alter_ts ? formatDateDDMMYYYY(new Date(item._alter_ts)) : '',
        ts_alter: item._ts_alter ? formatDateDDMMYYYY(new Date(item._ts_alter)) : '',
        _create_ts: item._create_ts ? formatDateDDMMYYYY(new Date(item._create_ts)) : '',
        ts_create: item._ts_create ? formatDateDDMMYYYY(new Date(item._ts_create)) : '',
        _create_user: item._create_user?.full_name ?? '',
        _alter_user: item._alter_user?.full_name ?? '',
        name: item.name ? setTableSearchStyle(item.name) : '',
        description_name: item.description_name ? setTableSearchStyle(item.description_name) : '',
        description_value: item.description_value ? setTableSearchStyle(item.description_value) : '',
        comment: item.comment ? setTableSearchStyle(item.comment) : '',
        code: item.code ?? '',
        full_name: item.full_name ?? '',
        en_name: item.en_name ?? '',
        tech_name_edm: item.tech_name_edm ?? '',
        block: item.block?.name ?? '',
        link_edm: <BlueLinkOpenInNewTab linkUrl={item.link_edm} linkTitle={'Смотреть в EDM'} />,
        integration_dwh: (
          <TextWithMark
            isComplete={item.integration_dwh}
            textDone={'Интегрировано с хранилищем'}
            textNotDone={'Не интегрировано с хранилищем'}
          />
        ),
        scan_metadata: (
          <TextWithMark isComplete={item.scan_metadata} textDone={'Сканировано'} textNotDone={'Не сканировано'} />
        ),
      }))
    : [];

  const onRowClick = (originalRow: { [key: string]: unknown }) => {
    const catalogItem = catalogItems?.data.find((item) => {
      return catalogActiveSection?.slug === 'system'
        ? item._uuid === originalRow._uuid
        : item._hash_key === originalRow._hash_key;
    });
    if (catalogItem?.name) {
      const bookmark = {
        label: removeHTMLTags(catalogItem.name),
        type: catalogActiveSection?.slug,
        slug: catalogActiveSection?.slug === 'system' ? 'system' : catalogItem.unified_object_slug,
        hash: catalogActiveSection?.slug === 'system' ? catalogItem._uuid : catalogItem._hash_key,
      } as BookmarkType;
      doExistedOrCreateNewBookmark(bookmark);
    }
  };

  return (
    <TableWrapper columns={columnsStore} isHierarchyOpen={isHierarchyOpen}>
      <Table
        data={tableData}
        columns={getShowColumns(columns)}
        onRowClick={onRowClick}
        isColumnsResizable={true}
        isRowHover={true}
      />
    </TableWrapper>
  );
};

export default TableCatalog;
