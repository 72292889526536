import React from 'react';

import classes from './ContentProcedureTriggerFuntionByTabs.module.css';
import { useAppSelector } from '../../../../../hooks/hooks';
import { selectorCatalogsIsLoadingBookmarkData } from '../../../../../store/catalogSlice';
import { Loader } from '@consta/uikit/Loader';
import { BookmarkType } from '../../../../../types/types';
import { CardTabType } from '../../../../../Components/CardTabsRow/CardTabsRow';
import ProcedureTriggerFunctionCommonInfoTab from './ProcedureTriggerFuntionCommonInfoTab/ProcedureTriggerFuntionCommonInfoTab';
import { TABS_PROCEDURE_TRIGGER_FUNCTION } from '../CardProcedureTriggerFuntionContent';
import ProcedureTriggerFunctionScriptTab from './ProcedureTriggerFuntionScriptTab/ProcedureTriggerFuntionScriptTab';
import UnifyScriptPreview from '../../../../../Components/UnifyScriptPreview/UnifyScriptPreview';

interface IContentRdbByTabsProps {
  activeTab?: CardTabType;
  bookmark: BookmarkType;
}

const ContentProcedureTriggerFunctionByTabs: React.FC<IContentRdbByTabsProps> = ({ activeTab, bookmark }) => {
  const isCommonInfoTab = activeTab?.label === TABS_PROCEDURE_TRIGGER_FUNCTION[0].label;
  const isScriptTab = activeTab?.label === TABS_PROCEDURE_TRIGGER_FUNCTION[1].label;
  const isLoadingBookmarkData = useAppSelector(selectorCatalogsIsLoadingBookmarkData);

  return (
    <div className={classes.container}>
      {isLoadingBookmarkData ? (
        <div style={{ display: 'flex', width: '100%' }}>
          <Loader />
        </div>
      ) : isCommonInfoTab ? (
        <ProcedureTriggerFunctionCommonInfoTab bookmark={bookmark} />
      ) : isScriptTab ? (
        <UnifyScriptPreview bookmark={bookmark} />
      ) : (
        <ProcedureTriggerFunctionScriptTab />
      )}
    </div>
  );
};

export default ContentProcedureTriggerFunctionByTabs;
