import { UserInfoType } from '../../types/types';
import { PageRoutesEnum } from '../../appMenuAndRouting/appRoutes';

export const getUserMenuByPermissions = (
  userInfo: UserInfoType | undefined,
  menuItems: { label: string; href: PageRoutesEnum }[]
) => {
  if (!userInfo) {
    return [];
  }
  return menuItems.filter((menuItem) =>
    userInfo.permissions.find((permission) => permission.slug === menuItem.href.slice(1))
  );
};
