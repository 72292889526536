import { HierarchyItemType } from '../../types/types';

export const searchParentInHierarchy = (
  hierarchyItems: HierarchyItemType[],
  child: HierarchyItemType
): HierarchyItemType | undefined => {
  let result: HierarchyItemType | undefined;
  for (let parent of hierarchyItems) {
    const isParentFound = parent.children?.filter((childItem) => childItem.uuid === child.uuid)?.[0];
    if (isParentFound) {
      result = parent;
    }
    if (!result && parent.children) {
      result = searchParentInHierarchy(parent.children, child);
    }
  }
  return result;
};
