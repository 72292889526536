import React, { ReactNode, useEffect, useState } from 'react';

import classes from './CollapseBlock.module.css';
import { Collapse } from '@consta/uikit/Collapse';
import { Text } from '@consta/uikit/Text';
import iconInfoCircle from '../../assets/CatalogPage/iconInfoCircle.svg';
import CustomHoverToolTip from '../CustomHoverToolTip/CustomHoverToolTip';

interface ICollapseBlockProps {
  title: string;
  children: ReactNode;
  isOpen?: boolean;
  isShortContainer?: boolean;
  isShortChildrenContainer?: boolean;
  informer?: string;
  onIsOpenChange?: (isOpen: boolean) => void;
}

const CollapseBlock: React.FC<ICollapseBlockProps> = ({
  title,
  children,
  isShortChildrenContainer,
  isShortContainer,
  informer,
  isOpen = false,
  onIsOpenChange,
}) => {
  const [isOpenState, setOpenState] = useState<boolean>(isOpen);

  const onClickHandler = () => {
    if (onIsOpenChange) {
      onIsOpenChange(!isOpenState);
    } else {
      setOpenState((prev) => !prev);
    }
  };

  useEffect(() => {
    setOpenState(isOpen);
  }, [isOpen]);

  return (
    <div className={`${isShortContainer ? classes.shortContainer : classes.container}`}>
      <Collapse
        size={'l'}
        label={
          <div className={classes.tittleContainer}>
            <Text className={classes.title}>{title}</Text>
            {informer && (
              <CustomHoverToolTip title={`${informer}`} zIndex={999}>
                <img src={iconInfoCircle} className={classes.ico} alt={'infoBtn'} />
              </CustomHoverToolTip>
            )}
          </div>
        }
        isOpen={isOpenState}
        iconPosition={'right'}
        onClick={onClickHandler}
        className={classes.collapse}
      >
        <div className={classes.content}>
          <div className={classes.delimiter} />
          <div className={`${isShortChildrenContainer ? classes.shortChildrenContainer : classes.childrenContainer}`}>
            {children}
          </div>
        </div>
      </Collapse>
    </div>
  );
};

export default CollapseBlock;
