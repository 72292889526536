import { useAppDispatch, useAppSelector } from './hooks';
import { getQuerySearchValue } from '../utils/getQuerySearchValue';
import { getQueryPaginationFromFirstPage } from '../utils/getQueryPaginationFromFirstPage';
import { combineQuery } from '../utils/combineQuery';

import { getInfoSystemsThunk, selectorInfoSystems, selectorSearchValueInfoSystems } from '../store/infoSystemsSlice';
import { abortControllerInfoSystems } from '../api/InfoSystemsApi';
import { useCallback, useRef } from 'react';

interface IUseGetTechMetaDocuments {
  searchValue?: string;
  queryPagination?: string;
}

export const useGetInfoSystems = () => {
  const dispatch = useAppDispatch();
  const metaStore = useAppSelector(selectorInfoSystems)?.meta;
  const searchValueStore = useAppSelector(selectorSearchValueInfoSystems);
  const requestRef = useRef(false);

  return useCallback(
    ({ searchValue, queryPagination }: IUseGetTechMetaDocuments) => {
      const querySearchValue = getQuerySearchValue(
        searchValue ? searchValue : searchValue === '' ? '' : searchValueStore
      );

      const requestQuery = combineQuery([
        querySearchValue,
        queryPagination ? queryPagination : getQueryPaginationFromFirstPage(metaStore ? metaStore : undefined),
      ]);
      if (requestRef.current) {
        abortControllerInfoSystems.abort();
        setTimeout(() => {
          requestRef.current = true;
          dispatch(getInfoSystemsThunk(requestQuery)).then(() => {
            requestRef.current = false;
          });
        });
      } else {
        requestRef.current = true;
        dispatch(getInfoSystemsThunk(requestQuery)).then(() => {
          requestRef.current = false;
        });
      }
    },
    [dispatch, metaStore, searchValueStore]
  );
};
