import React from 'react';

import classes from './CPViewSelector.module.css';
import { ChoiceGroup } from '@consta/uikit/ChoiceGroup';
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooks';
import {
  selectorConnectionsActiveViewSelector,
  setActiveViewSelector,
  setTableConnectionsHoveredRowUuid,
} from '../../../../store/connectionsSlice';
import { CP_VIEW_SELECTOR_ITEMS } from '../../../../utils/constants';
import { clearLaunchHistory, selectorSelectedConnectionUuId } from '../../../../store/launchHistorySlice';

export type CPViewSelectorItemType = {
  name: string;
  disabled?: boolean;
};

interface ICPViewSelector {}

const CPViewSelector: React.FC<ICPViewSelector> = () => {
  const dispatch = useAppDispatch();
  const activeViewSelector = useAppSelector(selectorConnectionsActiveViewSelector);
  const activeViewSelectorItem = CP_VIEW_SELECTOR_ITEMS.find((item) => item.name === activeViewSelector);
  const selectedConnectionUuId = useAppSelector(selectorSelectedConnectionUuId);

  const onChange = ({ value }: { value: CPViewSelectorItemType }) => {
    dispatch(setActiveViewSelector(value.name));
    if (selectedConnectionUuId) {
      dispatch(setTableConnectionsHoveredRowUuid(undefined));
      dispatch(clearLaunchHistory());
    }
  };

  return (
    <div className={classes.container}>
      <ChoiceGroup
        size={'s'}
        value={activeViewSelectorItem}
        onChange={onChange}
        items={CP_VIEW_SELECTOR_ITEMS}
        getItemLabel={(item: CPViewSelectorItemType) => item.name}
        multiple={false}
        name="CPViewSelector"
      />
    </div>
  );
};

export default CPViewSelector;
