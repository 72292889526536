import React from 'react';
import classes from './CreateConnectionAssetsCombobox.module.css';
import { ItemComboboxType } from '../../../../../../../types/types';
import { CheckConnectionAssetType } from '../../../../../../../api/serverResponse';

import CPRightSideBarModalCancel from '../../../../CPRightSideBarBottomButtons/CPRightSideBarModalCancel/CPRightSideBarModalCancel';
import ConnectionAssetsCombobox from '../../../../../../../Components/ConnectionAssetsCombobox/ConnectionAssetsCombobox';

interface ICreateConnectionAssetsCombobox {
  cardView: 'db' | 'oracle' | 'odata' | null | undefined;
  returnFromStepIndex: number | undefined;
  isConnectionChecked: boolean;
  connectionAssets: CheckConnectionAssetType[] | undefined;
  onChangeAssets: (value: ItemComboboxType[] | null) => void;
  connectionSelectedAssets: CheckConnectionAssetType[] | undefined;
  isRequiredSelectedAssets: boolean;
  onConfirmAssetsModal: () => void;
}

const CreateConnectionAssetsCombobox: React.FC<ICreateConnectionAssetsCombobox> = ({
  cardView,
  returnFromStepIndex,
  isConnectionChecked,
  connectionAssets,
  onChangeAssets,
  connectionSelectedAssets,
  isRequiredSelectedAssets,
  onConfirmAssetsModal,
}) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const isOdata = cardView === 'odata';
  const isOracle = cardView === 'oracle';
  const isDb = !isOdata && !isOracle;

  const onConfirmModal = () => {
    onConfirmAssetsModal();
    setIsModalOpen(false);
  };

  const onClickConnectionAssetsCombobox = () => {
    if (isConnectionChecked && returnFromStepIndex) {
      setIsModalOpen(true);
    }
  };

  return isDb ? (
    <div className={classes.container} onClick={onClickConnectionAssetsCombobox}>
      <ConnectionAssetsCombobox
        assets={connectionAssets}
        selectedAssets={connectionSelectedAssets}
        onChangeAssets={onChangeAssets}
        disabled={!isConnectionChecked || !connectionAssets?.length}
        required={true}
        caption={isRequiredSelectedAssets ? 'Обязательное к заполнению поле' : undefined}
        status={isRequiredSelectedAssets ? 'alert' : undefined}
      />
      <CPRightSideBarModalCancel
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        modalTitle={'Редактирование приведет к сбросу настроек'}
        modalMessage={'Редактирование списка баз данных приведет к сбросу расширенных настроек баз данных'}
        onConfirm={onConfirmModal}
      />
    </div>
  ) : null;
};

export default CreateConnectionAssetsCombobox;
