import { TableColumnType } from '../shared-front/Table/Table';

export const getIsColumnToShow = (columns: (object & TableColumnType)[]): boolean => {
  for (let column of columns) {
    if (column.isShow) {
      return true;
    }
  }
  return false;
};
