import { createTableColumn } from '../../../../../../../shared-front/Table/utils/createTableColumn';
import { ColumnDef } from '@tanstack/react-table';
import { ReactNode } from 'react';

// --1 колонка: Обозначение типа данных	= ? ПОКА ПОД ВОПРОСОМ ДИЗАЙН
// --1 колонка: Иконка ключевого поля		= is_in_primary_key|is_in_foreign_key (pbs.v_bse_key)
// --1 колонка: Тип ключа					= is_in_primary_key|is_in_foreign_key (pbs.v_bse_key)
// --Наименование							= name
// --Краткое описание						= name (pbs.v_met_description)
// --Расширенное описание					= value (pbs.v_met_description)
// --Описание из системы-источник			= comment
// --Тип данных 							= type
//
// --Дополнительные поля
// --Допускает пустое значение				= not_null
// --Значение по умолчанию					= default_value
// --Порядковый номер						= num

export type TableViewTableFunctionDataType = {
  type: ReactNode;
  name: string;
  dataType: string;
  short_description: string;
  description: string;
  comment: string;
  not_null: string;
  default_value: string;
  num: string;
};

export type TableRdbColumnsType = ColumnDef<TableViewTableFunctionDataType> & {
  title: string;
  isShow: boolean;
  accessorKey: string;
};

export const TABLE_VIEW_TABLE_FUNCTION_COLUMNS: TableRdbColumnsType[] = [
  createTableColumn<TableViewTableFunctionDataType>({
    accessorKey: 'type',
    title: '',
    size: 100,
    isShow: true,
  }),
  createTableColumn<TableViewTableFunctionDataType>({
    accessorKey: 'name',
    title: 'Наименование',
    size: 300,
    isShow: true,
  }),
  createTableColumn<TableViewTableFunctionDataType>({
    accessorKey: 'unified_data_type',
    title: 'Унифицированный тип данных',
    size: 300,
    isShow: true,
  }),
  createTableColumn<TableViewTableFunctionDataType>({
    accessorKey: 'dataType',
    title: 'Тип данных',
    size: 300,
    isShow: true,
  }),
  createTableColumn<TableViewTableFunctionDataType>({
    accessorKey: 'short_description',
    title: 'Краткое описание',
    size: 300,
    isShow: true,
  }),
  createTableColumn<TableViewTableFunctionDataType>({
    accessorKey: 'description',
    title: 'Расширенное описание',
    size: 300,
    isShow: true,
  }),
  createTableColumn<TableViewTableFunctionDataType>({
    accessorKey: 'comment',
    title: 'Описание из системы-источник',
    size: 300,
    isShow: true,
  }),
  createTableColumn<TableViewTableFunctionDataType>({
    accessorKey: 'not_null',
    title: 'Допускает пустое значение',
    size: 300,
    isShow: true,
  }),
  createTableColumn<TableViewTableFunctionDataType>({
    accessorKey: 'default_value',
    title: 'Значение по умолчанию',
    size: 300,
    isShow: true,
  }),
  createTableColumn<TableViewTableFunctionDataType>({
    accessorKey: 'num',
    title: 'Порядковый номер',
    size: 300,
    isShow: true,
  }),
];
