import React from 'react';
import { replaceAll } from '../replaceAll';

export const setTableSearchStyle = (value?: string | null | undefined) => {
  if (value) {
    const valOpenTagReplacement = replaceAll(value, '<b>', `<span style="background-color:#DEE4E8;">`);
    const tableSearchStyleValue = replaceAll(valOpenTagReplacement, '</b>', '</span>');
    return <div dangerouslySetInnerHTML={{ __html: tableSearchStyleValue }} />;
  }
  return null;
};
