import React, { FC } from 'react';

import classes from './RowSelection.module.css';
import { Checkbox } from '@consta/uikit/Checkbox';
import { Radio } from '@consta/uikit/Radio';

interface IRowSelectionProps {
  enableMultiSelection?: boolean;
  checked: boolean;
  intermediate?: boolean;
  selectionFn: () => void;
  zIndex?: number;
}

const RowSelectionCell: FC<IRowSelectionProps> = ({
  enableMultiSelection,
  checked,
  intermediate,
  selectionFn,
  zIndex,
}) => {
  return (
    <div className={classes.inputWrapper}>
      {enableMultiSelection ? (
        <Checkbox
          checked={checked}
          intermediate={intermediate}
          onClick={(e) => e.stopPropagation()}
          onChange={selectionFn}
          style={{ zIndex }}
        />
      ) : (
        <Radio checked={checked} onClick={(e) => e.stopPropagation()} onChange={selectionFn} style={{ zIndex }} />
      )}
    </div>
  );
};

export default RowSelectionCell;
