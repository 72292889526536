import React from 'react';

import classes from './BlueLinkOpenInNewTab.module.css';
import { Text } from '@consta/uikit/Text';
import { openURLinNewTab } from '../../utils/openURLinNewTab';
import { IconOpenInNew } from '@consta/uikit/IconOpenInNew';

interface IBlueLinkOpenInNewTabProps {
  linkUrl?: string | null | undefined;
  linkTitle?: string | null | undefined;
}

const BlueLinkOpenInNewTab: React.FC<IBlueLinkOpenInNewTabProps> = ({ linkUrl, linkTitle }) => {
  if (!linkUrl || !linkTitle) {
    return null;
  }

  const onClickHandler = (e: React.MouseEvent) => {
    e.stopPropagation();
    openURLinNewTab(linkUrl);
  };

  return (
    <div className={classes.container} onClick={onClickHandler}>
      <Text className={classes.title}>{linkTitle}</Text>
      <IconOpenInNew view="link" size={'xs'} style={{ marginTop: '-2px' }} />
    </div>
  );
};

export default BlueLinkOpenInNewTab;
