import React from 'react';

import { FilterBarItemType } from '../FilterBar';
import classes from './FilterBarItem.module.css';
import { Tag } from '@consta/uikit/Tag';

interface IFilterBarItemProps {
  item: FilterBarItemType;
  onCancel: (item: FilterBarItemType) => void;
}

const FilterBarItem: React.FC<IFilterBarItemProps> = ({ item, onCancel }) => {
  return (
    <div className={classes.container}>
      <Tag
        mode="cancel"
        size={'xs'}
        onCancel={() => {
          onCancel(item);
        }}
        label={item.title}
      />
    </div>
  );
};

export default FilterBarItem;
