import React from 'react';
import classes from './CreateConnectionCheckNewConnectionRow.module.css';
import { Button } from '@consta/uikit/Button';
import { ProgressSpin } from '@consta/uikit/ProgressSpin';
import CustomBlueTextButton from '../../../../../../../Components/CustomBlueTextButton/CustomBlueTextButton';
import { Badge } from '@consta/uikit/Badge';
import {
  selectorActiveConnectorType,
  selectorActiveInfoSystem,
  selectorActiveSourceType,
  selectorConnectionInstance,
  selectorConnectionPassword,
  selectorConnectionPort,
  selectorConnectionUrl,
  selectorConnectionUser,
  selectorIpAddress,
  selectorIsNewConnectionChecked,
  selectorIsNewConnectionOnChecking,
  selectorIsRequiredNewConnectionChecked,
  setFailureConnectionCheck,
  setFinishConnectionCheck,
  setInProgressConnectionCheck,
  setSuccessConnectionCheck,
  sliceValidateStepTwoDbInputs,
  sliceValidateStepTwoOdata,
  sliceValidateStepTwoOracle,
} from '../../../../../../../store/createNewConnectionSlice';
import { serverApi } from '../../../../../../../api/serverApi';
import { pushSnackBarItemConnections } from '../../../../../../../store/connectionsSlice';
import { getRejectedValue } from '../../../../../../../store/rejectedValueHelper';
import { useAppDispatch, useAppSelector } from '../../../../../../../hooks/hooks';
import { CheckNewConnectionParamsType } from '../../../../../../../api/createNewConnectionApi';
import ButtonDisplayExistedConnection from './ButtonDisplayExistedConnection/ButtonDisplayExistedConnection';
import { Text } from '@consta/uikit/Text';

export let abortControllerCheckNewConnection = new AbortController();

interface ICreateConnectionCheckNewConnectionRow {
  connectionExistedUuid: string | undefined;
  setConnectionExistedUuid: (val: string | undefined) => void;
}

const CreateConnectionCheckNewConnectionRow: React.FC<ICreateConnectionCheckNewConnectionRow> = ({
  connectionExistedUuid,
  setConnectionExistedUuid,
}) => {
  const dispatch = useAppDispatch();
  const valueIpAddress = useAppSelector(selectorIpAddress);
  const activeInfoSystem = useAppSelector(selectorActiveInfoSystem);
  const activeSourceType = useAppSelector(selectorActiveSourceType);
  const valueConnectionPort = useAppSelector(selectorConnectionPort);
  const valueConnectionUser = useAppSelector(selectorConnectionUser);
  const activeConnectorType = useAppSelector(selectorActiveConnectorType);
  const valueConnectionPassword = useAppSelector(selectorConnectionPassword);
  const valueConnectionInstance = useAppSelector(selectorConnectionInstance);
  const valueConnectionUrl = useAppSelector(selectorConnectionUrl);
  const isNewConnectionChecked = useAppSelector(selectorIsNewConnectionChecked);
  const isNewConnectionOnChecking = useAppSelector(selectorIsNewConnectionOnChecking);
  const isRequiredNewConnectionChecked = useAppSelector(selectorIsRequiredNewConnectionChecked);

  const checkNewConnection = async (params: CheckNewConnectionParamsType) => {
    if (!isNewConnectionOnChecking) {
      try {
        dispatch(setInProgressConnectionCheck());
        const checkConnectionResult = await serverApi.checkNewConnection(params);
        if (checkConnectionResult.data) {
          dispatch(setSuccessConnectionCheck(checkConnectionResult.data));
          dispatch(setFinishConnectionCheck());
        }
      } catch (e: any) {
        if (e.name === 'CanceledError') {
          abortControllerCheckNewConnection = new AbortController();
        } else if (e.response?.data?.data?.uuid_connection) {
          setConnectionExistedUuid(e.response.data.data.uuid_connection);
          dispatch(setFinishConnectionCheck());
        } else if (e.response?.data?.data?.message) {
          dispatch(
            pushSnackBarItemConnections({
              key: params.system + params.source_type + params.connector_type,
              message: `Ошибка проверки подключения:\n-${e.response.data.data.message}`,
              status: 'alert',
              autoClose: true,
            })
          );
        } else {
          dispatch(
            pushSnackBarItemConnections({
              key: params.system + params.source_type + params.connector_type,
              message: getRejectedValue('Ошибка проверки подключения:\n- ', e),
              status: 'alert',
              autoClose: true,
            })
          );
        }
        dispatch(setFailureConnectionCheck());
      }
    }
  };

  const onClickCheckNewConnection = async () => {
    if (activeSourceType?.card_view) {
      if (activeSourceType.card_view === 'oracle') {
        dispatch(sliceValidateStepTwoOracle());
        if (
          activeInfoSystem &&
          activeSourceType &&
          activeConnectorType &&
          valueIpAddress &&
          valueConnectionPort &&
          valueConnectionUser &&
          valueConnectionPassword &&
          valueConnectionInstance
        ) {
          const paramsOracle = {
            system: activeInfoSystem._uuid,
            source_type: activeSourceType._uuid,
            connector_type: activeConnectorType._uuid,
            host: valueIpAddress,
            port: Number(valueConnectionPort),
            user: valueConnectionUser,
            password: valueConnectionPassword,
            instance: valueConnectionInstance,
          };
          await checkNewConnection(paramsOracle);
        }
      } else if (activeSourceType.card_view === 'odata') {
        dispatch(sliceValidateStepTwoOdata());
        if (
          activeInfoSystem &&
          activeSourceType &&
          activeConnectorType &&
          valueConnectionUrl &&
          valueConnectionUser &&
          valueConnectionPassword
        ) {
          const paramsOdata = {
            system: activeInfoSystem._uuid,
            source_type: activeSourceType._uuid,
            connector_type: activeConnectorType._uuid,
            url: valueConnectionUrl,
            user: valueConnectionUser,
            password: valueConnectionPassword,
          };
          await checkNewConnection(paramsOdata);
        }
      } else {
        dispatch(sliceValidateStepTwoDbInputs());
        if (
          activeInfoSystem &&
          activeSourceType &&
          activeConnectorType &&
          valueIpAddress &&
          valueConnectionPort &&
          valueConnectionUser &&
          valueConnectionPassword
        ) {
          const paramsDb = {
            system: activeInfoSystem._uuid,
            source_type: activeSourceType._uuid,
            connector_type: activeConnectorType._uuid,
            host: valueIpAddress,
            port: Number(valueConnectionPort),
            user: valueConnectionUser,
            password: valueConnectionPassword,
          };
          await checkNewConnection(paramsDb);
        }
      }
    }
  };

  const onClickCancelCheckNewConnection = () => {
    abortControllerCheckNewConnection.abort();
  };

  return (
    <div>
      <div className={classes.container}>
        <Button
          size={'s'}
          label={'Проверить подключение'}
          onClick={onClickCheckNewConnection}
          disabled={isNewConnectionOnChecking || !!connectionExistedUuid}
        />
        {isNewConnectionOnChecking ? (
          <div className={classes.buttonsGroup}>
            <ProgressSpin style={{ marginLeft: '8px', height: '26px' }} />
            <CustomBlueTextButton title={'Отмена'} onClick={onClickCancelCheckNewConnection} />
          </div>
        ) : (
          <div className={classes.badgeSection}>
            <Badge
              status={!!connectionExistedUuid ? 'system' : isNewConnectionChecked ? 'success' : 'warning'}
              label={
                !!connectionExistedUuid
                  ? 'Подключение уже существует'
                  : isNewConnectionChecked
                  ? 'Подключение актуально'
                  : 'Подключение не проверено'
              }
            />
            <ButtonDisplayExistedConnection connectionExistedUuid={connectionExistedUuid} />
          </div>
        )}
      </div>
      <div className={classes.alertRow}>
        {isRequiredNewConnectionChecked && <Text className={classes.alert}>{'Пройдите проверку подключения'}</Text>}
      </div>
    </div>
  );
};

export default CreateConnectionCheckNewConnectionRow;
