import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { LaunchHistoryResponseType } from '../api/serverResponse';
import { RootState } from './store';
import { serverApi } from '../api/serverApi';
import { getRejectedValue } from './rejectedValueHelper';
import { ColumnGenericType } from '../types/types';

type LaunchHistorySlice = {
  launchHistory: LaunchHistoryResponseType | undefined;
  tableColumns: ColumnGenericType[];
  selectedConnectionUuid: string | undefined;
  isLoadingLaunchHistory: boolean;
  isErrorLaunchHistory: string | undefined;
};

const initialState: LaunchHistorySlice = {
  launchHistory: undefined,
  tableColumns: [],
  selectedConnectionUuid: undefined,
  isLoadingLaunchHistory: false,
  isErrorLaunchHistory: undefined,
};

export const getLaunchHistoryThunk = createAsyncThunk<LaunchHistoryResponseType, string, { rejectValue: string }>(
  'launchHistorySlice/getLaunchHistoryThunk',
  async (requestQuery, { rejectWithValue }) => {
    try {
      return await serverApi.getLaunchHistory(requestQuery);
    } catch (e) {
      return rejectWithValue(getRejectedValue('Ошибка получения данных по Истории Запусков', e));
    }
  }
);

export const launchHistorySlice = createSlice({
  name: 'launchHistorySlice',
  initialState,
  reducers: {
    clearLaunchHistory: (state) => {
      state.launchHistory = undefined;
      state.selectedConnectionUuid = undefined;
    },
    setTableColumnsLaunchHistory: (state, action) => {
      state.tableColumns = action.payload;
    },
    setSelectorSelectedConnectionUuId: (state, action) => {
      state.selectedConnectionUuid = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLaunchHistoryThunk.fulfilled, (state, action) => {
        state.isLoadingLaunchHistory = false;
        state.launchHistory = action.payload;
      })
      .addCase(getLaunchHistoryThunk.pending, (state) => {
        state.isLoadingLaunchHistory = true;
        state.isErrorLaunchHistory = undefined;
        state.launchHistory = undefined;
      })
      .addCase(getLaunchHistoryThunk.rejected, (state, action) => {
        state.isLoadingLaunchHistory = false;
        state.isErrorLaunchHistory = action.payload
          ? action.payload.toString()
          : 'Неизвестная ошибка - launchHistorySlice';
      });
  },
});

export const { clearLaunchHistory, setTableColumnsLaunchHistory, setSelectorSelectedConnectionUuId } =
  launchHistorySlice.actions;

export const selectorLaunchHistory = (state: RootState) => state.launchHistory.launchHistory;
export const selectorSelectedConnectionUuId = (state: RootState) => state.launchHistory.selectedConnectionUuid;
export const selectorIsLoadingLaunchHistory = (state: RootState) => state.launchHistory.isLoadingLaunchHistory;
export const selectorTableColumnsLaunchHistory = (state: RootState) => state.launchHistory.tableColumns;

export default launchHistorySlice.reducer;
