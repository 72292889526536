import React from 'react';

import classes from './ConnectionSearchRow.module.css';
import Delimiter from '../../shared-front/Delimiter/Delimiter';
import TextSearchArea from '../../shared-front/TextSearchArea/TextSearchArea';

interface ICreateConnectionSearchRowProps {
  searchValue: string | undefined;
  setSearchValue: (value: string) => void;
  cardView?: string | null | undefined;
}

const ConnectionSearchRow: React.FC<ICreateConnectionSearchRowProps> = ({ searchValue, setSearchValue, cardView }) => {
  const isOdata = cardView === 'odata';
  const isOracle = cardView === 'oracle';
  const isDb = !isOdata && !isOracle;

  return isDb ? (
    <div className={classes.container}>
      <Delimiter />
      <div className={classes.searchArea}>
        <TextSearchArea
          searchValue={searchValue ? searchValue : ''}
          setSearchValue={setSearchValue}
          customStyleWidth={'100%'}
          placeholder={'Поиск по Базам данных'}
          withoutDebounce={true}
        />
      </div>
      <Delimiter />
    </div>
  ) : null;
};

export default ConnectionSearchRow;
