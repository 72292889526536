import React, { useRef, useState } from 'react';

import classes from './ISPRightSideBarInfoRow.module.css';
import { IspRightSideBarEnum } from '../../InfoSystemsPage';
import { Text } from '@consta/uikit/Text';
import { Button } from '@consta/uikit/Button';
import { IconKebab } from '@consta/uikit/IconKebab';
import { ContextMenu } from '@consta/uikit/ContextMenu';
import { IconEdit } from '@consta/uikit/IconEdit';
import { IconTrash } from '@consta/uikit/IconTrash';
import ISPRightSideBarModalDelete from '../ISPRightSideBarModalDelete/ISPRightSideBarModalDelete';
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooks';
import { deleteInfoSystemThunk, getInfoSystemsThunk, selectorInfoSystems } from '../../../../store/infoSystemsSlice';
import { getQuerySearchValue } from '../../../../utils/getQuerySearchValue';
import { combineQuery } from '../../../../utils/combineQuery';
import { START_PAGINATION_QUERY } from '../../../../utils/constants';

type Item = {
  label: string;
  leftIcon: typeof IconEdit;
  accent?: 'alert';
};

const items: Item[] = [
  {
    label: 'Редактировать',
    leftIcon: IconEdit,
  },
  {
    label: 'Удалить',
    leftIcon: IconTrash,
    accent: 'alert',
  },
];

interface IISPRightSideBarInfoRowProps {
  rightSideBarMode: IspRightSideBarEnum | undefined;
  setRightSideBarMode?: (mode: IspRightSideBarEnum | undefined) => void;
  activeInfoSystemUuid?: string;
  closeSideBar?: () => void;
}

const ISPRightSideBarInfoRow: React.FC<IISPRightSideBarInfoRowProps> = ({
  rightSideBarMode,
  setRightSideBarMode,
  activeInfoSystemUuid,
  closeSideBar,
}) => {
  const ref = useRef(null);
  const dispatch = useAppDispatch();
  const infoSystems = useAppSelector(selectorInfoSystems);
  const [isContextMenuOpen, setIsContextMenuOpen] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const infoSystem = infoSystems?.data.find((infoSystem) => infoSystem._uuid === activeInfoSystemUuid);
  const modalDeleteTitle = infoSystem ? `Удалить «${infoSystem.name}»?` : undefined;

  const onClick = () => {
    setIsContextMenuOpen(!isContextMenuOpen);
  };

  const onItemClick = ({ item }: { item: Item }) => {
    if (item.label === 'Редактировать') {
      setRightSideBarMode && setRightSideBarMode(IspRightSideBarEnum.EditSystem);
    } else if (item.label === 'Удалить') {
      setIsModalOpen(true);
    }
    setIsContextMenuOpen(false);
  };

  const onConfirmDelete = () => {
    if (infoSystem?._uuid) {
      closeSideBar && closeSideBar();
      dispatch(deleteInfoSystemThunk(infoSystem._uuid)).then(() => {
        const searchValueQuery = getQuerySearchValue('');
        const requestQuery = combineQuery([searchValueQuery, START_PAGINATION_QUERY]);
        dispatch(getInfoSystemsThunk(requestQuery));
      });
    }
  };

  return (
    <div className={classes.container}>
      <Text className={classes.title}>
        {rightSideBarMode === IspRightSideBarEnum.DisplaySystem
          ? 'Интегрируемая система'
          : rightSideBarMode === IspRightSideBarEnum.CreateSystem
          ? 'Создать систему'
          : rightSideBarMode === IspRightSideBarEnum.EditSystem
          ? 'Редактировать систему'
          : ''}
      </Text>
      {rightSideBarMode === IspRightSideBarEnum.DisplaySystem && (
        <>
          <Button
            ref={ref}
            label="режим"
            view="ghost"
            size={'xs'}
            iconLeft={IconKebab}
            onlyIcon={true}
            onClick={onClick}
          />
          <ContextMenu
            isOpen={isContextMenuOpen}
            items={items}
            getItemStatus={(item) => item.accent}
            getItemLeftIcon={(item) => item.leftIcon}
            onItemClick={onItemClick}
            anchorRef={ref}
            direction="downStartLeft"
            style={{ zIndex: 999 }}
          />
        </>
      )}
      {modalDeleteTitle && (
        <ISPRightSideBarModalDelete
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          modalDeleteTitle={modalDeleteTitle}
          modalDeleteMessage={'Удаление ИС приведет к потере данных по подключениям и обьектам'}
          onConfirmDelete={onConfirmDelete}
        />
      )}
    </div>
  );
};

export default ISPRightSideBarInfoRow;
