import { useEffect, useState } from 'react';
import { ColumnGenericType } from '../types/types';

export const useColumnsStore = ({
  columnsStore,
  columnsTemplate = [],
}: {
  columnsStore?: ColumnGenericType[];
  columnsTemplate: ColumnGenericType[];
}) => {
  const [columns, setColumns] = useState<ColumnGenericType[]>([]);

  useEffect(() => {
    if (columnsStore) {
      const unitedColumns = columnsStore.map((columnStore) => {
        const columnDefault = columnsTemplate.find((item) => item.accessorKey === columnStore.accessorKey);
        return { ...columnDefault!, isShow: columnStore.isShow };
      });
      setColumns(unitedColumns);
    }
  }, [columnsStore, columnsTemplate]);
  return columns;
};
