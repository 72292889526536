import { useCallback, useState } from 'react';

export const useLoading = <T>(
  loadData: (requestParams: any) => Promise<T | undefined>,
  params?: unknown
): [boolean, () => Promise<[Awaited<T> | undefined, string | undefined]>] => {
  type returnValueType = [Awaited<T> | undefined, string | undefined];

  const [isLoadingData, setIsLoading] = useState<boolean>(false);

  const getData = useCallback(async () => {
    try {
      setIsLoading(true);
      const res = await loadData(params);
      return [res, undefined] as returnValueType;
    } catch (e: any) {
      const errorMessage = e.message ? (e.message as string) : undefined;
      return [undefined, errorMessage] as returnValueType;
    } finally {
      setIsLoading(false);
    }
  }, [loadData, params]);

  return [isLoadingData, getData];
};
