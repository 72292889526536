import React from 'react';

import classes from './InfoSystemSectionDisplay.module.css';
import { ConnectionSettingsItemType, InfoSystemConnectionSettingsType } from '../../types/types';
import { Text } from '@consta/uikit/Text';

interface IInfoSystemSectionDisplayProps {
  connectionSettings: ConnectionSettingsItemType[] | undefined;
}

const InfoSystemSectionDisplay: React.FC<IInfoSystemSectionDisplayProps> = ({ connectionSettings }) => {
  const infoSystemParam = connectionSettings?.find((setting) => setting.key === 'info_system');
  if (!infoSystemParam) {
    return null;
  }
  const infoSystemName = (infoSystemParam?.value as InfoSystemConnectionSettingsType)?.name;

  return (
    <div className={classes.sectionItem}>
      <Text className={classes.sectionItemTitle}>{`Интегрируемая система`}</Text>
      <Text className={classes.sectionItemValue}>{infoSystemName ?? '-'}</Text>
    </div>
  );
};

export default InfoSystemSectionDisplay;
