import React from 'react';

import classes from './DisplayConnectionContentSelector.module.css';
import { StepSelectorItemType } from '../../ConnectionStepSelector/ConnectionStepSelector';
import DisplayConnectionGeneral from './DisplayConnectionGeneral/DisplayConnectionGeneral';
import DisplayConnectionSettings from './DisplayConnectionSettings/DisplayConnectionSettings';
import DisplayConnectionAdditional from './DisplayConnectionAdditional/DisplayConnectionAdditional';
import DisplayConnectionSchedule from './DisplayConnectionSchedule/DisplayConnectionSchedule';
import { ConnectionDetailType, ConnectionType } from '../../../../../types/types';

interface IDisplayConnectionContentSelectorProps {
  stepSelectorValue: StepSelectorItemType | null;
  connection: ConnectionType;
  connectionDetail: ConnectionDetailType | undefined;
}

const DisplayConnectionContentSelector: React.FC<IDisplayConnectionContentSelectorProps> = ({
  stepSelectorValue,
  connection,
  connectionDetail,
}) => {
  return (
    <div className={classes.container}>
      {stepSelectorValue ? (
        stepSelectorValue.id === 'general' ? (
          <DisplayConnectionGeneral connection={connection} />
        ) : stepSelectorValue.id === 'settings' ? (
          <DisplayConnectionSettings connectionDetail={connectionDetail} />
        ) : stepSelectorValue.id === 'additional' ? (
          <DisplayConnectionAdditional connectionDetail={connectionDetail} />
        ) : stepSelectorValue.id === 'schedule' ? (
          <DisplayConnectionSchedule connectionDetail={connectionDetail} />
        ) : null
      ) : null}
    </div>
  );
};

export default DisplayConnectionContentSelector;
