import { FilterBarItemType } from '../shared-front/FilterBar/FilterBar';
import { MultiFilterType } from '../Components/FilterWithScrollAndSearch/FilterWithScrollAndSearch';

export const getNewActiveMultiFiltersByFilterBarTags = (
  multiFilters: MultiFilterType[],
  tagItems: FilterBarItemType[]
): MultiFilterType[] => {
  const activeMultiFiltersTagUuids = tagItems
    .filter((tagItem) => tagItem.uuid.split('-*-')[0] === 'multiFilter')
    .map((item) => item.uuid.split('-*-')[1]);

  return multiFilters.map((multiFilter) => {
    return {
      ...multiFilter,
      items: multiFilter.items.map((item) =>
        activeMultiFiltersTagUuids.includes(item.uuid) ? { ...item, isChecked: true } : { ...item, isChecked: false }
      ),
    };
  });
};
