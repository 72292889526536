import React from 'react';

import classes from './DisplayConnectionSchedule.module.css';
import { ConnectionDetailType } from '../../../../../../types/types';
import { Text } from '@consta/uikit/Text';
import CollapseBlock from '../../../../../../Components/CollapseBlock/CollapseBlock';

interface IDisplayConnectionScheduleProps {
  connectionDetail: ConnectionDetailType | undefined;
}

const DisplayConnectionSchedule: React.FC<IDisplayConnectionScheduleProps> = ({ connectionDetail }) => {
  return (
    <div className={classes.container}>
      <CollapseBlock
        title={'Расписание сканирования'}
        isOpen={true}
        isShortContainer={true}
        isShortChildrenContainer={true}
      >
        <div className={classes.contentCollapse}>
          <div className={classes.groupContainer}>
            <Text className={classes.groupTitle}>{'Расписание'}</Text>
            <div className={classes.groupRow}>
              {connectionDetail?.scanSchedule ? (
                <Text className={classes.groupText}>{connectionDetail.scanSchedule}</Text>
              ) : (
                <Text className={classes.groupText}>{'нет'}</Text>
              )}
            </div>
          </div>
          <div className={classes.groupContainer}>
            <Text className={classes.groupTitle}>{'CRON'}</Text>
            <div className={classes.groupRow}>
              {connectionDetail?.scanSchedule ? (
                <Text className={classes.groupText}>{connectionDetail.scanSchedule}</Text>
              ) : (
                <Text className={classes.groupText}>{'нет'}</Text>
              )}
            </div>
          </div>
        </div>
      </CollapseBlock>
      {connectionDetail?.previewSchedule && (
        <CollapseBlock
          title={'Расписание сканирования предпросмотра'}
          isOpen={true}
          isShortContainer={true}
          isShortChildrenContainer={true}
        >
          <div className={classes.contentCollapse}>
            <div className={classes.groupContainer}>
              <Text className={classes.groupTitle}>{'Расписание'}</Text>
              <div className={classes.groupRow}>
                {connectionDetail?.previewSchedule ? (
                  <Text className={classes.groupText}>{connectionDetail.previewSchedule}</Text>
                ) : (
                  <Text className={classes.groupText}>{'нет'}</Text>
                )}
              </div>
            </div>
            <div className={classes.groupContainer}>
              <Text className={classes.groupTitle}>{'CRON'}</Text>
              <div className={classes.groupRow}>
                {connectionDetail?.previewSchedule ? (
                  <Text className={classes.groupText}>{connectionDetail.previewSchedule}</Text>
                ) : (
                  <Text className={classes.groupText}>{'нет'}</Text>
                )}
              </div>
            </div>
          </div>
        </CollapseBlock>
      )}
    </div>
  );
};

export default DisplayConnectionSchedule;
