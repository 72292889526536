import React, { useEffect, useRef } from 'react';

import classes from './LaunchHistoryContentContainer.module.css';
import { Loader } from '@consta/uikit/Loader';
import LaunchHistoryTable from './LaunchHistoryTable/LaunchHistoryTable';
import { useAppSelector } from '../../../../../hooks/hooks';
import { selectorIsLoadingLaunchHistory } from '../../../../../store/launchHistorySlice';
import { useGetLaunchHistory } from '../../../../../hooks/useGetLaunchHistory';

interface ILaunchHistoryContentContainerProps {}

const LaunchHistoryContentContainer: React.FC<ILaunchHistoryContentContainerProps> = () => {
  const refFirstUpload = useRef(false);
  const getLaunchHistory = useGetLaunchHistory();
  const isLoadingLaunchHistory = useAppSelector(selectorIsLoadingLaunchHistory);

  useEffect(() => {
    if (!refFirstUpload.current) {
      refFirstUpload.current = true;
      getLaunchHistory({});
    }
  }, [getLaunchHistory]);

  return <div className={classes.container}>{isLoadingLaunchHistory ? <Loader /> : <LaunchHistoryTable />}</div>;
};

export default LaunchHistoryContentContainer;
