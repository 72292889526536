import { HierarchyItemType } from '../types/types';
import { checkIsAllGrandChildrenSelected } from './Hierarchy/checkIsAllGrandChildrenSelected';
import { getUniqTypedQueries } from './Hierarchy/getUniqTypedQueries';
import { getUniqTypesFromQueriesArray } from './Hierarchy/getUniqTypesFromQueriesArray';

export const getQueryHierarchyFilter = (hierarchyItems: HierarchyItemType[]) => {
  let queries: string[] = [];

  const makeHierarchyQuery = (hierarchyItem: HierarchyItemType) => {
    if (hierarchyItem.children) {
      if (checkIsAllGrandChildrenSelected(hierarchyItem)) {
        const curQuery = `filter[${hierarchyItem.type}]=${hierarchyItem.uuid}`;
        queries.push(curQuery);
      } else {
        hierarchyItem.children.forEach((childrenItem) => {
          if (!childrenItem.has_checkbox) {
            if (hierarchyItem.isChecked) {
              const curQuery = `filter[${hierarchyItem.type}]=${hierarchyItem.uuid}`;
              queries.push(curQuery);
            } else {
              makeHierarchyQuery(childrenItem);
            }
          } else {
            makeHierarchyQuery(childrenItem);
          }
        });
      }
    } else {
      if (hierarchyItem.isChecked) {
        const curQuery = `filter[${hierarchyItem.type}]=${hierarchyItem.uuid}`;
        queries.push(curQuery);
      } else {
        return;
      }
    }
    return;
  };

  hierarchyItems?.forEach((hierarchyItem) => {
    makeHierarchyQuery(hierarchyItem);
  });
  const uniqTypes = getUniqTypesFromQueriesArray(queries);
  const typedQueries = getUniqTypedQueries({ uniqTypes, queries });
  return typedQueries.join('&');
};
