import React from 'react';
import classes from './FilterWithScrollAndSearch.module.css';
import { TextField } from '@consta/uikit/TextField';
import { IconSearch } from '@consta/uikit/IconSearch';
import FilterItemsList from '../MultiFilter/FilterItemsList/FilterItemsList';
import { IconClose } from '@consta/uikit/IconClose';

export type MultiFilterItemType = {
  uuid: string;
  value: string;
  isChecked: boolean;
};

export type MultiFilterType = {
  name: string;
  title: string;
  items: MultiFilterItemType[];
  searchTitleValue?: string | null;
};

const getItemWithSearchValue = (items: MultiFilterItemType[], searchTitleValue: string | null) => {
  if (!searchTitleValue) {
    return true;
  }
  for (let item of items) {
    if (item.value.toLowerCase().includes(searchTitleValue.toLowerCase())) {
      return true;
    }
  }
  return false;
};

interface IFilterWithScrollAndSearchProps {
  name: string;
  title: string;
  items: MultiFilterItemType[];
  searchTitleValue: string | null;
  onChangeFilter: (multiFilter: MultiFilterType) => void;
}

const FilterWithScrollAndSearch: React.FC<IFilterWithScrollAndSearchProps> = ({
  name,
  title,
  items,
  searchTitleValue,
  onChangeFilter,
}) => {
  const handleChangeSearchTitleValue = ({ value }: { value: string | null }) => {
    onChangeFilter && onChangeFilter({ name, title, items, searchTitleValue: value });
  };

  const handleChangeFilterItems = (items: MultiFilterItemType[]) => {
    onChangeFilter && onChangeFilter({ name, title, items, searchTitleValue });
  };

  const isItemWithSearchValue = getItemWithSearchValue(items, searchTitleValue);

  const ResetSearchBtn = () => {
    const onClick = () => {
      onChangeFilter && onChangeFilter({ name, title, items, searchTitleValue: '' });
    };
    return (
      <IconClose style={{ width: '12px', cursor: 'pointer', marginTop: '2px', color: '#00395C' }} onClick={onClick} />
    );
  };

  return (
    <div className={classes.container}>
      <div className={classes.textField}>
        <TextField
          value={searchTitleValue}
          onChange={handleChangeSearchTitleValue}
          style={{ flexGrow: '1' }}
          leftSide={IconSearch}
          rightSide={searchTitleValue ? ResetSearchBtn : undefined}
          placeholder="Найти в списке"
          size={'s'}
        />
      </div>
      {isItemWithSearchValue && (
        <FilterItemsList
          items={items}
          onChangeFilterItems={handleChangeFilterItems}
          searchTitleValue={searchTitleValue}
        />
      )}
    </div>
  );
};

export default FilterWithScrollAndSearch;
