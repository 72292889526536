import React from 'react';

import classes from './CreateConnectionScanAll.module.css';
import { Checkbox } from '@consta/uikit/Checkbox';
import { CheckConnectionAssetType } from '../../../../../../../api/serverResponse';
import { ItemComboboxType } from '../../../../../../../types/types';
import { useAppSelector } from '../../../../../../../hooks/hooks';
import { selectorIsNewConnectionChecked } from '../../../../../../../store/createNewConnectionSlice';

interface ICreateConnectionScanAllProps {
  newConnectionScanAll: boolean;
  newConnectionToggleScanAll: () => void;
  connectionAssets: CheckConnectionAssetType[] | undefined;
  onChangeAssets: (value: ItemComboboxType[] | null) => void;
}

const CreateConnectionScanAll: React.FC<ICreateConnectionScanAllProps> = ({
  newConnectionScanAll,
  newConnectionToggleScanAll,
  connectionAssets,
  onChangeAssets,
}) => {
  const isNewConnectionChecked = useAppSelector(selectorIsNewConnectionChecked);

  const onChangeHandler = () => {
    if (!newConnectionScanAll && !!connectionAssets?.length) {
      const assets: ItemComboboxType[] = connectionAssets.map((asset, ind) => ({ label: asset.name ?? '', id: ind }));
      onChangeAssets(assets);
    }
    newConnectionToggleScanAll();
  };

  return (
    <div className={classes.container}>
      <Checkbox
        size={'m'}
        checked={newConnectionScanAll}
        label={'Сканировать все, автоматически включая новые'}
        onChange={onChangeHandler}
        disabled={!isNewConnectionChecked}
      />
    </div>
  );
};

export default CreateConnectionScanAll;
