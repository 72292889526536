import React from 'react';

import classes from './HierarchyLevelLabel.module.css';
import { HierarchyItemType } from '../../../../../../../types/types';
import { Text } from '@consta/uikit/Text';
import { Checkbox } from '@consta/uikit/Checkbox';
import classNames from 'classnames';
import { useAppDispatch } from '../../../../../../../hooks/hooks';
import {
  checkAndDeCheckParents,
  setIsSelectForHierarchyItemAndAllChildrenStore,
  setIsSelectHierarchyItemStore,
} from '../../../../../../../store/catalogSlice';
import { checkIsOneChildrenSelected } from '../../../../../../../utils/Hierarchy/checkIsOneChildrenSelected';

import { checkIsOneGrandChildrenSelected } from '../../../../../../../utils/Hierarchy/checkIsOneGrandChildrenSelected';
import { checkIsAllGrandChildrenSelected } from '../../../../../../../utils/Hierarchy/checkIsAllGrandChildrenSelected';

interface IHierarchyLevelLabelProps {
  hierarchyItem: HierarchyItemType;
  level?: number;
}

const HierarchyLevelLabel: React.FC<IHierarchyLevelLabelProps> = ({ hierarchyItem, level }) => {
  const dispatch = useAppDispatch();

  let intermediate = false;
  let checked = hierarchyItem.isChecked;

  const isOneChildrenSelected = checkIsOneChildrenSelected(hierarchyItem);
  const isAllGrandChildrenSelected = checkIsAllGrandChildrenSelected(hierarchyItem);
  const isOneGrandChildrenSelected = checkIsOneGrandChildrenSelected(hierarchyItem);

  if (isOneGrandChildrenSelected || isOneChildrenSelected) {
    intermediate = true;
    if (isAllGrandChildrenSelected && hierarchyItem.isChecked) {
      intermediate = false;
    }
  }

  const onClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (hierarchyItem.has_checkbox) {
      if (hierarchyItem.has_childs) {
        dispatch(
          setIsSelectForHierarchyItemAndAllChildrenStore({
            uuid: hierarchyItem.uuid,
            isChecked: !hierarchyItem.isChecked,
          })
        );
      } else {
        dispatch(setIsSelectHierarchyItemStore({ uuid: hierarchyItem.uuid, isChecked: !hierarchyItem.isChecked }));
      }
      dispatch(checkAndDeCheckParents(hierarchyItem));
    }
  };

  return (
    <div className={classes.container} onClick={onClick}>
      {hierarchyItem.has_checkbox && (
        <Checkbox size="m" checked={checked} className={classes.checkbox} intermediate={intermediate} />
      )}
      <div style={{ width: level ? 360 - level * (hierarchyItem.has_checkbox ? 28 : 16) : 360 }}>
        <Text className={classNames(classes.title, { [classes.groupTitle]: !hierarchyItem.has_checkbox })}>
          {hierarchyItem.name}
        </Text>
      </div>
    </div>
  );
};

export default HierarchyLevelLabel;
