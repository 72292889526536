import React from 'react';
import classes from './CardsList.module.css';
import CardsListItem from './CardsListItem/CardsListItem';
import { useAppSelector } from '../../../../../../hooks/hooks';
import { selectorCatalogItems } from '../../../../../../store/catalogSlice';

const CardsList: React.FC = () => {
  const catalogItems = useAppSelector(selectorCatalogItems);

  return (
    <div className={classes.container}>
      {catalogItems?.data.map((catalogItem, ind) => (
        <CardsListItem key={ind} catalogItem={catalogItem} />
      ))}
    </div>
  );
};

export default CardsList;
