import React from 'react';

import classes from './ConnectionStepSelector.module.css';
import { ChoiceGroup } from '@consta/uikit/ChoiceGroup';

interface IDisplayStepSelectorProps {
  stepSelectorValue: StepSelectorItemType | null;
  setStepSelectorValue: (value: StepSelectorItemType | null) => void;
}

export type StepSelectorItemType = {
  id: 'general' | 'settings' | 'additional' | 'schedule';
  name: string;
};

export const stepSelectorItems: StepSelectorItemType[] = [
  { id: 'general', name: 'Общие' },
  { id: 'settings', name: 'Настройки' },
  { id: 'additional', name: 'Расширенные' },
  { id: 'schedule', name: 'Расписание' },
];

const ConnectionStepSelector: React.FC<IDisplayStepSelectorProps> = ({ stepSelectorValue, setStepSelectorValue }) => {
  const setValueHandler = ({ value }: { value: StepSelectorItemType | null }) => {
    setStepSelectorValue(value);
  };

  return (
    <div className={classes.container}>
      <ChoiceGroup
        value={stepSelectorValue}
        onChange={setValueHandler}
        items={stepSelectorItems}
        getItemLabel={(item) => item.name}
        name="DisplayConnectionStepSelector"
        size={'s'}
        multiple={false}
        width={'full'}
        form={'brick'}
        className={classes.choiceGroup}
      />
    </div>
  );
};

export default ConnectionStepSelector;
