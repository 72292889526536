import { createTableColumn } from '../../../../../../shared-front/Table/utils/createTableColumn';
import { ColumnGenericType } from '../../../../../../types/types';
import { ReactNode } from 'react';

export type TableRdbDataType = {
  system?: string | null;
  name?: string | null;
  hierarchy?: ReactNode | null;
  unified_object?: string | null;
  connector_type?: string | null;
  _alter_ts?: string | null;
  description_name?: string | null;
  object?: string | null;
  object_attr?: string | null;
  attr_type?: string | null;
  object_kind?: string | null;
  description_value?: string | null;
  organization?: string | null;
  full_name?: string | null;
  table_part?: string | null;
};

export const TABLE_ODATA1C_COLUMNS: ColumnGenericType[] = [
  createTableColumn<TableRdbDataType>({
    accessorKey: 'system',
    title: 'Интегрируемая Система',
    textLinesNumber: 2,
    isShow: true,
    size: 300,
  }),
  createTableColumn<TableRdbDataType>({
    accessorKey: 'name',
    title: 'Наименование',
    textLinesNumber: 2,
    isShow: true,
    size: 300,
  }),
  createTableColumn<TableRdbDataType>({
    accessorKey: 'hierarchy',
    title: 'Иерархия',
    textLinesNumber: 2,
    isShow: true,
    size: 300,
  }),
  createTableColumn<TableRdbDataType>({
    accessorKey: 'unified_object',
    title: 'Тип',
    textLinesNumber: 2,
    isShow: true,
    size: 300,
  }),
  createTableColumn<TableRdbDataType>({
    accessorKey: 'connector_type',
    title: 'Тип источника',
    textLinesNumber: 2,
    isShow: true,
    size: 300,
  }),
  createTableColumn<TableRdbDataType>({
    accessorKey: '_alter_ts',
    title: 'Изменено',
    textLinesNumber: 2,
    isShow: true,
    size: 300,
  }),
  createTableColumn<TableRdbDataType>({
    accessorKey: 'description_name',
    title: 'Краткое описание',
    textLinesNumber: 2,
    isShow: true,
    size: 300,
  }),
  createTableColumn<TableRdbDataType>({
    accessorKey: 'object',
    title: 'Объект данных',
    textLinesNumber: 2,
    isShow: false,
    size: 300,
  }),
  createTableColumn<TableRdbDataType>({
    accessorKey: 'object_attr',
    title: 'Атрибут',
    textLinesNumber: 2,
    isShow: false,
    size: 300,
  }),
  createTableColumn<TableRdbDataType>({
    accessorKey: 'attr_type',
    title: 'Тип данных атрибута',
    textLinesNumber: 2,
    isShow: false,
    size: 300,
  }),
  createTableColumn<TableRdbDataType>({
    accessorKey: 'object_kind',
    title: 'Тип объекта данных БД',
    textLinesNumber: 2,
    isShow: false,
    size: 300,
  }),
  createTableColumn<TableRdbDataType>({
    accessorKey: 'description_value',
    title: 'Расширенное описание',
    textLinesNumber: 2,
    isShow: false,
    size: 300,
  }),
  createTableColumn<TableRdbDataType>({
    accessorKey: 'organization',
    title: 'Организация',
    textLinesNumber: 2,
    isShow: false,
    size: 300,
  }),
  createTableColumn<TableRdbDataType>({
    accessorKey: 'full_name',
    title: 'Полное наименование',
    textLinesNumber: 2,
    isShow: false,
    size: 300,
  }),
  createTableColumn<TableRdbDataType>({
    accessorKey: 'table_part',
    title: 'Табличная часть',
    textLinesNumber: 2,
    isShow: false,
    size: 300,
  }),
];
