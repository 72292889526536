import { ConnectionDetailType } from '../../types/types';

export type SettingsValidationErrorType = 'requiredConnectionAssets';

export const validateSettingsForEditConnection = (editConnection: ConnectionDetailType) => {
  let validationErrors: SettingsValidationErrorType[] = [];
  if (!editConnection.assets?.length) {
    validationErrors.push('requiredConnectionAssets');
  }

  if (!!validationErrors.length) {
    return validationErrors;
  } else {
    return undefined;
  }
};
