import React from 'react';
import classes from './TabsRow.module.css';

import { Tabs } from '@consta/uikit/Tabs';

import {
  selectorCatalogActiveSection,
  selectorCatalogHierarchy,
  selectorCatalogSections,
  selectorCatalogsIsLoadingItems,
  setActiveSection,
} from '../../../../../store/catalogSlice';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/hooks';
import { CatalogSectionType } from '../../../../../types/types';
import { useGetTechMetaDocuments } from '../../../../../hooks/useGetTechMetaDocuments';
import { abortControllerCatalogItems } from '../../../../../api/catalogApi';

const TabsRow: React.FC = () => {
  const dispatch = useAppDispatch();
  const getTechMetaDocuments = useGetTechMetaDocuments();
  const catalogSections = useAppSelector(selectorCatalogSections);
  const catalogHierarchy = useAppSelector(selectorCatalogHierarchy);
  const catalogActiveSection = useAppSelector(selectorCatalogActiveSection);
  const isLoadingCatalogItems = useAppSelector(selectorCatalogsIsLoadingItems);

  const changeHandler = ({ value }: { value: CatalogSectionType }) => {
    const hierarchyItems = catalogHierarchy[`${value?.slug}`];
    if (!isLoadingCatalogItems) {
      dispatch(setActiveSection(value));
      getTechMetaDocuments({ catalogActiveSection: value, hierarchyItems });
    } else {
      abortControllerCatalogItems.abort();
      dispatch(setActiveSection(value));
      setTimeout(() => {
        getTechMetaDocuments({ catalogActiveSection: value, hierarchyItems });
      });
    }
  };

  return (
    <div className={classes.container}>
      {!!catalogSections.length && catalogActiveSection && (
        <Tabs view={'clear'} value={catalogActiveSection} items={catalogSections} onChange={changeHandler} size="m" />
      )}
    </div>
  );
};

export default TabsRow;
