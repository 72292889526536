import React, { useState } from 'react';
import classes from './FilterCollapseContainer.module.css';
import { Collapse } from '@consta/uikit/Collapse';
import { Text } from '@consta/uikit/Text';
import { Button } from '@consta/uikit/Button';

import './constaClassesFix.css';

interface IFilterContainerProps {
  title: string;
  onClearFilter: () => void;
  children: React.ReactNode;
  isFilterHasSelectedItem: boolean;
}

const FilterCollapseContainer: React.FC<IFilterContainerProps> = ({
  title,
  onClearFilter,
  children,
  isFilterHasSelectedItem,
}) => {
  const [isOpen, setOpen] = useState<boolean>(false);

  const clearFilterHandler = (e: React.MouseEvent) => {
    e.stopPropagation();
    onClearFilter();
  };

  return (
    <Collapse
      iconPosition="right"
      className={classes.container}
      isOpen={isOpen}
      onClick={() => setOpen(!isOpen)}
      label={
        <div className={classes.label}>
          <Text className={classes.title} truncate>
            {title}
          </Text>
          {isFilterHasSelectedItem && (
            <Button
              label="Сбросить"
              view="clear"
              size={'s'}
              className={classes.resetBtn}
              onClick={clearFilterHandler}
            />
          )}
        </div>
      }
    >
      <div className={classes.collapsePart}>
        <div className={classes.delimiter} />
        <div className={classes.collapseContent}>{children}</div>
      </div>
    </Collapse>
  );
};

export default FilterCollapseContainer;
