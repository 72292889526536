import React from 'react';

import classes from './TextWithMark.module.css';
import iconAllDone from '../../assets/CatalogPage/Cards/iconAllDone.svg';
import { Text } from '@consta/uikit/Text';
import iconCancel from '../../assets/CatalogPage/Cards/iconCancel.svg';

interface ITextWithMarkProps {
  isComplete: boolean | null | undefined;
  textDone: string;
  textNotDone: string;
  isSecondaryColor?: boolean;
}

const TextWithMark: React.FC<ITextWithMarkProps> = ({ isComplete, textDone, textNotDone, isSecondaryColor }) => {
  return (
    <div className={`${classes.container} ${isSecondaryColor ? classes.secondaryColor : ''}`}>
      {isComplete ? (
        <div className={classes.row}>
          <img src={iconAllDone} alt="iconAllDone" />
          <Text className={classes.title}>{textDone}</Text>
        </div>
      ) : (
        <div className={classes.row}>
          <img src={iconCancel} alt="iconCancel" />
          <Text className={classes.title}>{textNotDone}</Text>
        </div>
      )}
    </div>
  );
};

export default TextWithMark;
