import { useAppDispatch, useAppSelector } from './hooks';
import {
  clearConnectionsError,
  selectorConnections,
  selectorConnectionsTableActiveRowUuid,
  setConnectionsError,
} from '../store/connectionsSlice';
import { selectorNewCreatedConnection } from '../store/createNewConnectionSlice';
import { useCallback, useState } from 'react';
import { ConnectionDetailType } from '../types/types';
import { serverApi } from '../api/serverApi';

export const useDisplayConnection = (closeSideBar: () => void) => {
  const dispatch = useAppDispatch();
  const connections = useAppSelector(selectorConnections);
  const newCreatedConnection = useAppSelector(selectorNewCreatedConnection);
  const connectionsTableActiveRowUuid = useAppSelector(selectorConnectionsTableActiveRowUuid);

  const activeConnection = connections?.data.find((connection) => connection._uuid === connectionsTableActiveRowUuid);
  const displayConnection = newCreatedConnection ?? activeConnection;

  const [connectionDetail, setConnectionDetail] = useState<ConnectionDetailType | undefined>(undefined);
  const [isLoadingConnectionDetail, setIsLoadingConnectionsDetail] = useState(false);

  const getConnectionDetail = useCallback(() => {
    if (displayConnection?._uuid) {
      setIsLoadingConnectionsDetail(true);
      dispatch(clearConnectionsError());
      serverApi
        .getConnectionDetail(displayConnection._uuid)
        .then((result) => {
          setConnectionDetail(result);
        })
        .catch((e) => {
          closeSideBar();
          dispatch(setConnectionsError('Ошибка получения данных для подключения.\n' + e.message));
        })
        .finally(() => {
          setIsLoadingConnectionsDetail(false);
        });
    }
  }, [dispatch, closeSideBar, displayConnection?._uuid]);

  return { displayConnection, connectionDetail, getConnectionDetail, isLoadingConnectionDetail };
};
