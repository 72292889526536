import React, { useEffect, useRef } from 'react';

import Delimiter from '../../Delimiter/Delimiter';
import { GeneralTableColumnType } from '../../Table/Table';
import { getShortColumns } from '../../Table/utils/getShortColumns';
import classes from './TableSettingsControl.module.css';
import { Checkbox } from '@consta/uikit/Checkbox';
import { Text } from '@consta/uikit/Text';

interface ITableSettingsControlProps {
  columns: GeneralTableColumnType[];
  setColumns: (columns: GeneralTableColumnType[]) => void;
  defaultColumns?: GeneralTableColumnType[];
}

const getNotSelectedAllColumns = (columns: GeneralTableColumnType[]): boolean => {
  for (let column of columns) {
    if (column.isShow) {
      return false;
    }
  }
  return true;
};

const getIsSelectedAllColumns = (columns: GeneralTableColumnType[]): boolean => {
  for (let column of columns) {
    if (!column.isShow) {
      return false;
    }
  }
  return true;
};

const getIsSelectedOneColumn = (columns: GeneralTableColumnType[]): boolean => {
  for (let column of columns) {
    if (column.isShow) {
      return true;
    }
  }
  return false;
};

const TableSettingsControl: React.FC<ITableSettingsControlProps> = ({ columns, setColumns, defaultColumns }) => {
  const isSelectedAllColumns = getIsSelectedAllColumns(columns);
  const isNotSelectedAllColumns = getNotSelectedAllColumns(columns);
  const isSelectedOneColumn = getIsSelectedOneColumn(columns);

  const handlerToggleSelectAll = () => {
    if (isSelectedAllColumns) {
      setColumns(columns.map((column) => ({ ...column, isShow: false })));
    } else {
      setColumns(columns.map((column) => ({ ...column, isShow: true })));
    }
  };

  const refDefaultColumns = useRef<GeneralTableColumnType[]>();
  useEffect(() => {
    if (!refDefaultColumns.current) {
      refDefaultColumns.current = columns;
    }
  }, [columns]);

  const setDefaultColumns = () => {
    if (defaultColumns) {
      setColumns(getShortColumns(defaultColumns));
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.checkBoxRow}>
        <Checkbox
          checked={isSelectedAllColumns}
          intermediate={!isSelectedAllColumns && !isNotSelectedAllColumns && isSelectedOneColumn}
          onChange={handlerToggleSelectAll}
        />
        <Text className={classes.title}>{`СТОЛБЦЫ`}</Text>
        {defaultColumns && (
          <div className={classes.rightPart}>
            <Text className={classes.textButton} onClick={setDefaultColumns}>{`По умолчанию`}</Text>
          </div>
        )}
      </div>
      <Delimiter />
    </div>
  );
};

export default TableSettingsControl;
