import { BookmarkType, CatalogSectionType, ObjectsDataType } from '../../types/types';
import { combineQuery } from '../combineQuery';
import { getPaginationQuery } from './getPaginationQuery';

export const getObjectsQueryParams = ({
  bookmark,
  objectTypeTab,
  objectsData,
  paginationString,
  searchValue,
  resetPagination,
}: {
  bookmark: BookmarkType;
  objectTypeTab: CatalogSectionType;
  searchValue: string;
  objectsData?: ObjectsDataType;
  paginationString?: string;
  resetPagination?: boolean;
}) => {
  const filterValue = bookmark.slug === 'schema' ? 'schema' : bookmark.slug === 'db' ? bookmark.type : bookmark.slug;
  const objectsQuery = `filter[${filterValue}]=${bookmark.hash}&page[type]=${objectTypeTab.slug}`;

  const paginationQuery = paginationString
    ? paginationString
    : getPaginationQuery({
        pageSize: objectsData ? objectsData[objectTypeTab.slug]?.per_page : undefined,
        pageNumber: objectsData ? (resetPagination ? 1 : objectsData[objectTypeTab.slug]?.from) : undefined,
      });
  const searchQuery = searchValue ? `filter[search]=${searchValue}` : '';
  return combineQuery([objectsQuery, searchQuery, paginationQuery, 'filter[exclude]=attributes']);
};
