import React from 'react';

import classes from './ObjectTypeTabsRow.module.css';
import { Tabs } from '@consta/uikit/Tabs';
import { CatalogSectionType } from '../../../../../../../types/types';

interface IObjectTypeTabsRowProps {
  tabs: CatalogSectionType[];
  activeTab: CatalogSectionType | undefined;
  onChangeTab: ({ value }: { value: CatalogSectionType }) => void;
  isLoadingObjects: boolean | undefined;
}

const ObjectTypeTabsRow: React.FC<IObjectTypeTabsRowProps> = ({ tabs, activeTab, onChangeTab, isLoadingObjects }) => {
  const onChangeHandler = ({ value }: { value: CatalogSectionType }) => {
    if (!isLoadingObjects) {
      onChangeTab({ value });
    }
  };

  return (
    <div className={classes.container}>
      <Tabs view={'clear'} value={activeTab} items={tabs} onChange={onChangeHandler} size="m" />
    </div>
  );
};

export default ObjectTypeTabsRow;
