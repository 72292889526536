import { HierarchyItemType } from '../../types/types';

const selectAllChildren = ({
  hierarchyItem,
  isChecked,
}: {
  hierarchyItem: HierarchyItemType;
  isChecked: boolean;
}): HierarchyItemType => {
  return {
    ...hierarchyItem,
    isChecked,
    children: hierarchyItem.children
      ? hierarchyItem.children.map((child) => selectAllChildren({ hierarchyItem: child, isChecked }))
      : undefined,
  };
};

export const setIsSelectForHierarchyItemAndAllChildren = ({
  hierarchyItems,
  itemUuid,
  isChecked,
}: {
  hierarchyItems: HierarchyItemType[];
  itemUuid: string;
  isChecked: boolean;
}): HierarchyItemType[] => {
  return hierarchyItems.map((hierarchyItem) =>
    hierarchyItem.uuid === itemUuid
      ? {
          ...hierarchyItem,
          isChecked,
          children: hierarchyItem.children
            ? hierarchyItem.children.map((child) => selectAllChildren({ hierarchyItem: child, isChecked }))
            : undefined,
        }
      : hierarchyItem.children
      ? {
          ...hierarchyItem,
          children: setIsSelectForHierarchyItemAndAllChildren({
            hierarchyItems: hierarchyItem.children,
            itemUuid,
            isChecked,
          }),
        }
      : hierarchyItem
  );
};
