import React from 'react';
import classes from './DetailedAssetByTypeCard.module.css';
import { Text } from '@consta/uikit/Text';
import { Button } from '@consta/uikit/Button';
import { AssetByTypeType } from '../../../../api/serverResponse';
import { useAppDispatch } from '../../../../hooks/hooks';
import { setActiveSection } from '../../../../store/catalogSlice';
import { useNavigate } from 'react-router-dom';
import { PageRoutesEnum } from '../../../../appMenuAndRouting/appRoutes';
import { CatalogSectionType } from '../../../../types/types';
import { declOfNum } from '../../../../shared-front/utils/declOfNum';
import SourcesList from './SourcesList/SourcesList';

type PropsType = {
  assetByType: AssetByTypeType;
};

const DetailedAssetByTypeCard: React.FC<PropsType> = ({ assetByType }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const clickHandler = () => {
    const catalogSection: CatalogSectionType = {
      id: assetByType.slug,
      shortName: assetByType.short_name,
      label: assetByType.name,
      disabled: !assetByType.assets_count,
      slug: assetByType.slug,
    };
    dispatch(setActiveSection(catalogSection));
    navigate(PageRoutesEnum.CatalogPage);
  };

  return (
    <div className={classes.container}>
      <div className={classes.leftPart}>
        <div className={classes.infoBlock}>
          <Text className={classes.title}>{assetByType.name}</Text>
          <Text className={classes.description}>{assetByType.description}</Text>
        </div>
        <Button
          className={classes.buttonOpen}
          label="Открыть"
          form="round"
          disabled={!assetByType.assets_count}
          onClick={clickHandler}
          size={'s'}
        />
      </div>
      <div className={classes.verticalDelimiter} />
      <div className={classes.rightPart}>
        {!!assetByType.source_types?.length && (
          <>
            <Text className={classes.description}>{'Ресурсы:'}</Text>
            <SourcesList sourceTypes={assetByType.source_types} />
          </>
        )}
      </div>
      <div className={classes.assetsAmountColumn}>
        <Text className={classes.amount}>{assetByType.assets_count.toLocaleString()}</Text>
        <Text className={classes.amountDescription}>
          {declOfNum(
            Number(assetByType.assets_count),
            assetByType.slug === 'system'
              ? ['инф. система', 'инф. системы', 'инф. систем']
              : ['объект', 'объекта', 'объектов']
          )}
        </Text>
      </div>
    </div>
  );
};

export default DetailedAssetByTypeCard;
