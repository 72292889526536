import React, { useEffect } from 'react';

import classes from './Card1CObjectContent.module.css';
import Info1CObjectRow from './Info1CObjectRow/Info1CObjectRow';
import { BookmarkType } from '../../../../types/types';
import CardTabsRow, { CardTabType } from '../../../../Components/CardTabsRow/CardTabsRow';
import Content1CObjectByTabs from './Content1CObjectByTabs/Content1CObjectByTabs';
import { useActiveTab } from '../../../../hooks/useActiveTab';
import { useLoadBookmarkData } from '../../../../hooks/useLoadBookmarkData';
import WrapperCatalogObjectNotFound from '../../../../Components/WrapperCatalogObjectNotFound/WrapperCatalogObjectNotFound';

interface ICardSchemaContentProps {
  bookmark: BookmarkType;
}

export const TABS_1C_OBJECT: CardTabType[] = [
  { id: 0, label: 'Общая информация' },
  { id: 1, label: 'Атрибуты' },
  { id: 2, label: 'Предпросмотр' },
];

const Card1CObjectContent: React.FC<ICardSchemaContentProps> = ({ bookmark }) => {
  const loadBookmarkData = useLoadBookmarkData();
  const [activeTab, setActiveTab] = useActiveTab({ bookmark, tabs: TABS_1C_OBJECT });

  useEffect(() => {
    loadBookmarkData(bookmark);
  }, [bookmark, loadBookmarkData]);

  return (
    <div className={classes.container}>
      <WrapperCatalogObjectNotFound>
        <Info1CObjectRow bookmark={bookmark} />
        <CardTabsRow tabs={TABS_1C_OBJECT} activeTab={activeTab} setActiveTab={setActiveTab} />
        <Content1CObjectByTabs activeTab={activeTab} bookmark={bookmark} />
      </WrapperCatalogObjectNotFound>
    </div>
  );
};

export default Card1CObjectContent;
