import React from 'react';

import classes from './CustomBlueTextButton.module.css';
import { Text } from '@consta/uikit/Text';

interface ICustomBlueTextButtonProps {
  title: string;
  onClick?: () => void;
  className?: string;
  IconLeft?: React.ReactNode;
  IconRight?: React.ReactNode;
}

const CustomBlueTextButton: React.FC<ICustomBlueTextButtonProps> = ({
  title,
  onClick,
  className,
  IconLeft,
  IconRight,
}) => {
  const onClickHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    onClick && onClick();
  };

  return (
    <div className={`${classes.container} ${className ? className : ''}`}>
      {IconLeft ? IconLeft : null}
      <Text className={`${classes.textButton} ${className ? className : ''}`} onClick={onClickHandler}>
        {title}
      </Text>
      {IconRight ? IconRight : null}
    </div>
  );
};

export default CustomBlueTextButton;
