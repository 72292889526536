import React from 'react';

import classes from './InfoSystemRow.module.css';
import { Text } from '@consta/uikit/Text';
import { Badge } from '@consta/uikit/Badge';
import { BookmarkSystemType, BookmarkType } from '../../../../../types/types';
import { getBadgeColor } from '../../../../../utils/Catalog/getBadgeColor';
import { selectorCatalogsIsLoadingBookmarkData } from '../../../../../store/catalogSlice';
import { useAppSelector } from '../../../../../hooks/hooks';

interface IInfoRowProps {
  bookmark: BookmarkType;
}

const InfoSystemRow: React.FC<IInfoRowProps> = ({ bookmark }) => {
  const isLoadingBookmarkData = useAppSelector(selectorCatalogsIsLoadingBookmarkData);
  const data = bookmark.data as BookmarkSystemType;

  return (
    <div className={classes.container}>
      {!isLoadingBookmarkData && (
        <>
          <Text className={classes.title}>{data?.name ? data.name : ' '}</Text>
          <div className={classes.row}>
            {data?.unified_object && (
              <Badge
                view="stroked"
                label={data.unified_object.unified_name}
                style={{
                  color: getBadgeColor(data.unified_object.color_group),
                  boxShadow: `inset 0 0 0 1px ${getBadgeColor(data.unified_object.color_group)}`,
                }}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default InfoSystemRow;
