import React, { useState } from 'react';

import classes from './CPRightSideBarModalCheckConnection.module.css';
import { Text } from '@consta/uikit/Text';
import { Button } from '@consta/uikit/Button';
import { Modal } from '@consta/uikit/Modal';
import { ConnectionType } from '../../../../types/types';
import Delimiter from '../../../../shared-front/Delimiter/Delimiter';
import { Badge } from '@consta/uikit/Badge';
import { serverApi } from '../../../../api/serverApi';
import { pushSnackBarItemConnections } from '../../../../store/connectionsSlice';
import { getRejectedValue } from '../../../../store/rejectedValueHelper';
import { useAppDispatch } from '../../../../hooks/hooks';
import { ProgressSpin } from '@consta/uikit/ProgressSpin';
import CustomBlueTextButton from '../../../../Components/CustomBlueTextButton/CustomBlueTextButton';

interface IISPRightSideBarModalDeleteProps {
  isModalOpen: boolean;
  setIsModalOpen: (val: boolean) => void;
  modalTitle: string;
  activeConnection: ConnectionType;
}

export let abortControllerCheckConnectionByUuid = new AbortController();

const CPRightSideBarModalCheckConnection: React.FC<IISPRightSideBarModalDeleteProps> = ({
  isModalOpen,
  setIsModalOpen,
  modalTitle,
  activeConnection,
}) => {
  const dispatch = useAppDispatch();
  const [connectionCheckedStatus, setConnectionCheckedStatus] = useState<'success' | 'system' | 'error'>('system');
  const [isConnectionOnChecking, setIsConnectionOnChecking] = useState(false);

  const onCancel = () => {
    isConnectionOnChecking && abortControllerCheckConnectionByUuid.abort();
    isConnectionOnChecking && setIsConnectionOnChecking(false);
    connectionCheckedStatus !== 'system' && setConnectionCheckedStatus('system');
    setIsModalOpen(false);
  };

  const checkExistedConnection = async () => {
    if (!isConnectionOnChecking) {
      setIsConnectionOnChecking(true);
      setConnectionCheckedStatus('system');
      try {
        const checkConnectionResult = await serverApi.checkConnectionByUuid(activeConnection._uuid);
        if (!checkConnectionResult.data) {
          setIsConnectionOnChecking(false);
          setConnectionCheckedStatus('error');
          dispatch(
            pushSnackBarItemConnections({
              key: Date.now(),
              message: 'Ошибка проверки подключения',
              status: 'alert',
              autoClose: true,
            })
          );
        } else {
          setIsConnectionOnChecking(false);
          setConnectionCheckedStatus('success');
        }
      } catch (e: any) {
        if (e.name === 'CanceledError') {
          abortControllerCheckConnectionByUuid = new AbortController();
        } else if (e.response?.data?.message) {
          setConnectionCheckedStatus('error');
          dispatch(
            pushSnackBarItemConnections({
              key: Date.now(),
              message: `Ошибка проверки подключения\n-${e.response.data.message}`,
              status: 'alert',
              autoClose: true,
            })
          );
        } else {
          setConnectionCheckedStatus('error');
          dispatch(
            pushSnackBarItemConnections({
              key: Date.now(),
              message: getRejectedValue('Ошибка проверки подключения\n- ', e),
              status: 'alert',
              autoClose: true,
            })
          );
        }
        setIsConnectionOnChecking(false);
      }
    }
  };

  return (
    <Modal
      isOpen={isModalOpen}
      hasOverlay
      onClickOutside={onCancel}
      onEsc={onCancel}
      style={{ zIndex: 999 }}
      className={classes.container}
    >
      <Text className={classes.title}>{modalTitle}</Text>
      <div className={classes.section}>
        <div className={classes.sectionItem}>
          <Text className={classes.sectionItemTitle}>{`Наименование`}</Text>
          <Text className={classes.sectionItemValue}>{activeConnection?.name ?? '-'}</Text>
        </div>
      </div>
      <div className={classes.rowResult}>
        {isConnectionOnChecking ? (
          <div className={classes.buttonCancelGroup}>
            <ProgressSpin style={{ marginLeft: '8px', height: '26px' }} />
            <CustomBlueTextButton title={'Отмена'} onClick={onCancel} />
          </div>
        ) : (
          <Badge
            status={connectionCheckedStatus}
            label={
              connectionCheckedStatus === 'system'
                ? 'подключение не проверено'
                : connectionCheckedStatus === 'success'
                ? 'подключение актуально'
                : 'ошибка в подключении'
            }
            style={{ marginBottom: '24px' }}
          />
        )}
      </div>

      <Delimiter />
      <div className={classes.buttonGroup}>
        <Button size="m" view="ghost" label="Отменить" style={{ width: '234px' }} onClick={onCancel} />
        <Button
          size="m"
          view="primary"
          label="Начать проверку"
          style={{ width: '234px' }}
          onClick={checkExistedConnection}
        />
      </div>
    </Modal>
  );
};

export default CPRightSideBarModalCheckConnection;
