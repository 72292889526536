import React, { ReactNode, useEffect, useRef, useState } from 'react';

import classes from './CustomHoverToolTip.module.css';
import { Tooltip } from '@consta/uikit/TooltipCanary';
import { Direction } from '@consta/uikit/__internal__/src/components/Popover/Popover';
import { removeHTMLTags } from '../../shared-front/utils/removeHTMLTags';

interface ICustomHoverToolTipProps {
  title: string;
  children: ReactNode;
  zIndex?: number;
  direction?: Direction;
  className?: string;
  classNameTooltip?: string;
  isRemoveHTMLTags?: boolean;
}

const CustomHoverToolTip: React.FC<ICustomHoverToolTipProps> = ({
  title,
  children,
  zIndex,
  direction,
  className,
  classNameTooltip,
  isRemoveHTMLTags,
}) => {
  const anchorRef = useRef<HTMLDivElement>(null);
  const timer = useRef<NodeJS.Timeout | null>(null);
  const [isToolTipVisible, setIsToolTipVisible] = useState(false);

  const onMouseEnter = () => {
    if (!isToolTipVisible) {
      timer.current = setTimeout(() => {
        setIsToolTipVisible(true);
      }, 500);
    }
  };

  const onMouseLeave = () => {
    if (isToolTipVisible) {
      setIsToolTipVisible(false);
    }
    clearTimeout(timer.current!);
  };

  useEffect(() => {
    return () => {
      clearTimeout(timer.current!);
    };
  }, []);

  return (
    <div
      className={`${classes.container} ${className ? className : ''}`}
      ref={anchorRef}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
      {isToolTipVisible && (
        <Tooltip
          isOpen={true}
          size="l"
          anchorRef={anchorRef}
          direction={direction ? direction : 'downLeft'}
          className={`${classes.toolTip} ${classNameTooltip ? classNameTooltip : ''}`}
          style={{ zIndex: zIndex ? zIndex : undefined }}
        >
          {isRemoveHTMLTags ? removeHTMLTags(title) : title}
        </Tooltip>
      )}
    </div>
  );
};

export default CustomHoverToolTip;
