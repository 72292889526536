import React from 'react';

import classes from './SystemCard.module.css';
import CardsContentBlock from '../CardsContentBlock/CardsContentBlock';
import { Text } from '@consta/uikit/Text';
import { BookmarkType } from '../../types/types';
import { useExistedOrCreateNewBookmark } from '../../hooks/useExistedOrCreateNewBookmark';
import { formatDateDDMMYYYYHHMM } from '../../utils/Formatters/formatDateDDMMYYYYHHMM';

interface ISystemCardProps {
  systemUuid?: string | null;
  systemName?: string | null;
  systemSourceIco?: string | null;
  systemSourceType?: string | null;
  connection_name?: string | null;
  last_scanned_at?: string | null;
  created_on_portal_ts?: string | null;
  updated_on_portal_ts?: string | null;
}

const SystemCard: React.FC<ISystemCardProps> = ({
  systemName,
  systemUuid,
  systemSourceIco,
  systemSourceType,
  connection_name,
  last_scanned_at,
  created_on_portal_ts,
  updated_on_portal_ts,
}) => {
  const doExistedOrCreateNewBookmark = useExistedOrCreateNewBookmark();

  const onClick = () => {
    const bookmark = {
      label: systemName,
      type: 'system',
      slug: 'system',
      hash: systemUuid,
    } as BookmarkType;
    doExistedOrCreateNewBookmark(bookmark);
  };

  return (
    <div className={classes.container}>
      <CardsContentBlock title={'Интегрируемая система'}>
        <Text className={classes.linkToSystem} onClick={onClick}>
          {systemName ? systemName : '-'}
        </Text>
      </CardsContentBlock>
      {systemSourceType && (
        <CardsContentBlock title={'Тип источника'}>
          <div className={classes.sourceContainer}>
            {systemSourceIco && (
              <img
                src={`data:image/svg+xml;base64,${systemSourceIco}`}
                className={classes.sourceIco}
                alt="source icon"
              />
            )}
            <Text className={classes.source}>{systemSourceType}</Text>
          </div>
        </CardsContentBlock>
      )}
      <CardsContentBlock title={'Подключение'}>
        <Text className={classes.subTitle}>{`Наименование`}</Text>
        <Text className={classes.subValue}>{connection_name ? connection_name : '-'}</Text>
        <Text className={classes.subTitle}>{`Последнее сканирование`}</Text>
        <Text className={classes.subValue}>
          {last_scanned_at ? formatDateDDMMYYYYHHMM(new Date(last_scanned_at)) : '-'}
        </Text>
      </CardsContentBlock>
      <CardsContentBlock title={'Создано'}>
        <Text className={classes.subValue}>
          {created_on_portal_ts ? formatDateDDMMYYYYHHMM(new Date(created_on_portal_ts)) : '-'}
        </Text>
      </CardsContentBlock>
      <CardsContentBlock title={'Изменено'}>
        <Text className={classes.subValue}>
          {updated_on_portal_ts ? formatDateDDMMYYYYHHMM(new Date(updated_on_portal_ts)) : '-'}
        </Text>
      </CardsContentBlock>
    </div>
  );
};

export default SystemCard;
