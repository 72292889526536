import { FiltersGroupType } from '../Components/FiltersGroup/FiltersGroup';

export const getQueryGroupFilters = (groupFilters: FiltersGroupType[]) => {
  const queries: string[] = [];
  groupFilters.forEach((groupFilter) => {
    groupFilter.groupFilters.forEach((item) => {
      if (item.isChecked) {
        queries.push(`filter[${item.name}]=true`);
      }
    });
  });
  return queries.join('&');
};
