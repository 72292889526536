import React, { useEffect, useRef, useState } from 'react';

import classes from './ConnectionsPage.module.css';
import CPViewSelectorRow from './CPViewSelectorRow/CPViewSelectorRow';
import CPContentSelector from './CPContentSelector/CPContentSelector';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import {
  clearConnections,
  clearRunningScannersUuids,
  clearSnackBarItemsConnections,
  clearTableConnectionsActiveRowUuid,
  deleteSnackBarItemConnections,
  selectorIsErrorConnections,
  selectorSnackBarItemsConnections,
} from '../../store/connectionsSlice';
import ModalError from '../../Components/ModalError/ModalError';
import { SnackBar } from '@consta/uikit/SnackBar';
import { useGetConnections } from '../../hooks/useGetConnections';
import CPRightSideBar from './CPRightSideBar/CPRightSideBar';
import { LeftSideBarHandleType } from '../../shared-front/LeftSideBar/LeftSideBar';

export enum CPRightSideBarEnum {
  TuneTable = 'tune_table',
  DisplayConnection = 'display_connection',
  CreateConnection = 'create_connection',
  EditConnection = 'edit_connection',
}

const ConnectionsPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const refFirstUpload = useRef(false);
  const getConnections = useGetConnections();
  const refSidebar = useRef<LeftSideBarHandleType>(null);
  const snackBarItemsStore = useAppSelector(selectorSnackBarItemsConnections);
  const connectionsError = useAppSelector(selectorIsErrorConnections);

  const [rightSideBarMode, setRightSideBarMode] = useState<CPRightSideBarEnum | undefined>(undefined);

  const snackBarItems = snackBarItemsStore?.map((snackBarItem) => ({
    ...snackBarItem,
    onClose: (item: { key: string }) => {
      dispatch(deleteSnackBarItemConnections({ key: item.key }));
    },
  }));

  useEffect(() => {
    dispatch(clearConnections());
    dispatch(clearRunningScannersUuids());
    dispatch(clearSnackBarItemsConnections());
    dispatch(clearTableConnectionsActiveRowUuid());
  }, [dispatch]);

  useEffect(() => {
    if (!refFirstUpload.current) {
      refFirstUpload.current = true;
      getConnections({});
    }
  }, [getConnections]);

  useEffect(() => {
    document.title = `ТехМет Подключения`;
  }, []);

  return (
    <div className={classes.container}>
      <CPViewSelectorRow />
      <CPContentSelector refSidebar={refSidebar} setRightSideBarMode={setRightSideBarMode} />
      <CPRightSideBar
        refSidebar={refSidebar}
        rightSideBarMode={rightSideBarMode}
        setRightSideBarMode={setRightSideBarMode}
      />
      <ModalError errorMessage={connectionsError} />
      <div className={classes.snackBarContainer} style={{ zIndex: 1000 }}>
        <SnackBar items={snackBarItems ? snackBarItems : []} style={{ whiteSpace: 'pre-line' }} />
      </div>
    </div>
  );
};

export default ConnectionsPage;
