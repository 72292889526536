import { FiltersGroupType } from '../Components/FiltersGroup/FiltersGroup';

export const clearAllGroupFilters = (
  groupFilters: FiltersGroupType[],
  setGroupFilters: (filters: FiltersGroupType[]) => void
) => {
  setGroupFilters(
    groupFilters.map((filterGroup) => ({
      ...filterGroup,
      groupFilters: filterGroup.groupFilters.map((filter) => ({ ...filter, isChecked: false })),
    }))
  );
};
