import { createTableColumn } from '../../shared-front/Table/utils/createTableColumn';
import { ColumnGenericType } from '../../types/types';
import { ReactNode } from 'react';

export type ObjectsTableOdata1cDataType = {
  name?: string | null;
  hierarchy?: ReactNode | null;
  unified_object?: string | null;
  connector_type?: string | null;
  _create_ts?: string | null;
  _alter_ts?: string | null;
  description_name?: string | null;
  object?: string | null;
  object_attr?: string | null;
  attr_type?: string | null;
  object_kind?: string | null;
  description_value?: string | null;
  organization?: string | null;
  full_name?: string | null;
  table_part?: string | null;
  ts_alter?: string | null;
  ts_create?: string | null;
};

export const OBJECTS_TABLE_ODATA1C_COLUMNS: ColumnGenericType[] = [
  createTableColumn<ObjectsTableOdata1cDataType>({
    accessorKey: 'connector_type',
    title: 'Тип источника',
    textLinesNumber: 2,
    isShow: true,
    size: 300,
  }),
  createTableColumn<ObjectsTableOdata1cDataType>({
    accessorKey: 'name',
    title: 'Наименование',
    textLinesNumber: 2,
    isShow: true,
    size: 300,
  }),
  createTableColumn<ObjectsTableOdata1cDataType>({
    accessorKey: 'unified_object',
    title: 'Тип',
    textLinesNumber: 2,
    isShow: true,
    size: 300,
  }),
  createTableColumn<ObjectsTableOdata1cDataType>({
    accessorKey: 'hierarchy',
    title: 'Иерархия',
    textLinesNumber: 2,
    isShow: true,
    size: 300,
  }),
  createTableColumn<ObjectsTableOdata1cDataType>({
    accessorKey: 'description_name',
    title: 'Краткое описание',
    textLinesNumber: 2,
    isShow: true,
    size: 300,
  }),
  createTableColumn<ObjectsTableOdata1cDataType>({
    accessorKey: '_create_ts',
    title: 'Создано',
    textLinesNumber: 2,
    isShow: true,
    size: 300,
  }),
  createTableColumn<ObjectsTableOdata1cDataType>({
    accessorKey: '_alter_ts',
    title: 'Изменено',
    textLinesNumber: 2,
    isShow: true,
    size: 300,
  }),
  createTableColumn<ObjectsTableOdata1cDataType>({
    accessorKey: 'object',
    title: 'Объект данных',
    textLinesNumber: 2,
    isShow: false,
    size: 300,
  }),
  createTableColumn<ObjectsTableOdata1cDataType>({
    accessorKey: 'table_part',
    title: 'Табличная часть',
    textLinesNumber: 2,
    isShow: false,
    size: 300,
  }),
  createTableColumn<ObjectsTableOdata1cDataType>({
    accessorKey: 'full_name',
    title: 'Полное наименование',
    textLinesNumber: 2,
    isShow: false,
    size: 300,
  }),
  createTableColumn<ObjectsTableOdata1cDataType>({
    accessorKey: 'object_kind',
    title: 'Тип объекта',
    textLinesNumber: 2,
    isShow: false,
    size: 300,
  }),
  createTableColumn<ObjectsTableOdata1cDataType>({
    accessorKey: 'description_value',
    title: 'Расширенное описание',
    textLinesNumber: 2,
    isShow: false,
    size: 300,
  }),
];

// createTableColumn<ObjectsTableOdata1cDataType>({
//   accessorKey: 'object_attr',
//   title: 'Атрибут',
//   textLinesNumber: 2,
//   isShow: false,
//   size: 300,
// }),
// createTableColumn<ObjectsTableOdata1cDataType>({
//   accessorKey: 'attr_type',
//   title: 'Тип данных атрибута',
//   textLinesNumber: 2,
//   isShow: false,
//   size: 300,
// }),
// createTableColumn<ObjectsTableOdata1cDataType>({
//   accessorKey: 'organization',
//   title: 'Организация',
//   textLinesNumber: 2,
//   isShow: false,
//   size: 300,
// }),
