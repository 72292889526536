import React from 'react';

import classes from './TableContainer.module.css';
import { Table } from '../../../../../../../shared-front/Table/Table';
import { TABLE_VIEW_TABLE_FUNCTION_COLUMNS, TableViewTableFunctionDataType } from './tableViewTableFunctionColumns';
import { useAppSelector } from '../../../../../../../hooks/hooks';
import { selectorCatalogsIsLoadingAttributesData } from '../../../../../../../store/catalogSlice';
import { Loader } from '@consta/uikit/Loader';
import { BookmarkTableViewTableFunctionType, BookmarkType } from '../../../../../../../types/types';
import AttributeTableType from '../../../../../../../Components/AttributeTableType/AttributeTableType';
import { Text } from '@consta/uikit/Text';
import { useExistedOrCreateNewBookmark } from '../../../../../../../hooks/useExistedOrCreateNewBookmark';

interface ITableContainerProps {
  bookmark: BookmarkType;
}

const TableContainer: React.FC<ITableContainerProps> = ({ bookmark }) => {
  const doExistedOrCreateNewBookmark = useExistedOrCreateNewBookmark();
  const isLoading = useAppSelector(selectorCatalogsIsLoadingAttributesData);
  const data = bookmark.data as BookmarkTableViewTableFunctionType | undefined;

  const tableData: TableViewTableFunctionDataType[] =
    data?.attributesData?.data.map((attribute) => {
      return {
        type: (
          <AttributeTableType
            type={attribute.type}
            is_in_primary_key={!!attribute.is_in_primary_key}
            is_in_foreign_key={!!attribute.is_in_foreign_key}
          />
        ),
        name: attribute.name || '',
        unified_data_type: attribute.unified_data_type || '',
        dataType: attribute.attribute_type || '',
        short_description: attribute.short_description || '',
        description: attribute.description || '',
        comment: attribute.comment || '',
        not_null: attribute.not_null || '',
        default_value: attribute.default_value || '',
        num: attribute.num || '',
        uuid: attribute._uuid,
      };
    }) || [];

  const onRowClick = (originalRow: { [key: string]: unknown }) => {
    const item = data?.attributesData?.data.find((item) => item._uuid === originalRow.uuid);
    if (item?.name) {
      const newBookmark = {
        label: item.name,
        type: bookmark.type,
        slug: 'attr',
        hash: item._uuid,
      } as BookmarkType;
      doExistedOrCreateNewBookmark(newBookmark);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.tableContainer}>
        {isLoading ? (
          <Loader />
        ) : !!tableData.length ? (
          <div className={classes.tableWrapper}>
            <Table
              data={tableData}
              columns={TABLE_VIEW_TABLE_FUNCTION_COLUMNS}
              isColumnsResizable={true}
              isRowHover={true}
              onRowClick={onRowClick}
            />
          </div>
        ) : (
          <Text className={classes.nothingFound}>{`Ничего не найдено`}</Text>
        )}
      </div>
    </div>
  );
};

export default TableContainer;
