import { instanceAxios } from './instanceAxios';
import { LaunchHistoryResponseType } from './serverResponse';

export let abortControllerLaunchHistory = new AbortController();

export const launchHistoryApi = {
  async getLaunchHistory(requestQuery: string, selectedConnectionId?: string) {
    return instanceAxios
      .get<LaunchHistoryResponseType>(
        `/connections/history${selectedConnectionId ? '/' + selectedConnectionId : ''}${
          requestQuery ? requestQuery : ''
        }`
      )
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        if (e.name === 'CanceledError') {
          abortControllerLaunchHistory = new AbortController();
          return {
            data: [],
            meta: { from: 0, to: 0, total: 0, per_page: 0, current_page: 0, last_page: 0 },
          } as LaunchHistoryResponseType;
        } else {
          throw new Error(e.message);
        }
      });
  },
};
