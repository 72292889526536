import React from 'react';

import classes from './SectionInDevelopment.module.css';

interface ISectionInDevelopmentProps {
  title?: string;
}

const SectionInDevelopment: React.FC<ISectionInDevelopmentProps> = ({ title = '' }) => {
  return <div className={classes.container}>{`Раздел ${title} еще в разработке`}</div>;
};

export default SectionInDevelopment;
