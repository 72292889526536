import React, { useState } from 'react';

import { FilterBarItemType } from '../FilterBar';
import classes from './FilterBarModalForm.module.css';
import { Button } from '@consta/uikit/Button';
import { Tag } from '@consta/uikit/Tag';
import { Text } from '@consta/uikit/Text';

interface IFilterBarModalFormProps {
  items: FilterBarItemType[];
  onSave: (items: FilterBarItemType[]) => void;
  onClose: () => void;
}

const FilterBarModalForm: React.FC<IFilterBarModalFormProps> = ({ items, onSave, onClose }) => {
  const [itemsState, setItemsState] = useState(items);

  const onCancel = (item: FilterBarItemType) => {
    setItemsState(itemsState.filter((curItem) => curItem.uuid !== item.uuid));
  };

  const onClickSave = () => {
    onSave(itemsState);
    onClose();
  };

  return (
    <div className={classes.container}>
      <Text className={classes.title}>{'Примененные фильтры'}</Text>
      <div className={classes.delimiter} />
      <div className={classes.tagsList}>
        {itemsState.map((item, ind) => (
          <Tag
            key={ind}
            mode="cancel"
            size={'xs'}
            onCancel={() => {
              onCancel(item);
            }}
            label={item.title}
          />
        ))}
        {itemsState.length === 0 && <Text className={classes.noItems}>{'Сбросить все'}</Text>}
      </div>
      <div className={classes.delimiter} />
      <div className={classes.btnRow}>
        <Button view={'ghost'} label="Отменить" onClick={onClose} />
        <Button
          view={'ghost'}
          label="Сохранить"
          onClick={onClickSave}
          disabled={itemsState?.length === items?.length}
        />
      </div>
    </div>
  );
};

export default FilterBarModalForm;
