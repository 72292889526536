import React from 'react';

import classes from './LaunchHistoryButtonLink.module.css';
import { Button } from '@consta/uikit/Button';
import { IconOpenInNew } from '@consta/uikit/IconOpenInNew';
import { openURLinNewTab } from '../../../../../../../utils/openURLinNewTab';

interface ILaunchHistoryButtonLinkProps {
  airflowLink?: string | null;
}

const LaunchHistoryButtonLink: React.FC<ILaunchHistoryButtonLinkProps> = ({ airflowLink }) => {
  if (!airflowLink) {
    return null;
  }

  const onClick = () => {
    openURLinNewTab(airflowLink);
  };

  return (
    <div className={classes.container}>
      <Button iconRight={IconOpenInNew} onClick={onClick} onlyIcon view={'ghost'} size={'xs'} />
    </div>
  );
};

export default LaunchHistoryButtonLink;
