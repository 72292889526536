import { CreateNewConnectionsSliceType } from '../createNewConnectionSlice';

export const validateStepTwoOdata = (state: CreateNewConnectionsSliceType) => {
  let validateError = false;

  if (!state.connectionUrl) {
    validateError = true;
    state.isRequiredConnectionUrl = true;
  }
  if (!state.connectionUser) {
    if (!validateError) {
      validateError = true;
    }
    state.isRequiredConnectionUser = true;
  }
  if (!state.connectionPassword) {
    if (!validateError) {
      validateError = true;
    }
    state.isRequiredConnectionPassword = true;
  }
  if (!state.isNewConnectionChecked) {
    if (!validateError) {
      validateError = true;
    }
    state.isRequiredNewConnectionChecked = true;
  }

  return validateError;
};
