import React from 'react';

import classes from './CreateProgressStepBar.module.css';
import { ProgressStepBar } from '@consta/uikit/ProgressStepBar';
import { useAppSelector } from '../../../../../hooks/hooks';
import { selectorActiveStepIndex } from '../../../../../store/createNewConnectionSlice';

type Item = {
  label: string;
};

const steps: Item[] = [
  {
    label: 'Общие',
  },
  {
    label: 'Настройки',
  },
  {
    label: 'Наименование',
  },
  {
    label: 'Расширенные',
  },
  {
    label: 'Расписание',
  },
];

interface ICreateProgressStepBarProps {}

const CreateProgressStepBar: React.FC<ICreateProgressStepBarProps> = () => {
  const activeStepIndex = useAppSelector(selectorActiveStepIndex);
  return (
    <div className={classes.container}>
      <ProgressStepBar size="xs" steps={steps} activeStepIndex={activeStepIndex} />
    </div>
  );
};

export default CreateProgressStepBar;
