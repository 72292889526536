import { CatalogSectionType, ObjectTypesType } from '../../types/types';

export const getObjectTypeTabs = (objectTypes?: [ObjectTypesType]) => {
  return objectTypes?.map((section) => {
    const catalogSection: CatalogSectionType = {
      id: section.objectType,
      shortName: section.objectType,
      label: section.name,
      disabled: !section.hasObjects,
      slug: section.objectType,
    };
    return catalogSection;
  });
};
