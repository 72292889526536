import React from 'react';

import classes from './ISPControlRow.module.css';
import { Button } from '@consta/uikit/Button';
import { IconAdd } from '@consta/uikit/IconAdd';
import { IconSettings } from '@consta/uikit/IconSettings';
import { selectorSearchValueInfoSystems, setSearchValueInfoSystems } from '../../../store/infoSystemsSlice';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { LeftSideBarHandleType } from '../../../shared-front/LeftSideBar/LeftSideBar';
import { IspRightSideBarEnum } from '../InfoSystemsPage';
import TextSearchArea from '../../../shared-front/TextSearchArea/TextSearchArea';
import { useGetInfoSystems } from '../../../hooks/useGetInfoSystems';

interface IISPControlRowProps {
  refSidebar: React.RefObject<LeftSideBarHandleType>;
  setRightSideBarMode: (mode: IspRightSideBarEnum | undefined) => void;
}

const ISPControlRow: React.FC<IISPControlRowProps> = ({ refSidebar, setRightSideBarMode }) => {
  const dispatch = useAppDispatch();
  const searchValue = useAppSelector(selectorSearchValueInfoSystems);

  const getInfoSystems = useGetInfoSystems();

  const onClickButtonTableTune = () => {
    setRightSideBarMode(IspRightSideBarEnum.TuneTable);
    const isSidebarOpen = refSidebar.current?.getIsSidebarOpen();
    if (!isSidebarOpen) {
      refSidebar.current?.openSidebar();
    }
  };

  const onClickButtonCreateSystem = () => {
    setRightSideBarMode(IspRightSideBarEnum.CreateSystem);
    const isSidebarOpen = refSidebar.current?.getIsSidebarOpen();
    if (!isSidebarOpen) {
      refSidebar.current?.openSidebar();
    }
  };

  const setSearchValue = (value: string) => {
    dispatch(setSearchValueInfoSystems(value));
  };

  const onSearch = (value: { searchValue?: string }) => {
    getInfoSystems(value);
  };

  return (
    <div className={classes.container}>
      <Button label="Создать" view="secondary" iconLeft={IconAdd} size={'s'} onClick={onClickButtonCreateSystem} />
      <div className={classes.rightColumn}>
        <TextSearchArea searchValue={searchValue} setSearchValue={setSearchValue} onSearch={onSearch} />
        <Button
          label="Настройки"
          view="ghost"
          size={'s'}
          iconLeft={IconSettings}
          onlyIcon={true}
          onClick={onClickButtonTableTune}
        />
      </div>
    </div>
  );
};

export default ISPControlRow;
