import React from 'react';

import classes from './LaunchHistoryPaginationRow.module.css';
import { useAppSelector } from '../../../../../hooks/hooks';
import Pagination from '../../../../../shared-front/Pagination/Pagination';
import { useGetLaunchHistory } from '../../../../../hooks/useGetLaunchHistory';
import { selectorLaunchHistory } from '../../../../../store/launchHistorySlice';

interface ILaunchHistoryPaginationRowProps {}

const LaunchHistoryPaginationRow: React.FC<ILaunchHistoryPaginationRowProps> = () => {
  const getLaunchHistory = useGetLaunchHistory();
  const meta = useAppSelector(selectorLaunchHistory)?.meta;

  const onChangePagination = (queryPagination: string) => {
    getLaunchHistory({ queryPagination });
  };

  return (
    <div className={classes.container}>
      <Pagination
        currentPageSize={meta?.per_page}
        currentPage={meta?.current_page}
        total={meta?.total}
        className={classes.pagination}
        onChangePagination={onChangePagination}
        lastPage={meta?.last_page}
      />
    </div>
  );
};

export default LaunchHistoryPaginationRow;
