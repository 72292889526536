import React, { useRef, useState } from 'react';
import classes from './ControllRow.module.css';

import iconTree from '../../../../../assets/CatalogPage/iconTree.svg';
import iconTreeWhite from '../../../../../assets/CatalogPage/iconTreeWhite.svg';

import { Button } from '@consta/uikit/Button';
import SortingSelector from './SortingSelector/SortingSelector';
import ViewSelector, { VIEW_SELECTOR_ITEMS } from './ViewSelector/ViewSelector';
import FiltersButton from './FiltersButton/FiltersButton';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/hooks';
import {
  selectorCatalogActiveViewSelectorName,
  selectorCatalogIsHierarchyOpen,
  toggleIsOpenHierarchy,
} from '../../../../../store/catalogSlice';
import {
  getMetaHintsSearchThunk,
  resetSearchHints,
  selectorMetaSearchValue,
  setSearchValue,
} from '../../../../../store/metaSlice';
import { useGetTechMetaDocuments } from '../../../../../hooks/useGetTechMetaDocuments';
import TableSettingsButton from './TableSettingsButton/TableSettingsButton';
import SearchRow from '../../../../MainPage/MainPageHero/SearchRow/SearchRow';
import useDebouncedFunction from '../../../../../shared-front/hooks/useDebounceFunction';
import { removeHTMLTags } from '../../../../../utils/Formatters/removeHTMLTags';
import { LeftSideBarHandleType } from '../../../../../shared-front/LeftSideBar/LeftSideBar';

interface IControlRow {}

const ControlRow: React.FC<IControlRow> = () => {
  const dispatch = useAppDispatch();
  const getTechMetaDocuments = useGetTechMetaDocuments();
  const isHierarchyOpen = useAppSelector(selectorCatalogIsHierarchyOpen);
  const activeViewSelectorName = useAppSelector(selectorCatalogActiveViewSelectorName);
  const refSidebar = useRef<LeftSideBarHandleType>(null);
  const isTableView = activeViewSelectorName === VIEW_SELECTOR_ITEMS[1].name;

  const clickHandler = () => {
    dispatch(toggleIsOpenHierarchy());
  };

  const searchValue = useAppSelector(selectorMetaSearchValue);
  const [showSearchHints, setShowSearchHints] = useState(false);
  const [clearDebounce, setClearDebounce] = useState(false);

  const sendRequest = useDebouncedFunction(
    (value) => {
      if (value) {
        setShowSearchHints(true);
        dispatch(getMetaHintsSearchThunk(value));
      }
    },
    500,
    clearDebounce
  );

  const changeHandler = (value: string) => {
    setShowSearchHints(false);
    dispatch(resetSearchHints());
    dispatch(setSearchValue(value));
    sendRequest(value);
    if (clearDebounce) {
      setClearDebounce(false);
    }
  };

  const selectHandler = (value?: string) => {
    if (value) {
      const searchValue = removeHTMLTags(value);
      dispatch(setSearchValue(searchValue));
      getTechMetaDocuments({ searchValue });
    } else {
      dispatch(setSearchValue(''));
      getTechMetaDocuments({ searchValue: undefined });
    }
  };

  return (
    <div className={classes.container}>
      <Button
        label="Иерархия"
        view={isHierarchyOpen ? 'primary' : 'ghost'}
        size={'s'}
        iconLeft={() =>
          isHierarchyOpen ? (
            <img src={iconTreeWhite} style={{ marginTop: '1px', marginLeft: '2px', width: '16px' }} alt={'close'} />
          ) : (
            <img src={iconTree} style={{ marginRight: '12px', width: '12px' }} alt={'close'} />
          )
        }
        onClick={clickHandler}
        onlyIcon={isHierarchyOpen}
      />
      <SearchRow
        value={searchValue}
        onChange={changeHandler}
        onSelect={selectHandler}
        showSearchHints={showSearchHints}
        setShowSearchHints={setShowSearchHints}
        placeHolder={'Поиск по каталогу и карточкам'}
        isCatalogSearchView={true}
        setClearDebounce={setClearDebounce}
      />
      <SortingSelector />
      <TableSettingsButton isShow={isTableView} refSidebarExternal={refSidebar} />
      <FiltersButton />
      <ViewSelector />
    </div>
  );
};

export default ControlRow;
