import React from 'react';

import classes from './CatalogContent.module.css';
import HierarchyFilter from './HierarchyFilter/HierarchyFilter';
import ControlRow from './ControlRow/ControllRow';
import ActiveFiltersRow from './ActiveFiltersRow/ActiveFiltersRow';
import TabsRow from './TabsRow/TabsRow';
import CatalogDataContainer from './CatalogDataContainer/CatalogDataContainer';
import PaginationRow from './PaginationRow/PaginationRow';

interface ICatalogContentProps {}

const CatalogContent: React.FC<ICatalogContentProps> = () => {
  return (
    <div className={classes.container}>
      <div className={classes.leftColumn}>
        <HierarchyFilter />
      </div>
      <div className={classes.rightColumn}>
        <div className={classes.contentWrapper}>
          <ControlRow />
          <ActiveFiltersRow />
          <TabsRow />
        </div>
        <div className={classes.delimiter} />
        <CatalogDataContainer />
        <div className={classes.delimiter} />
        <PaginationRow />
      </div>
    </div>
  );
};

export default CatalogContent;
