import React, { useRef } from 'react';

import classes from './SearchRow.module.css';
import { Button } from '@consta/uikit/Button';
import { FieldGroup } from '@consta/uikit/FieldGroup';
import { IconSearch } from '@consta/uikit/IconSearch';
import { TextField } from '@consta/uikit/TextField';
import SearchHints from './SearchHints/SearchHints';
import { IconClose } from '@consta/uikit/IconClose';

interface ISearchContainerProps {
  value: string;
  onChange: (val: string) => void;
  onSelect: (val: string) => void;
  showSearchHints: boolean;
  setShowSearchHints: (val: boolean) => void;
  placeHolder?: string;
  isCatalogSearchView?: boolean;
  withoutSearchHints?: boolean;
  setClearDebounce?: (value: boolean) => void;
}

const SearchRow: React.FC<ISearchContainerProps> = ({
  value,
  placeHolder,
  onChange,
  onSelect,
  showSearchHints,
  setShowSearchHints,
  isCatalogSearchView,
  withoutSearchHints,
  setClearDebounce,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const hideHints = () => {
    setShowSearchHints(false);
  };

  const onChangeHandler = ({ value }: { value: string | null }) => {
    if (value) {
      onChange(value);
    } else {
      onChange('');
    }
  };

  const onSearchHandler = () => {
    onSelect(value);
    setClearDebounce && setClearDebounce(true);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.code === 'Enter') {
      onSelect(value);
      hideHints();
      setClearDebounce && setClearDebounce(true);
      (document.activeElement as HTMLElement).blur();
    }
  };

  const onSelectHint = (value: string) => {
    onSelect(value);
    hideHints();
  };

  const refIsMouseOverResetSearchBtn = useRef<boolean>(false);

  const ResetSearchBtn = () => {
    const onMouseEnter = () => {
      if (!refIsMouseOverResetSearchBtn.current) {
        refIsMouseOverResetSearchBtn.current = true;
      }
    };

    const onMouseLeave = () => {
      if (refIsMouseOverResetSearchBtn.current) {
        refIsMouseOverResetSearchBtn.current = false;
      }
    };

    const onClick = () => {
      onChange('');
    };

    return (
      <div ref={ref} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        <IconClose style={{ width: '12px', cursor: 'pointer', marginTop: '6px', color: '#00395C' }} onClick={onClick} />
      </div>
    );
  };

  const onFocus = () => {
    if (!showSearchHints && !withoutSearchHints && !refIsMouseOverResetSearchBtn) {
      onChange(value);
    }
  };

  return (
    <div className={`${classes.container} ${isCatalogSearchView ? classes.catalogSearchView : ''} `}>
      <FieldGroup size={isCatalogSearchView ? 's' : 'm'} style={{ width: '100%' }}>
        <TextField
          type="text"
          value={value}
          onChange={onChangeHandler}
          placeholder={placeHolder}
          style={{ width: '100%' }}
          leftSide={IconSearch}
          rightSide={value ? ResetSearchBtn : undefined}
          onKeyDown={handleKeyDown}
          onFocus={onFocus}
          size={'m'}
        />
        {isCatalogSearchView ? (
          <Button label="Найти" view={'secondary'} onClick={onSearchHandler} />
        ) : (
          <Button iconRight={IconSearch} onClick={onSearchHandler} onlyIcon />
        )}
      </FieldGroup>
      {showSearchHints && (
        <SearchHints value={value} hideHints={hideHints} onSelectHint={onSelectHint} refResetSearch={ref} />
      )}
    </div>
  );
};

export default SearchRow;
