import React from 'react';

import classes from './ConnectionsContent.module.css';
import ConnectionsContentControlRow from './ConnectionsContentControlRow/ConnectionsContentControlRow';
import ConnectionsContentContainer from './ConnectionsContentContainer/ConnectionsContentContainer';
import ConnectionsContentPaginationRow from './ConnectionsContentPaginationRow/ConnectionsContentPaginationRow';
import { LeftSideBarHandleType } from '../../../../shared-front/LeftSideBar/LeftSideBar';
import { CPRightSideBarEnum } from '../../ConnectionsPage';
import Delimiter from '../../../../shared-front/Delimiter/Delimiter';

interface IConnectionsContentProps {
  refSidebar: React.RefObject<LeftSideBarHandleType>;
  setRightSideBarMode: (mode: CPRightSideBarEnum | undefined) => void;
}

const ConnectionsContent: React.FC<IConnectionsContentProps> = ({ refSidebar, setRightSideBarMode }) => {
  return (
    <div className={classes.container}>
      <div className={classes.growContainer}>
        <ConnectionsContentControlRow refSidebar={refSidebar} setRightSideBarMode={setRightSideBarMode} />
        <Delimiter />
        <ConnectionsContentContainer refSidebar={refSidebar} setRightSideBarMode={setRightSideBarMode} />
      </div>
      <Delimiter />
      <ConnectionsContentPaginationRow />
    </div>
  );
};

export default ConnectionsContent;
