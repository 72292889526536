import React from 'react';

import classes from './EditConnectionSettings.module.css';
import EditCheckConnectionRow from './EditCheckConnectionRow/EditCheckConnectionRow';
import EditSettingsForm from './EditSettingsForm/EditSettingsForm';
import EditConnectionAssets from './EditConnectionAssets/EditConnectionAssets';
import EditConnectionFullAssetsRow from './EditConnectionFullAssetsRow/EditConnectionFullAssetsRow';

interface IEditConnectionSettingsProps {}

const EditConnectionSettings: React.FC<IEditConnectionSettingsProps> = () => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <EditSettingsForm />
        <EditCheckConnectionRow />
        <EditConnectionAssets />
        <EditConnectionFullAssetsRow />
      </div>
    </div>
  );
};

export default EditConnectionSettings;
