import React from 'react';
import classes from './ISPPaginationRow.module.css';
import Pagination from '../../../shared-front/Pagination/Pagination';
import { useAppSelector } from '../../../hooks/hooks';
import { selectorInfoSystems } from '../../../store/infoSystemsSlice';
import { useGetInfoSystems } from '../../../hooks/useGetInfoSystems';

const ISPPaginationRow: React.FC = () => {
  const catalogItems = useAppSelector(selectorInfoSystems);
  const getInfoSystems = useGetInfoSystems();

  const onChangePagination = (queryPagination: string) => {
    getInfoSystems({ queryPagination });
  };

  return (
    <div className={classes.container}>
      <Pagination
        currentPageSize={catalogItems?.meta.per_page}
        currentPage={catalogItems?.meta.current_page}
        total={catalogItems?.meta.total}
        className={classes.pagination}
        onChangePagination={onChangePagination}
        lastPage={catalogItems?.meta.last_page}
      />
    </div>
  );
};

export default ISPPaginationRow;
