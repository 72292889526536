import React, { ReactNode, useEffect, useRef, useState } from 'react';

import RowSelectionCell from './RowSelectionCell/RowSelectionCell';
import classes from './Table.module.css';
import type {
  ColumnDef,
  ColumnFiltersState,
  ColumnOrderState,
  Header,
  OnChangeFn,
  Row,
  RowSelectionState,
  SortingState,
  VisibilityState,
} from '@tanstack/react-table';
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';

export type TableColumnType = {
  title: string;
  isShow: boolean;
  accessorKey: string;
};

export type InnerRowsType<T> = {
  [uuid: string]: T[];
};

export type GeneralTableColumnType = object & TableColumnType;

interface ReactTableProps<T extends object & { id?: string }> {
  data: T[];
  columns: ColumnDef<T>[];
  isRowHover?: boolean;
  onRowClick?: (originalRow: T, e: React.MouseEvent) => void;
  visibleColumns?: VisibilityState;
  columnOrder?: ColumnOrderState;
  sortingState?: SortingState;
  setSortingState?: OnChangeFn<SortingState>;
  isRowSelectionActive?: boolean;
  enableMultiRowSelection?: boolean;
  rowSelectionState?: RowSelectionState;
  rowDataIdAttributeName?: string;
  onRowSelection?: (originalRow: T) => void;
  onAllRowsSelection?: () => void;
  isColumnsResizable?: boolean;
  defaultColumn?: Partial<ColumnDef<T, unknown>>;
  highlightedRowId?: string;
  columnFilters?: ColumnFiltersState;
  onToggleRowHover?: (originalRow: T | undefined) => void;
  rowSelectionMode?: 'include' | 'exclude';
  enableMultiSort?: boolean;
}

export const Table = <T extends object & { id?: string }>({
  data,
  columns,
  onRowClick,
  isRowHover,
  visibleColumns,
  columnOrder,
  sortingState,
  setSortingState,
  isRowSelectionActive,
  enableMultiRowSelection,
  rowSelectionState,
  onRowSelection,
  onAllRowsSelection,
  isColumnsResizable,
  defaultColumn,
  highlightedRowId,
  columnFilters,
  onToggleRowHover,
  rowSelectionMode = 'include',
  enableMultiSort = true,
}: ReactTableProps<T & { isOpenDropDownRow?: boolean; rowId?: string; dropDownRow?: ReactNode }>) => {
  const tableRef = useRef<HTMLTableElement>(null);
  const [tableContainerWidth, setTableContainerWidth] = useState<number | undefined>(undefined);

  const isSomeRowsSelected = rowSelectionState ? Object.values(rowSelectionState).find((value) => value) : false;

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getRowId: isRowSelectionActive ? (originalRow) => originalRow.id! : undefined,
    manualSorting: true,
    enableMultiSort,
    onSortingChange: setSortingState,
    columnResizeMode: 'onChange',
    defaultColumn: defaultColumn,
    state: {
      columnVisibility: visibleColumns,
      columnOrder: columnOrder,
      sorting: sortingState?.length ? sortingState : undefined,
      rowSelection: rowSelectionState,
      columnFilters: columnFilters,
    },
  });

  const handlerClick = (originalRow: T, e: React.MouseEvent) => {
    onRowClick && onRowClick(originalRow, e);
  };

  const handleSelectRow = (originalRow: T) => {
    onRowSelection && onRowSelection(originalRow);
  };

  const handleSelectAllRows = () => {
    onAllRowsSelection && onAllRowsSelection();
  };

  const tableWidthWithoutLastColumn = table
    .getVisibleLeafColumns()
    .slice(0, -1)
    .reduce((result, column) => result + column.getSize(), 0);

  const rowSelectionOffset = isRowSelectionActive ? (data.length ? 51 : 1) : 0;

  const lastColumnWidth = tableContainerWidth && tableContainerWidth - tableWidthWithoutLastColumn - rowSelectionOffset;

  const calculateHeaderSize = (header: Header<T, unknown>) =>
    header.index === table.getVisibleLeafColumns().length - 1 ? lastColumnWidth : header.getSize();

  const lastHeaderGroupIndex = table.getHeaderGroups().length - 1;

  useEffect(() => {
    if (tableRef.current) {
      const tableContainerWidth = tableRef.current.parentElement?.clientWidth;
      setTableContainerWidth(tableContainerWidth);
    }
  }, []);

  const onToggleRowHoverHandler = (
    row: Row<
      T & { isOpenDropDownRow?: boolean | undefined; rowId?: string | undefined; dropDownRow?: ReactNode }
    > | null
  ) => {
    onToggleRowHover && onToggleRowHover(row?.original);
  };

  return (
    <table
      className={classes.table}
      ref={tableRef}
      style={{ width: isColumnsResizable ? table.getTotalSize() : undefined }}
    >
      <thead>
        {table.getHeaderGroups().map((headerGroup) => {
          const isLastHeaderGroup = Number(headerGroup.id) === lastHeaderGroupIndex;

          return (
            <tr key={headerGroup.id}>
              {isRowSelectionActive && (
                <th className={isLastHeaderGroup ? classes.th : classes.th_placeholder}>
                  {enableMultiRowSelection && isLastHeaderGroup && onAllRowsSelection && (
                    <RowSelectionCell
                      enableMultiSelection={true}
                      checked={rowSelectionMode === 'exclude' && !isSomeRowsSelected}
                      intermediate={isSomeRowsSelected}
                      selectionFn={() => handleSelectAllRows()}
                      zIndex={100}
                    />
                  )}
                </th>
              )}
              {headerGroup.headers.map((header) => {
                const isPlaceholder = header.isPlaceholder;

                return (
                  <th
                    key={header.id}
                    className={isPlaceholder ? classes.th_placeholder : classes.th}
                    colSpan={header.colSpan}
                    style={{
                      minWidth: calculateHeaderSize(header),
                      width: header.getSize(),
                    }}
                  >
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    {isColumnsResizable && <div className={classes.resizer} onMouseDown={header.getResizeHandler()} />}
                  </th>
                );
              })}
            </tr>
          );
        })}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => {
          return (
            <React.Fragment key={row.id}>
              <tr
                onMouseEnter={() => onToggleRowHoverHandler(row)}
                onMouseLeave={() => onToggleRowHoverHandler(null)}
                onClick={(e) => handlerClick(row.original, e)}
                className={`${isRowHover ? classes.trHover : ''} ${
                  (highlightedRowId && highlightedRowId === row.original.id) ||
                  (rowSelectionState &&
                    ((rowSelectionMode === 'include' && row.getIsSelected()) ||
                      (rowSelectionMode === 'exclude' && !row.getIsSelected())))
                    ? classes.tr_highlighted
                    : ''
                } ${String(row.original.rowId)?.includes('innerRow') ? classes.innerRow : ''}`}
              >
                {isRowSelectionActive && (
                  <td className={classes.td}>
                    <RowSelectionCell
                      enableMultiSelection={enableMultiRowSelection}
                      checked={rowSelectionMode === 'include' ? row.getIsSelected() : !row.getIsSelected()}
                      selectionFn={() => handleSelectRow(row.original)}
                    />
                  </td>
                )}
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td
                      className={`${classes.td} ${isRowHover ? classes.tdRowHover : ''}`}
                      key={cell.id}
                      style={{
                        width: cell.column.getSize(),
                        maxWidth: isColumnsResizable ? cell.column.getSize() : undefined,
                      }}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  );
                })}
              </tr>
              {data.find((rowData) => rowData.rowId === row.id)?.isOpenDropDownRow && (
                <tr>
                  <td className={`${classes.td} ${isRowHover ? classes.tdRowHover : ''}`} colSpan={columns.length}>
                    {data.find((rowData) => rowData.rowId === row.id)?.dropDownRow}
                  </td>
                </tr>
              )}
            </React.Fragment>
          );
        })}
      </tbody>
    </table>
  );
};
