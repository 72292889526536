import React from 'react';

import classes from './ConnectionItemSettings.module.css';
import SystemObjectsSettings from './SystemObjectsSettings/SystemObjectsSettings';
import LimitsForSchemaSettings from './LimitsForSchemaSettings/LimitsForSchemaSettings';
import LimitsForObjectsSettings from './LimitsForObjectsSettings/LimitsForObjectsSettings';
import LimitsForPreviewSettings from './LimitsForPreviewSettings/LimitsForPreviewSettings';
import AdditionalSetting from './AdditionalSetting/AdditionalSetting';
import SendToEdmSettings from './SendToEdmSettings/SendToEdmSettings';
import { ConnectionSettingsItemType, InfoSystemType } from '../../types/types';
import InfoSystemSelectorExtManagement from '../InfoSystemSelectorExtManagement/InfoSystemSelectorExtManagement';
import InfoSystemSectionDisplay from '../InfoSystemSectionDisplay/InfoSystemSectionDisplay';
import InfoSystemSettingsSelector from '../InfoSystemSettingsSelector/InfoSystemSettingsSelector';

interface ICreateConnectionItemSettingsProps {
  connectionSettings: ConnectionSettingsItemType[] | undefined;
  setConnectionSettings: (connectionSettings: ConnectionSettingsItemType[] | undefined) => void;
  infoSystems: InfoSystemType[] | undefined;
  isModeDisplay?: boolean;
  isModeEdit?: boolean;
}

const ConnectionItemSettings: React.FC<ICreateConnectionItemSettingsProps> = ({
  connectionSettings,
  setConnectionSettings,
  isModeDisplay,
  infoSystems,
  isModeEdit,
}) => {
  return (
    <div className={classes.container}>
      {isModeDisplay ? (
        <InfoSystemSectionDisplay connectionSettings={connectionSettings} />
      ) : isModeEdit ? (
        <InfoSystemSettingsSelector
          connectionSettings={connectionSettings}
          setConnectionSettings={setConnectionSettings}
          infoSystems={infoSystems}
        />
      ) : (
        <InfoSystemSelectorExtManagement
          connectionSettings={connectionSettings}
          setConnectionSettings={setConnectionSettings}
        />
      )}

      <SystemObjectsSettings connectionSettings={connectionSettings} setConnectionSettings={setConnectionSettings} />
      <LimitsForSchemaSettings connectionSettings={connectionSettings} setConnectionSettings={setConnectionSettings} />
      <LimitsForObjectsSettings connectionSettings={connectionSettings} setConnectionSettings={setConnectionSettings} />
      <LimitsForPreviewSettings connectionSettings={connectionSettings} setConnectionSettings={setConnectionSettings} />
      <AdditionalSetting connectionSettings={connectionSettings} setConnectionSettings={setConnectionSettings} />
      <SendToEdmSettings connectionSettings={connectionSettings} setConnectionSettings={setConnectionSettings} />
    </div>
  );
};

export default ConnectionItemSettings;
