import { createTableColumn } from '../../../shared-front/Table/utils/createTableColumn';
import { ColumnDef } from '@tanstack/react-table';
import { ReactNode } from 'react';

// Основные поля
// --Наименование							  = name
// --Краткое описание						= name (pbs.v_met_description)
// --Расширенное описание			  = value (pbs.v_met_description)
// --Тип данных 							  = type
//
// Дополнительные поля
// --Допускает пустое значение	= not_null

export type TableOdata1CObjectAttributesType = {
  name?: ReactNode;
  short_description?: string | null;
  description?: string | null;
  type?: string | null;
  not_null?: string | null;
  uuid?: string;
  unified_data_type?: string | null;
};

export type TableOdata1CObjectAttributesColumnsType = ColumnDef<TableOdata1CObjectAttributesType> & {
  title: string;
  isShow: boolean;
  accessorKey: string;
};

export const TABLE_ODATA1C_OBJECT_ATTRIBUTES_COLUMNS: TableOdata1CObjectAttributesColumnsType[] = [
  createTableColumn<TableOdata1CObjectAttributesType>({
    accessorKey: 'name',
    title: 'Наименование',
    isShow: true,
    size: 300,
  }),
  createTableColumn<TableOdata1CObjectAttributesType>({
    accessorKey: 'unified_data_type',
    title: 'Унифицированный тип данных',
    size: 300,
    isShow: true,
  }),
  createTableColumn<TableOdata1CObjectAttributesType>({
    accessorKey: 'type',
    title: 'Тип данных',
    size: 300,
    isShow: true,
  }),
  createTableColumn<TableOdata1CObjectAttributesType>({
    accessorKey: 'short_description',
    title: 'Краткое описание',
    size: 300,
    isShow: true,
  }),
  createTableColumn<TableOdata1CObjectAttributesType>({
    accessorKey: 'description',
    title: 'Расширенное описание',
    size: 300,
    isShow: true,
  }),
  createTableColumn<TableOdata1CObjectAttributesType>({
    accessorKey: 'not_null',
    title: 'Допускает пустое значение',
    size: 300,
    isShow: true,
  }),
];
