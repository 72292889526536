import { HierarchyItemType } from '../../types/types';

const closeAllChildren = ({ hierarchyItem }: { hierarchyItem: HierarchyItemType }): HierarchyItemType => {
  return {
    ...hierarchyItem,
    isOpen: false,
    children: hierarchyItem.children
      ? hierarchyItem.children.map((child) => closeAllChildren({ hierarchyItem: child }))
      : undefined,
  };
};

export const closeAllHierarchyItems = ({
  hierarchyItems,
}: {
  hierarchyItems: HierarchyItemType[];
}): HierarchyItemType[] => {
  return hierarchyItems.map((hierarchyItem) => ({
    ...hierarchyItem,
    isOpen: false,
    children: hierarchyItem.children
      ? hierarchyItem.children.map((child) => closeAllChildren({ hierarchyItem: child }))
      : undefined,
  }));
};
