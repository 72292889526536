import React from 'react';

import classes from './CPRightSideBarBottomButtons.module.css';
import Delimiter from '../../../../shared-front/Delimiter/Delimiter';
import { Button } from '@consta/uikit/Button';
import CPRightSideBarModalCancel from './CPRightSideBarModalCancel/CPRightSideBarModalCancel';
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooks';
import {
  clearCreateNewConnection,
  createNewConnectionThunk,
  goBackActiveStepIndex,
  selectorActiveStepIndex,
  selectorIsCreatingNewConnection,
  validateAndGoForwardStep,
} from '../../../../store/createNewConnectionSlice';

interface ICPRightSideBarBottomButtonsProps {
  closeSideBar?: () => void;
}

const CPRightSideBarBottomButtons: React.FC<ICPRightSideBarBottomButtonsProps> = ({ closeSideBar }) => {
  const dispatch = useAppDispatch();
  const activeStepIndex = useAppSelector(selectorActiveStepIndex);
  const isCreatingNewConnection = useAppSelector(selectorIsCreatingNewConnection);

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const isFinalStep = activeStepIndex === 4;

  const onCancelClick = () => {
    setIsModalOpen(true);
  };

  const cancelButtonClick = () => {
    closeSideBar && closeSideBar();
    dispatch(clearCreateNewConnection());
  };

  const onConfirmModal = () => {
    cancelButtonClick();
  };

  const goBackClick = () => {
    dispatch(goBackActiveStepIndex());
  };

  const goForwardClick = () => {
    if (isFinalStep) {
      dispatch(createNewConnectionThunk());
    } else {
      dispatch(validateAndGoForwardStep());
    }
  };

  return (
    <div className={classes.container}>
      <Delimiter />
      <div className={classes.bottomButtons}>
        <Button size={'s'} view={'ghost'} label={'Отменить'} style={{ width: '140px' }} onClick={onCancelClick} />
        <div className={classes.buttonsGroupRight}>
          {activeStepIndex > 0 && (
            <Button size={'s'} view={'ghost'} label={'Назад'} style={{ width: '140px' }} onClick={goBackClick} />
          )}
          <Button
            size={'s'}
            label={isFinalStep ? 'Создать' : 'Далее'}
            style={{ width: '140px' }}
            onClick={goForwardClick}
            loading={isCreatingNewConnection}
          />
        </div>
      </div>
      <CPRightSideBarModalCancel
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        modalTitle={'Внимание'}
        modalMessage={'Прогресс заполнения будет утерян'}
        onConfirm={onConfirmModal}
      />
    </div>
  );
};

export default CPRightSideBarBottomButtons;
