import React, { useEffect } from 'react';

import classes from './Unify1CObjectAttributesTab.module.css';
import { Bookmark1CObject, BookmarkType } from '../../types/types';
import { useAppDispatch } from '../../hooks/hooks';
import { combineQuery } from '../../utils/combineQuery';
import { getBookmarkAttributesDataThunk } from '../../store/catalogSlice';
import TableContainer1CObject from './TableContainer1CObject/TableContainer1CObject';
import Delimiter from '../../shared-front/Delimiter/Delimiter';
import Pagination from '../../shared-front/Pagination/Pagination';
import { START_PAGINATION_QUERY } from '../../utils/constants';

interface IRdbObjectsTabProps {
  bookmark: BookmarkType;
}

const Unify1CObjectAttributesTab: React.FC<IRdbObjectsTabProps> = ({ bookmark }) => {
  const dispatch = useAppDispatch();

  const data = bookmark.data as Bookmark1CObject | undefined;

  useEffect(() => {
    if (!data?.attributesData) {
      const requestQuery = combineQuery([START_PAGINATION_QUERY]);
      dispatch(getBookmarkAttributesDataThunk({ bookmark, requestQuery }));
    }
  }, [dispatch, bookmark, data?.attributesData]);

  const onChangePagination = (paginationQuery: string) => {
    const requestQuery = combineQuery([paginationQuery]);
    dispatch(getBookmarkAttributesDataThunk({ bookmark, requestQuery }));
  };

  return (
    <div className={classes.container}>
      <div className={classes.container}>
        <TableContainer1CObject bookmark={bookmark} />
        <Delimiter />
        <div className={classes.paginationContainer}>
          <Pagination
            currentPageSize={data?.attributesData?.meta.per_page}
            currentPage={data?.attributesData?.meta.current_page}
            total={data?.attributesData?.meta.total}
            onChangePagination={onChangePagination}
            lastPage={data?.attributesData?.meta.last_page}
          />
        </div>
      </div>
    </div>
  );
};

export default Unify1CObjectAttributesTab;
