import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import { TableColumnType } from '../../../Table/Table';
import classes from './ColumnsTuneListItem.module.css';
import { Checkbox } from '@consta/uikit/Checkbox';
import { IconDraggable } from '@consta/uikit/IconDraggable';

interface IColumnsTuneListItemProps {
  column: object & TableColumnType & { id: string };
  onChangeShowColumn: (val: string) => void;
  moveColumn: (dragColumn: object & TableColumnType, hoverColumn: object & TableColumnType) => void;
}

const ColumnsTuneListItem: React.FC<IColumnsTuneListItemProps> = ({ column, onChangeShowColumn, moveColumn }) => {
  const ref = useRef(null);

  const onChange = () => {
    onChangeShowColumn(column.accessorKey);
  };

  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: 'column',
      item: column,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (dragColumn, monitor) => {
        const didDrop = monitor.didDrop();
        if (!didDrop) {
          moveColumn(dragColumn, column);
        }
      },
    }),
    [column]
  );

  const [, drop] = useDrop(
    () => ({
      accept: 'column',
      hover(hoverColumn: object & TableColumnType) {
        if (hoverColumn.accessorKey && hoverColumn.accessorKey !== column.accessorKey) {
          moveColumn(hoverColumn, column);
        }
      },
    }),
    [moveColumn]
  );

  drag(drop(ref));

  return (
    <div ref={preview} className={classes.container} style={{ opacity: isDragging ? 0 : 1 }}>
      <div ref={ref} className={classes.icoDraggable}>
        <IconDraggable
          size={'s'}
          style={{ marginRight: '12px', marginTop: '4px', cursor: isDragging ? 'grabbing' : 'grab' }}
        />
      </div>
      <Checkbox checked={column.isShow} label={column.title} onChange={onChange} />
    </div>
  );
};

export default ColumnsTuneListItem;
