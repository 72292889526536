import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from './hooks';
import { getMetaAssetsByTypeThunk, selectorMetaAssetsByType } from '../store/metaSlice';
import { selectorCatalogActiveSection, setActiveSection } from '../store/catalogSlice';
import { CatalogSectionType } from '../types/types';

export const useCatalogActiveSectionForDefaultStart = () => {
  const dispatch = useAppDispatch();
  const metaAssetsByType = useAppSelector(selectorMetaAssetsByType);
  const catalogActiveSection = useAppSelector(selectorCatalogActiveSection);

  useEffect(() => {
    if (metaAssetsByType.length > 0) {
      if (!catalogActiveSection) {
        const catalogSection: CatalogSectionType = {
          id: metaAssetsByType[0].slug,
          shortName: metaAssetsByType[0].short_name,
          label: metaAssetsByType[0].name,
          disabled: !metaAssetsByType[0].assets_count,
          slug: metaAssetsByType[0].slug,
        };
        dispatch(setActiveSection(catalogSection));
      }
    } else {
      dispatch(getMetaAssetsByTypeThunk());
    }
  }, [dispatch, metaAssetsByType, catalogActiveSection]);
};
